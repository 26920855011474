import { initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithCustomToken, signInWithPopup } from 'firebase/auth';
import { connectDatabaseEmulator, get, getDatabase, limitToFirst, limitToLast, onValue, orderByKey, push, query, ref, set } from "firebase/database";
import { useEffect, useState } from 'react';
import { nullA } from './stdtypes';

var app = null;
var auth = null;
var database = null;
var global_firebaseUser = null;
var global_fbuser_watchers = [];

function wrapDOMException(error, context) {
    if (error instanceof DOMException) {
        return new Error(`DOM Exception (${context}): ${error.message} [${error.name}]`);
    } else {
        return error;
    }
}

export function setFirebaseConfig(deploymentConfig) {
    try {
        const firebaseConfig = deploymentConfig.firebaseConfig;
        app = initializeApp(firebaseConfig);
        auth = getAuth(app);
        database = getDatabase(app);

        if (deploymentConfig.emulateFirebase) {
            const host = window.location.hostname;
            console.log('Using local database and auth emulator');
            connectDatabaseEmulator(database, host, 9000);
            connectAuthEmulator(auth, `http://${host}:9099`);
        }

        onAuthStateChanged(auth, (user) => {
            global_firebaseUser = user;
            global_fbuser_watchers.forEach(watcher => watcher(user));
        });    
    } catch (error) {
        throw wrapDOMException(error, 'firebase initialization');
    }
}

export function getFirebaseApp() {
    return app;

}

export function getFirebaseUser() {
    return global_firebaseUser;
}

export function useFirebaseUser() {
    const [user, setUser] = useState(global_firebaseUser);
    useEffect(() => {
        try {
            const unsubscribe = onAuthStateChanged(auth, (user) => {
                global_firebaseUser = user;
                setUser(user);
            });
            return unsubscribe;
        } catch (error) {
            throw wrapDOMException(error, 'auth state change');
        }
    }, []);
    return user;           
}

export async function getFirebaseIdTokenAsync() {
    try {
        return await auth.currentUser?.getIdToken() || null;
    } catch (error) {
        throw wrapDOMException(error, 'getting ID token');
    }
}

export function firebaseSignOut() {
    try {
        return auth.signOut();
    } catch (error) {
        throw wrapDOMException(error, 'sign out');
    }
}

export function onFbUserChanged(callback) {
    global_fbuser_watchers.push(callback);
    return () => {
        global_fbuser_watchers = global_fbuser_watchers.filter(watcher => watcher !== callback);
    }
}

export function firebaseNewKey() {
    try {
        return push(ref(database)).key;
    } catch (error) {
        throw wrapDOMException(error, 'generating new key');
    }
}

export function firebaseWriteAsync(pathList, data) {
    try {
        const pathString = makeFirebasePath(pathList);
        return set(ref(database, pathString), data);
    } catch (error) {
        throw wrapDOMException(error, 'database write');
    }
}

export function firebaseWatchValue(pathList, callback) {
    try {
        const pathString = makeFirebasePath(pathList);
        return onValue(ref(database, pathString), snapshot => {
            callback(snapshot.val())
        }, error => {
            throw wrapDOMException(error, 'database watch callback');
        });
    } catch (error) {
        throw wrapDOMException(error, 'setting up database watch');
    }
}

// This needs to cope with temporarily null path elements, so that we can
// use it to look up properties of a user, even when the user isn't logged in yet.
export function useFirebaseData(pathList, {defaultValue=nullA, limit=null, oldest=false, once=false, memoized=false}={}) {
    const [data, setData] = useState(undefined);
    const pathString = makeFirebasePath(pathList, true);
    const nullPath = pathList.some(p => p == null || p == undefined);

    useEffect(() => {
        if (nullPath) return;

        try {
            if (memoized) {
                getFirebaseDataMemoizedAsync(pathList).then(data => {
                    setData(data || defaultValue);
                });
            } else if (once) {
                getFirebaseDataAsync(pathList).then(data => {
                    setData(data || defaultValue);
                });
            } else {
                let dbRef = ref(database, pathString);
                if (limit && oldest) {
                    dbRef = query(dbRef, orderByKey(), limitToFirst(limit));
                } else if (limit && !oldest) {
                    dbRef = query(dbRef, orderByKey(), limitToLast(limit));
                }

                const unsubscribe = onValue(dbRef, snapshot => {
                    setData(snapshot.val() || defaultValue)
                });
                return unsubscribe;
            }
        } catch (error) {
            throw wrapDOMException(error, 'useFirebaseData');
        }
    }, [pathString, once, limit, oldest]);
    return data;
}

var global_cached_data_promises = {};
export async function getFirebaseDataMemoizedAsync(pathList) {
    try {
        const pathString = makeFirebasePath(pathList);
        if (!global_cached_data_promises[pathString]) {
            global_cached_data_promises[pathString] = getFirebaseDataAsync(pathList);
        }
        return await global_cached_data_promises[pathString];
    } catch (error) {
        throw wrapDOMException(error, 'memoized data fetch');
    }
}

export async function getFirebaseDataAsync(pathList) {
    try {
        const pathString = makeFirebasePath(pathList);
        const snapshot = await get(ref(database, pathString));
        return snapshot.exists() ? snapshot.val() : null;
    } catch (error) {
        throw wrapDOMException(error, 'data fetch');
    }
}

function makeFirebasePath(path, skipCheck=false) {
    if (typeof path == 'string') {
        return path;
    } else {
        if (!skipCheck && path.some(p => !p)) {
            console.error('Bad firebase path', path, skipCheck);
            throw new Error('Firebase path cannot contain null or undefined elements: ' + JSON.stringify(path));
        }
        return path.join('/');
    }
}

export function fbKeyToString(input) {
    const reverseMapping = {
        '%d': '.',
        '%h': '#',
        '%s': '$',
        '%f': '/',
        '%l': '[',
        '%r': ']',
        '%%': '%',
        '%q': "'"
    };

    return input.replace(/%d|%h|%s|%f|%l|%r|%%|%q/g, match => reverseMapping[match]);
}

export function stringToFbKey(input) {
    const mapping = {
        '.': '%d',
        '#': '%h',
        '$': '%s',
        '/': '%f',
        '[': '%l',
        ']': '%r',
        '%': '%%',
        "'": '%q'
    };

    return input.replace(/[.$#/[\]%]/g, match => mapping[match]);
}

export function signInWithGoogle() {
    try {
        const provider = new GoogleAuthProvider();
        return signInWithPopup(auth, provider);
    } catch (error) {
        throw wrapDOMException(error, 'Google sign in');
    }
}

export async function signInWithTokenAsync(token) {
    try {
        return await signInWithCustomToken(auth, token);
    } catch (error) {
        throw wrapDOMException(error, 'custom token sign in');
    }
}


export function resetMockFirebaseData() {
    console.error('This should only be called in the mock version');
}
