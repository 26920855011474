import { REPLACE_ZDF_FilterButton } from "../../component/zdf/button";
import { useState } from "react";
import { CLICK, DemoSection, SpacedArray } from "../../system/demo";
import { UtilityText } from "../../component/text";
import { colorTextSecondary } from "../../component/color";

const filters = [
    {
        emoji: "🦖",
        text: "!Theropods",
    },
    {
        emoji: "🦕",
        text: "!Sauropods",
    },
    {
        emoji: "🐘",
        text: "!Mammals",
    },
];

function FilterButtonDemo() {
    const [selectedFilters, setSelectedFilters] = useState([filters[0]]);

    function toggleFilter({ filterOption }) {
        // Remove filter from list
        if (selectedFilters.includes(filterOption)) {
            const filterOptionIndex = selectedFilters.indexOf(filterOption);
            const newSelectedFilters = [...selectedFilters];
            newSelectedFilters.splice(filterOptionIndex, 1);
            setSelectedFilters(newSelectedFilters);
        }
        // Add filter to list
        else {
            setSelectedFilters([...selectedFilters, filterOption]);
        }
    }

    return (
        <DemoSection text={"Filter Button (ZDF Version)"}>
            <SpacedArray horiz>
                <REPLACE_ZDF_FilterButton
                    label={filters[0].text}
                    emoji={filters[0].emoji}
                    selected={selectedFilters.includes(filters[0])}
                    count={12}
                    countColor={colorTextSecondary}
                    onPress={() => toggleFilter({ filterOption: filters[0] })}
                />
                <REPLACE_ZDF_FilterButton
                    label={filters[1].text}
                    emoji={filters[1].emoji}
                    count={8}
                    countColor={colorTextSecondary}
                    selected={selectedFilters.includes(filters[1])}
                    onPress={() => toggleFilter({ filterOption: filters[1] })}
                />
                <REPLACE_ZDF_FilterButton
                    label={filters[2].text}
                    emoji={filters[2].emoji}
                    count={7}
                    countColor={colorTextSecondary}
                    selected={selectedFilters.includes(filters[2])}
                    onPress={() => toggleFilter({ filterOption: filters[2] })}
                />
            </SpacedArray>
            <SpacedArray pad={5}>
                <UtilityText text={"Prehistoric animals:"} strong />
                {selectedFilters.map((selectedFilter, idx) => (
                    <UtilityText text={"- " + selectedFilter.text} key={"filter-" + idx} />
                ))}
            </SpacedArray>
        </DemoSection>
    );
}

export function FilterButtonStorySets() {
    return [
        {
            label: "Filter Button Actions",
            content: <FilterButtonDemo/>,
            stories: [
                { label: "Select filter", actions: [CLICK(filters[1].text)] },
                { label: "Deselect filter", actions: [CLICK(filters[0].text)] },
                {
                    label: "Select and deselect multiple filters",
                    actions: [
                        CLICK(filters[1].text),
                        CLICK(filters[2].text),
                        CLICK(filters[2].text),
                        CLICK(filters[1].text),
                        CLICK(filters[0].text),
                    ],
                },
            ],
        },
    ];
}
