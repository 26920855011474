import { ChevronDown, ChevronUp } from "@carbon/icons-react";
import { HorizBox, HoverView, Pad, PadBox } from "component/basics"
import { useDatastore } from "util/datastore"
import { logEventAsync } from "util/eventlog"
import React from "react";
import { StyleSheet, View } from "react-native";
import { colorAmbiguousAccent, colorAmbiguousWhite, colorBorderPrimary, colorGreyFormHover, colorGreyFormPress, colorTagHighlight } from "../color";

/* Differences compared to the regular AccordionField:
- Chevrons always have the same size (16 x 16). Before this adjustment, the chevrons caused a problem in the "Votings" tab of the Video Voting Tool. One of the listed questions had a smaller chevron.
- Background color on hover and on press is black + transparency. This is how the design does it. Using an opaque gray color like the regular AccordionField doesn't work as well when a component's background color isn't white, e.g. the blue Conversation Helper widget.
- titleContent is wrapped into a View with a set width. Otherwise long text can cause issues and doesn't break into multiple lines.
- borderBelowTitle adds an optional bottom border
*/
export function REPLACE_ZDF_AccordionField({ titleContent, defaultOpen, forceOpen, children, nameInLog = "", testId, color, borderBelowTitle = false }) {
    const datastore = useDatastore();
    const s = AccordionFieldStyle;
    const [open, setOpen] = React.useState(defaultOpen);

    const pressAccordionField = () => {
        open
            ? logEventAsync(datastore, "accordionfield-close", { name: nameInLog })
            : logEventAsync(datastore, "accordionfield-open", { name: nameInLog });
        setOpen(!open);
    }

    return (
        <View>
            <HoverView
                style={[s.titleBar, borderBelowTitle && s.borderBelowTitle]}
                hoverStyle={s.hover}
                pressedStyle={s.pressed}
                onPress={pressAccordionField}
                testID={testId}
            >
                <HorizBox center spread>
                    <View style={s.titleContent}>{titleContent}</View>
                    {!forceOpen && <View style={s.chevron}>{open ? <ChevronUp color={color}/> : <ChevronDown color={color}/>}</View>}
                </HorizBox>
            </HoverView>
            {(open || forceOpen) && children}
        </View>
    );
}

const AccordionFieldStyle = StyleSheet.create({
    titleBar: {
        minHeight: 48,
        justifyContent: "center",
        paddingRight: 16,
    },
    titleContent: {
        width: "calc(100% - 16px)",
    },
    borderBelowTitle: {
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        borderBottomColor: colorBorderPrimary,
    },
    hover: {
        backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
    pressed: {
        backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
    chevron: {
        height: 16,
        width: 16
    },
});

const ToggleWithLabelWidgetStyle = StyleSheet.create({
    toggleZone: {
        width: 56,
        height: 32,
        backgroundColor: colorBorderPrimary,
        borderRadius: 100,
        transition: 'background-color 0.2s ease-in-out'
    },
    toggleBall: {
        boxShadow: '2px 0px 10px rgba(0, 0, 0, 0.30)',
        elevation: 5,  // for Android,
        position: 'absolute',
        left: 2,
        top: 2,
        width: 28,
        height: 28,
        borderRadius: 100,
        backgroundColor: colorAmbiguousWhite,
        transition: 'left 0.2s ease-in-out, background-color 0.2s ease-in-out'
    },
    toggleZoneSelected: {
        width: 56,
        height: 32,
        backgroundColor: colorTagHighlight,
        borderRadius: 100,
        transition: 'background-color 0.2s ease-in-out'
    },
    toggleBallSelected: {
        backgroundColor: colorAmbiguousAccent,
        left: 56-28-2,
        top: 2,
        width: 28,
        height: 28,
        borderRadius: 100,
        transition: 'left 0.2s ease-in-out, background-color 0.2s ease-in-out'
    },
    hover: {
        backgroundColor: colorGreyFormHover,
    },
    pressed: {
        backgroundColor: colorGreyFormPress,
        borderTopRightRadius: 100,
        borderBottomRightRadius: 100,    
    }
})

/** Toggle component that takes a custom `labelWidget` and renders it left of the toggle button */
export function ToggleWithLabelWidget({ labelWidget, value, spread, onChange, testID, ariaText }) {
    const s = ToggleWithLabelWidgetStyle;

    return (
        <HoverView
            hoverStyle={s.hover}
            testID={testID}
            onPress={() => onChange(!value)}
            role="button"
            ariaText={value ? `${ariaText}, checked` : `${ariaText}, unchecked`}
        >
            <PadBox vert={8}>
                <HorizBox center spread={spread}>
                    <HorizBox center shrink>
                        {labelWidget && React.createElement(labelWidget)}
                    </HorizBox>
                    {labelWidget && <Pad size={12} />}
                    <View style={value ? s.toggleZoneSelected : s.toggleZone} onPress={() => onChange(!value)}>
                        <View style={value ? s.toggleBallSelected : s.toggleBall} />
                    </View>
                </HorizBox>
            </PadBox>
        </HoverView>
    );
}