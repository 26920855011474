import { PadBox } from "component/basics"
import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { ClipEditor } from "../../component/zdf/clipeditor";
import { IconButton, TextButton } from "component/button"
import { getPrettyTimestamp, setVideoTime } from "../../system/videotime";
import { UtilityText } from "component/text"
import { ClipPlayer } from "../../component/zdf/clipplayer";
import { useSessionData } from "util/datastore"

export const VideoQuoteFeature = {
    name: 'Video Quote',
    key: 'videoquote',
    config: {
        commentEditTopWidgets: [ToggleClipEditor],
        commentTopWidgets: [EmbeddedClip],
        commentPostCheckers: [checkClip]
    }
}

function ToggleClipEditor({ comment, setComment }) {
    const [clipAdded, setClipAdded] = useState(false);
    const editing = useSessionData(['editComment', comment.key]);
    
    function addClip() {
        setClipAdded(true);
    }
    
    function removeClip() {
        setComment({ ...comment, clip: null });
        setClipAdded(false);
    }

    return (clipAdded || comment.clip && editing) ?
        <View>
            <RemoveClipButton onPress={removeClip} />
            <PadBox bottom={20}>
                <ClipEditor onChangeValue={clip => setComment({ ...comment, clip })} currentClip={comment.clip} />
            </PadBox>
        </View>
        : <AddClipButton onPress={addClip} />;
}

function AddClipButton({ onPress }) {
    return <PadBox bottom={20} >
        <IconButton label="➕ Add Clip" onPress={onPress}/>
    </PadBox>
}

function RemoveClipButton({ onPress }) {
    return <PadBox bottom={20} >
        <IconButton label="➖ Remove Clip" onPress={onPress}/>
    </PadBox>
}

function EmbeddedClip({ comment }) {
    const editing = useSessionData(['editComment', comment.key]);

    // Don't show clip player if there is no clip attached to the comment
    if (!comment.clip) {
        return;
    }

    // Don't show clip player if the comment is being edited and the clip editor is open
    if (editing){
        return;
    }

    function jumpToStartTime() {
        setVideoTime(comment.clip.start);
    }

    function jumpToEndTime() {
        setVideoTime(comment.clip.end);
    }

    return <View style={VideoQuoteStyle.embeddedClip}>
        <ClipPlayer clip={comment.clip} />
        {/* For the timestamps, we need to check for null specifically because 0 is a valid timestamp */}
        {(comment.clip && comment.clip.source && comment.clip.start !== null && comment.clip.start !== undefined && comment.clip.end !== null && comment.clip.end !== undefined) ?
            <View style={VideoQuoteStyle.clipSource}>
                <UtilityText label="↪ Referencing " color="gray"/>
                <TextButton label={getPrettyTimestamp(comment.clip.start)} onPress={ jumpToStartTime } color="#5A1EF5" underline={true} />
                <UtilityText label=" - " color="gray"/>
                <TextButton label={getPrettyTimestamp(comment.clip.end)} onPress={ jumpToEndTime } color="#5A1EF5" underline={true}/>
            </View>
            : null
        } 
    </View>
}

function checkClip({ datastore, comment }) {
    // Don't post comments with invalid clips
    return {
        allow: comment.clip ? comment.clip.valid : true,
    }
}

const VideoQuoteStyle = StyleSheet.create({
    embeddedClip: {
        paddingBottom: "20px"
    },
    clipSource: {
        paddingTop: "10px",
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline"
    }
})