import { CLICK, INPUT } from "system/demo"
import { ModerationDashScreen, ModerationDashStructure } from "../../structure/moderationdash"

export const ModerationDashDemoFeature = {
    key: 'demo_moderationdash',
    name: 'Moderation Dashboard Demo',
    config: {
        componentSections: [
            {label: 'Admin', key: 'internal', pages: [
                {
                    label: 'Moderation Dashboard', key: 'moderationdash', 
                    storySets: moderationDashStorySets
                }
            ]}
        ],
    }
}

const modulePublic = {moderation: {queue: {
    a: {text: 'Awaiting Judgement', structureKey: 'question', instanceKey: 'bla'},
    b: {text: 'Rejected', judgement: 'reject'},
    c: {text: 'Also awaiting Judgement'},
}}}

function moderationDashStorySets() {return [
    {
        label: 'Moderation Dashboard', key: 'moderationdash',
        modulePublic,
        roles: ['Moderator'],
        config: ModerationDashStructure.defaultConfig,
        serverCall: {moderation: {
            getModItemAuthors: async () => {
                return {
                    a: {authorName: 'Dick Dastardly', from: 'd'},
                    b: {authorName: 'Big Bill Brown', from: 'b'},
                    c: {authorName: 'Cruela Deville', from: 'c'},
                }
            },
            setJudgement: () => {}
        }},
        content: <ModerationDashScreen />,
        
        stories: [
            {label: 'Reject Item', actions: [
                CLICK('a'), 
                INPUT('explanation', 'Your comment sucked'),
                CLICK('Reject')
            ]},
            {label: 'Approve item', actions: [
                CLICK('c'), CLICK('Approve')
            ]}
        ]
    },
    {
        label: 'Transparency Dashboard', key: 'moderationdash',
        modulePublic,
        config: ModerationDashStructure.defaultConfig,
        content: <ModerationDashScreen />,
        
        stories: [
            {label: 'Change Filter', actions: [
                CLICK('Rejected')
            ]}
        ]
    }
]}

