import { useCollection, useDatastore, usePersonaKey } from "util/datastore";
import { PollSurveyThenResults } from "component/poll";

export const PollFeature = {
    name: 'Poll',
    key: 'poll',
    config: {
        PollWidget: PollWidget,
    },
    defaultConfig: {
        visualizationType: 'percent'
    }
}

export function PollWidget({ 
    pollKey, question, optionLabels, optionTexts, showOtherOption, 
    otherOptionLabel, otherOptionText, otherOptionPlaceholder = null 
}) {

    const datastore = useDatastore();
    const personaKey = usePersonaKey();
    const voteObjects = useCollection(pollKey);

    const votes = voteObjects.map(vote => vote?.value?.label);
    const myVoteObject = voteObjects.find(v => v?.from == personaKey);

    const myVote = myVoteObject?.value?.label;
    const myComment = myVoteObject?.value?.comment;

    const onVote = (vote, comment = null) => {
        const existing = datastore.getCollection(pollKey, { filter: { from: personaKey } });
        existing.forEach(r => datastore.deleteObject(pollKey, r.key));
        datastore.addObject(pollKey, { from: personaKey, value: { label: vote, ...(comment && { comment }) } });
    }

    return <PollSurveyThenResults
        question={question}
        optionLabels={optionLabels}
        optionTexts={optionTexts}
        onVote={datastore.needsLogin(onVote, 'vote')}
        votes={votes}
        myVote={myVote}
        myComment={myComment}
        showOtherOption={showOtherOption}
        otherOptionLabel={otherOptionLabel}
        otherOptionText={otherOptionText}
        otherOptionPlaceholder={otherOptionPlaceholder}
    />
}
