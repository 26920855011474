import { useState } from "react";
import { CLICK, DemoSection } from "../../system/demo";
import { View } from "react-native-web";
import { HorizBox, Pad } from "../../component/basics";
import { UtilityText } from "../../component/text";
import { ToggleWithLabelWidget } from "../../component/zdf/form";
import { colorTextHint } from "../../component/color";
import { ToolTip } from "../../component/zdf/tooltip";
import { Information } from "@carbon/icons-react";

export function ToggleWithLabelWidgetDemo() {
    const [switchValue1, setSwitchValue1] = useState(false);
    const [switchValue2, setSwitchValue2] = useState(false);
    const [switchValue3, setSwitchValue3] = useState(false);
    const [switchValue4, setSwitchValue4] = useState(false);
    const [switchValue5, setSwitchValue5] = useState(false);

    return (
        <View>
            <DemoSection text="Toggle with Label Widget">
                <ToggleWithLabelWidget
                    ariaText="No widget"
                    testID={"No widget"}
                    value={switchValue1}
                    onChange={setSwitchValue1}
                />

                <ToggleWithLabelWidget
                    ariaText={"Simple text"}
                    testID={"Simple text"}
                    labelWidget={SimpleTextLabelWidget}
                    value={switchValue2}
                    onChange={setSwitchValue2}
                />

                <ToggleWithLabelWidget
                    ariaText={"Simple text spread"}
                    testID={"Simple text spread"}
                    labelWidget={SimpleTextSpreadLabelWidget}
                    value={switchValue3}
                    onChange={setSwitchValue3}
                    spread
                />

                <ToggleWithLabelWidget
                    labelWidget={TextWithTooltipLabelWidget}
                    ariaText="Text with tooltip"
                    testID={"Text with tooltip"}
                    value={switchValue4}
                    onChange={setSwitchValue4}
                    spread
                />

                <ToggleWithLabelWidget
                    labelWidget={MultilineLabelWidget}
                    ariaText="Multiline widget"
                    testID={"Multiline widget"}
                    value={switchValue5}
                    onChange={setSwitchValue5}
                    spread
                />
            </DemoSection>
        </View>
    );
}

function SimpleTextLabelWidget() {
    return <UtilityText text="Simple text" type="large" />;
}

function SimpleTextSpreadLabelWidget() {
    return <UtilityText text="Simple text spread" type="large" />;
}

function TextWithTooltipLabelWidget() {
    return (
        <HorizBox center>
            <View>
                <UtilityText text={"Text with tooltip"} type="large" />
            </View>
            <Pad size={8} />
            <ToolTip toolTipWidget={<UtilityText text="Tooltip" />}>
                <Information />
            </ToolTip>
        </HorizBox>
    );
}

function MultilineLabelWidget() {
    return (
        <View>
            <UtilityText text="Multiline widget" type="large" />
            <Pad size={4} />
            <UtilityText text="Second line" type="small" color={colorTextHint} />
        </View>
    );
}

export function toggleWithOptionalTextStorySets() {
    return [
        {
            label: "Toggle Actions",
            content: <ToggleWithLabelWidgetDemo />,
            stories: [
                {
                    label: "Press no widget toggle",
                    actions: [CLICK("No widget")],
                },
                {
                    label: "Press simple text toggle",
                    actions: [CLICK("Simple text")],
                },
                {
                    label: "Press simple text spread toggle",
                    actions: [CLICK("Simple text spread")],
                },
                {
                    label: "Press text with tooltip toggle",
                    actions: [CLICK("Text with tooltip")],
                },
                {
                    label: "Press multiline widget toggle",
                    actions: [CLICK("Multiline widget")],
                },
            ],
        },
    ];
}
