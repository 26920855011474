import { ClickableAITag } from "component/aitag";

export const AITagFeature = {
        name: 'AI Tag',
        key: 'aitag',
        dev: true,
        config: {
            pageTopRightWidgets: [() => <ClickableAITag />]
        }
    }

