import React, { useState } from "react";
import { StyleSheet } from "react-native";
import { ActivityIndicator, View } from "react-native-web";
import { HoverView, Pad, PadBox } from "../basics";
import { UtilityText } from "../text";
import { colorAccentHover, colorAccentPress, colorAmbigousBlack, colorAmbiguousAccent, colorAmbiguousLightGrey, colorAmbiguousWhite, colorBlackHover, colorBorderPrimary, colorBorderSelected, colorButtonAccent, colorButtonDisabled, colorGreyHover, colorPrimaryPress, colorSecondaryPress, colorTextWarning, colorWhite } from "../color";

/* Design: https://www.figma.com/design/MX0AcO8d0ZlCBs4e9vkl5f/PSI-Design-System?node-id=6295-30850&node-type=frame&t=20KG9DOYaEhzgPAI-0

Changes compared to original FilterButton:
- selection is saved after press
- gray background on press
- white background when selected or unselected
- dark gray outline and black text when selected
- count is red by default but the color can be customized */

export function REPLACE_ZDF_FilterButton({emoji, label, text, count, countColor = colorTextWarning, selected, onPress}) {
    const s = FilterButtonStyle;

    const [pressed, setPressed] = useState(false);

    function toggleFilter() {
        onPress();
    }

    return (
        <HoverView
            style={[s.button, pressed && s.pressed, selected && s.selected]}
            pressedStyle={s.pressed}
            hoverStyle={s.hover}
            onPress={toggleFilter}
            setPressed={setPressed}
            testID={label ?? text}
        >
            {emoji && (
                <PadBox right={8}>
                    <UtilityText text={emoji} type="tiny" weight="medium" />
                </PadBox>
            )}
            <UtilityText label={label} text={text} type="tiny" color={colorAmbigousBlack} weight="medium" />
            {count ? <Pad size={8} /> : null}
            <UtilityText text={count} type="tiny" color={countColor} />
        </HoverView>
    );
}
const FilterButtonStyle = StyleSheet.create({
    button: {
        height: 32,
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'flex-start',
        backgroundColor: colorAmbiguousWhite,
        borderColor: colorBorderPrimary,
        borderWidth: 1,
        borderRadius: 100,
        paddingHorizontal: 12
    },
    hover: {
        backgroundColor: colorGreyHover,
    },
    pressed: {
        backgroundColor: colorBorderPrimary,
    },
    selected: {
        borderColor: colorBorderSelected,   
        backgroundColor: colorAmbiguousWhite,
    }
});


export function ThinTag({ emoji, icon, label, text, textColor = colorAmbigousBlack, backgroundColor = colorAmbiguousWhite, formatParams }) {
    return (
        <View
            style={{
                justifyContent: "center",
                width: "fit-content",
                height: 20,
                borderRadius: 32,
                paddingHorizontal: 6,
                backgroundColor: backgroundColor,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 6
            }}
        >
            {emoji && <UtilityText text={emoji} type="tiny" weight="medium" />}
            {icon && <>{icon}</>}
            <UtilityText label={label} text={text} type="tiny" color={textColor} formatParams={formatParams} weight="medium"/>
        </View>
    );
}

/** Based on `CTAButton`. Has an additional prop `processing`. If `true`, a loading spinner will appear and the text/label and icon become invisible. */
export function CTAButtonWithLoadingSpinner({label, text, testID, formatParams, icon, type='primary', disabled, size='large', wide=false, borderless=false, onPress, processing=false}) {
    const s = CTAButtonWithLoadingSpinnerStyle;

    const styleMap = {
        primary: {normal: s.primary, hover: s.primaryHover, pressed: s.primaryPressed, textColor: colorAmbiguousWhite},
        secondary: {normal: s.secondary, hover: s.secondaryHover, pressed: s.secondaryPressed, textColor: colorAmbigousBlack},
        accent: {normal: s.accent, hover: s.accentHover, pressed: s.accentPressed, textColor: colorAmbiguousWhite},
        disabled: {normal: s.disabled, hover: s.disabled, pressed: s.disabled, textColor: colorAmbiguousLightGrey},
        delete: {normal: s.delete, hover: s.deleteHover, pressed: s.deletePressed, textColor: colorTextWarning}
    }
    const {normal, hover, pressed, textColor} = styleMap[disabled ? 'disabled' : type] ?? styleMap.primary;

    const sizeMap = {
        large: s.largeButton,
        compact: s.compactButton,
        small: s.smallButton,
    };

    return (
        <HoverView
            disabled={processing || disabled}
            role="button"
            testID={testID ?? label ?? text}
            style={[sizeMap[size], wide && s.wide, normal, borderless && s.borderless]}
            hoverStyle={[s.hover, hover]}
            pressedStyle={pressed}
            onPress={onPress}
        >
            {processing && (
                <ActivityIndicator
                    size={"large"}
                    style={s.activityIndicator}
                    color={type === "secondary" || type === "delete" ? colorButtonAccent : colorWhite}
                />
            )}
            {icon && (
                <PadBox aria-hidden={processing} right={label || text ? (size === "small" ? 6 : 8) : null}>
                    {React.cloneElement(icon, processing ? { opacity: 0 } : null)}
                </PadBox>
            )}
            <UtilityText
                aria-hidden={processing}
                type={size === "small" ? "tiny" : "large"}
                label={label}
                text={text}
                formatParams={formatParams}
                color={!processing ? textColor : "transparent"}
            />
        </HoverView>
    );
}

const CTAButtonWithLoadingSpinnerStyle = StyleSheet.create({
    hover: {
    },
    primary: {
        backgroundColor: colorBorderSelected,
        borderColor: colorBorderSelected,
    },
    primaryHover: {
        backgroundColor: colorBlackHover,
        borderColor: colorBlackHover,
    },
    primaryPressed: {
        backgroundColor: colorPrimaryPress
    },
    secondary: {
        borderColor: colorBorderPrimary,
        backgroundColor: colorAmbiguousWhite,
    },
    secondaryPressed: {
        backgroundColor: colorSecondaryPress,
    },
    secondaryHover: {
        backgroundColor: colorGreyHover,
        borderColor: colorBorderPrimary
    },
    accent: {
        backgroundColor: colorAmbiguousAccent,
        borderColor: colorAccentHover
    },
    accentPressed: {
        backgroundColor: colorAccentPress,
    },
    accentHover: {
        backgroundColor: colorAccentHover,
        borderColor: colorAccentHover
    },
    delete: {
        borderColor: colorBorderPrimary,
    },
    deletePressed: {
        backgroundColor: colorSecondaryPress,
        borderColor: colorBorderPrimary,
    },
    deleteHover: {
        backgroundColor: colorGreyHover,
        borderColor: colorBorderPrimary
    },
    disabled: {
        backgroundColor: colorButtonDisabled,
        borderColor: colorButtonDisabled,
        color: colorAmbiguousLightGrey
    },
    largeButton: {
        paddingHorizontal: 20,
        paddingVertical: 12,
        borderRadius: 32,
        alignSelf: 'flex-start',
        borderWidth: 1,
        flexDirection: 'row',
        height: 44,
        alignItems: 'center',
    },
    compactButton: {
        paddingHorizontal: 12,
        height: 40,
        borderRadius: 32,
        alignSelf: 'flex-start',
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 1
    },
    smallButton: {
        paddingHorizontal: 12,
        height: 32,
        borderRadius: 32,
        alignSelf: 'flex-start',
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 1
    },
    wide: {
        alignSelf: 'stretch',
        justifyContent: 'center',
        flex: 1
    },
    borderless: {
        borderWidth: 0
    },
    activityIndicator: {
        position: "absolute",
        zIndex: 1,
        left: 0,
        right: 0,
        margin: "auto",
    }
})