import { ConversationScreen, Pad, PadBox } from "component/basics"
import { CTAButton, TextButton } from "component/button"
import { Heading } from "component/text"
import { gotoInstanceScreen, pushSubscreen } from "../../../util/navigate"
import { VideoVotingEditor } from "../../../component/zdf/videovoting/videovotingEditor"
import { useAllVideoVotings } from "../../../component/zdf/videovoting/videovoting.util"

export const VideoVotingListFeature = {
    name: 'Video Voting List',
    key: 'videovotinglist',
    subscreens: {
        videovotinglist: VideoVotingListScreen,
        createVoting: VideoVotingCreationScreen,
    },
    config: {
        quickLinks: [
            { label: '!Video Votings', screenKey: 'videovotinglist' },
        ]
    },
}

export function VideoVotingListScreen() {

    const votings = useAllVideoVotings()

    const openVoting = (votingKey) => {
        gotoInstanceScreen({ structureKey: "videovoting", instanceKey: votingKey, screenKey: "edit" })
    }
    return <ConversationScreen pad>
        <Pad size={40} />
        <Heading type='large' label='Votings' />
        <Pad />
        <CTAButton onPress={() => { pushSubscreen("createVoting") }} label={"Create a new voting"} />
        <Pad />
        {Object.values(votings ?? {}).map((voting) => {
            return <PadBox key={voting.key} vert={10}>
                <TextButton key={voting.key} text={voting.name} onPress={() => { openVoting(voting.key) }} />
            </PadBox>
        })}
    </ConversationScreen>
}

export function VideoVotingCreationScreen() {
    return <ConversationScreen pad>
        <Heading type="large" label={"Create a new voting"} />
        <VideoVotingEditor voting={null} />
    </ConversationScreen>
}