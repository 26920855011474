import { colorAmbiguousHighlight } from "component/color"
import { useEffect, useRef, useState } from "react";
import { Animated, Easing } from "react-native";

export function CoinSplash({x = 10}){
    return Array.from({ length: x }, (_, i) => {
        return <SingleSplashAnimation key={i}></SingleSplashAnimation>
    })
}

function SingleSplashAnimation(){

  const translateX = useRef(new Animated.Value(0)).current;
  const translateY = useRef(new Animated.Value(0)).current;
  const rotateX = useRef(new Animated.Value(0)).current;
  const rotateY = useRef(new Animated.Value(0)).current;
  const rotateZ = useRef(new Animated.Value(0)).current;
  const opacity = useRef(new Animated.Value(1)).current;

  const [randomSize, _] = useState(Math.random() * 12 - 6 + 15)

  useEffect(() => {
    //Vector of up to 100px length
    let basePath = [Math.random() * 100, 0]
    // Rotate it randomly
    const theta = Math.random() * Math.PI * 2.0 - Math.PI
    const pathX = basePath[0] * Math.cos(theta) - basePath[1] * Math.sin(theta)
    const pathY = basePath[0] * Math.sin(theta) + basePath[1] * Math.cos(theta)

    // Random 3D Spin
    const randomRotationX = Math.random() * 720 - 360
    const randomRotationY = Math.random() * 720 - 360
    const randomRotationZ = Math.random() * 720 - 360

    // Start the animation: move the coin in a random direction and spin it
    Animated.parallel([
      Animated.timing(translateX, {
        toValue: pathX,
        duration: 1000,
        easing: Easing.out(Easing.ease),
        useNativeDriver: true,
      }),
      Animated.timing(translateY, {
        toValue: pathY,
        duration: 1000,
        easing: Easing.out(Easing.ease),
        useNativeDriver: true,
      }),
      Animated.timing(rotateX, {
        toValue: randomRotationX,
        duration: 1000,
        easing: Easing.out(Easing.ease),
        useNativeDriver: true,
      }),
      Animated.timing(rotateY, {
        toValue: randomRotationY,
        duration: 1000,
        easing: Easing.out(Easing.ease),
        useNativeDriver: true,
      }),
      Animated.timing(rotateZ, {
        toValue: randomRotationZ,
        duration: 1000,
        easing: Easing.out(Easing.ease),
        useNativeDriver: true,
      }),
      Animated.timing(opacity, {
        toValue: 0,
        duration: 1000,
        easing: Easing.out(Easing.ease),
        useNativeDriver: true,
      }),
    ]).start();
  }, []);

  const spinX = rotateX.interpolate({
    inputRange: [0, 360],
    outputRange: ['0deg', '360deg'],
  });
  const spinY = rotateX.interpolate({
    inputRange: [0, 360],
    outputRange: ['0deg', '360deg'],
  });
  const spinZ = rotateX.interpolate({
    inputRange: [0, 360],
    outputRange: ['0deg', '360deg'],
  });

    return <Animated.View
    style={{
        position: "absolute",
      width: randomSize,
      height: randomSize,
      backgroundColor: colorAmbiguousHighlight,
      borderRadius: 20,
      borderWidth:1,
      opacity: opacity,
      transform: [
        { translateX },
        { translateY },
        { rotateX: spinX},
        { rotateY: spinY},
        { rotateZ: spinZ}
      ],
    }}
  />
}