
export const AdminModLinkFeature = {
    key: 'admin_link_mod',
    name: 'Legacy Moderation Link',
    config: {
        quickLinks: [{label: 'Legacy Moderation Dashboard', structureKey: 'moderationdash'}]
    }
}
 
export const NewModLinkFeature = {
    key: 'admin_new_link_mod',
    name: 'New Moderation Link',
    config: {
        quickLinks: [{label: 'New Moderation Dashboard', structureKey: 'moddashboard'}]
    }
}
 
