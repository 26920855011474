import { useState } from "react";
import { CTAButton } from "../../../component/button";
import { colorBorderPrimary, colorSurfaceSecondary, colorTextSecondary } from "../../../component/color";
import { AutoSizeTextInput, UtilityText } from "../../../component/text";
import { CLICK, DemoSection, INPUT, POPUP } from "../../../system/demo";
import { callServerApiAsync } from "../../../system/servercall";
import { Datastore, useDatastore } from "../../../util/datastore";
import { StyleSheet, View } from "react-native-web";
import { RichText } from "../../../component/richtext";
import { BlacklistAddTermField, BlacklistItemList, BlacklistSearchField } from "../../../component/zdf/blacklist/blacklist";
import { demoBlacklistEntries } from "../moddashboard/moddashboard.data";

const BlacklistLogStyle = StyleSheet.create({
    logBox: {
        backgroundColor: colorSurfaceSecondary,
        padding: 6,
        borderColor: colorBorderPrimary,
        borderWidth: 1,
    },
});

export function BlacklistDemo() {
    const datastore = useDatastore();
    const [blacklist, setBlacklist] = useState({
        ["someId"]: { term: "bad word", isSingularTerm: true, hasAlternativeSpellings: false },
    });
    const [text, setText] = useState("");
    const [hasViolation, setHasViolation] = useState(null);
    const [selectedTerms, setSelectedTerms] = useState([]);

    async function onPressCheckText() {
        setHasViolation(null);

        const doesViolateBlacklist = await callServerApiAsync({
            datastore,
            component: "blacklist",
            funcname: "testCheckBlacklist",
            params: { blacklist: blacklist, text: text },
        });

        setHasViolation(doesViolateBlacklist);
    }

    async function onChangeTerm({ text }) {
        setBlacklist({ ["someId"]: { term: text, isSingularTerm: true, hasAlternativeSpellings: false } });
    }

    const s = BlacklistLogStyle;
    const [demoTerms, setDemoTerms] = useState(demoBlacklistEntries);
    const replacements = {
        3: "e",
        0: "o",
    };
    const serverCall = {
        blacklist: {
            getBlacklist: () => demoTerms,
            findTermInBlacklist: ({ term }) => {
                return demoTerms.some((entry) => entry.term === term);
            },
            addToBlacklist: ({ blacklistEntries }) => {
                const newEntries = blacklistEntries.filter(
                    (entry) => !demoTerms.some((term) => term.term === entry.term)
                );
            
                if (newEntries.length > 0) {
                    setDemoTerms([...demoTerms, ...newEntries]);
                }
            },
            
            removeFromBlacklist: ({ terms }) => {
                const newTerms = demoTerms.filter(entry => !terms.includes(entry.term)); 
                setDemoTerms(newTerms);
            },
            getCharacterReplacements: () => replacements,
        },
    };
    

    return (
        <Datastore
            testState={{
                roles: ["Super-Moderator"],
                serverCall,
            }}
        >
            <DemoSection text="Blacklist Management">
            <DemoSection text="Singular term detection">
            <View>
                <RichText text={"Term: **" + Object.values(blacklist)[0].term + "**"} />
                <AutoSizeTextInput
                    style={{ border: "1px solid " + colorBorderPrimary }}
                    placeholder={"Enter a new blocklisted term"}
                    onChange={(text) => onChangeTerm({ text })}
                />
            </View>

            <View>
                <UtilityText text="Blocklist:" />
                <View style={s.logBox}>
                    <UtilityText type="tiny" weight="medium" color={colorTextSecondary} text={JSON.stringify(blacklist)} />
                </View>
            </View>

             <View>
                <UtilityText text={"Text:"} />
                <AutoSizeTextInput
                    style={{ border: "1px solid " + colorBorderPrimary }}
                    placeholder={"Enter a text to check against the blocklist"}
                    onChange={(text) => setText(text)}
                />
            </View>

            <CTAButton text="Check text" onPress={onPressCheckText} />
            {hasViolation !== null && <UtilityText text={"Violation found: " + hasViolation} />}
                </DemoSection>

                <DemoSection text="Add Term">
                    <BlacklistAddTermField  />
                </DemoSection>
                
                <DemoSection text="Search">
                    <BlacklistSearchField />
                </DemoSection>

                <DemoSection text="Blacklist Items">
                    <BlacklistItemList
                        blacklistEntries={demoTerms}
                        selectedTerms={selectedTerms}
                        setSelectedTerms={setSelectedTerms}
                    />
                </DemoSection>
            </DemoSection>
        </Datastore>
    );
}

export function blacklistStorySets() {
    return [
        {
            label: 'Add Term',
            content: <BlacklistAddTermField/>,
            serverCall: {
                blacklist: {
                    addToBlacklist: ()=>{return true},
                    RemoveFromBlacklist: ()=>{return true},
                    getCharacterReplacements: () => {return true},
                    getBlacklist: () => {return true},
                    findTermInBlacklist: ()=>{return false}
                }
            },
            stories: [{   
                label: 'Enter terms separated by semicolons and save', actions: [
                    INPUT('blacklistChipField','term1; term2'),
                    CLICK('Add term'),
                    POPUP(CLICK('Save')),
                ]},
                {
                    label: 'Enter single term and click add', actions: [
                        INPUT('blacklistChipField','term1'),
                        CLICK('Add term'),
                        POPUP(CLICK('Save')),
                    ]},
            ],           
            
        },
    ]
}
