// Ambigous colors (should replace with more specific colors)
export const colorAmbigousBlack = '#000000';
export const colorAmbiguousLightGrey = '#818181';
export const colorAmbiguousWhite = '#ffffff';
export const colorAmbiguousHighlight = '#5A1EF5';
export const colorAmbiguousAccent = '#5A1EF5';
export const colorAmbigousAccentLight = '#edebff';

// Text colors
export const colorTextPrimary = '#000000';
export const colorTextSecondaryOnLightColorBg = '#2B2B2B';
export const colorTextSecondary = '#65676B';
export const colorTextHint = '#818181'
export const colorTextDisabled = '#818181'
export const colorTextWarning = '#F53C19';
export const colorTextSuccess = '#00af87';
export const colorTextHighlight = '#5A1EF5';
export const colorTextOnBlackBackground = '#ffffff';
export const colorTextOnDarkBackground = '#ffffff';
export const colorTextOnLightColorTag = '#000000';


// Button colors
export const colorButtonPrimary = '#000000';
export const colorButtonAccent = '#5A1EF5';
export const colorButtonSecondary = '#ffffff';
export const colorButtonDisabled = '#E9E9E9';


// Surface
export const colorSurfacePrimary = '#ffffff';
export const colorSurfaceSecondary = '#F7F7F7';
export const colorSurfaceDiscovery = '#FEF7F2';
export const colorSurfaceDiscoveryShade = '#F6F0EC';
export const colorSurfaceDiscoveryShadeDark = '#F6F0EC';
export const colorSurfaceInfo = '#F5F9FF';
export const colorSurfaceInfoShade = '#F6F0EC';
export const colorSurfaceWarning = '#FCEBEA';
export const colorSurfaceSuccess = '#B9EBDC';


// Tag
export const colorTagDefault = '#F7F7F7';
export const colorTagHighlight = '#ECECFF';
export const colorTagEmphasized = '#FDEBEA';
export const colorTagVerified = '#CDE1FF';
export const colorTagSuccess = '#B9EBDC';


// Border
export const colorBorderPrimary = '#D9D9D9';
export const colorBorderSelected = '#0f0f0f';
export const colorBorderWarning = '#F53C19';
export const colorBorderFacepile = '#ffffff';


// Data Viz
export const colorDataVizStrong = '#7265FF';
export const colorDataVizRegular = '#DCE0E5';
export const colorDataVizLight = '#ECECFF';
export const colorDataVizScale1 = '#F53C19';
export const colorDataVizScale2 = '#FA7355';
export const colorDataVizScale3 = '#FAC841';
export const colorDataVizScale4 = '#FFC841';
export const colorDataVizScale5 = '#058287';


// Primary Brand
export const colorWhite = '#FFFFFF';
export const colorBlack = '#000000';
export const colorIris = '#5A1EF5';
export const colorLavender = '#7265FF';

// Secondary Brand
export const colorGarnet3 = '#FCEBEA';
export const colorHoh3 = '#B9EBDC';
export const colorTanzanite3 = '#ECECFF';
export const colorJasper3Shade1 = '#F6F0EC';
export const colorJasper3Shade2 = '#EDE4DE';
export const colorHydrangea3Shade1 = '#E8EFF9';

// Utility Colors
export const colorGreyBlue = '#DCE0E5';

// Greyscale
export const colorGrey1 = '#F7F7F7';
export const colorGrey2 = '#E9E9E9';
export const colorGrey3 = '#D9D9D9';
export const colorGrey4 = '#818181';
export const colorGrey5 = '#65676B';
export const colorGrey6 = '#2B2B2B';


// Illustration Palette 1
export const colorJasper3 = '#FEF7F2'
export const colorJasper3Shade = '#F6F0EC'
export const colorHydrangea3 = '#F5F9FF';
export const colorHydrangea3Shade = '#E8EFF9';
export const colorKilauea1 = '#F53C19';
export const colorKilauea3 = '#FFB4A5';
export const colorHoh1 = '#058287';
export const colorTanzanite1 = '#3F3F80';

// Illustration Palette 2
export const colorEmperor = '#525DCF';
export const colorHydrangea1 = '#CDE1FF';
export const colorMum = '#00AF87';
export const colorHoh2 = '#91D7BE';
export const colorGarnet2 = '#B57A75';
export const colorCarnation1 = '#C34646';
export const colorCarnation3 = '#F07D7D';
export const colorKilauea2 = '#FA7355';
export const colorSaguaro2 = '#FFC841';

// Bold cards
export const colorGarnet1 = '#763A35';



// Legacy hover/press colors (should replace with darkenColor())
export const colorBlackHover = '#2B2B2B';
export const colorGreyHover = '#E9E9E9';
export const colorAccentHover = '#4718C4';
export const colorGreyHoverBorder = '#0f0f0f';

export const colorGreyFormHover ='#f2f2f2';
export const colorGreyFormPress = '#e5e5e5';

export const colorPrimaryPress = '#6f6f6f';
export const colorSecondaryPress = '#cccccc';
export const colorAccentPress = '#361293';
export const colorIconButtonPress = '#bababa';

export const colorPinkHover = '#E4D3D4';
export const colorPinkPress = '#CABCBB';
export const colorLightGreen = '#B9EBDC';
export const colorPurplishBlue = '#525DCF';


// Other legacy colors (should replace with the correct other colors)
export const colorTeaserBackground = '#F5F9FF';
export const colorTealBackground = '#058287';
export const colorMintGreenBackground = '#91D7BE';
export const colorSecondaryJasper = '#EDE4DE';
export const colorBarBackground = '#8EB5FF';


// Default avatar colors
export const jasper1 = '#7d6453';
export const garnet1 = '#763a35';
export const garnet2 = '#b57a75';
export const beetle = '#a95851';
export const carnation1 = '#c34646';
export const kilauea1 = '#F53C19';
export const elsinore1 = '#f56400';
export const saguaro1 = '#a57328';
export const hoh1 = '#058287';
export const pisgah1 = '#553cd7';
export const timucuan1 = '#be73cd';
export const tanzanite1 = '#3f3f80';
export const tanzanite2 = '#7f7fc0';
export const emperor = '#525dcf';
export const lavender = '#7d7dff';
export const defaultAvatarColors = [jasper1, garnet1, garnet2, beetle, carnation1, kilauea1, elsinore1, saguaro1, hoh1, pisgah1, timucuan1, tanzanite1, tanzanite2, emperor, lavender];
