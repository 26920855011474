import { View } from "react-native";
import { SubtleButton } from "component/button"
import { colorBlackHover, colorTeaserBackground } from "component/color"
import { Pin } from "@carbon/icons-react";
import React, { useState } from "react";
import { Banner } from "component/banner"
import { HorizBox, Pad, PadBox } from "component/basics"
import { Modal } from "component/modal"
import { Heading, Paragraph, UtilityText } from "component/text"
import { spacings } from "component/constants";
import { RichText } from "component/richtext";

export const CommunityGuidelinesFeature = {
    name: 'Community Guidelines',
    key: 'community_guidelines',
    dev: true,
    config: {
        headerTopLeftWidgets: [CommunityGuidelines]
    }
}

export const CommunityGuidelinesLeftFeature = {
    name: 'Community Guidelines',
    key: 'community_guidelines',
    dev: true,
    config: {
        topicHeaderLeftWidgets: [CommunityGuidelines]
    }
}

export const GUIDELINES = {
    NO_UNCIVIL: 'Express your ideas with civility',
    NO_HARM: 'No violent content or calls for harm',
    NO_INSULTS: 'Avoid sarcasm and dismissive responses',
    NO_LABELS: 'Avoid derogatory labels',
    BACKUP_CLAIMS: 'Provide sources for factual claims',
    NO_SPAM: 'No spam or commercial promotion',
    RESPECT_PRIVACY: 'Respect the privacy of others, no personal information sharing',
    OFF_TOPIC: 'No off-topic posts',
    NO_ILLEGAL: 'No illegal content',
    NO_PERSONAL_ATTACKS: 'Focus on ideas, rather than character judgments',
    BE_CONSTRUCTIVE: 'Explain disagreements constructively',
}

export const PARENT_GUIDELINES = {
    ENGAGE_RESPECTFULLY: 'Engage Respectfully',
    SUPPORT_YOUR_CLAIMS: 'Support Your Claims',
    NO_HARMFUL_CONTENT: 'No Harmful Content',
    NO_DISRUPTIVE_BEHAVIOR: 'No Disruptive Behavior',
}

// ruleIds here must map to those used in server/prompts/moderate.txt
export const communityGuidelines = [
    {
        parent: PARENT_GUIDELINES.ENGAGE_RESPECTFULLY,
        children: [
            { ruleId: 1, text: GUIDELINES.NO_UNCIVIL },
            { ruleId: 11, text: GUIDELINES.NO_UNCIVIL },
            { ruleId: 3, text: GUIDELINES.NO_PERSONAL_ATTACKS },
            { ruleId: 4, text: GUIDELINES.NO_LABELS },
            { ruleId: 5, text: GUIDELINES.NO_INSULTS },
            { ruleId: 6, text: GUIDELINES.BE_CONSTRUCTIVE },            
        ]
    },
    {
        parent: PARENT_GUIDELINES.SUPPORT_YOUR_CLAIMS,
        children: [
            { ruleId: 7, text: GUIDELINES.BACKUP_CLAIMS }
        ]
    },
    {
        parent: PARENT_GUIDELINES.NO_HARMFUL_CONTENT,
        children: [
            { ruleId: 2, text: GUIDELINES.NO_HARM },
            { ruleId: 9, text: GUIDELINES.RESPECT_PRIVACY },
            { text: GUIDELINES.NO_ILLEGAL } // we don't currently AI-moderate this
        ]
    },
    {
        parent: PARENT_GUIDELINES.NO_DISRUPTIVE_BEHAVIOR,
        children: [
            { ruleId: 8, text: GUIDELINES.NO_SPAM },
            { ruleId: 10, text: GUIDELINES.OFF_TOPIC }
        ]
    }
];

export function getRuleById(ruleId) {
    for (const group of communityGuidelines) {
        const childRule = group.children.find(child => child.ruleId === ruleId);
        if (childRule) return childRule.text;
    }
    return GUIDELINES.NO_UNCIVIL; // Default in case there's no match
}

export function getParentRuleByChildText(childText) {
    for (const group of communityGuidelines) {
        const childRule = group.children.find(child => child.text === childText);
        if (childRule) {
            return group.parent;
        }
    }
    return null;
}

export function CommunityGuidelines() {
    const [showCommunityGuidelines, setShowCommunityGuidelines] = useState(false);
    return <View>
        <SubtleButton type='tiny' icon={Pin} color={colorBlackHover} label='Community Guidelines' ariaLabel={'Community Guidelines (pop-up window)'} onPress={() => setShowCommunityGuidelines(true)}/>
        {showCommunityGuidelines && React.createElement(GuidelinesModal, {onClose: () => setShowCommunityGuidelines(false)})}
    </View>
}

export function GuidelinesModal({onClose}) {
    const renderedRules = new Set();

    return <Modal onClose={onClose} >
        <PadBox horiz={spacings.lg} vert={spacings.lg}>
            <Heading type="large" weight="medium" label='Community Guidelines'/>
            <Pad />
            <Paragraph label='We strive for a kind and constructive discussion space where everyone feels welcome to share their perspectives and experiences. You can contribute to a discussion with responses that are on-topic and relevant to the subject matter, including meaningful debate.' />
            <Pad size={21} />
            <RichText label='To maintain a welcoming and respectful environment, this space uses AI to help moderate comments. AI may flag your post for your additional review if it does not follow the [Community Guidelines]($onPress)' suffixText=':'/>
            <Pad size={32} /> 
            <Banner color={colorTeaserBackground}>
                {communityGuidelines.map((group, index) => (
                    <View key={index} role="list">
                        <Heading type="small" label={group.parent} />
                        <Pad size={8} />
                        {group.children
                            .filter(rule => !renderedRules.has(rule.text) && renderedRules.add(rule.text))
                            .map((rule, i) => (<BulletText key={i} label={rule.text} />))}
                        <Pad size={16} />
                    </View>
                ))}            
            </Banner>
        </PadBox>
    </Modal>    
}

function BulletText({label, text}) {
    return <View>
        <Pad size={5} />
        <HorizBox role="listitem">
            <Pad size={10}/>
            <UtilityText text='•' />
            <Pad size={10}/>
            <UtilityText label={label} text={text} />
        </HorizBox>
        <Pad size={5} />
    </View>
}