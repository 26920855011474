import { LengthLimitFeature } from "feature/general/LengthLimitFeature"
import { UpvoteFeature } from "feature/general/UpvoteFeature"
import { VideoSyncFeature } from "./deprecated/VideoSyncFeature"
import { AgreeDisagreeFeature, CommentSliderEnableVisualizationFeature, CommentSliderFeature, CommentSliderHorizVisualizationFeature, CommentSliderNoDefaultFeature, CommentSliderPollPickerFeature, CommentSliderSliderFeature, CommentSliderVertVisualizationFeature, YesNoFeature } from "./question/CommentSliderFeature"
import { ModerateRepliesStrictlyFeature, ModerateWithGPTFeature, ModerateWithJigsawFeature, ModerationHelpFeature, PreModeratorFeature } from "./moderation/AIPreModeratorFeature"
import { ProfileArticleRecommendationsFeature } from "./zdf/ProfileArticleRecommendationsFeature"
import { ProfileConversationRecommendationsFeature } from "./zdf/ProfileConversationRecommendationsFeature"
import { ProfileInterestsFeature } from "./zdf/ProfileInterestsFeature"
import { VideoQuoteFeature } from "./zdf/VideoQuoteFeature"
import { ArticleTopicTaggingFeature } from "./zdf/ArticleTopicTagFeature"
import { ConvoMatchFeature } from "./zdf/ConvoMatchFeature"
import { ProfileAnswersFeature } from "./profile/ProfileAswersFeature"
import { CMSEmbeddedQuestionTeaserFeature, CMSEmbeddedStandardFeature, EditorialQuestionFeature, GreenQuestionTeaserFeature, TanQuestionTeaserFeature } from "./teaser/EditorialQuestionFeature"
import { EmbeddedQuestionTeaserFeature } from "./teaser/EmbeddedQuestionTeaser"
import { FunReactionsFeature, NewPublicReactionsFeature, PersonalReactionsFeature, ReactionTypesFeature, ReactionTypesMultipleButtonsFeature, ReactionTypesPopupButtonFeature, SeriousReactionsFeature } from "./question/ReactionTypesFeature"
import { ReplyNotificationsFeature } from "feature/question/ReplyNotifsFeature"
import { VideoVotingFeature, VideoVotingVideoOverlayFeature } from "./zdf/videovoting/VideoVotingArticleFeature"
import { VideoVotingOverviewFeature } from "./zdf/videovoting/VideoVotingOverviewFeature"
import { ProfileSettingsFeature } from "./zdf/ProfileSettingsFeature"
import { AccountVideoVotingSettingFeature } from "./zdf/videovoting/AccountVideoVotingSettingFeature"
import { CBCRCDemoFeature } from "../demo/cbcrc/componentdemo"
import { ZDFDemoFeature } from "../demo/zdf/componentdemozdf"
import { DemoConversationFeatures } from "../demo/question/conversationfeatures"
import { RepresentingPerspectivesCardFeature, RepresentingPerspectivesFeature, RepresentingPerspectivesSpeechBubbleFeature, RepresentingPerspectivesSubtleBubbleFeature } from "./question/RepresentingPerspectives"
import { ContextEntryPointsFeature } from "./question/ContextEntryPointsFeature"
import { ProfileTitledWriterFeature } from "./cbcrc/profile/ProfileTitledWriterFeature"
import { TitledWriterFeature } from "./cbcrc/general/TitledWriterFeature"
import { TranslationFeature } from "../contrib/srg/translation/TranslationFeature"
import { ConversationHelperAddonFeatures, ConversationHelperFeature, ConversationHelperGuidanceFeedbackSet1Feature, ConversationHelperGuidanceFeedbackSet2Feature } from "./zdf/ConversationHelperFeature"
import { ConversationHelperCommentBasedTimingFeature, ConversationHelperCommentBasedTimingFeature1, ConversationHelperCommentBasedTimingFeature2, ConversationHelperCommentBasedTimingFeature3, ConversationHelperCommentBasedTimingFeature4, ConversationHelperManualTimingFeature, ConversationHelperSettingsFeatures, GuidanceUpdateCommentIntervalFeature0, GuidanceUpdateCommentIntervalFeature1, GuidanceUpdateCommentIntervalFeature2, GuidanceUpdateCommentIntervalFeature3, GuidanceUpdateCommentIntervalFeature4, GuidanceUpdateIntervalFeature } from "./zdf/ConversationHelperAdminFeature"
import { TeaserDemoFeature } from "../demo/teaser/teaserdemo"
import { ProfileAnswersDemo } from "../demo/profile/profileanswers-demo"
import { AskMeAnythingTeaserFeature, TopicTeaserFeature } from "./cbcrc/connect/TopicTeaserFeature"
import { PinCommentFeature } from "./question/PinCommentFeature"
import { ReportCommentFeature } from "./moderation/ReportCommentFeature"
import { AdminModLinkFeature, NewModLinkFeature } from "./admin/AdminModLinkFeature"
import { DemoModerationFeature } from "../demo/moderation/moderation-demo"
import { HideRejectedFeature } from "./moderation/HideRejectedFeature"
import { HumanReviewsEverythingFeature } from "./moderation/HumanReviewsEverythingFeature"
import { DemoFallbacksFeature } from "../demo/moderation/fallbacks-demo"
import { ModerationDashDemoFeature } from "../demo/admin/moderationdash-demo"
import { CloseConversationFeature } from "feature/question/CloseConversationFeature"
import { OpenLinksInNewTabFeature } from "feature/general/OpenLinksInNewTabFeature"
import { BlacklistFeature } from "./zdf/premoderation/BlacklistFeature"
import { CommunityGuidelinesFeature, CommunityGuidelinesLeftFeature } from "./question/CommunityGuidelinesFeature"
import { SingleResponseHelpFeature } from "./question/SingleResponseHelpFeature"
import { NextTopicPollFeature, TopicPollBottomPositionFeature, TopicPollFeature, TopicPollInContentPositionFeature, TopicPollTopPositionFeature } from "./cbcrc/topic/TopicPollFeature"
import { TopicAIModerationTagFeature, TopicOfTheMomentCallToActionFeature, TopicPopularQuestionsFeature, TopicUserQuestionCallToActionFeature, TopicUserQuestionsFeature } from "./cbcrc/topic/UserQuestionsFeature"
import { TopicRelatedConversationsFeature } from "./cbcrc/topic/RelatedConversationsFeature"
import { TopicRelatedArticlesCarouselFeature } from "./cbcrc/topic/RelatedArticlesCarouselFeature"
import { TopicLiveStatusRightFeature } from "./cbcrc/topic/LiveStatusFeature"
import { TopicParticipantsFeature } from "./cbcrc/topic/TopicParticipants"
import { TopicPhotoTeaserForTopicFeature } from "./teaser/TopicPhotoTeaser"
import { QuestionCardsFeature } from "./topic/QuestionCardsFeature"
import { EmbeddedFeature } from "./question/EmbeddedFeature"
import { ReadingListFeature } from "./topic/ReadingListFeature"
import { AskMeAnythingHeaderFeature } from "./cbcrc/topic/AskMeAnythingHeaderFeature"
import { TopicConfigDemoFeature, TopicConfigFeature } from "../demo/topic/topic-config"
import { DemoFeature, DemoSecondaryFeature } from "demo/core-design-system/comment-config"
import { DemoQuestionBasicsFeature } from "../demo/question/questionbasics-demo"
import { PuppetSupportFeature } from "./admin/PuppetTestSupportFeature"
import { DemoFilterFeature } from "../demo/core-design-system/DemoFilter"
import { BasicTeaserFeature } from "./teaser/BasicTeaserFeature"
import { DemoProfileFeature } from "../demo/profile/profile-config"
import { ProfileCommentsFeature } from "./profile/ProfileCommentsFeature"
import { DesignSystemDemoFeature } from "../demo/core-design-system/designsystem-demo"
import { CommentDemoFeature } from "../demo/core-design-system/comment-demo"
import { ProfileDemoFeature } from "../demo/profile/profile-demo"
import { ProfileEditDemo } from "../demo/profile/profileedit-demo"
import { ProfilePhotoAndNameFeature } from "./profile/ProfilePhotoAndName"
import { AdminUsersFeature } from "./admin/AdminUsersFeature"
import { AdminDemo } from "../demo/admin/admin-demo"
import { TopBarDemoFeature } from "../demo/core-design-system/topbar-demo"
import { BasicFeaturesDemoFeature } from "../demo/core-design-system/basicfeatures-demo"
import { EmbeddedInstanceDemoFeature } from "../demo/core-design-system/datamodel-demo"
import { LoginDemo } from "../demo/core-design-system/login-demo"
import { HelpDemoFeature } from "../demo/core-design-system/help-demo"
import { ProfileDeleteDemo } from "demo/account/profiledelete-demo"
import { AccountDeleteFeature } from "./account/AccountDeleteFeature"
import { AskMeAnythingConfigFeature, BaseTopicConfigFeature, TopicOfTheMomentConfigFeature } from "./cbcrc/topic/TopicOfTheMomentConfigFeature"
import { PinAnswerFeature } from "./cbcrc/topic/PinAnswerFeature"
import { JumpToCommentFeature } from "./zdf/JumpToCommentFeature"
import { AutoApproveFeature } from "./zdf/premoderation/AutoApproveFeature"
import { EnableModDashboardThreadDetailsFeature } from "./zdf/question/EnableThreadModDashboardViewFeature"
import { RecordEntryUrlFeature } from "./question/RecordEntryUrl"
import { AccountRequestDataFeature } from "./account/RequestDataFeature"
import { QuestionListFeature } from "./admin/QuestionList"
import { TopicListFeature } from "./cbcrc/admin/TopicListFeature"
import { AITagFeature } from "./question/AITagFeature"
import { CommentsSortFeature } from "./cbcrc/comments/CommentsSortFeature"
import { DeleteCommentsInModDashboardFeature } from "./zdf/question/DeleteCommentsinModDashboardFeature"
import { PollDemoFeature } from "demo/core-design-system/poll-demo"
import { SurveyDemoFeature } from "demo/general/survey-demo"
import { SatisfactionSurveyFeature, UsabilitySurveyFeature } from "./general/SurveyFeature"
import { VideoVotingListFeature } from "./zdf/admin/VideoVotingListFeature"
import { TopicDemoFeature } from "demo/topic/topic-demo"
import { PuppetDemoFeature } from "demo/admin/puppet-demo"
import { ExportCommentsFeature, ImportCommentsFeature } from "./developer/ImportExportFeature"
import { RankingSelectorFeature } from "./ranking/RankingSelectorFeature"
import { MostReactedFeature, MostRecentFeature, MostUpvotedFeature, OldestFeature } from "./ranking/SimpleRankingFeature"
import { AIQualityRankingFeature, ShowRankingFeaturesFeature } from "./ranking/AIQualityRankingFeature"
import { EmailPreviewFeature } from "./admin/EmailPreviewFeature"
import { PrivateCommentFeedbackFeature } from "./zdf/PrivateCommentFeedbackFeature"
import { TopTabFeature } from "./ranking/TopTabFeature"
import { DialogueFeature } from "../contrib/srg/feature/question/DialogueFeature";
import { DialogueEmbeddedQuestionTeaserFeature, DialogueGreenQuestionTeaserFeature, DialogueTanQuestionTeaserFeature } from "../contrib/srg/feature/teaser/dialog-teaser";
import { PostAnywayFilterFeature } from "./moddash/PostAnywayFilterFeature"
import { Feature } from "util/stdtypes"

// Base types with discriminator field
export type SectionFeature = {
    kind: 'section';
    label: string;
    level?: number;
    features: FeatureMenuItem[];
}

export type CompositeFeature = {
    kind: 'composite';
    parent: Feature;
    features: FeatureMenuItem[];
}

export type ChooseOneFeature = {
    kind: 'chooseOne';
    label: string | null;
    features: FeatureMenuItem[];
}

// Helper functions that create the correct discriminated types
export function SECTION(label : string, features : FeatureMenuItem[]) : SectionFeature { 
    return { kind: 'section', label, features } 
}

export function SUBSECTION(label : string, features : FeatureMenuItem[]) : SectionFeature { 
    return { kind: 'section', level: 2, label, features } 
}

export function COMPOSITE(parent : Feature, features : FeatureMenuItem[]) : CompositeFeature { 
    return { kind: 'composite', parent, features } 
}

export function CHOOSEONE(label : string | null, features : FeatureMenuItem[]) : ChooseOneFeature { 
    return { kind: 'chooseOne', label, features } 
}

// Union type of all possible feature types
export type FeatureGroup = SectionFeature | CompositeFeature | ChooseOneFeature;
export type FeatureMenuItem = FeatureGroup | Feature;


export var features : Record<string, FeatureGroup[]> = {
    account: [
        SECTION('Account Management', [
            AccountRequestDataFeature,
            AccountDeleteFeature,
            AccountVideoVotingSettingFeature
        ]),
        SECTION('Account Settings', [
            AccountVideoVotingSettingFeature
        ])
    ],
    admin: [
        SECTION('Admin Modules', [
            AdminUsersFeature,
            QuestionListFeature,
            TopicListFeature,
            VideoVotingListFeature,
            EmailPreviewFeature
        ]),
        SECTION('Quick links', [
            AdminModLinkFeature,
            NewModLinkFeature
        ]),
        SECTION('Screens', [
            PuppetSupportFeature
        ])
    ],
    article: [
        COMPOSITE(EditorialQuestionFeature, [
            CHOOSEONE('Teaser Type', [
                GreenQuestionTeaserFeature,
                TanQuestionTeaserFeature,
                AskMeAnythingTeaserFeature,
                TopicTeaserFeature,
                DialogueGreenQuestionTeaserFeature,
                DialogueTanQuestionTeaserFeature,
                EmbeddedQuestionTeaserFeature
            ])
        ]),
        // EditorialQuestionFeature,
        COMPOSITE(VideoVotingFeature, [
            VideoVotingVideoOverlayFeature
        ]),
    ],
    componentdemo: [
        SECTION('Core Component Sets', [
            DesignSystemDemoFeature,
            CommentDemoFeature,
            ProfileDemoFeature,
            ProfileEditDemo,
            AdminDemo,
            TopBarDemoFeature,
            BasicFeaturesDemoFeature,
            EmbeddedInstanceDemoFeature,
            LoginDemo,
            HelpDemoFeature,
            ProfileDeleteDemo,
            PollDemoFeature,
            SurveyDemoFeature,
            PuppetDemoFeature,
            CBCRCDemoFeature,
            ZDFDemoFeature,
            DemoConversationFeatures,
            TeaserDemoFeature,
            ProfileAnswersDemo,
            DemoModerationFeature,
            DemoFallbacksFeature,
            ModerationDashDemoFeature,
            TopicConfigDemoFeature,
            DemoQuestionBasicsFeature,
            TopicDemoFeature
        ]),
        SECTION('Configuration', [
            OpenLinksInNewTabFeature
        ])
    ],
    moderationdash: [
        SECTION('Filters', [
            PostAnywayFilterFeature
        ])
    ],
    profile: [
        SECTION('Developer', [
            DemoProfileFeature,
            ProfileCommentsFeature
        ]),
        SECTION('General', [
            ProfilePhotoAndNameFeature,
            ProfileAnswersFeature,
        ]),
        SECTION('ZDF', [
            ProfileInterestsFeature,
            ProfileConversationRecommendationsFeature,
            ProfileArticleRecommendationsFeature,
            COMPOSITE(ProfileSettingsFeature, [
            ])
        ]),
        SECTION('CBCRC', [
            ProfileTitledWriterFeature,
        ]),        
    ],
    question: [
        SECTION('Conversation Type', [
            COMPOSITE(CommentSliderFeature, [
                CHOOSEONE('Spectrum', [
                    YesNoFeature,
                    AgreeDisagreeFeature
                ]),
                CHOOSEONE('Composer', [
                    CommentSliderPollPickerFeature,
                    CommentSliderSliderFeature,
                    CommentSliderNoDefaultFeature
                ]),
                COMPOSITE(CommentSliderEnableVisualizationFeature, [
                    CHOOSEONE(null, [
                        CommentSliderHorizVisualizationFeature,
                        CommentSliderVertVisualizationFeature
                    ]),
                ]),
            ]),
            COMPOSITE(RepresentingPerspectivesFeature, [
                CHOOSEONE('UI Variant', [
                    RepresentingPerspectivesCardFeature,
                    RepresentingPerspectivesSpeechBubbleFeature,
                    RepresentingPerspectivesSubtleBubbleFeature
                ]),
            ]),
            DialogueFeature,
            COMPOSITE(ConversationHelperFeature, [
                ...ConversationHelperAddonFeatures,
                CHOOSEONE('Feedback Set', [
                    ConversationHelperGuidanceFeedbackSet1Feature,
                    ConversationHelperGuidanceFeedbackSet2Feature
                ]),
                PrivateCommentFeedbackFeature,
                SECTION('Timing of Analysis', [
                        // TODO: How can we make automatic analysis and manual analysis an "exclusive or"? Being able to turn both on is confusing and doesn't make sense. CHOOSEONE can't have a sub-menu though.
                        COMPOSITE(ConversationHelperCommentBasedTimingFeature, [
                            CHOOSEONE('Analyze after every...', [
                                ConversationHelperCommentBasedTimingFeature1,
                                ConversationHelperCommentBasedTimingFeature2,
                                ConversationHelperCommentBasedTimingFeature3,
                                ConversationHelperCommentBasedTimingFeature4,
                            ]),
                        ]),
                        ConversationHelperManualTimingFeature,
                        COMPOSITE(GuidanceUpdateIntervalFeature, [
                            CHOOSEONE("Update based on comments...", [
                                GuidanceUpdateCommentIntervalFeature0,
                                GuidanceUpdateCommentIntervalFeature1,
                                GuidanceUpdateCommentIntervalFeature2,
                                GuidanceUpdateCommentIntervalFeature3,
                                GuidanceUpdateCommentIntervalFeature4,
                            ]),
                        ]),
                    ]),
                    ...ConversationHelperSettingsFeatures,

            ]),
        ]),
        SECTION('Moderation', [
            HideRejectedFeature,
            COMPOSITE(PreModeratorFeature, [
                ModerateWithGPTFeature,
                ModerateWithJigsawFeature,
                ModerateRepliesStrictlyFeature,
                BlacklistFeature,
            ]),            
            AutoApproveFeature,
            ModerationHelpFeature,
            ReportCommentFeature,
            TopTabFeature,
            EnableModDashboardThreadDetailsFeature,
            DeleteCommentsInModDashboardFeature
        ]),
        SECTION('General', [
            AITagFeature,
            JumpToCommentFeature,
            LengthLimitFeature,
            CommunityGuidelinesFeature,
            ContextEntryPointsFeature,
            EmbeddedFeature,
            OpenLinksInNewTabFeature,
            PinCommentFeature,
            COMPOSITE(ReactionTypesFeature, [
                CHOOSEONE('Interaction', [
                    ReactionTypesMultipleButtonsFeature,
                    ReactionTypesPopupButtonFeature
                ]),
                CHOOSEONE('Reaction Set', [
                    NewPublicReactionsFeature,
                    SeriousReactionsFeature,
                    PersonalReactionsFeature,
                    FunReactionsFeature,
                ])
            ]),
            RecordEntryUrlFeature,
            VideoVotingOverviewFeature,
            ReplyNotificationsFeature,
            DemoFeature,
            SingleResponseHelpFeature,
            COMPOSITE(CMSEmbeddedQuestionTeaserFeature, [
                DialogueEmbeddedQuestionTeaserFeature
            ]),
            SatisfactionSurveyFeature,
            UsabilitySurveyFeature,
            TitledWriterFeature,
            TranslationFeature,
            UpvoteFeature,
            ExportCommentsFeature
        ]),
        SECTION('Fallbacks', [
            HumanReviewsEverythingFeature,
            CloseConversationFeature,
        ]),
        SECTION('Ranking', [
            RankingSelectorFeature,
            MostRecentFeature,
            OldestFeature,
            MostUpvotedFeature,
            MostReactedFeature,
            AIQualityRankingFeature,
            ShowRankingFeaturesFeature,
        ])
    ],
    simplecomments: [
        SECTION('Developer', [
            COMPOSITE(DemoFeature, [
                DemoSecondaryFeature,
            ]),
            DemoModerationFeature,
            DemoFilterFeature,
            ImportCommentsFeature,
            ExportCommentsFeature
        ]),
        SECTION('General', [
            UpvoteFeature,
            LengthLimitFeature,
            BasicTeaserFeature,
            ReplyNotificationsFeature,
            CloseConversationFeature,
            OpenLinksInNewTabFeature,
        ]),
        SECTION('Ranking', [
            RankingSelectorFeature,
            MostRecentFeature,
            OldestFeature,
            MostUpvotedFeature,
            MostReactedFeature,
            AIQualityRankingFeature,
            ShowRankingFeaturesFeature,
        ]),
        SECTION('PSI Extensions', [
            CommentSliderFeature,
            TranslationFeature,
            VideoSyncFeature,
            VideoQuoteFeature,
            ArticleTopicTaggingFeature,
            ConvoMatchFeature,
            CommunityGuidelinesFeature,
        ]),
        SECTION('Moderation', [
            HideRejectedFeature,
            COMPOSITE(PreModeratorFeature, [
                ModerateWithGPTFeature,
                ModerateWithJigsawFeature,
                ModerateRepliesStrictlyFeature,
                BlacklistFeature,
            ]),
            AutoApproveFeature,
            ReportCommentFeature,
            HumanReviewsEverythingFeature,
            CloseConversationFeature
        ]),
    ],
    topic: [
        SECTION('Topic template', [
            COMPOSITE(BaseTopicConfigFeature, [
                TopicUserQuestionCallToActionFeature,
            ]),
            COMPOSITE(TopicOfTheMomentConfigFeature, [
                TopicLiveStatusRightFeature,
                TopicParticipantsFeature,
                TopicOfTheMomentCallToActionFeature,
                CommunityGuidelinesLeftFeature,
            ]),
            COMPOSITE(AskMeAnythingConfigFeature, [
                AskMeAnythingHeaderFeature,
                CommunityGuidelinesLeftFeature,
            ]),
        ]),
        SECTION('Content', [
            TopicUserQuestionsFeature, // DEV feature enabled by default
            TopicPopularQuestionsFeature, // DEV feature enabled by default
            COMPOSITE(TopicPollFeature, [
                CHOOSEONE('Position', [
                    TopicPollTopPositionFeature,
                    TopicPollInContentPositionFeature,
                    TopicPollBottomPositionFeature
                ])]),
            TopicRelatedConversationsFeature,
            TopicRelatedArticlesCarouselFeature,
            NextTopicPollFeature,
            QuestionCardsFeature, // DEV feature
            UpvoteFeature, // DEV feature enabled by default
        ]),
        SECTION('Moderation', [
            HideRejectedFeature, // DEV feature enabled by default
            COMPOSITE(PreModeratorFeature, [
                ModerateWithGPTFeature,
                ModerateWithJigsawFeature,
                ModerateRepliesStrictlyFeature,
                BlacklistFeature,
            ]),
            AutoApproveFeature,
            ModerationHelpFeature, // DEV feature enabled by default
            ReportCommentFeature,
            EnableModDashboardThreadDetailsFeature,
            DeleteCommentsInModDashboardFeature
        ]),
        SECTION('General', [
            TopicAIModerationTagFeature, // DEV feature enabled by default
            CommunityGuidelinesFeature, // DEV feature enabled by default
            ContextEntryPointsFeature,
            ReadingListFeature,
            PinCommentFeature,
            ReplyNotificationsFeature, // DEV feature enabled by default
            RecordEntryUrlFeature, // DEV feature enabled by default
            TopicPhotoTeaserForTopicFeature, // DEV feature
            TopicConfigFeature, // DEV feature disabled by default
            TitledWriterFeature, // DEV feature enabled by default
            PinAnswerFeature, // DEV feature enabled by default
            CommentsSortFeature, // DEV feature enabled by default
            SatisfactionSurveyFeature, // DEV feature enabled by default
            UsabilitySurveyFeature, // DEV feature enabled by default
            EnableModDashboardThreadDetailsFeature, // Dev feature, enables detail view within mod dashboard
        ]),
        SECTION('Fallbacks', [
            HumanReviewsEverythingFeature,
            CloseConversationFeature, // this needs to be supported in Topic, right now it doesnt do anything
        ])
    ],
}

export var defaultFeatureConfig = {
    account: {
        profilerequestdata: true,
        profiledelete: true
    },
    admin: {
        admin_link_mod: true,
        admin_new_link_mod: true,
        editorialtopic: true,
        puppettest: true,
        videovotinglist: true,
        adminusers: true,
        questionlist: true,
        topiclist: true,
        emailpreview: true
    },
    article: {
        editorialquestion: true,
        videovotingArticle: false,
        videovotingArticleTextIntegration: false,
    },
    articlequestions: {
        upvotequestion: true,
        questionteaser: true
    },
    componentdemo: {
        demo_designsystem: true,
        demo_comment: true,
        demo_profile: true,
        demo_eventlog: true,
        demo_profileedit: true,
        demo_admin: true,
        demo_topbar: true,
        demo_basicfeatures: true,
        demo_embedded: true,
        demo_login: true,
        demo_help: true,
        demo_profile_delete: true,
        demo_profileanswers: true,
        demo_poll: true,
        demo_survey: true,
        demo_puppet: true,
        demo_questionbasics: true,
        demo_cbcrc: true,
        demo_zdf: true,
        demo_conversation: true,
        demo_teaser: true,
        demo_fallbacks: true,
        demo_moderationdash: true,
        demo_moderation: true,
        demo_topic: true,
        demo_topic_config: true
    },    
    moderationdash: {
        post_anyway_filter: true
    },
    profile: {
        profileanswers: true,
        profiledelete: true,
        profileeditname: true,
    },
    simplecomments: {
        openlinksinnewtab: true,
        premoderator: true,
        articletopictagging: false,
        convomatch: false,
        translationfeature: false,
        hide_rejected: true,
        premoderation: true,
        aimoderation: true,
        jigsawmoderation: true,
        strictreplymoderation: true,
        report_comment: true
    },
    question: {
        lengthlimit: false,
        upvote: false,
        reactiontypes: true,
        replynotifications: true,
        commentslider: false,
        translationfeature: false,
        community_guidelines: true,
        votingoverview: false,
        record_entry_url: true,
        aitag: true,
        pin_comment: true,
        single_response_help: true,
        satisfaction_survey: true,
        usability_survey: true,

        jumptocomment: true,
        context_entry_points: true,

        // Radio button first features: TODO - auto-select these
        commentslider_yes: true,
        commentslider_slider: true,
        commentslider_horiz_visualization: true,
        commentslider_enable_visualization: true,
        newpublicreactions: true,
        // representing_perspectives_card: true,
        representing_perspectives_subtle_bubble: true,
        reactiontypesmultiplebuttons: true,

        // Moderation 
        hide_rejected: true,
        aimoderation: true,
        jigsawmoderation: true,
        premoderation: true,
        report_comment: true,
        // moderationhelp: true,
        autoapprovecomments: true,
        deletecommentsinmoddashboard: true,

        //Conversation helper Settings
        conversationhelper_timing_comments: true,
        conversationhelper_timing_comments_1: true,
        conversationhelper_personality_b: true,
        conversationhelper_scope_all_comments: true,
        conversationhelper_scope_threshold_1: true,
        conversationhelper_top_x_percent_3: true,
        conversationhelper_guidance_update_comment_interval_0: true,
        conversationhelper_guidance_feedback_set_1: true,
        cmsembeddedquestionteaser: true,
        conversationhelper_ai_generated:true
    },
    topic: {
        editorialtopic: true,
        topictype: true,
        poll: true,
        upvote: true,
        commentssort: true,
        premoderator: false,
        record_entry_url: true,
        context_entry_points: true,
        community_guidelines: true,
        pin_comment: true,
        satisfaction_survey: true,
        usability_survey: true,

        // All CBC modules on by default for now
        amaheader: true,
        basetopicconfig: true,
        topicuserctaquestions: true,
        topicpopularquestions: true,
        topicuserquestions: true,
        topicrelatedcontent: true,
        topicpoll: true,
        nexttopicpoll: true,
        topicfollow: true,
        topiclivestatusright: true,
        topicparticipants: true,
        titledWriter: true,
        pin_answer: true,
        tomuserctaquestions: true,

        topicphototeaser_topic: true,
        replynotifications: true,

        // Moderation 
        topicaimoderationtag: true,
        hide_rejected: true,
        aimoderation: true,
        jigsawmoderation: true,
        premoderation: true,
        report_comment: true,
        moderationhelp: true,
        autoapprovecomments: true,
        deletecommentsinmoddashboard: true,
    },
}

export var roles = {
    Owner: {
        allCapabilities: true,
        can: [
            'adminusers/modify-admins'
        ]
    },
    Developer: {
        inherits: ['Editorial', 'Super-Moderator', 'Analyst']
    },
    'Super-Moderator': {
        inherits: ['Moderator']
    },
    Moderator: {
        can: [
            'remove-comment',
            'moderate'
        ]
    },
    Editorial: {
    },
    Analyst: {
        can: [
            'eventlog/view'
        ]
    }
}

export function addFeatures(newFeaturesForStructs) {
    Object.keys(newFeaturesForStructs).forEach(structureKey => {
        const oldFeatures = features[structureKey] || [];
        const newFeatures = newFeaturesForStructs[structureKey];
        features[structureKey] = [...oldFeatures, ...newFeatures]
    })
}

export function addDefaultFeatures(newDefaultFeatures) {
    Object.keys(newDefaultFeatures).forEach(structureKey => {
        const newDefaults = newDefaultFeatures[structureKey];
        const oldDefaults = defaultFeatureConfig[structureKey] || {};
        const mergedDefaults = { ...oldDefaults, ...newDefaults };
        defaultFeatureConfig[structureKey] = mergedDefaults;
    })
}

export const newFeatures = {}

export const newDefaultFeatures = {}

export const newRoles = {
    Owner: {
    },
    Developer: {
        inherits: ['Editorial', 'Super-Moderator', 'Analyst'],
        can: ['features/toggle-experimental', 'developer/add-comments']
    },
    'Super-Moderator': {
        inherits: ['Moderator'],
        can: ['moderation/edit-blacklist']
    },
    Moderator: {
        can: ['moderation/judge', 'moderation/view', 'moderation/view-blacklist']
    },
    Editorial: {
        can: ['features/toggle-stable']
    },
    Analyst: {
        can: [
            'eventlog/view'
        ]
    }
}
