import React, { useState } from "react";
import { UtilityText } from "./text";
import { colorAmbigousBlack, colorBorderPrimary, colorTextSecondary } from "./color";
import { Catcher } from "system/catcher";
import { StyleSheet, View } from "react-native";
import { HorizBox, HoverView, Pad } from "./basics";
import { useDatastore } from "util/datastore";

export function TabbedView({tabs, selectedTabKey, onSelectTab}) {
    if (tabs.length === 1) {
        return <Catcher>
            {React.createElement(tabs[0].component)}
        </Catcher>  
    }
    
    let selectedTab = getTab(tabs, selectedTabKey);
    return <View>
        <TabBar tabs={tabs} selectedTabKey={selectedTab.key} onSelectTab={onSelectTab} />
        <Pad size={32} />
        <Catcher>
            {React.createElement(selectedTab.component)}
        </Catcher>
    </View>
}

function getTab(tabs, selectedTabKey) {
    if (tabs.length === 1) {
        return tabs[0];
    }
    let tab = tabs.find(tab => tab.key === selectedTabKey);
    if (tab == null) {
        tab = tabs[0];
    }
    return tab;
}

export function TabBar({tabs, selectedTabKey, onSelectTab}) {
    return <View>
        <HorizBox gap={24}>
            {tabs.map(tab => (
                <TabButton key={tab.key} tab={tab} selectedTabKey={selectedTabKey} onSelectTab={onSelectTab} />
            ))}
        </HorizBox>
        <TabBarUnderline />
    </View>
}

function TabBarUnderline() {
    const s = TabBarUnderlineStyle;
    return <View style={s.tabBarUnderline} />
}
const TabBarUnderlineStyle = StyleSheet.create({
    tabBarUnderline: {
        height: 1,
        position: 'relative',
        bottom: 1,
        zIndex: -1,
        backgroundColor: colorBorderPrimary
    }
})

function TabButton({tab, selectedTabKey, onSelectTab}) {
    const s = TabButtonStyle;
    const datastore = useDatastore();
    const [hover, setHover] = useState(false);
    const isSelected = selectedTabKey === tab.key;

    function onPress() {
        onSelectTab(tab.key);
        if (tab.onShow) {
            tab.onShow({datastore});
        }
    }

    return <HoverView 
            onPress={onPress} 
            style={isSelected ? s.selectedTabButton : s.unselectedTabButton}
            setHover={setHover}
        >
        <UtilityText 
            weight={(isSelected || hover) ? 'strong' : 'normal'} 
            color={isSelected ? colorAmbigousBlack : colorTextSecondary}
            label={tab.label} 
            text={tab.text}
        />
    </HoverView>
}
const TabButtonStyle = StyleSheet.create({
    selectedTabButton: {
        paddingBottom: 10,
        borderBottomWidth: 2,
        borderBottomColor: 'black'
    },
    unselectedTabButton: {
        paddingBottom: 10,
        borderBottomWidth: 2,
        borderBottomColor: 'transparent'
    },
    selectedTabButtonText: {
        fontWeight: 'bold'
    },
    unselectedTabButtonText: {
        fontWeight: 'normal'
    }
})