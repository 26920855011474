import { Pad } from "component/basics";
import { colorTextSecondary } from "component/color";
import { RichText } from "component/richtext";
import { Heading } from "component/text";
import { View } from "react-native";

export const AccountRequestDataFeature = {
    name: 'Profile Request Data',
    key: 'profilerequestdata',
    config: {
        accountWidgets: [AccountRequestDataWidget]
    }
}

export function AccountRequestDataWidget() {
    return <View>
        <Pad size={40} />
        <Heading type='small' label='Request my data'/>
        <Pad size={8} />
        <RichText color={colorTextSecondary} label='Send an email to [privacy@newpublic.org](mailto:privacy@newpublic.org) to request a copy of your data associated with this discussion space. It may take up to 30 days to process your request.'/>        
    </View>
}