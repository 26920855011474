export const ui_human_translations_french = {
    // Post Anyway
    "📢 Write with more influence": "📢 Augmentez votre influence",
    "You can still post anyway, but rewording may help your post sway more people and lead to more constructive discussion. Without changes, your post will appear in the \"All\" tab and may be reviewed by moderators.": "Votre réponse peut être publiée telle quelle, mais reformulée, elle pourrait être plus influente et susciter des échanges plus constructifs. Si vous ne la modifiez pas, elle s’affichera sous l’onglet « Tout » et pourrait être révisée par un modérateur.",
    "To have your post reach more people, consider these tips:": "Suivez ces conseils pour toucher plus de gens :",
    "AI review found language in your response that might turn away readers with different viewpoints.": "La révision par l’IA révèle que votre message pourrait rebuter les personnes qui ne partagent pas votre point de vue.",

    // Mod Dashboard
    "My bookmarks": "Mes marqués",
    "Manually rejected ": "Rejeté manuellement ",
    "Today": "Aujourd’hui",
    "Yesterday": "Hier",
    "Channels:": "Rédaction :",
    "Open Threads": "Ouvert",
    "Closed Threads": "Fermé",
    "Heated Threads": "Intense",
    "Threads with flagged comments": "Commentaires signalés",
    "Open": "Ouvert",
    "Closed": "Fermé",
    "Newest": "Plus récents",
    "Oldest": "Plus anciens",
    "Heated": "Intense",
    "Author: {author}": "Auteur : {author}",
    "Back to threads": "Retour aux fils de discussion",
    "{numArticles} linked stories": "{numArticles} articles liés",
    "Linked stories": "Articles liés",
    "Origin:": "Origine :",
    "No origin available": "Aucune origine disponible",
    "View": "Voir",
    "Awaiting decision": "En attente de décision",
    "Automatically approved": "Approuvé automatiquement",
    "Manually approved": "Approuvé manuellement",
    "Load more": "Charger plus",
    "No comments": "Pas de commentaires",
    "No comments.": "Aucun commentaire.",
    "Message to moderator": "Message au modérateur",
    "Comment information": "Information sur le commentaire",
    "Submitted": "Soumis",
    "Comment ID": "ID du commentaire",
    "Status": "Statut",
    "Internal Notes": "Notes internes",
    "View in thread": "Voir dans le fil de discussion",
    "Add your notes...": "Ajoutez vos notes...",
    "User report": "Rapport utilisateur",
    "📌 Community guideline violation": "📌 Violation des règles communautaires",
    "⚠️ Community guideline violation": "⚠️ Violation des règles communautaires",
    "Reported by": "Signalé par",
    "Reported by users": "Signalé par les utilisateurs",
    "{count} users": "{count} utilisateurs",
    "User information": "Informations utilisateurs",
    "Member since ": "Membre depuis ",
    "AI flag": "Signalé par l’IA",
    "Pre-moderation flag": "Signalé par la pré-modération",
    "⚠️ Blocklist words": "⚠️ Mots de la liste noire", 
    "comments & replies": "Commentaires & réponses",
    "rejected comments": "Commentaires rejetés",
    "reported comments": "Commentaires signalés",
    "flagged comments": "Commentaires marqués",
    "user warnings": "Avertissements à l’utilisateur",
    "comment & reply": "Commentaire & réponse",
    "rejected comment": "Commentaire rejeté",
    "reported comment": "Commentaire signalé",
    "flagged comment": "Commentaire marqué",
    "user warning": "Avertissement à l’utilisateur",
    "Warnings": "Avertissements",
    "Back to selection": "Retour à la sélection ",
    "Which Community Guideline does this comment violate?": "Quelle règle communautaire ce commentaire enfreint-il?",
    "Want to do anything else?": "Vous voulez faire autre chose?",
    "No thanks": "Non merci",
    "Warn user": "Avertir l’utilisateur",
    "The user receives an automatic message by e-mail after the warning. It is possible to cancel the warning.": "L’utilisateur reçoit un message automatique par courrier électronique après l'avertissement. Il est possible d'annuler l'avertissement.",
    "User has already been warned {count} {noun}.": "L’utilisateur a déjà été averti {count} {noun}.",
    "time": "fois",
    "times": "fois",
    "Show user profile": "Afficher le profil de l’utilisateur",
    "Block user": "Bloquer l’utilisateur",
    "User has already been warned {count} {noun} and was blocked by {blockedByName}.": "L’utilisateur a déjà été averti {count} {noun} et bloqué par {blockedByName}.",
    "A user must be warned 2 times before they can be blocked.": "Un utilisateur doit être averti 2 fois avant d’être bloqué.",
    "Block this user?": "Bloquer cet utilisateur?",
    "Do you really want to block this user? You can unblock the user via the profile page.": "Voulez-vous vraiment bloquer cet utilisateur? Vous pouvez débloquer l’utilisateur via la page de profil.",
    "Unblock this user?": "Débloquer cet utilisateur?",
    "Do you really want to unblock this user?": "Voulez-vous vraiment débloquer cet utilisateur?",
    "Blocked": "Bloqué",
    "Warned": "Averti",
    "Unblock user": "Débloquer l’utilisateur",
    "User Profile": "Profil de l’utilisateur",
    "User Settings": "Paramètres",
    "Rejected by": "Rejeté par",
    "Show less": "Montrer moins",
    "Show more": "Afficher plus",
    "Back to comment": "Retour aux commentaires",
    "You are reviewing": "Vous vérifiez",
    "{viewerName} is reviewing": "{viewerName} vérifie actuellement",
    "Only one moderator at a time can work on a comment.": "Un seul modérateur à la fois peut travailler sur un commentaire.",
    "PSI Moderation": "Modération PSI",
    "Threads": "Fils de discussion",
    "Comments": "Commentaires",
    "No user logged in": "Aucun utilisateur connecté",
    "Just now": "À l’instant",
    "Moderation Queue": "File d’attente de modération",
    "Most recent": "Plus récents",
    "Settings": "Paramètres",
    "Read article": "Consulter l’article",
    "AI explanation": "Explication de l'IA",


    "AI review found that your perspective label may be incompatible with the following [Community Guidelines]($onPress): ":"La révision par l’IA a déterminé que votre perspective pourrait être incompatible avec les règles de la [communauté suivantes]($onPress) : ",
    "Your response has been sent to a moderator for review.":"Votre réponse a été envoyée au modérateur pour révision.",
    "What guideline does this violate? Select all that apply.":"Quelle règle de la communauté a été enfreinte? Sélectionnez toutes les options applicables.",
    "Updating...":"Mise à jour en cours...",
    "This discussion is temporarily unavailable. Check back for updates.":"Cette conversation est temporairement indisponible. Revenez plus tard pour des mises à jour.",
    "This custom name is incompatible with our Community Guidelines.":"Ce pseudonyme ne respecte pas les règles de la communauté.",
    "Sent to moderator: {time}":"Envoyé au modérateur : {time}",
    "Sending...":"Envoi en cours...",
    "Send to a moderator for review":"Envoyer à un modérateur pour révision",
    "Send an email to [privacy@newpublic.org](mailto:privacy@newpublic.org) to request a copy of your data associated with this discussion space. It may take up to 30 days to process your request.":"Envoyez un courriel à [privacy@newpublic.org](mailto:privacy@newpublic.org) pour demander une copie de vos données associées à cet espace de conversation. Le traitement de votre demande peut prendre jusqu’à 30 jours.",
    "Saving...":"Sauvegarde en cours...",
    "Save":"Sauvegarder ",
    "Relatable":"Je m’identifie",
    "Provide sources for factual claims":"Fournissez des sources qui étayent vos propos ",
    "Logging in...":"Connexion en cours...",
    "Let's talk about...":"Participez à la conversation",
    "It's complicated":"C’est compliqué",
    "I agree to the Community Guidelines, Privacy Notice, and Terms of Service.":"J’accepte les règles de la communauté, la politique de confidentialité et les conditions d’utilisation.",
    "I agree to the [Community Guidelines]({communityGuidelinesLink}), [Privacy Notice]({privacyNoticeLink}), and [Terms of Service]({termsOfServiceLink}).":"J’accepte les [règles de la communauté]({communityGuidelinesLink}), la [politique de confidentialité]({privacyNoticeLink}), et les [conditions d’utilisation]({termsOfServiceLink}).",
    "Focus on ideas, rather than character judgments":"Concentrez-vous sur les idées, plutôt que sur les jugements de valeur",
    "Discussion History":"Historique des conversations",
    "Did AI get it wrong?":"L’IA a-t-elle fait une erreur?",
    "Deleting profile data. Please wait...":"Suppression des données du profil en cours. Veuillez patienter...",
    "Custom names can only be changed once per week.":"Les pseudonymes ne peuvent être modifiés qu’une fois par semaine.",
    "Custom names are not allowed to look like real names.":"Les pseudonymes ne doivent pas ressembler à de vrais noms.",
    "Consider rewording your response:":"Pensez à reformuler votre réponse :",
    "Community Guidelines":"Règles de la communauté",
    "Choose a name and photo that appears when you post in discussions.":"Choisissez un nom et une photo qui apparaissent lorsque vous publiez dans des conversations.",
    "AI review found that your response may be incompatible with the following [Community Guidelines]($onPress): ":"La révision par l’IA a déterminé que votre publication pourrait être incompatible avec les règles de la [communauté suivantes]($onPress) : ",    
    "AI helps us moderate comments, but sometimes it can make a mistake. If AI is preventing you from sharing a respectful opinion, send your comment to a moderator for review.":"L’intelligence artificielle (IA) nous aide à modérer les commentaires, mais elle peut parfois faire des erreurs. Si l’IA vous empêche de partager une opinion respectueuse, veuillez soumettre votre commentaire à un modérateur pour révision.",
    "Add a custom name":"Ajouter un pseudonyme",
    "🔍 Under Review":"🔍 Révision en cours",
    "**Share details** (required)":"**Partager des détails** (obligatoire)",
    "To maintain a welcoming and respectful environment, this space uses AI to help moderate comments. AI may flag your post for your additional review if it does not follow the [Community Guidelines]($onPress)":"Pour maintenir un environnement accueillant et respectueux, cet espace utilise l’intelligence artificielle (IA) pour aider à modérer les publications. L’IA peut signaler votre publication pour une révision supplémentaire si elle ne respecte pas les [règles de la communauté]($onPress)",
    "Learn more.": "En savoir plus.",
    "Add":"Ajouter",
    "Add your own":"Ajouter",
    "Hello everyone!": "Assistant IA",
    "This feature is not implemented yet." : "Cette fonctionnalité n’est pas encore mise en œuvre.",
    "Your Account":"Votre profil",
    "Open Admin Dashboard": "Ouvrir le tableau de bord (admin)",

    "Continue with RTBF": "Continuer avec mon compte RTBF",

    // Video Voting Tool - Estimation Slider
    "Community average": "Moyenne communauté",


    // Reaction Types
    "Why do you appreciate this post?": "Pourquoi vous aimez ça ?",
    "Nuanced": "Nuancé",
    "Playful": "Taquin",
    "Enjoyable": "J'apprécie",

    // Fonctionnalité liste de blocage
    "Blocklist": "liste de blocage",
    "Manage terms that should be reported automatically.": "Gérer les termes qui sont contestés et qui doivent être signalés automatiquement.",
    "Add terms - separate multiple terms with ;": "Ajouter des termes - séparez plusieurs termes avec ;",
    "Search": "Rechercher",
    "The blocklist is empty.": "La liste de blocage est vide.",
    "Add term": "Ajouter un terme",
    "Info": "Info",
    "The blocklist allows you to define terms that are automatically prevented from being published in community posts. You can add terms to this pre-moderation yourself. Add all terms that should not be published without human review to the list.": "La liste de blocage vous permet de définir des termes qui sont automatiquement empêchés d’être publiés dans les messages de la communauté. Vous pouvez ajouter vous-même des termes à cette pré-modération. Ajoutez à la liste tous les termes qui ne doivent pas être publiés sans contrôle humain.",
    "Singular term:": "Terme singulier",
    "You can decide whether only the singular term should be considered or also terms that contain the entered term.": "Vous pouvez décider si seul le terme singulier doit être pris en compte ou si les termes qui contiennent le terme saisi doivent l’être également.",
    "Example: “person” (singular term) → “persons”. “persons” would also be taken into account if the “singular term” checkbox is not activated for “person”. This also applies to the string “personality”, for example.": "Exemple : « personne » (terme singulier) → « personnes ». Le terme « personnes » sera également pris en compte si la case à cocher « Terme singulier » n'est pas activée pour le terme « personne ».",
    "Alternative spellings:": "Orthographes alternatives :",
    "You can decide whether alternative spellings are taken into account. The following spellings, numbers and special characters are also taken into account:": "Vous pouvez décider si des orthographes alternatives sont prises en compte. Les orthographes, chiffres et caractères spéciaux suivants sont également pris en compte :",
    "A → a  | a → A | a → @ | a → 4 | i → 1 | e → 3 | o → 0 | s → $ | s → 5 | t → 7 | b → 8 | g → 9": "A → a  | a → A | a → @ | a → 4 | i → 1 | e → 3 | o → 0 | s → $ | s → 5 | t → 7 | b → 8 | g → 9",
    "Upper and lower case are taken into account": "Les majuscules et les minuscules sont prises en compte",
    "All right": "Confirmer",
    "Settings for the term": "Paramètres pour le terme",
    "Select whether terms are to be adopted exactly as your input is, or whether they are to be included in expanded form.": "Choisissez si les termes doivent être adoptés tels quels ou s'ils doivent être inclus sous forme développée.",
    "Only singular term": "Seul le terme singulier",
    "Example:": "Exemple :",
    "“person” (singular term) → “persons”. “persons” would also be taken into account if the “singular term” checkbox is not activated for “person”. However, this also applies to the string “personality”, for example.": "« personne » (terme singulier) → « personnes ». Le terme « personnes » sera également pris en compte si la case à cocher « Terme singulier » n'est pas activée pour le terme « personne ».",
    "Include alternative spellings": "Inclure des orthographes alternatives",
    "A → a | a → A | a → @ | a → 4 | i → 1 |e → 3 \n|o → 0 | s → $ | s → 5 | t → 7 | b → 8 | g → 9": "A → a | a → A | a → @ | a → 4 | i → 1 |e → 3 \n|o → 0 | s → $ | s → 5 | t → 7 | b → 8 | g → 9",
    "Singular Term": "Terme singulier",
    "Edit Term": "Modifier le terme",
    "Delete Terms": "Supprimer les termes",
    "Should these terms really be deleted? You can add them again later.": "Ces termes doivent-ils vraiment être supprimés ? Vous pouvez les ajouter à nouveau plus tard.",
    "This term is already in the list": "Ce terme figure déjà dans la liste",
    "{termsCount} terms were successfully added": "Les termes {termsCount} ont été ajoutés avec succès",
    "The term \"{singleTerm}\" was added": "Le terme « {singleTerm} » a été ajouté",
    "Something went wrong, try again later.": "Un problème s'est produit, réessayez plus tard",
    "Terms may not be longer than 150 characters.": "Les termes ne doivent pas dépasser 150 caractères.",
    "Terms longer than 150 characters have been removed.": "Les termes de plus de 150 caractères ont été supprimés.",
    
    "A-Z": "A-Z",
    "Z-A": "Z-A",
    "{keyCount} selected items": "{keyCount} éléments sélectionnés",
    "Delete": "Supprimer",
    "Back": "Retour",
    "Close": "Fermer"
}
