import { ui_human_translations_french } from './ui_human_french';

export const ui_human_translations_french_canadian = {
    ...ui_human_translations_french,
    "Why do you appreciate this post?": "Pourquoi vous aimez ça?",

    "You will receive an email notification when your post is approved or rejected by moderators.":"Vous recevrez une notification par courriel lorsque votre publication sera approuvée ou rejetée par les modérateurs.",
    "Yes with reservations":"Oui avec réserves",
    "We'd particularly like to hear from:":"Exemples de perspectives qu’il serait intéressant de représenter dans cette conversation :",
    "We strive for a kind and constructive discussion space where everyone feels welcome to share their perspectives and experiences. You can contribute to a discussion with responses that are on-topic and relevant to the subject matter, including meaningful debate.":"Nous nous efforçons de créer un espace de conversation respectueux et constructif où chacun se sent invité à partager ses perspectives et expériences. Vous pouvez contribuer à une discussion avec des réponses pertinentes en lien avec le sujet, y compris des débats constructifs.",
    "Update your response":"Modifier ma réponse",
    "Update your previous response with fresh insights":"Mettez à jour votre réponse si votre réflexion a changé.",
    "To maintain a welcoming and respectful environment, this space uses AI to help moderate comments. AI may flag your post for your additional review if it does not follow the [Community Guidelines]($onPress)": "Pour maintenir un environnement accueillant et respectueux, cet espace utilise l’intelligence artificielle (IA) pour aider à modérer les publications. L’IA peut signaler votre publication pour une révision supplémentaire si elle ne respecte pas les [règles de la communauté]($onPress)",
    "This discussion space uses only essential cookies and cookies for analytics. We do not use cookies for advertising purposes.":"Cet espace de conversation utilise uniquement des témoins essentiels et des témoins d’analyse. Nous n’utilisons pas de témoins à des fins publicitaires.",
    "This custom name is already in use.":"Ce pseudonyme est déjà utilisé.",
    "Thank you!":"Merci!",
    "Support Your Claims":"Appuyez vos affirmations",
    "Slide to respond:":"Glissez pour répondre : ",
    "Reviewing your perspective label with AI to ensure it follows our Community Guidelines":"Révision de votre suggestion de perspective en cours avec l’IA afin d’assurer sa conformité avec les règles de la communauté",
    "Provide any details to help us determine if your post follows our Community Guidelines.":"Fournissez tous les détails pour nous aider à déterminer si votre publication respecte les règles de la communauté.",
    "Once you log in, enter a display name":"Une fois connecté, saisissez un nom d’affichage.",
    "No with reservations":"Non avec réserves",
    "No off-topic posts":"Pas de publications hors sujet",
    "No more responses":"Fin des réponses",
    "Log in":"Me connecter",
    "How do you relate to this issue?":"Depuis quelle perspective vous exprimez-vous sur le sujet?",
    "Engage Respectfully":"Demeurez respectueux",
    "Custom names can only include lowercase letters and numbers.":"Les pseudonymes peuvent uniquement inclure des lettres minuscules et des chiffres.",
    "Continue with Radio Canada":"Continuer avec Radio-Canada",
    "Continue with Radio Canada Internal":"Continuer avec Radio-Canada interne",
    "Consider rewording your perspective:":"Pensez à reformuler votre perspective :",
    "By participating in our beta, you may be invited to submit feedback on your experience using this product.":"En participant à cette version à l'essai, vous pourriez être invité à partager votre avis sur votre expérience d'utilisation.",
    "Beta":"À l’essai",
    "Avoid derogatory labels":"Évitez les appellations dénigrantes",
    "Are you sure you want to delete your profile data?":"Êtes-vous sûr de vouloir supprimer les données associées à votre profil?",
    "📫 What happens next":"📫 Ce qui se passe ensuite",
    "🍪 This discussion space uses only essential cookies. We do not use cookies for advertising purposes. [Learn more]({privacyNoticeLink}).":"🍪 Cet espace de conversation utilise uniquement des témoins essentiels. Nous n'utilisons pas de témoins à des fins publicitaires. [En savoir plus]({privacyNoticeLink}).",
    "Use left & right or up & down arrow keys to adjust slider": "Utilisez les touches fléchées gauche et droite ou haut et bas pour ajuster le curseur",
    "Enter a perspective label":"Ajouter une perspective personnalisée",
    "This discussion space is hosted by New_ Public, in collaboration with {psmName}.": "Les espaces de conversation sont hébergés par New_ Public en collaboration avec {psmName}.",
    "Manage terms that should be reported automatically.": "Gérer les termes qui sont contestés et qui doivent être signalés automatiquement.",
    // This translation does not match the English meaning, but the team decided on Jan 30, 2025 to make an exception here
    "To maintain a welcoming and respectful environment, this space uses AI to moderate comments. AI may flag your post for your additional review if it does not follow the community guidelines. ":"Cet espace teste l'intelligence artificielle (IA) pour améliorer la qualité des conversations. L'IA peut, par exemple, nous aider à modérer les publications, à résumer les échanges ou à vous suggérer des angles pour enrichir les discussions. Si vous avez des commentaires ou des questions sur son utilisation dans ces conversations, écrivez-nous à : privacy@newpublic.org. ",    
    "AI-moderated": "Usage de l’IA",
    "This guidance is generated by AI.":"Ces conseils sont générés par l’IA.",             
    "About Conversation Helper":"À propos de l’Assistant IA",      
    "To maintain a welcoming and respectful atmosphere, we also use AI to support moderation. In this conversation, an AI gives hints for a more constructive conversation atmosphere and summarizes the conversation.":"Cet espace teste l'intelligence artificielle (IA) pour améliorer la qualité des conversations. L'IA peut, par exemple, nous aider à modérer les publications, à résumer les échanges ou à vous suggérer des angles pour enrichir les discussions. Si vous avez des commentaires ou des questions sur son utilisation dans ces conversations, écrivez-nous à : privacy@newpublic.org.",
    "Reply": "Répondre",
    "Joined from": "À partir de",
    
    "Search...": "Recherche",
    "Share your thoughts...": "M’exprimer sur le sujet",
    "Reply to {authorName}...": "Répondre à {authorName}",
    "Please Wait...": "Veuillez patienter",
    "Saving..." : "Enregistrement en cours",
    "Loading...": "Chargement",
    "Posting..." : "Publication en cours",
    "Loading questions...": "Chargement des questions",
    "Let's talk about..." : "Participez à la conversation",
    "Let's talk about" : "Participez à la conversation",
    "Updating...": "Mise à jour",
    "AI helps us to manage moderation, but sometimes it triggers incorrectly. If you are unable to get our AI to accept a respectfully expressed opinion, please send it to our moderators for review.": "L’IA nous aide à gerer la modération, mais certainement, il se fait mal. Si vous ne pouvez pas obtenir l’IA à accepter une opinion en consciences, envoyez-la au modérateur pour la révisée.",
    "Message to moderator...": "Message au modérateur",
    "Share your thoughts... (optional)": "Partagez vos pensées (facultatif)",
    "Ask a question..." : "Poser une question",
    "Write your answer...": "Écrivez votre réponse",
    "Adding..." : "Ajout",
    "Write in a topic...": "Rédiger un sujet",
    "Search a topic...": "Rechercher un sujet",
    "Option...": "Choix de réponse",
    "Explain your decision...": "Expliquez votre décision",
    "Ask us a question...": "Réagir ou poser une question",
    "Discuss this topic with other citizens": "On en parle aussi",
    "Analyze after every...": "Analyser après chaque",
    "Update based on comments...": "Actualiser sur la base des commentaires",
    "Update based on time...": "Actualiser sur la base du temps",
    "Analyze...": "Analyser",
    "Use percentage...": "Choisir le pourcentage",
    "Apply percentage...": "Utiliser des pourcentages",
    "Use personality...": "Utilise la personnalité",
    "This message was generated because...": "Cette note a été générée parce que",
    "Add your notes...": "Ajoutez vos notes",
    "Sending...": "Envoi",
    "Logging in...": "Connexion",
    "Deleting profile data. Please wait...": "Suppression des données du profil. Veuillez patienter",
    "Loading admin users...": "Chargement des utilisateurs admin",
    "Updating roles...": "Mise à jour des rôles",
    "Adding Admins...": "Ajout d’administrateurs",
    "Message to Moderator...": "Message au modérateur",
    "Loading Admins...": "Chargement des administrateurs",
    "Title... (ex: journalist)": "Titre (ex: journaliste)",

    // Surveys
    "This conversation platform is easy to use": "Cette interface de conversation est facile à utiliser.",
    "This conversation platform meets my needs": "Cette interface de conversation répond à mes besoins.",
    "How satisfied are you with this conversation platform?": "Comment trouvez-vous cette interface de conversation?",
    "Agree": "En accord",
    "Strongly agree": "Fortement en accord",
    "Unsatisfied": "Insatisfaisante",
    "Neither agree nor disagree": "Ni en accord, ni en désaccord",
    "Neither satisfied nor unsatisfied": "Ni satisfaisante, ni insatisfaisante",
    "We'd love your feedback!": "Nous aimerions connaître votre avis!",
    "Satisfied": "Satisfaisante",
    "Very unsatisfied": "Très insatisfaisante",
    "Very satisfied": "Très satisfaisante",
    "Please indicate how much you agree or disagree with each statement": "Veuillez indiquer dans quelle mesure vous êtes en accord ou en désaccord avec chaque affirmation.",
    "Share your feedback to help improve our Beta experience.": "Vos réponses nous aideront à améliorer les conversations à l'essai.",
    "What influenced your answer? Choose all that apply.": "Qu'est-ce qui a influencé votre réponse? Choisissez toutes les options qui s'appliquent.",
    
}