// Template: Just the general structure of the voting. No results or state
export const endometriosisVideoVotingTemplateDE = {
    name: "Endometriosis",
    key: "endometriosis-template",
    chapters: [
        {
            key: "chapter-0",
            name: "Chapter 1"
        },
        {
            key: "chapter-1",
            name: "Chapter 2"
        }
    ],
    questions: [{
        text: "Hast du schon mal von Endometriose gehört?",
        key: "endometriosis-template-question-0",
        type: 'poll',
        timestamp: 0,
        initial: true,
        options: [
            {
                key: "endometriosis-template-question-0-option-0",
                text: "Ja"
            },
            {
                key: "endometriosis-template-question-0-option-1",
                text: "Nein"
            }
        ]
    },
    {
        text: "Wie wichtig ist es für Betroffene, dass die Erkrankung und der damit einhergehende Leidensdruck anerkannt wird?",
        timestamp: 638,
        key: "endometriosis-template-question-1",
        type: 'poll',
        options: [
            {
                key: "endometriosis-template-question-1-option-0",
                text: "Sehr wichtig, da Endometriose den Alltag der Betroffenen sehr einzuschränken scheint."
            },
            {
                key: "endometriosis-template-question-1-option-1",
                text: "Wichtig, wie bei allen anderen Erkrankungen auch."
            }
        ]
    },
    {
        text: 'Ist es richtig, dass die Regierung Geld in die Erforschung von "Endometriose" investiert?',
        timestamp: 720,
        key: "endometriosis-template-question-2",
        type: 'poll',
        options: [
            {
                key: "endometriosis-template-question-2-option-0",
                text: "Ja"
            },
            {
                key: "endometriosis-template-question-2-option-1",
                text: "Nein"
            },
            {
                key: "endometriosis-template-question-2-option-2",
                text: "Weiß nicht"
            }
        ]
    },
    {
        text: "Wie wichtig findest du es, dass mehr Menschen über Endometriose Bescheid wissen?",
        key: "endometriosis-template-question-3",
        type: 'poll',
        timestamp: 750,
        final: true,
        options: [
            {
                key: "endometriosis-template-question-3-option-0",
                text: "Sehr wichtig"
            },
            {
                key: "endometriosis-template-question-3-option-1",
                text: "Wichtig"
            },
            {
                key: "endometriosis-template-question-3-option-2",
                text: "Nicht wichtig"
            }
        ]
    }]
}

export const endometriosisVideoVotingTemplateEN = {
    name: "Endometriosis",
    key: "endometriosis-template",
    chapters: [
        {
            key: "chapter-0",
            name: "Chapter 1"
        },
        {
            key: "chapter-1",
            name: "Chapter 2"
        }
    ],
    questions: [{
        text: "Have you ever heard of endometriosis?",
        key: "endometriosis-template-question-0",
        type: 'poll',
        timestamp: 0,
        initial: true,
        options: [
            {
                key: "endometriosis-template-question-0-option-0",
                text: "Yes"
            },
            {
                key: "endometriosis-template-question-0-option-1",
                text: "No"
            }
        ]
    },
    {
        text: "How important is it to you that this illness and the suffering of those affected get recognized?",
        timestamp: 638,
        key: "endometriosis-template-question-1",
        type: 'poll',
        options: [
            {
                key: "endometriosis-template-question-1-option-0",
                text: "Very important, because endometriosis restricts the daily lives of those affected"
            },
            {
                key: "endometriosis-template-question-1-option-1",
                text: "Important, as with every illness"
            }
        ]
    },
    {
        text: "Is it right for the government to invest money in research into endometriosis?",
        timestamp: 720,
        key: "endometriosis-template-question-2",
        type: 'poll',
        options: [
            {
                key: "endometriosis-template-question-2-option-0",
                text: "Yes"
            },
            {
                key: "endometriosis-template-question-2-option-1",
                text: "No"
            },
            {
                key: "endometriosis-template-question-2-option-2",
                text: "I don't know"
            }
        ]
    },
    {
        text: "How important do you think it is that more people know about endometriosis?",
        key: "endometriosis-template-question-3",
        type: 'poll',
        timestamp: 750,
        final: true,
        options: [
            {
                key: "endometriosis-template-question-3-option-0",
                text: "Very important"
            },
            {
                key: "endometriosis-template-question-3-option-1",
                text: "Important"
            },
            {
                key: "endometriosis-template-question-3-option-2",
                text: "Not that important"
            }
        ]
    }]
}

export const howWeWantToLoveVideoVotingTemplateDE = {
    name: "Wie wir lieben wollen",
    key: "howWeWantToLove-template",
    questions: [{
        text: "Kannst du andere Leute verstehen, die ihre Beziehung öffnen?",
        key: "howWeWantToLove-template-question-0",
        timestamp: 0,
        type: "connected",
        subQuestions: [
            {
                initial: true,
                timestamp: 181.5,
                key: "howWeWantToLove-template-question-0-0",
            },
            {
                timestamp: 535,
                key: "howWeWantToLove-template-question-0-1",
            },
            {
                timestamp: 1136,
                key: "howWeWantToLove-template-question-0-2",
            },
            {
                timestamp: 1461,
                key: "howWeWantToLove-template-question-0-3",
            },
            {
                timestamp: 1675,
                key: "howWeWantToLove-template-question-0-4",
                final: true
            }
        ],
        options: [
            {
                key: "howWeWantToLove-template-question-0-option-0",
                text: "Ja"
            },
            {
                key: "howWeWantToLove-template-question-0-option-1",
                text: "Weiß nicht"
            },
            {
                key: "howWeWantToLove-template-question-0-option-2",
                text: "Nein"
            }
        ]
    }]
}

export const howWeWantToLoveVideoVotingTemplateEN = {
    name: "How we want to love",
    key: "howWeWantToLove-template",
    questions: [{
        text: "Can you understand other people who open up their relationship?",
        key: "howWeWantToLove-template-question-0",
        timestamp: 0,
        type: "connected",
        subQuestions: [
            {
                initial: true,
                timestamp: 181.5,
                key: "howWeWantToLove-template-question-0-0",
            },
            {
                timestamp: 535,
                key: "howWeWantToLove-template-question-0-1",
            },
            {
                timestamp: 1136,
                key: "howWeWantToLove-template-question-0-2",
            },
            {
                timestamp: 1461,
                key: "howWeWantToLove-template-question-0-3",
            },
            {
                timestamp: 1675,
                key: "howWeWantToLove-template-question-0-4",
                final: true
            }
        ],
        options: [
            {
                key: "howWeWantToLove-template-question-0-option-0",
                text: "Yes"
            },
            {
                key: "howWeWantToLove-template-question-0-option-1",
                text: "Don't know"
            },
            {
                key: "howWeWantToLove-template-question-0-option-2",
                text: "No"
            }
        ]
    }]
}

export const cashForTrashVideoVotingTemplateEN = {
    name: "Cash for Trash",
    key: "cash4trash-template",
    questions: [
        {
            key: "cash4trash-template-question-0",
            type: "slider",
            text: 'How much is the "Man with Cat" worth?',
            minValue: 0,
            maxValue: 5000,
            stepSize: 100,
            unit: "€",
            timestamp: 90,
            predeterminedAnswers: [
                {
                    text: "Expert",
                    value: 2000,
                },
            ],
        },
    ]
}

export const godzillaVideoVotingTemplateIndividual = {
    name: "How Much Do You Actually Know About Godzilla?",
    key: "godzilla-template-i",
    questions: [
        {
            text: "Do you believe Godzilla is the ultimate king of monsters?",
            key: "godzilla-template-question-0",
            timestamp: 0,
            initial: true,
            options: [
                {
                    key: "godzilla-template-question-0-option-0",
                    text: "Yes, all hail the King!"
                },
                {
                    key: "godzilla-template-question-0-option-1",
                    text: "Nah, Mothra’s got my vote."
                }
            ]
        },
        {
            text: "How important is it that the world recognizes the destruction Godzilla causes?",
            timestamp: 600,
            key: "godzilla-template-question-1",
            options: [
                {
                    key: "godzilla-template-question-1-option-0",
                    text: "Extremely important, cities aren't cheap to rebuild!"
                },
                {
                    key: "godzilla-template-question-1-option-1",
                    text: "Eh, it's just a bit of property damage, no biggie."
                }
            ]
        },
        {
            text: "Should the government fund research to understand why Godzilla has such a bad temper?",
            timestamp: 720,
            key: "godzilla-template-question-2",
            options: [
                {
                    key: "godzilla-template-question-2-option-0",
                    text: "Yes, maybe Godzilla just needs a hug."
                },
                {
                    key: "godzilla-template-question-2-option-1",
                    text: "No, we've got bigger issues—like actual volcanoes."
                },
                {
                    key: "godzilla-template-question-2-option-2",
                    text: "I don't know, ask Dr. Serizawa."
                }
            ]
        },
        {
            text: "How important is it for more people to know about Godzilla's legendary status?",
            key: "godzilla-template-question-3",
            timestamp: 840,
            final: true,
            options: [
                {
                    key: "godzilla-template-question-3-option-0",
                    text: "Super important, long live the legend!"
                },
                {
                    key: "godzilla-template-question-3-option-1",
                    text: "Important, but maybe we could let him chill in the ocean."
                },
                {
                    key: "godzilla-template-question-3-option-2",
                    text: "Not important, I'm more of a King Kong person."
                }
            ]
        }
    ]
}

export const godzillaVideoVotingTemplateConnected = {
    name: "Could You Handle a Godzilla Rampage?",
    key: "godzillaRampage-template",
    questions: [{
        text: "Could you keep your cool during a Godzilla rampage?",
        key: "godzillaRampage-template-question-0",
        type: "connected",
        subQuestions: [
            {
                initial: true,
                key: "godzillaRampage-template-question-0-0",
                timestamp: 180
            },
            {
                key: "godzillaRampage-template-question-0-1",
                timestamp: 300
            },
            {
                key: "godzillaRampage-template-question-0-2",
                timestamp: 500
            },
            {
                key: "godzillaRampage-template-question-0-3",
                timestamp: 840,
                final: true
            }
        ],
        options: [
            {
                key: "godzillaRampage-template-question-0-option-0",
                text: "Yes"
            },
            {
                key: "godzillaRampage-template-question-0-option-1",
                text: "Maybe"
            },
            {
                key: "godzillaRampage-template-question-0-option-2",
                text: "Nope"
            }
        ]
    }]
}

export const auctionVideoVotingTemplateSlider = {
    name: "Cash for Trash",
    key: "auction-template",
    questions: [
        {
            key: "auction-template-question-0",
            type: "slider",
            text: "How much is this painting worth?",
            minValue: 0,
            maxValue: 500,
            unit: "€",
            stepSize: 10,
            timestamp: 100,
            predeterminedAnswers: [
                {
                    text: "Expert",
                    value: 200,
                },
            ],
        },
        {
            key: "auction-template-question-1",
            type: "slider",
            text: "How much will this vase be sold for?",
            minValue: 0,
            maxValue: 1000,
            stepSize: 50,
            unit: "€",
            timestamp: 500,
            predeterminedAnswers: [
                {
                    text: "Highest bid",
                    value: 100,
                },
            ],
        },
        {
            key: "auction-template-question-2",
            type: "slider",
            text: "How much would you pay for this antique telephone?",
            minValue: 0,
            maxValue: 3000,
            stepSize: 100,
            unit: "€",
            timestamp: 800,
        },
        {
            key: "auction-template-question-3",
            type: "slider",
            text: "How much is this book worth?",
            minValue: 0,
            maxValue: 3000,
            stepSize: 100,
            unit: "€",
            timestamp: 900,
            predeterminedAnswers: [
                {
                    text: "Contestant",
                    value: 150,
                },
                {
                    text: "Real value",
                    value: 2000,
                },
            ],
        },
        {
            key: "auction-template-question-4",
            type: "slider",
            text: "How many marbles are in the jar?",
            minValue: 0,
            maxValue: 200,
            stepSize: 5,
            timestamp: 1000,
            predeterminedAnswers: [
                {
                    text: "Actual number",
                    value: 59,
                },
            ],
        },
        {
            key: "auction-template-question-5",
            type: "slider",
            text: "How much is this jar of marbles worth?",
            minValue: 0,
            maxValue: 3000,
            stepSize: 100,
            unit: "$",
            isUnitBeforeValue: true,
            timestamp: 1100,
            predeterminedAnswers: [
                {
                    text: "Real value",
                    value: 2000,
                },
            ],
        },
        {
            key: "auction-template-question-6",
            type: "slider",
            text: "How much would you pay for this jar of marbles?",
            minValue: 0,
            maxValue: 3000,
            stepSize: 100,
            unit: "$",
            isUnitBeforeValue: true,
            timestamp: 1200,
        },
    ],
};
// Example video voting template with chapters
export const chapterVideoVotingTemplate = {
    name: "Trash or Treasure?",
    key: "chapter-template",
    // These chapters are deliberately out of order to test if chronological sorting works correctly
    chapters: [
        {
            key: "chapter-1",
            name: "Bovine",
        },
        {
            key: "chapter-2",
            name: "Golden pin",
        },
        {
            key: "chapter-4",
            name: "Wristband with pearls",
        },
        {
            key: "chapter-3",
            name: "Woven chairs",
        },
        {
            key: "chapter-0",
            name: "Man with Cat",
        },
        // Chapter without questions. This should not appear in the voting results.
        {
            key: "chapter-5",
            name: "Empty",
        },
    ],
    // These question are deliberately out of order to test if sorting them works correctly
    questions: [
        {
            text: "Would you buy this sculpture?",
            key: "chapter-template-question0",
            chapterKey: "chapter-0",
            timestamp: 90,
            type: "poll",
            options: [
                {
                    key: "chapter-template-question0-option0",
                    text: "Yes!",
                },
                {
                    key: "chapter-template-question0-option1",
                    text: "No...",
                },
                {
                    key: "chapter-template-question0-option2",
                    text: "Maybe if it was cheaper?",
                },
            ],
        },
        {
            text: "Is this sculpture ugly?",
            key: "chapter-template-question1",
            chapterKey: "chapter-0",
            timestamp: 270,
            type: "poll",
            options: [
                {
                    key: "chapter-template-question1-option0",
                    text: "Yes...",
                },
                {
                    key: "chapter-template-question1-option1",
                    text: "No, it's art!",
                },
            ],
        },
        {
            text: "Is this a cow or a bull?",
            key: "chapter-template-question2",
            chapterKey: "chapter-1",
            timestamp: 630,
            type: "poll",
            options: [
                {
                    key: "chapter-template-question2-option0",
                    text: "Cow",
                },
                {
                    key: "chapter-template-question2-option1",
                    text: "Bull",
                },
            ],
        },
        {
            text: "What occasion would you wear this pin to?",
            key: "chapter-template-question3",
            chapterKey: "chapter-2",
            timestamp: 1359,
            type: "poll",
            options: [
                {
                    key: "chapter-template-question3-option0",
                    text: "My wedding",
                },
                {
                    key: "chapter-template-question3-option1",
                    text: "The Vienna Opera Ball... if I was invited",
                },
                {
                    key: "chapter-template-question3-option2",
                    text: "I wouldn't wear it at all, I wouldn't want to lose it!",
                },
            ],
        },
        {
            text: "Do you think these chairs are rare?",
            key: "chapter-template-question4",
            chapterKey: "chapter-3",
            timestamp: 1815,
            type: "poll",
            options: [
                {
                    key: "chapter-template-question4-option0",
                    text: "Yes, they are special!",
                },
                {
                    key: "chapter-template-question4-option1",
                    text: "No, I've seen chairs like that a million times",
                },
            ],
        },
        {
            text: "Does antique jewelry look better than today's?",
            key: "chapter-template-question5",
            chapterKey: "chapter-4",
            timestamp: 2529,
            type: "poll",
            options: [
                {
                    key: "chapter-template-question5-option0",
                    text: "No, it's just different",
                },
                {
                    key: "chapter-template-question5-option1",
                    text: "Yes, modern jewelry looks cheap and boring",
                },
                {
                    key: "chapter-template-question5-option2",
                    text: "I don't wear jewelry, I couldn't care less",
                },
            ],
        },
        {
            text: 'Do you think this sculpture is made out of bronze?',
            key: "chapter-template-question6",
            chapterKey: "chapter-1",
            timestamp: 500,
            type: "poll",
            options: [
                {
                    key: "chapter-template-question6-option0",
                    text: "Yes, it looks like bronze to me",
                },
                {
                    key: "chapter-template-question6-option1",
                    text: "No, there are other materials that look the same",
                },
            ],
        },
        {
            text: "If this sculpture was gifted to you, would you re-gift it to someone else?",
            key: "chapter-template-question7",
            chapterKey: "chapter-0",
            timestamp: 880,
            type: "poll",
            options: [
                {
                    key: "chapter-template-question7-option0",
                    text: "No, of course I would keep it!",
                },
                {
                    key: "chapter-template-question7-option1",
                    text: "Yes, I don't want that thing in my home",
                },
            ],
        },
    ],
};

// Example video voting template with chapters and questions that are not assigned to a chapter
export const chapterQuestionMixVideoVotingTemplate = {
    name: "Trash or Treasure? Pt. 2",
    key: "chapter-question-mix-template",
    // These chapters are deliberately out of order to test if chronological sorting works correctly
    chapters: [
        {
            key: "chapter-1",
            name: "Antique telephone",
        },
        {
            key: "chapter-0",
            name: "Flower vase",
        },
        // Chapter without questions. This should not appear in the voting results.
        {
            key: "chapter-2",
            name: "Empty",
        },
    ],
    // These question are deliberately out of order to test if sorting them works correctly
    questions: [
        {
            text: "What flowers would you put in this vase?",
            key: "chapter-question-mix-template-question0",
            chapterKey: "chapter-0",
            timestamp: 90,
            type: "poll",
            options: [
                {
                    key: "chapter-question-mix-template-question0-option0",
                    text: "Roses",
                },
                {
                    key: "chapter-question-mix-template-question0-option1",
                    text: "Tulips",
                },
                {
                    key: "chapter-question-mix-template-question0-option2",
                    text: "Something else",
                },
            ],
        },
        {
            text: "Have you ever dropped an expensive vase?",
            key: "chapter-question-mix-template-question1",
            chapterKey: "chapter-0",
            timestamp: 120,
            type: "poll",
            options: [
                {
                    key: "chapter-question-mix-template-question1-option0",
                    text: "Yes, unfortunately",
                },
                {
                    key: "chapter-question-mix-template-question1-option1",
                    text: "No, luckily not!",
                },
                {
                    key: "chapter-question-mix-template-question1-option2",
                    text: "No, but my cat knocked one over...",
                },
            ],
        },
        {
            text: "Who invented the telephone?",
            key: "chapter-question-mix-template-question2",
            chapterKey: "chapter-1",
            timestamp: 400,
            type: "poll",
            options: [
                {
                    key: "chapter-question-mix-template-question2-option0",
                    text: "Thomas Edison",
                },
                {
                    key: "chapter-question-mix-template-question2-option1",
                    text: "Alexander Graham Bell",
                },
                {
                    key: "chapter-question-mix-template-question2-option2",
                    text: "Someone else",
                },
            ],
        },
        {
            text: "Do you still have a landline phone at home?",
            key: "chapter-question-mix-template-question3",
            chapterKey: "chapter-1",
            timestamp: 300,
            type: "poll",
            options: [
                {
                    key: "chapter-question-mix-template-question3-option0",
                    text: "No, I don't know anyone who does",
                },
                {
                    key: "chapter-question-mix-template-question3-option1",
                    text: "No, but part of my family still does",
                },
                {
                    key: "chapter-question-mix-template-question3-option2",
                    text: "Yes!",
                },
            ],
        },
        // Question without chapter assigned
        {
            text: "Have you ever been to an auction?",
            key: "chapter-question-mix-template-question4",
            timestamp: 0,
            initial: true,
            type: "poll",
            options: [
                {
                    key: "chapter-question-mix-template-question4-option0",
                    text: "Yes",
                },
                {
                    key: "chapter-question-mix-template-question4-option1",
                    text: "No",
                },
                {
                    key: "chapter-question-mix-template-question4-option2",
                    text: "Do online auctions count?",
                },
            ],
        }
    ],
};
