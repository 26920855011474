import { Add, ArrowUpRight, Bookmark, BookmarkFilled, Chat, IdManagement, Subtract, Time, UserMultiple } from "@carbon/icons-react";
import { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { ModerationFilters } from "./moddashboardqueue";
import { useParentDatastore } from "../parentdatastore";
import { ModDashboardUserStatistics, useUserProfileModerationData } from "./moddashboarduserprofile";
import { ThinTag } from "../button";
import { useDatastore, useModulePublicData, useModuleUserGlobalData, usePersonaKey, useServerCallResult } from "../../../util/datastore";
import { BreadCrumb, CTAButton, IconButton, SubtleButton, TextButton } from "../../button";
import { HorizBox, Pad, PadBox, Separator } from "../../basics";
import { colorAmbigousBlack, colorAmbiguousLightGrey, colorAmbiguousWhite, colorBorderPrimary, colorButtonDisabled, colorLightGreen, colorSurfaceWarning, colorTagHighlight, colorTagVerified, colorTextSecondary } from "../../color";
import { Heading, Paragraph, TextField, TextFieldButton, UtilityText } from "../../text";
import { FaceImage, LetterFace } from "../../people";
import { Banner } from "../../banner";
import { Modal } from "../../modal";
import { Catcher } from "../../../system/catcher";
import { gotoInstance } from "../../../util/navigate";
import { useInstanceParams } from "../../../util/params";
import { ModDashboardDateTime } from "./datetime";
import { BulletText } from "component/text";
import { usePersonasByIds } from "../moderation";
import { scrollTo } from "../../../platform-specific/navigation";

const ModDashboardCommentCardStyle = StyleSheet.create({
    cardTopBar: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    commentStatusTags: {
        display: "flex",
        flexDirection: "row",
        gap: 12,
        alignItems: "center"
    },
    commentDetailSection: {
        borderTopColor: colorBorderPrimary,
        borderTopWidth: 1,
        paddingVertical: 16,
        gap: 24,
    },
    commentInformationContainer: {
        backgroundColor: colorAmbiguousWhite,
        flexDirection: "row",
        borderRadius: 11,
        padding: 16,
        gap: 24,
    },
    skeleton: {
        left: 0, right: 0, bottom: 0, top: 0,
        position: "absolute",
        backgroundColor: colorAmbiguousWhite,
        zIndex: 10
    },
    bookmarkContainer: {
        borderWidth: 1,
        borderColor: colorBorderPrimary,
        borderRadius: 16,
    }
});

export function ModDashboardCommentCard({ 
    modAuthor,
    modTask,
    onPressView,
    viewLabel = "View",
    onClickCard,
    onExpand,
    onCollapse,
    highlighted,
    isBookmarked = false,
}) {
    const s = ModDashboardCommentCardStyle;

    const expanded = highlighted;
    const datastore = useDatastore();
    const linkedQuestion = useModulePublicData("question", [modTask?.instanceKey]);
    const linkedTopic = useModulePublicData("topic", [modTask?.instanceKey]);
    const bookmarkCount = useModuleUserGlobalData("bookmarks_count", ["bookmarkCount"]) || 0;

    const textColorBlack = modTask?.deleted ? colorAmbiguousLightGrey : colorAmbigousBlack;
    const textColorGrey = modTask?.deleted ? colorAmbiguousLightGrey : colorTextSecondary;

    const onPressBookmark = async () => {
        const isBookmarkedAfterPress = !isBookmarked;

        datastore.setModuleUserGlobal(
            "bookmarks",
            [modTask.key],
            isBookmarkedAfterPress === true
                ? {
                      key: modTask?.key,
                      instanceKey: modTask?.instanceKey,
                      time: modTask?.time,
                      type: modTask?.type,
                      isBookmarked: isBookmarkedAfterPress,
                  }
                : null
        );

        const newBookmarkCount = isBookmarkedAfterPress
            ? bookmarkCount + 1
            : bookmarkCount >= 1
                ? bookmarkCount - 1
                : 0;

        datastore.setModuleUserGlobal("bookmarks_count", ["bookmarkCount"], newBookmarkCount);
    };

    const onPressExpand = () => {
        onExpand && onExpand(modTask);
        const elementID = "card-" + modTask.key;
    
        setTimeout(() => {
            scrollTo({ elementID, block: "start" });
        }, 100);
    };

    const onPressWholeCard = (e) => {
        if(!expanded && !highlighted){
            onClickCard && onPressExpand()
        }
        // Mark click event as from modTask an propagate it
        e.isFromModTask = true
        return false
    }

    const onPressCollapse = () => {
        onCollapse && onCollapse(modTask)
    }

    // Additional widgets to display in case the comment card is expanded
    const expandedSection = expanded && modTask && (
        <>
            <MessageToModerator modTask={modTask} />
            <CommentInformation modTask={modTask}/>
            <NotesFromColleagues modTask={modTask}/>

            {!modTask.deleted && (
                <>
                    {modTask.source === "premoderation" && <ViolationsFoundByPreModeration modTask={modTask} />}
                    {modTask.source === "report" && <UserReport modTask={modTask} />}
                    {modTask.judgement === "reject" && <JudgementInformation modTask={modTask} />}
                </>
            )}

            {modAuthor && <UserInformation modAuthor={modAuthor} disabled={modTask.deleted}/>}
            <SubtleButton icon={(props) => <Subtract {...props} style={{ flexShrink: 0 }}/>} label={"Show less"} onPress={onPressCollapse} color={colorAmbigousBlack} />
        </>
    );

    return (
        <Catcher>
            <View
                id={"card-" + modTask.key}
                testID={"card-" + modTask.key}
                style={[
                    ModQueueCommentCardStyle.cardContainer, expanded && ModQueueCommentCardStyle.cardContainerExpanded]}
                onClick={onPressWholeCard}
            >
                {!modTask && <View style={s.skeleton}></View>}
                <PadBox horiz={20} vert={20}>
                    <View style={{ gap: 16 }}>
                        <View style={s.cardTopBar}>
                            <View style={s.commentStatusTags}>
                                <JudgementTag modTask={modTask} />
                                {modTask?.receivedWarning && <WarningTag />}
                                {modTask?.deleted && <DeletedTag />}
                            </View>
                            <PadBox horiz={8}/>
                            <View style={s.bookmarkContainer}>
                                <BreadCrumb
                                    icon={isBookmarked ? BookmarkFilled : Bookmark}
                                    iconProps={{ color: textColorBlack }}
                                    onPress={!modTask?.deleted && onPressBookmark}
                                    testID={"Bookmark-" + modTask.key}
                                />
                            </View>
                        </View>

                        {modAuthor && (
                            <BylineModerationCard
                                photoUrl={modAuthor.authorPhoto}
                                name={modAuthor.authorName}
                                userId={modAuthor.authorKey}
                                hue={modAuthor.authorHue}
                                disabled={modTask?.deleted}
                                StatusTag={<StatusTag modAuthor={modAuthor} />}
                                SecondaryLineComponent={() =>
                                    ModDashboardDateTime({ timestamp: modTask?.objectTime ?? modTask?.time, color: textColorGrey})
                                }
                            />
                        )}
                        <View style={{ flexDirection: "row" }}>
                            <Paragraph text={modTask?.text != null ? modTask.text : modAuthor?.text} numberOfLines={!expanded && 2} color={textColorBlack}/>
                        </View>
                        <View style={{flexDirection: "row", justifyContent: "space-between", gap: 40}}>
                            <View  style={{flexDirection: "row", gap: 6, flex: 1, paddingTop: 5}}>
                                <Paragraph strong label={"Origin:"} color={textColorBlack}/>
                                <Paragraph
                                    text={linkedQuestion || linkedTopic ? (linkedQuestion?.question ?? linkedTopic?.name) : "No origin available"}
                                    color={textColorGrey}
                                    numberOfLines={!expanded ? 1 : 3}
                                />
                            </View>
                            <IconButton icon={ArrowUpRight} iconProps={{color:textColorBlack}} label={viewLabel} onPress={!modTask?.deleted && onPressView} textColor={textColorBlack}/>
                        </View>
                        {!expanded && <SubtleButton icon={(props) => <Add {...props} style={{ flexShrink: 0 }}/>} label={"Show more"} onPress={onPressExpand} color={colorAmbigousBlack}/>}
                        {expanded && expandedSection}
                    </View>
                </PadBox>
            </View>
        </Catcher>
    );
}

function StatusTag({modAuthor}) {
    const userId = modAuthor?.authorKey;
    const timestampLastModerationAction = useModulePublicData("moderation", ["user", userId, "timeLastModerationAction"]);
    const isBlocked = useServerCallResult("moderationZdf", "getIsUserBlocked", { key: userId, timestampLastModerationAction });
    return <>{isBlocked && <ThinTag emoji={"🚫"} label="Blocked" backgroundColor={colorSurfaceWarning} />}</>
}

function MessageToModerator({ modTask }) {
    const s = MessageToModeratorStyle;

    const textColor = modTask.deleted ? colorAmbiguousLightGrey : colorAmbigousBlack;

    if (modTask?.appealText != null && modTask?.appealText != undefined) {
        return (
            <View style={s.container}>
                <View style={s.headingRow}>
                    <Chat color={textColor} style={{ flexShrink: 0 }}/>
                    <UtilityText label={"Message to moderator"} type="small" weight="medium" color={textColor}/>
                </View>
                <View style={{ flexDirection: "row" }}>
                    <Paragraph text={modTask?.appealText} type="small" color={textColor}/>
                </View>
            </View>
        );
    }
}

const MessageToModeratorStyle = StyleSheet.create({
    container: {
        paddingVertical: 16,
        gap: 12,
    },
    headingRow: {
        gap: 8,
        flexDirection: "row",
        flexShrink: 0,
    },
});

function CommentDetailSection({ children }) {
    const s = ModDashboardCommentCardStyle;

    return <View style={s.commentDetailSection}>
        {children}
    </View>
}

function CommentInformation({ modTask }) {
    const s = ModDashboardCommentCardStyle;
    const textColorBlackBase = modTask.deleted ? colorAmbiguousLightGrey : colorAmbigousBlack;
    const textColorGreyBase = modTask.deleted ? colorAmbiguousLightGrey : colorTextSecondary;

    return (
        <CommentDetailSection>
            <View style={{ flexDirection: "row"}}>
                <Heading label={"Comment information"} color={textColorBlackBase} />
            </View>
            <View style={[s.commentInformationContainer]}>
                <CommentInformationBlock label={"Submitted"} disabled={modTask.deleted}>
                    <HorizBox>
                        <Time color={colorTextSecondary}/>
                        <Pad size={4} />
                        <ModDashboardDateTime timestamp={modTask.objectTime ?? modTask.time} color={textColorGreyBase}/>
                    </HorizBox>
                </CommentInformationBlock>
                {modTask.id && <CommentInformationBlock label={"Comment ID"} disabled={modTask.deleted}>
                    <HorizBox gap={4}>
                        <IdManagement color={colorTextSecondary} />
                        <UtilityText text={modTask.id} color={colorTextSecondary}/>
                    </HorizBox>
                </CommentInformationBlock>}
                <CommentInformationBlock label={"Status"} modTask={modTask} disabled={modTask.deleted}>
                    <JudgementTag modTask={modTask} />
                    {modTask.deleted && <DeletedTag />}
                </CommentInformationBlock>
            </View>
        </CommentDetailSection>
    );
}

function CommentInformationBlock({ children, label, disabled }) {

    return <View style={ModQueueCommentCardStyle.commentInformationBlock}>
        <UtilityText type="small" weight="medium" label={label} color={disabled ? colorAmbiguousLightGrey : colorAmbigousBlack}></UtilityText>
        {children}
    </View>
}

function NoteFromColleague({ time, text, author, disabled }) {
    const textColorBlack = disabled ? colorAmbiguousLightGrey : colorAmbigousBlack;
    const textColorGrey = disabled ? colorAmbiguousLightGrey : colorTextSecondary;

    if (author) {
        return (
            <View style={{ gap: 8}}>
                <BylineModerationCard
                    name={author?.name}
                    photoUrl={author?.photoUrl}
                    hue={author?.hue}
                    color={textColorBlack}
                    disabled={disabled}
                    SecondaryLineComponent={() =>
                        ModDashboardDateTime({ timestamp: time, color: textColorGrey})
                    }
                />
                <View style={{ flexDirection: "row"}}>
                    <Paragraph text={text} type="small" color={textColorBlack}/>
                </View>
            </View>
        );
    }

}

async function saveNote({ dataStore, from, text, modTaskKey }) {
    await dataStore.callServerAsync("moderationZdf", "setModeratorNote", {
        text: text,
        from: from,
        modTaskKey: modTaskKey
    })
}


function NotesFromColleagues({ modTask }) {
    const [modNotes, setModNotes] = useState([]);
    const dataStore = useDatastore()
    const [canWrite, setCanWrite] = useState(false)
    const [text, setText] = useState("")
    const personaKey = usePersonaKey()
    const modNoteCount = useModulePublicData("moderation", ["moderatorNotes", modTask.key, "amount"])
    const personaKeys = modNotes.map(note => note.from)
    const notePersonaObjects = usePersonasByIds(personaKeys)

    const s = NotesFromColleaguesStyle;

    const textColorBlackBase = modTask.deleted ? colorAmbiguousLightGrey : colorAmbigousBlack;

    useEffect(() => {
        async function getNotes() {
            const notes = await dataStore.callServerAsync("moderationZdf", "getModeratorNotes", {
                modTaskKey: modTask.key
            })
            setModNotes(Object.values(notes ?? {}))
        }
        getNotes()
    }, [modNoteCount])

    if(modTask.deleted && modNotes.length <= 0) {
        return null
    }

    return <CommentDetailSection>
        <View style={s.row}>
            <Heading label={"Internal Notes"} weight="strong" color={textColorBlackBase}/>
        </View>

        <View style={{gap: 12}}>
            {modNotes.length > 0 ? modNotes.map((note, idx) => (<NoteFromColleague key={note.key} author={notePersonaObjects[note.from]} time={note.time} text={note.text} disabled={modTask.deleted}/>)) : null}
            {!modTask.deleted && <>
                {!canWrite ? <TextFieldButton testID={'Add your notes...'} placeholder='Add your notes...' onPress={() => { setCanWrite(true) }} /> : <View style={{ gap: 8 }}>
                    <TextField testID={"moderatorNoteInput"} autoFocus placeholder={"Add your notes..."} value={text} onChange={(newText) => setText(newText)} />
                    <HorizBox right center>
                        <View style={{ flexDirection: "row"}}>
                            <TextButton label={"Cancel"} onPress={() => {
                                setText("")
                                setCanWrite(false)

                            }} />
                            <Pad size={28} />
                            <CTAButton disabled={text.length <= 0} label={"Post"} type="large" onPress={() => {
                                saveNote({ dataStore: dataStore, from: personaKey, modTaskKey: modTask.key, text: text })
                                setCanWrite(false)
                                setText("")
                            }} />
                        </View>
                    </HorizBox>
                </View>}
            </>}
        </View>  
    </CommentDetailSection>
}

const NotesFromColleaguesStyle = StyleSheet.create({
    container: {
        paddingVertical: 16,
        gap: 12
    },
    row: {
        gap: 8,
        flexDirection: "row"
    }
})

function ViolationsFoundByPreModeration({ modTask }) {
    return (
        <CommentDetailSection>
            <View style={{ flexDirection: "row" }}>
                <Heading label={"Pre-moderation flag"} />
            </View>
            <ViolationContainer>
                <RuleViolationList
                    violations={modTask.violations}
                    blacklistedTerms={
                        modTask.metadata && modTask.metadata.blacklistedTerm ? [modTask.metadata.blacklistedTerm] : []
                    }
                    aiExplanation={modTask.metadata?.explanation}
                />
            </ViolationContainer>
        </CommentDetailSection>
    );
}

function ViolationContainer({ children }) {
    return <Banner>
        <View style={{ gap: 16 }}>
            {children}
        </View>
    </Banner>
}

// Shows a button, which opens a modal dialog that shows each report in a list inside a modal
function MultipleUserReportsModalButton({reports = []}){
    const reporters = reports.filter((report => report.reporter)).map(report=>report.reporter)
    const count = reporters?.length ?? 0;
    const [showReporters, setShowReporters] = useState(false)
    return <>
        <HorizBox center>
            <SubtleButton testID={"reporters"} color={colorAmbigousBlack} strong={true} icon={UserMultiple} label={"{count} users"} formatParams={{ count: count.toString() }} onPress={() => { setShowReporters(true) }} />
        </HorizBox>
        {showReporters && <Modal onClose={() => { setShowReporters(false) }}>
            <PadBox horiz={20} vert={40}>
                <View style={{ gap: 32 }}>
                    <HorizBox center>
                        <Heading type="small" strong label={"Reported by users"} />
                        <Pad size={4} />
                        <View style={MultiReporterButtonStyle.count}>
                            <UtilityText type="small" text={reporters.length} center strong />
                        </View>
                    </HorizBox>
                    <View style={{ gap: 24 }}>
                        {reports.map(report => {
                            const reporter = report.reporter
                            return reporter ? <BylineModerationCard key={report.key} userId={reporter.key} name={reporter.name} hue={reporter.hue} photoUrl={reporter.photoUrl} SecondaryLineComponent={
                                () => ModDashboardDateTime({ timestamp: report.time, color: colorTextSecondary })
                            } /> : null
                        })}
                    </View>
                </View>
            </PadBox>
        </Modal>}
    </>
}

const MultiReporterButtonStyle = StyleSheet.create(
    {
        count: { 
            width: 25,
            height: 25, 
            borderRadius: "50%", 
            backgroundColor: colorButtonDisabled, 
            justifyContent: "center", 
            alignItems: "center" }
    }
)

function UserReport({modTask={}}){
    const reports = useServerCallResult("moderationZdf", "getReportsForModTask", {
        key: modTask.key
    })
    const reportObjects = reports ? Object.values(reports) : [];
    const reporters = reportObjects.filter((report => report.reporter)).map(report => report.reporter)

    return (
        <Catcher>
            <CommentDetailSection>
                <View style={{ flexDirection: "row"}}>
                    <Heading label={"User report"} />
                </View>
                <ViolationContainer>
                    <RuleViolationList violations={modTask.violations} />
                    {reporters.length > 0 && <><Separator />
                        <View>
                            <View style={{ flexDirection: "row"}}>
                                <UtilityText label={"Reported by"} type="small" weight="medium" />
                            </View>
                            <Pad size={16} />
                            {reporters.length > 1 && <MultipleUserReportsModalButton reports={reportObjects} />}
                            {reporters.length === 1 && (
                                <BylineModerationCard
                                    photoUrl={reporters[0].photoUrl}
                                    name={reporters[0].name}
                                    hue={reporters[0].hue}
                                    userId={reporters[0].key}
                                    SecondaryLineComponent={() =>
                                        ModDashboardDateTime({
                                            timestamp: modTask.judgementTime ?? modTask.time,
                                            color: colorTextSecondary,
                                        })
                                    }
                                />
                            )}
                        </View></>}
                </ViolationContainer>
            </CommentDetailSection>
        </Catcher>
    );
}

function JudgementInformation({ modTask={} }) {
    const judge = useModulePublicData("moderation", ["moderator", modTask.judgeKey])

    return (
        <CommentDetailSection>
            <View style={{ flexDirection: "row"}}>
                <Heading label={"Manually rejected "} />
            </View>
            <ViolationContainer>
                <RuleViolationList localized={false} violations={modTask.reasoning} />
                <Separator />
                <View>
                    <View style={{ flexDirection: "row"}}>
                        <UtilityText label={"Rejected by"} weight="medium" />
                    </View>
                    <Pad size={8} />
                    {judge && <BylineModerationCard
                        photoUrl={judge?.photoUrl}
                        name={judge?.name}
                        hue={judge?.hue}
                        userId={judge?.key}
                        SecondaryLineComponent={() =>
                            ModDashboardDateTime({
                                timestamp: modTask.judgementTime ?? modTask.time,
                                color: colorTextSecondary,
                            })
                        }
                    />}
                </View>
            </ViolationContainer>
        </CommentDetailSection>
    );
}

function RuleViolationList({ violations, blacklistedTerms = [], aiExplanation, localized = true }) {
    return (
        <View style={{ flexDirection: "column", gap: 4 }}>
            {blacklistedTerms.length === 0 && (
                <CommunityGuidelineViolation violations={violations} localized={localized} />
            )}
            {blacklistedTerms.length > 0 && <BlocklistViolation blocklistTerms={blacklistedTerms} />}
            {aiExplanation && <AIExplanation aiExplanation={aiExplanation} />}
        </View>
    );
}

function CommunityGuidelineViolation({ violations, localized }) {
    let violationArray = [];
    if (violations === "" || violations === undefined || violations === null) {
        violationArray = ["No information available."];
    }
    else {
        try {
            violationArray = JSON.parse(violations);
            if (!Array.isArray(violationArray)) {
                violationArray = [violations.toString()];
            }
        }
        catch {
            violationArray = [violations.toString()];
        }
    }

    // Gets rid of duplicate violations
    const deduplicatedViolations = [...new Set(violationArray)];

    return (
        <>
            <Heading label={"⚠️ Community guideline violation"} />
            <View style={{ gap: 12, margin: 8 }}>
                {deduplicatedViolations.map((violation, idx) => (
                    <BulletText
                        label={localized ? violation : null}
                        text={!localized ? violation : null}
                        key={"violation-" + idx}
                    />
                ))}
            </View>
        </>
    );
}

function BlocklistViolation({ blocklistTerms }) {
    return (
        <>
            <Heading label={"⚠️ Blocklist words"} />
            <View style={{ gap: 12, margin: 8 }}>
                {/* Blacklist terms can't be localized. They are fixed terms. */}
                {blocklistTerms.map((blocklistTerm, idx) => (
                    <BulletText text={blocklistTerm} key={"violation-" + idx} />
                ))}
            </View>
        </>
    );
}

function AIExplanation({ aiExplanation }) {
    if (!aiExplanation) return null;
    return (
        <>
            <PadBox vert={8}>
                <Separator />
            </PadBox>
            <Heading label={"AI explanation"} />
            <Pad size={8} />
            <UtilityText text={aiExplanation} />
        </>
    );
}

function UserInformation({ modAuthor, disabled }) {
    const profileData = useUserProfileModerationData({userId: modAuthor.authorKey})

    const textColorBlack = disabled ? colorAmbiguousLightGrey : colorAmbigousBlack;
    const textColorGrey = disabled ? colorAmbiguousLightGrey : colorTextSecondary;

    const memberSince = () => (
        <HorizBox>
            <UtilityText label={"Member since "} color={colorTextSecondary}  type="tiny"/>
            <UtilityText text={new Date(modAuthor.authorMemberSince).getFullYear().toString()} color={textColorGrey} type="tiny"/>
        </HorizBox>
    );

    return (
        <CommentDetailSection>
            <View style={{ flexDirection: "row"}}>
                <Heading label={"User information"} color={textColorBlack}/>
            </View>
            <BylineModerationCard
                photoUrl={modAuthor.authorPhoto}
                name={modAuthor.authorName}
                userId={modAuthor.authorKey}
                hue={modAuthor.authorHue}
                size={"large"}
                disabled={disabled}
                SecondaryLineComponent={memberSince}
            />
            <ModDashboardUserStatistics userProfileData={profileData} disabled={disabled}/>
        </CommentDetailSection>
    );
}

function JudgementTag({ modTask }) {
    let emoji = ModerationFilters.AwaitingDecision.emoji;
    let label = ModerationFilters.AwaitingDecision.text;

    let isReject = false;
    let isWaiting = true;

    if (modTask.judgement) {
        isReject = modTask?.judgement === 'reject';
        isWaiting = false;

        if (isReject) {
            emoji = ModerationFilters.Rejected.emoji;
            label = ModerationFilters.Rejected.text;
        } else {
            if (modTask.humanJudgement === true) {
                emoji = ModerationFilters.HumanApproved.emoji;
                label = ModerationFilters.HumanApproved.text;
            } else {
                emoji = ModerationFilters.AutomaticallyApproved.emoji;
                label = ModerationFilters.AutomaticallyApproved.text;
            }
        }
    }

    return (
        <ThinTag
            emoji={emoji}
            label={label}
            backgroundColor={isWaiting === true ? colorTagVerified : isReject === true ? colorSurfaceWarning : colorLightGreen}
        />
    );
}

function WarningTag() {
    return <ThinTag emoji="🚦" label="Warned" backgroundColor={colorSurfaceWarning}/>;
}

function DeletedTag() {
    return <ThinTag emoji="🗑️" label="Deleted by user" backgroundColor={colorSurfaceWarning}/> ;
}

const ModQueueCommentCardStyle = StyleSheet.create({
    cardContainer: {
        flex: 1,
        minWidth: 0,
        borderRadius: 8,
        boxShadow: "0px 2px 10px 0px #0000001A",
        backgroundColor: colorAmbiguousWhite,
        position: "relative",
        overflow: "hidden",
        borderWidth: 1,
        borderColor: colorAmbiguousWhite,
        cursor: "pointer" 
    },
    cardContainerExpanded: {
        borderColor: colorAmbigousBlack,
        cursor: "auto" 

    },
    cardTopBar: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    commentStatusTags: {
        display: "flex",
        flexDirection: "row",
        gap: 12,
    },
    commentDetailSection: {
        borderTopColor: colorBorderPrimary,
        borderTopWidth: 1,
        paddingVertical: 16,
        gap: 24,
    },
    commentInformationContainer: {
        backgroundColor: colorAmbiguousWhite,
        flexDirection: "row",
        borderRadius: 11,
        gap: 24,
    },
    commentInformationBlock: {
        gap: 8,
        minWidth: 180
    },
    eyeIcon: {
        flexDirection: "row",
        height: 20,
        paddingHorizontal: 6,
        gap: 6,
        backgroundColor: colorTagHighlight,
        borderRadius: 32,
        alignItems: "center"
    },
    skeleton: {
        left: 0, right:0, bottom:0, top:0,
        position: "absolute",
        backgroundColor: colorAmbiguousWhite,
        zIndex: 10
    }
})

function BylineModerationCard({ photoUrl, hue, name, userId, size = "small", StatusTag, SecondaryLineComponent, disabled = false}) {
    // We have to use the parentDatastore in case the Moderation Card is shown within an embedded instance
    const parentDatastore = useParentDatastore();
    const params = useInstanceParams();

    function onProfile() {
        if (!params) return
        params.userId = userId;
        gotoInstance({structureKey: "moddashboard", instanceKey: parentDatastore.props.instanceKey, params: params})

    }

    const s = ModerationBylineStyle;

    return (
        <View style={s.container}>
            <View>
                {photoUrl ?
                    <FaceImage type={size} photoUrl={photoUrl} faint={disabled}/> :
                    <LetterFace type={size} name={name} hue={hue} faint={disabled}/>
            } 
            </View>
            <View>
                <View style={s.column}>
                    <HorizBox center gap={8}>
                       <TextButton type='small' text={name} strong onPress={!disabled && userId && onProfile} color={disabled ? colorAmbiguousLightGrey : colorAmbigousBlack}/>
                       {StatusTag}
                    </HorizBox>
                    <View style={s.statusTag}>
                        {SecondaryLineComponent && <SecondaryLineComponent />}
                    </View>
                </View>
            </View>
        </View>
    );
}

const ModerationBylineStyle = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8,
    },
    column: {
        flexDirection: 'column',
        gap: 2
    }
});