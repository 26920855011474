import { FragmentRedirectScreen, LoginBlockedScreen, OnboardingScreen, UnauthenticatedLoginScreen } from "structure/login"
import { CLICK, INPUT } from "system/demo"
import { StructureDemo } from "util/instance"

export const LoginDemo = {
    key: 'demo_login',
    name: 'Login Demo',
    config: {
        componentSections: [
            {label: 'Core Design System', key: 'core', pages: [
                {label: 'Login', key: 'login', storySets: loginStorySets}
            ]}
        ],
        structureSections: [
            {label: 'Core Structures', key: 'core', pages: [
                {label: 'Login Blocked', key: 'loginblocked', screen: LoginBlockedDemoScreen},
            ]},
        ]
    }
}

function loginStorySets() {return [
    {
        label: 'Unauthenticated Login Screen',
        content: <UnauthenticatedLoginScreen />,
        stories: [
            {label: 'Continue with RC Internal', actions: [
               CLICK('Continue with Radio Canada Internal')
            ]},
        ]
    },    
    {
        label: 'Onboarding',
        content: <OnboardingScreen />,
        serverCall: {profile: {
            checkName: ({name}) => ({violates: name == 'meanword'}),
            update: () => {}
        }},
        time: 1234567812345,
        stories: [
            {label: 'Just Continue', actions: [
                CLICK('consent-agreement'),
                CLICK('Finish')
            ]},
            {label: 'Letter Photo', actions: [
                CLICK('consent-agreement'),
                CLICK('letter'), CLICK('Finish')
            ]},
            {label: 'Good Pseudonym', actions: [
                CLICK('consent-agreement'),
                CLICK('Add a custom name'), 
                INPUT('pseudonym', 'malice'), CLICK('Finish')
            ]},
            {label: 'Bad Pseudonym', actions: [
                CLICK('consent-agreement'),
                CLICK('Add a custom name'), 
                INPUT('pseudonym', 'meanword'), CLICK('Finish')
            ]},
        ]
    },
    {
        label: 'Fragment Redirect Screen',
        urlFragment: '#state=global-rcInt-true&id_token=eyJhbGciOiJSUzI1NiIsImtpZCI6IkNBMkM3QzJFQjlEMkE1MkE1QTJEMzdDQzBFRTczMjI1QzcwM0ZBMDYiLCJ4NXQiOiJ5aXg4THJuU3BTcGFMVGZNRHVjeUpjY0QtZ1kiLCJ0eXAiOiJKV1QifQ.eyJleHAiOjE3MzAzMzIwOTUsIm5iZiI6MTczMDMxMDQ5NSwidmVyIjoiMS4wIiwiaXNzIjoiaHR0cHM6Ly9pbnQtbG9naW4uY2JjLnJhZGlvLWNhbmFkYS5jYS8wYWExZThjMi01MjQyLTQ3YTQtOTk2YS02ZWQ2OTRlZTcxMjcvdjIuMC8iLCJzdWIiOiJkMDFjZGUzNi00MjJkLTRkYjAtOTVhMS1hNDMzYzVhOTJiNjAiLCJhdWQiOiI3N2QxYjA4NS0xYzM5LTRmMGUtYjZjZS05MmFjYWY5OTIyOTUiLCJhY3IiOiJiMmNfMWFfZXh0ZXJuYWxjbGllbnRfZnJvbnRlbmRfbG9naW4iLCJub25jZSI6IjM4NGdrc3d0MHNlYW92MXJtNHVsdSIsImlhdCI6MTczMDMxMDQ5NSwiYXV0aF90aW1lIjoxNzMwMzEwNDk1LCJhenBDb250ZXh0IjoiaWNpY2EiLCJvaWQiOiIwNmQ1ZTlhZi0wYWU5LTQ0ZmUtYWZlMC01OWNlYTFlY2ZjNWUiLCJlbWFpbCI6InJvYkBuZXdwdWJsaWMub3JnIiwicmNpZCI6ImQwMWNkZTM2LTQyMmQtNGRiMC05NWExLWE0MzNjNWE5MmI2MCIsImdpdmVuX25hbWUiOiJSb2IiLCJmYW1pbHlfbmFtZSI6IkVubmFscyIsIm5hbWUiOiJSb2IgRW5uYWxzIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImlkcFVzZXJJZCI6ImQxNTBiMWE2OWE4MjQ2Yzk4YThlNDgwMDM4ODA1ZjkyIiwibHJhdCI6IjY3MjE2MGNmLWZlMWYtNGIxMy1hYzZhLTM1Y2YwYWVlMDIwMCIsImF1dGhfZnJvbV9zZXNzaW9uIjp0cnVlLCJpZHAiOiJyYWRpb2NhbmFkYSIsImp0aSI6IjBhYTFlOGMyLTUyNDItNDdhNC05OTZhLTZlZDY5NGVlNzEyN18zNjBmODIzMi1lMDM4LTRhMjYtYTkyOS1hZjRiZDU1ZDdiNjIifQ.V9E0TmX_wY8R57YdHABmJAU9T8d3PEWUdAcjHDO73Owbd9H21pocJaEk--HX6MF9NpPwyJKV8C4yXNUkfOdi--qmVpKJVjBreerVvDhd-8FFTykP3EurVpFE5kgmW-LjIXOIVIyHtnNu53Uxq-vaJufAQlAf0tAnE4h1cVSF-ycs2iSeTqT_zH-DXlkKWrOOfZXzztjKwAP7UN9AINo2eMf9iTS0FYsdgyNnniL6t5wKI9PyRcweZaQl4AFv5gJxv0NCs7DSGp8audfNiQcR4Pycu2nQGP7bSvnQT1scf2-W6SxpOCQA6wL0KHxcGkTiqxQ9ttNj6uzAmtvmXEzgoQ',
        content: <FragmentRedirectScreen />,
        serverCall: {
            auth: {convertToken: () => ({loginToken: 'firebase-token'})},
            local: {signInWithToken: () => {}}
        }
    },
    {
        label: 'Fragment Redirect Screen (No Popup)',
        urlFragment: '#state=global-rtbf-true-true&id_token=eyJhbGciOiJSUzI1NiIsImtpZCI6IkNBMkM3QzJFQjlEMkE1MkE1QTJEMzdDQzBFRTczMjI1QzcwM0ZBMDYiLCJ4NXQiOiJ5aXg4THJuU3BTcGFMVGZNRHVjeUpjY0QtZ1kiLCJ0eXAiOiJKV1QifQ.eyJleHAiOjE3MzAzMzIwOTUsIm5iZiI6MTczMDMxMDQ5NSwidmVyIjoiMS4wIiwiaXNzIjoiaHR0cHM6Ly9pbnQtbG9naW4uY2JjLnJhZGlvLWNhbmFkYS5jYS8wYWExZThjMi01MjQyLTQ3YTQtOTk2YS02ZWQ2OTRlZTcxMjcvdjIuMC8iLCJzdWIiOiJkMDFjZGUzNi00MjJkLTRkYjAtOTVhMS1hNDMzYzVhOTJiNjAiLCJhdWQiOiI3N2QxYjA4NS0xYzM5LTRmMGUtYjZjZS05MmFjYWY5OTIyOTUiLCJhY3IiOiJiMmNfMWFfZXh0ZXJuYWxjbGllbnRfZnJvbnRlbmRfbG9naW4iLCJub25jZSI6IjM4NGdrc3d0MHNlYW92MXJtNHVsdSIsImlhdCI6MTczMDMxMDQ5NSwiYXV0aF90aW1lIjoxNzMwMzEwNDk1LCJhenBDb250ZXh0IjoiaWNpY2EiLCJvaWQiOiIwNmQ1ZTlhZi0wYWU5LTQ0ZmUtYWZlMC01OWNlYTFlY2ZjNWUiLCJlbWFpbCI6InJvYkBuZXdwdWJsaWMub3JnIiwicmNpZCI6ImQwMWNkZTM2LTQyMmQtNGRiMC05NWExLWE0MzNjNWE5MmI2MCIsImdpdmVuX25hbWUiOiJSb2IiLCJmYW1pbHlfbmFtZSI6IkVubmFscyIsIm5hbWUiOiJSb2IgRW5uYWxzIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImlkcFVzZXJJZCI6ImQxNTBiMWE2OWE4MjQ2Yzk4YThlNDgwMDM4ODA1ZjkyIiwibHJhdCI6IjY3MjE2MGNmLWZlMWYtNGIxMy1hYzZhLTM1Y2YwYWVlMDIwMCIsImF1dGhfZnJvbV9zZXNzaW9uIjp0cnVlLCJpZHAiOiJyYWRpb2NhbmFkYSIsImp0aSI6IjBhYTFlOGMyLTUyNDItNDdhNC05OTZhLTZlZDY5NGVlNzEyN18zNjBmODIzMi1lMDM4LTRhMjYtYTkyOS1hZjRiZDU1ZDdiNjIifQ.V9E0TmX_wY8R57YdHABmJAU9T8d3PEWUdAcjHDO73Owbd9H21pocJaEk--HX6MF9NpPwyJKV8C4yXNUkfOdi--qmVpKJVjBreerVvDhd-8FFTykP3EurVpFE5kgmW-LjIXOIVIyHtnNu53Uxq-vaJufAQlAf0tAnE4h1cVSF-ycs2iSeTqT_zH-DXlkKWrOOfZXzztjKwAP7UN9AINo2eMf9iTS0FYsdgyNnniL6t5wKI9PyRcweZaQl4AFv5gJxv0NCs7DSGp8audfNiQcR4Pycu2nQGP7bSvnQT1scf2-W6SxpOCQA6wL0KHxcGkTiqxQ9ttNj6uzAmtvmXEzgoQ',
        content: <FragmentRedirectScreen />,
        serverCall: {
            auth: {convertToken: () => ({loginToken: 'firebase-token'})},
            local: {signInWithToken: () => {}}
        }
    },
    {
        label: 'Login Blocked Screen',
        content: <LoginBlockedScreen />,
        personaKey: 'b',
        modulePublicData: {
            moderation: {
                suspension: {
                    b: {isBlocked: true}
                }
            }
        },
        serverCall: {
            local: {signOut: () => {}}
        },
        stories: [
            {label: 'Okay', actions: [
                CLICK('Okay')
            ]}
        ]
    }
]}

function LoginBlockedDemoScreen() {
    const modulePublic = {moderation: {
        suspension: {
            a: {isBlocked: true}
        }
    }}
    return <StructureDemo 
        testState={{modulePublic}} 
        structureKey='simplecomments' 
    />
}