import { useEffect, useState } from "react";
import { Pressable, Role, ScrollView, StyleSheet, View } from "react-native";
import { colorAmbiguousHighlight, colorAmbiguousWhite, colorBorderPrimary, colorSurfaceDiscovery, colorTagVerified } from "./color";
import { UtilityText, WebLinkTextButton } from "./text";
import { setTitle } from "../platform-specific/url";
import { Banner } from "./banner";
import { useIsEmbedded, useIsInSidebar, useSiloKey } from "../util/datastore";
import { useTranslation } from "./translation";
import { undefN, undefR, undefS } from "../util/stdtypes";


const MIN_HEIGHT_CLICKZONE = 44;
const MIN_WIDTH_CLICKZONE = 44;

export function Pad({size=20}) {
    return <View style={{height: size, width: size}}/>
}

const undefRole : Role | undefined = undefined;

// Note: react-testing-library only supports DOM events, so we can't test hover events right now
export function HoverView({ style=undefR, ariaLabel=undefS, ariaText=undefS, testID=undefS, role=undefRole, hoverStyle=undefR, pressedStyle=undefR, children, disabled=false, onPress, shrink=false, setHover=(h:boolean)=>{}, setPressed=(p:boolean)=>{}, ...props}) {
    const [localHover, setLocalHover] = useState(false);
    const [localPressed, setLocalPressed] = useState(false);
    const [accessibilityPress, setAccessibilityPress] = useState(false);
    
    const handleLayout = (event) => {
        const { height, width } = event.nativeEvent.layout || {};
        if (height <= MIN_HEIGHT_CLICKZONE || width <= MIN_WIDTH_CLICKZONE) {
            setAccessibilityPress(true);
        }
    };
    const tAriaLabel = useTranslation(ariaLabel);

    if (disabled || !onPress) {
       return <View role={role} aria-disabled aria-label={tAriaLabel ?? ariaText} style={style}>{children}</View>
    }
    return <Pressable
        role={role}
        aria-label={tAriaLabel ?? ariaText}
        testID={testID ?? ariaLabel}
        style={[style, localHover ? hoverStyle : null, localPressed ? pressedStyle : null, shrink ? {flexShrink: 1} : null]}
        onPressIn={() => {setLocalPressed(true); setPressed(true)}}
        onPressOut={() => {setLocalPressed(false); setPressed(false)}}
        onPress={onPress}
        onHoverIn={() => { setLocalHover(true); setHover(true) }}
        onHoverOut={() => { setLocalHover(false); setHover(false) }}
        onLayout={handleLayout}
        {...props}
    >
        {children}
       {(accessibilityPress) && <InvisibleExtendedClickArea />}
    </Pressable>
}

function InvisibleExtendedClickArea() {
  const s = InvisibleExtendedClickAreaStyle;
  return <View aria-hidden style={s.clickArea}  />
}

// HACK: React-native doesn't support non-numeric positions.
const InvisibleExtendedClickAreaStyle = StyleSheet.create({
  clickArea: {
    position: 'absolute',
    left: '50%' as any,
    top: '50%' as any,
    width: MIN_WIDTH_CLICKZONE,
    height: MIN_HEIGHT_CLICKZONE,
    transform: "translate(-50%, -50%)" as any,
    minWidth: '100%' as any,
    minHeight: '100%' as any
  }
});

export function Card({ testID, children }) {
    const s = CardStyle;
    return <View style={s.card} testID={testID}>
        {children}
    </View>
}

const CardStyle = StyleSheet.create({
    card: {
        paddingBottom: 20,
        backgroundColor: colorAmbiguousWhite,
        borderBottomColor: colorBorderPrimary,
        borderBottomWidth: 1
    }
});

export function ConversationScreen({children, backgroundColor=colorAmbiguousWhite, pad=false, noWrap=false}) {
    const s = ConversationScreenStyle;
    const embedded = useIsEmbedded();
    const isInSidebar = useIsInSidebar();
    const [narrow, setNarrow] = useState(false);
    const useWrapper = !isInSidebar && !narrow && !noWrap;    
    const hasEmulateWarning = window.location.hostname == 'localhost' && !embedded;
 
    function onLayout(event) {
        const {width} = event.nativeEvent.layout;
        setNarrow(width < 540);
    }

    return <ScrollView style={[s.scroller, useWrapper ? null : {backgroundColor}]} onLayout={onLayout}>
        {useWrapper && <Pad size={40} />}
        <View style={s.outer}>
            <View style={[s.screen, 
                pad ? {paddingHorizontal: 20} : null, 
                useWrapper ? s.wrapper : null]}>
                {children}
            </View>
        </View>
        {useWrapper && <Pad size={48} />}
        <NewPublicFooter />
        {hasEmulateWarning && <Pad size={36} />}
    </ScrollView>
}

const ConversationScreenStyle = StyleSheet.create({
    wrapper: {
        borderRadius: 12,
        borderColor: colorBorderPrimary,
        borderWidth: 1,
        overflow: 'hidden',
    },
    scroller: {
        flex: 1,
        overscrollBehavior: 'none'
    },
    outer: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        backgroundColor: colorAmbiguousWhite,
        flex: 1
    },
    screen: {
        maxWidth: 600,
        flex: 1
    },
})

export function NewPublicFooter() {
    const siloKey = useSiloKey();
    if (siloKey != 'np') return null;
    return <Center>
        <HorizBox center>
            <UtilityText type='large' label='Made by ' mono />
            <WebLinkTextButton type='large' color={colorAmbiguousHighlight} mono url='https://newpublic.org' text='New_ Public' />
        </HorizBox>
        <Pad size={80} />
    </Center>
}

export function PadBox({children, horiz=undefN, vert=undefN, top=undefN, bottom=undefN, left=undefN, right=undefN}) {
    return <View style={{paddingHorizontal: horiz, paddingVertical: vert, paddingTop: top, paddingBottom: bottom, paddingLeft: left, paddingRight: right}}>
        {children}
    </View>
}

export function HorizBox({children, gap=undefN, center=false, shrink=false, right=false, spread=false}) {
    return <View style={{
            flexDirection: 'row', 
            alignItems: center ? 'center' : 'flex-start', 
            justifyContent: spread ? 'space-between' : right ? 'flex-end' : 'flex-start',
            flexShrink: shrink ? 1 : 0,
            gap
        }}>
        {children}
    </View>
}

export function VertBox({children, gap=undefN, wide=false,center=false, role=undefRole}) {
    return <View role={role} style={{
        flexDirection: 'column',
        alignItems: center ? 'center' : wide ? undefined : 'flex-start',
        width: wide ? '100%' : null,
        gap
    }}>
        {children}
    </View>
}


export function LoadingScreen({label = 'Loading...'}) {
    const [show, setShow] = useState(false);
    const s = LoadingScreenStyle;

    useEffect(() => {
        const timeout = setTimeout(() => setShow(true), 1000);
        return () => clearTimeout(timeout);
    }, []);

    if (show) {
        return <View style={s.outer}>
            {/* <UtilityText style={s.text} label={label} /> */}
            <Banner color={colorTagVerified}><UtilityText label={label} /></Banner>
        </View>
    } else {
        return null;
    }
}
const LoadingScreenStyle = StyleSheet.create({
    outer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        color: '#666'
    }
});

export function Narrow({children, pad=true}) {
    return <View style={{flexDirection: 'row', justifyContent: 'space-around', paddingVertical: pad ? 20 : undefined}}>
        <View style={{maxWidth: 600, flexShrink: 1, flexGrow: 1, marginHorizontal: pad ? 20 : undefined}}>
            {children}
        </View>
    </View>
}

export function Center({children, pad=0}) {
    return <View style={{alignSelf: 'center', alignItems: 'center', margin: pad}}>
        {children}
    </View>  
}

export function HeaderBox({children, backgroundColor=colorSurfaceDiscovery, horiz=20, vert=20}) {
    return <View style={{paddingHorizontal:horiz, paddingVertical:vert, backgroundColor}}>{children}</View>
}

export function Separator() {
    const s = SeparatorStyle;
    return <View style={s.separator} />
}
const SeparatorStyle = StyleSheet.create({
    separator: {
        height: 1,
        backgroundColor: colorBorderPrimary,
    }
});

export function WindowTitle({label, text, formatParams=undefR}) {
    const tLabel = useTranslation(label, formatParams);
    setTitle(tLabel ?? text);
    return null;
}

// HACK: Bump the key to force a re-render, otherwise the old hover state is preserved 
// when the component is unselected, and potentially moves to longer be under the mouse
// without getting a hover out event
export function HoverSelectBox({selected, testID, onPress, children}) {
    const s = HoverSelectBoxStyle;
    const [key, setKey] = useState(0);
    function onLocalPress() {
        onPress();
        setKey(key+1)
    }
    return <HoverView key={key} testID={testID} onPress={onLocalPress} disabled={!onPress || selected} style={s.outline} hoverStyle={s.hover}>
        {children}
    </HoverView>
}
const HoverSelectBoxStyle = StyleSheet.create({
    hover: {
        borderColor: colorBorderPrimary,
        borderWidth: 1,
        borderRadius: 8,
        padding: 0,
        boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.10)',
    },
    outline: {
        borderColor: colorBorderPrimary,
        borderWidth: 1,
        borderRadius: 8,
        padding: 0
    }
})

export function ShadowBox({children}) {
    const s = ShadowBoxStyle;
    return <View style={s.shadow}>{children}</View>
}

const ShadowBoxStyle = StyleSheet.create({
    shadow: {
        boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.10)',
        borderRadius: 12
    }
})

export function FlowBox({children, center=false, rowGap=undefN, columnGap=undefN}) {
    return <View style={{
            flexDirection: 'row', flexWrap: 'wrap', justifyContent: center ? 'center' : 'flex-start',
            rowGap, columnGap
        }}>
        {children}
    </View>
}

export function Container({children}) {
    const s = ContainerStyle;
    return <View style={s.outer}>
        {children}
    </View>
}

const ContainerStyle = StyleSheet.create({
    outer: {
        borderColor: colorBorderPrimary,
        borderWidth: 1,
        borderRadius: 12,
    }
});
