import { Text } from 'react-native';

import { colorTextPrimary } from './color';
import { LinkText, Paragraph, TextType } from './text';
import { useTranslation } from './translation';
import { TextButton } from './button';
import { emptyR, undefA, undefN, undefR, undefS } from '../util/stdtypes';

export const urlRegex = /(http:\/\/|https:\/\/)[-a-zA-Z0-9.-]{2,256}\.[a-z]{2,4}([\/?](\.?[-\w@:%+~#?&\/=]+)*)?/gi;
const markdownLinkRegex = /\[([^\]]+)\]\(([^\)]+)\)/;
const markdownBoldRegex = /\*\*([^\*]+)\*\*/;

function trimUrl(url) {
    if (url.length > 40) {
        return url.slice(0, 40) + '...'
    } else {
        return url;
    }
}

function addPrefixToUrl(url) {
    if (!url.startsWith(url, 'http')) {
        return 'http://' + url;
    } else {
        return url;
    }
}

function removeTrailingPeriod(url) {
    if (url[url.length - 1] == '.') {
        return url.slice(0, -1);
    } else {
        return url;
    }
}

function RichTextPart({ text, color, type = 'small' as TextType, callbacks = {} }) {
    if (!text) return null;
    const mMarkdown = text.match(markdownLinkRegex);
    if (mMarkdown && mMarkdown.length > 0) {
        const mdLink = mMarkdown[0];
        const title = mMarkdown[1];
        const url = mMarkdown[2];
        const start = text.indexOf(mdLink);
        const before = text.slice(0, start);
        const after = text.slice(start + mdLink.length);
        if (url.startsWith('$')) {
            const callback = callbacks[url.slice(1)];
            if (callback) {
                return (
                    <Text>
                        <RichTextPart color={color} type={type} text={before} callbacks={callbacks} />
                        <TextButton underline type={type} text={title} onPress={callback} />
                        <RichTextPart color={color} type={type} text={after} callbacks={callbacks} />
                    </Text>
                )
            } else {
                return <Text>
                    <RichTextPart color={color} type={type} text={before} callbacks={callbacks} />
                    <Paragraph type={type} text={title} />
                    <RichTextPart color={color} type={type} text={after} callbacks={callbacks} />
                </Text>
            }
        } else {
            return (
                <Text>
                    <RichTextPart color={color} type={type} text={before} callbacks={callbacks} />
                    <LinkText type={type} url={url} text={title} />
                    <RichTextPart color={color} type={type} text={after} callbacks={callbacks} />
                </Text>
            )
        }
    }
    const mUrl = text.match(urlRegex);
    if (mUrl && mUrl.length > 0) {
        const url = mUrl[0];
        const linkUrl = removeTrailingPeriod(addPrefixToUrl(url));
        const start = text.indexOf(url);
        const before = text.slice(0, start);
        const after = text.slice(start + url.length);
        return (
            <Text>
                <RichTextPart color={color} type={type} text={before} callbacks={callbacks} />
                <LinkText type={type} url={linkUrl} text={trimUrl(url)} />
                <RichTextPart color={color} type={type} text={after} callbacks={callbacks} />
            </Text>
        )
    }
    const mBold = text.match(markdownBoldRegex);
    if (mBold && mBold.length > 0) {
        const boldText = mBold[1];
        const start = text.indexOf(mBold[0]);
        const before = text.slice(0, start);
        const after = text.slice(start + mBold[0].length);
        return (
            <Text>
                <RichTextPart color={color} type={type} text={before} callbacks={callbacks} />
                <Paragraph color={color} type={type} text={boldText} strong />
                <RichTextPart color={color} type={type} text={after} callbacks={callbacks} />
            </Text>
        )
    }
    return <Paragraph color={color} type={type} text={text} />
}

export function RichText({ text=undefS, numberOfLines = undefN, onTextLayout=undefA, type = undefA, color = colorTextPrimary, label = undefS, formatParams = undefR, style = emptyR, callbacks = emptyR, suffixText = '' }) {
    const tText = useTranslation(label, formatParams);
    return <Text numberOfLines={numberOfLines} onTextLayout={onTextLayout} style={style}>
        <RichTextPart color={color} type={type} text={(text ?? tText) + suffixText} callbacks={callbacks} />
    </Text>
}
