import { CHECK_TEXT, CLICK, DemoSection, INPUT, POPUP } from "system/demo"
import { useState } from "react"
import { CircleCount, ComposerPerspectiveSelector, ComposerPerspectiveSelectorWithWriteIn, MissingPerspectivesCard, PerspectiveFilter, PerspectiveFilterPopup, PerspectivesForComment, PerspectivesSubtleBubble, PerspectiveToggle, WriteInPerspective } from "../../feature/question/RepresentingPerspectives"
import { Datastore } from "util/datastore"
import { addKey, removeKey } from "util/util"
import { StructureDemo } from "util/instance"
import { View } from "react-native"

export function RepresentingPerspectivesComponentsDemo() {
    const [comment, setComment] = useState({perspectives: {parent: {emoji: '👩‍👧‍👦', text: 'Parent'}, child: {emoji: '👧', text: 'Child'}}})
    const globals = {perspectives: '👩‍👧‍👦 Parent\n👧 Child\n👨‍🏫 Teacher\n👩‍💼 School Administrator\nEmojiless Person'}

    function onClose() {
        console.log('onClose');
    }
    const collections = {comment: [
        {from: 'a', text: 'a comment', perspectives: {Parent: {emoji: '👩‍👧‍👦', text: 'Parent'}, Child: {emoji: '👧', text: 'Child'}}}
    ]}
    const [editedSelected, setEditedSelected] = useState({Parent: true});
    function togglePerspective(text) {
        setEditedSelected(editedSelected?.[text] ? removeKey(editedSelected, text) : addKey(editedSelected, text));
    }

    return <View>
            <DemoSection text='Admin View - Missing Perspectives Card'>
                <Datastore testState={{globals, collections, roles: ['Editorial']}}>
                    <MissingPerspectivesCard />
                </Datastore>
            </DemoSection>   
            <DemoSection text='Perspectives For Comment' >
                <PerspectivesForComment comment={comment} />
                <PerspectivesForComment comment={{text: 'hello'}} />
            </DemoSection>
            <DemoSection text='Missing Perspectives Card'>
                <Datastore testState={{globals, collections}}>
                    <MissingPerspectivesCard />
                </Datastore>
                <Datastore testState={{globals, collections, personaKey:'b'}} >
                    <MissingPerspectivesCard />
                </Datastore>
                <Datastore testState={{globals}} >
                    <MissingPerspectivesCard />
                </Datastore>
                <Datastore testState={{globals:{perspectives: '👩‍👧‍👦 Parent\n👧 Child'}, collections}}>
                    <MissingPerspectivesCard />
                </Datastore>
            </DemoSection>         
            <DemoSection text='Composer Perspective Selector'>
                <Datastore testState={{globals}}>
                    <ComposerPerspectiveSelector comment={comment} setComment={setComment} />
                </Datastore>
            </DemoSection>
            <DemoSection text='Perspective Toggle'>
                <PerspectiveToggle comment={comment} setComment={setComment} perspective={{emoji: '👩‍👧‍👦', text: 'Parent'}} />
            </DemoSection>
            <DemoSection text='Perspective Filter'>
                <Datastore testState={{globals, collections}} config={{perspectiveShowFilter: true}} >
                    <PerspectiveFilter />
                </Datastore>
            </DemoSection>
            <DemoSection text='Perspective Filter Popup'>
                <Datastore testState={{globals, collections}} >
                <PerspectiveFilterPopup editedSelected={editedSelected} togglePerspective={togglePerspective}
                    perspectiveList={[{emoji: '👩‍👧‍👦', text: 'Parent'}, {emoji: '👧', text: 'Child'}]} onApply={onClose} />
                </Datastore>
            </DemoSection>
            <DemoSection text='Circle Count'>
                <CircleCount count={4} />
            </DemoSection>
    </View>
}

const comments = [
    {key: 1, from: 'a', text: 'Lead it away with food', perspectives: {"Animal Rights Activist": {"emoji":"🐾","text":"Animal Rights Activist"}}},
    {key: 2, from: 'b', text: 'Blow it up', perspectives: {"Military Leader": {"emoji":"🪖","text":"Military Leader"}}},
    {key: 3, from: 'c', text: "I don't know, but it just destroyed my house", perspectives: {"New York Resident": {"emoji":"🗽","text":"New York Resident"}}},
    {key: 4, from: 'd', text: "Make friends with it", perspectives: {writeIn: { 
        text: 'writeInText', 
        violates: true, 
        violations: ['No insults, mockery, threats, or personal attacks']}
    }}
]

const globals = {
    name: 'What should we do about the Giant Gorilla rampaging New York City?',
    perspectives: '🗽 New York Resident\n🐾 Animal Rights Activist\n🏙️ City Planner\n🪖 Military Leader',
    questionAuthor: 'a',
    questionTime: Date.now(),
}

export function representingPerspectivesStorySets() {return [
    {
        label: 'Perspective Filter',
        collections: {comment: [{key: 1, from: 'a', text: 'Lead it away with food'}]},
        globals: {perspectives: '🐾 Animal Rights Activist\n🗽 New York Resident'},        
        content: <PerspectiveFilter />, 
        config: {
            perspectiveShowFilter: true
        },
        stories: [
            {label: 'Open Popup and Select', actions: [
                CLICK('Sort by perspective'),
                POPUP(CLICK('Animal Rights Activist')),
            ]},
            {label: 'Add Filter', actions: [
                CLICK('Sort by perspective'),
                POPUP(CLICK('Animal Rights Activist')),
                POPUP(CLICK('Apply'))
            ]},
        ]
    },
    {
        label: 'Subtle Bubble',
        globals: {perspectives: '🐾 Animal Rights Activist\n🗽 New York Resident'},        
        content: <PerspectivesSubtleBubble />,
        stories: [
            {label: 'Navigate to Perspective', actions: [
                CLICK('Animal Rights Activist'),
            ]}
        ]
    },
    {
        label: 'Subtle Bubble Empty',
        // globals: {perspectives: '🐾 Animal Rights Activist\n🗽 New York Resident'},        
        content: <PerspectivesSubtleBubble />,
    },
    {
        label: 'Simple Write-In Perspective',
        globals: {perspectives: '🐾 Animal Rights Activist\n🗽 New York Resident'},
        content: <ComposerPerspectiveSelectorWithWriteIn comment={comments[3]} setComment={() => {}} />,
        stories: [
            {
                label: 'Write and Add Violating Perspective',
                actions: [
                    CLICK('Add your own'),
                ],
            },
        ],
    },
    {
        label: 'Violating Write-In Perspective',
        globals: {perspectives: '🐾 Animal Rights Activist\n🗽 New York Resident'},
        content: <WriteInPerspectiveDemo comment={comments[3]} />,
        serverCall: {
            moderation: {
                checkPerspectiveWithGpt: async () => ({
                    violates: true,
                    rulesBroken: [1, 2],
                }),
            },
        },
        stories: [
            {
                label: 'Write and Add Violating Perspective',
                actions: [
                    INPUT('write-in-perspective', 'Violating Perspective'),
                    CLICK('Add'),
                    CHECK_TEXT('Community Guidelines'),
                ],
            },
        ],
    },       
]}

function WriteInPerspectiveDemo({comment}) {
    const [writeInText, setWriteInText] = useState(''); 
    return <WriteInPerspective comment={comment} setComment={() => {}} 
            writeInText={writeInText} setWriteInText={setWriteInText} 
            sleep={() => Promise.resolve()} />
}

export function RepresentingPerspectivesFullDemo() {
    const collections = {comment: comments}
    return <StructureDemo 
        testState={{roles: ['Editoral'], globals, collections}}
        structureKey='question' 
        features={{representing_perspectives: true, 
            representing_perspectives_card: true,
            representing_perspectives_subtle_bubble: false,
            representing_perspectives_speech_bubble: false,
            reactiontypes: false
        }} 
    />
}