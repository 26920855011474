import { Banner } from "component/banner"
import { Pad, PadBox } from "component/basics"
import { CTAButton, SubtleButton } from "component/button"
import { colorAmbigousBlack, colorBlackHover, colorBorderPrimary, colorSurfaceDiscovery, colorSurfaceInfo, colorSurfaceWarning, colorTextSecondary } from "component/color"
import { RichText } from "component/richtext"
import { BulletText, Heading, TextField, UtilityText } from "component/text"
import { useDatastore, useModulePublicData, useSessionData } from "util/datastore"
import React, { useState } from "react"
import { Modal } from "component/modal"
import { goBack } from "util/navigate"
import { logEventAsync, useLogEvent } from "util/eventlog"
import { getParentRuleByChildText, getRuleById, GUIDELINES, GuidelinesModal } from "../question/CommunityGuidelinesFeature"
import { StyleSheet, Text, View } from "react-native"
import { Send } from "@carbon/icons-react"
import { formatDate } from "component/date"
import { getWillHelpBeSeenAsync, HelpBubble } from "component/help"
import { sleepMillisAsync } from "util/util"
import { AITag } from "component/aitag"

export const PreModeratorFeature = {
    name: 'Pre Moderation',
    key: 'premoderation',
    config: {
        commentPostBlockers: [isPostButtonDisabledAsync],
        commentEditBottomWidgets: [ModerationReport],
        commentPostCheckers: [checkPostAsync],
        topBarHelpBubbles: [ModerationInProgessHelpBubble]
    },
    defaultConfig: {
        commentModerators: [],
        useOnlyGptModeration: false
    }
}

export const ModerationHelpFeature = {
    name: 'Moderation Toast',
    key: 'moderationhelp',
    dev: true,
    config: {
        topBarHelpBubbles: [ModerationInProgessHelpBubble],
        commentPostCheckers: [waitForHelpBubbleAsync],
        commentPostTriggers: [clearHelpBubbleAsync]
    }
}

export const ModerateWithGPTFeature = {
    name: 'GPT',
    key: 'aimoderation',
    config: {
        commentModerators: [moderateWithGPT],
        commentPostActionLabel: commentPostActionLabel,
    },
    defaultConfig: {
        gptCheckSeverity: false
    }
}

export const ModerateWithJigsawFeature = {
    name: 'Jigsaw',
    key: 'jigsawmoderation',
    config: {
        commentModerators: [moderateWithJigsaw]
    }
}

function commentPostActionLabel({datastore, inProgress, isNewComment, comment}) {
    if (comment.minorViolation && comment.text == comment.violatingText) {
        if (inProgress) {
            return isNewComment ? 'Posting anyway...' : 'Updating anyway...';
        } else {
            return isNewComment ? 'Post anyway' : 'Update anyway';
        }
    }
    return null
}

async function moderateWithGPT({datastore, comment, questionText}) {
    const {gptCheckSeverity} = datastore.getConfig();
    const gptResult = await datastore.callServerAsync(
        'moderation', 'checkCommentWithGpt', {commentText: comment.text, questionText});

    if (gptResult.violates) {
        const violations = gptResult.rulesBroken?.map(getRuleById) ?? [];
        
        return {
            violates: gptResult.violates, violations, source: 'ai',
            metadata: {
                rulesBroken: JSON.stringify(gptResult.rulesBroken ?? []),
                explanation: gptResult.explanation ?? null,
                rewrite: gptResult.rewrite ?? null,
            },
            severe: gptResult.severe,
            minorViolation: gptCheckSeverity ? !gptResult.severe : null
        };
    } else {
        return {violates: false}
    }
}

async function moderateWithJigsaw({datastore, comment}) {
    const moderationResult = await datastore.callServerAsync(
        'moderation', 'checkCommentWithJigsaw', {text: comment.text});
    if (moderationResult.violates) {
        return {
            violates: true, source: 'jigsaw', 
            violations: [GUIDELINES.NO_UNCIVIL],
            metadata: {toxicity: moderationResult.toxicity}
        };
    } else {
        return {violates: false}
    }
}

export const ModerateRepliesStrictlyFeature = {
    name: 'Strict Reply Moderation',
    key: 'strictreplymoderation',
    config: {
        commentModerators: [moderateRepliesStrictly]
    }
}


async function moderateRepliesStrictly({datastore, comment}) {
    if (!comment.replyTo) {
        return {violates: false}
    }
    const parentComment = await datastore.getObject('comment', comment.replyTo);
    const moderationResult = await datastore.callServerAsync('moderation', 'moderateReplyStrictly', {
        parentText: parentComment.text, text: comment.text, 
        commentKey: comment.key || null, inReview: comment.inReview
    });
    if (!moderationResult.allow) {
        return {violates: true, source: 'ai-reply-moderaton',
            violations: [GUIDELINES.BE_CONSTRUCTIVE]
        }
    } else {
        return {violates: false}
    }
}


async function checkPostAsync({datastore, comment, questionText}) {
    const {commentModerators} = datastore.getConfig();

    if (comment.minorViolation && comment?.violatingText == comment.text) {
        return {allow: true, commentProps: {violates: false, inReview: false, minorViolation: true}};
    }

    if (!comment.text?.trim()) {
        return {allow: true, commentProps: {violates: false, inReview: false}};
    }

    var violations = [];
    var metadata = null;
    var minorViolation = true;

    for (const moderator of (commentModerators || [])) {
        const moderationResult = await moderator({datastore, comment, questionText});
        if (moderationResult.violates) {
            violations = [...violations, ...moderationResult.violations];
            metadata = {...metadata, ...moderationResult?.metadata};
            minorViolation = minorViolation && moderationResult.minorViolation;
        }
    }
    violations = [...new Set(violations)];
    const violates = violations.length > 0;
    const violatingTextChanged = comment.violatingText && (comment.text !== comment.violatingText);

    if (violates && minorViolation) {
        logEventAsync(datastore, 'moderation-post-anyway', {commentKey: comment.key, text: comment.text, violations});
    } else if (violates) {
        logEventAsync(datastore, 'moderation-block', {commentKey: comment.key, text: comment.text, violations});
    }

    if (comment.inReview && (!violates || violatingTextChanged)) {
        await datastore.callServerAsync('moderation', 'removeCommentFromReviewQueue', {commentKey: comment.key});
        await datastore.updateObject("comment", comment.key, { ...comment, inReview: false });
    }

    return {
        allow: !violates, commentProps: {
            violates, inReview: false, violations,
            violatingText: violates ? comment.text : null,
            moderationMetadata: metadata,
            minorViolation: violates ? minorViolation : null
        }
    };
}

export async function checkPerspectiveAsync({datastore, perspectiveText, questionText, language='english'}) {
    const moderationResult = await datastore.callServerAsync(
        'moderation', 'checkPerspectiveWithGpt', {perspectiveText, questionText, language});
    if (moderationResult.violates) {
        const violations = moderationResult.rulesBroken?.map(getRuleById) ?? [];
        return {violates: true, violations: [...new Set(violations)]}
    } else {
        return {violates: false}
    }
}

function ModerationInProgessHelpBubble() {
    const moderationInProgress = useSessionData('moderationInProgress');
    if (!moderationInProgress) { return null; }
    return <HelpBubble right label='Your response is being reviewed by AI to ensure it follows our Community Guidelines' 
        condition={moderationInProgress} helpKey='moderationInProgress'
    />
}

async function waitForHelpBubbleAsync({datastore, comment}) {
    const helpSeen = await getWillHelpBeSeenAsync({datastore, helpKey: 'moderationInProgress'});
    if (comment.replyTo && !helpSeen) return {allow: true};
    datastore.setSessionData('moderationInProgress', true);
    if (datastore.getSiloKey() != 'puppet-test') {
        await sleepMillisAsync(4000);
    }
    return {allow: true}; 
}

async function clearHelpBubbleAsync({datastore, comment}) {
    datastore.setSessionData('moderationInProgress', false);
}


function isPostButtonDisabledAsync({datastore, comment}) {
    return comment.violates && comment.violatingText == comment.text && !comment.minorViolation;
}

export function ModerationInfoBanner({comment}) {
    const [showCommunityGuidelines, setShowCommunityGuidelines] = useState(false);
    return <View style={{backgroundColor: colorSurfaceInfo, paddingHorizontal: 20, paddingVertical: 16}}>
        <AITag />
        <Pad size={16} />
        <RichText 
            label='To maintain a welcoming and respectful environment, this space uses AI to help moderate comments. AI may flag your post for your additional review if it does not follow the [Community Guidelines]($onPress)' 
            suffixText='.'
            callbacks={{onPress: () => setShowCommunityGuidelines(true)}}
        />
        {showCommunityGuidelines && <GuidelinesModal onClose={() => setShowCommunityGuidelines(false)}/>}
    </View>
}

export function ModerationReport({comment, onCancel, isPerspectiveModeration=false}) {
    const s = moderationReportStyles;
    const showExtraModerationMessage = useModulePublicData('showExtraModerationMessage');
    const [showModerationModal, setShowModerationModal] = useState(false);
    const [showCommunityGuidelines, setShowCommunityGuidelines] = useState(false);
    const target = isPerspectiveModeration ? comment?.perspectives?.writeIn : comment;
    const parentViolations = Array.from(new Set(target?.violations?.map(getParentRuleByChildText) ?? []));
    const moderatedThing = isPerspectiveModeration ? 'perspective label' : 'response';

    const titleMessage = comment.minorViolation 
    ? `📢 Write with more influence`
    : `⚠️ Check your ${moderatedThing}`

    const warningMessage = comment.minorViolation 
    ? `AI review found language in your ${moderatedThing} that might turn away readers with different viewpoints.`
    : `AI review found that your ${moderatedThing} may be incompatible with the following [Community Guidelines]($onPress): `

    const listIntroMessage = comment.minorViolation 
    ? `To have your post reach more people, consider these tips:`
    : `Consider rewording your ${moderatedThing}:`

    if (!target.violates) {
        return <ModerationInfoBanner comment={comment} />
    } else {
        return <View style={{backgroundColor: comment.minorViolation ? colorSurfaceDiscovery : colorSurfaceWarning}} 
                testID='moderation-report'>
            <PadBox horiz={20} vert={16}>
                <AITag />
                <Pad size={16} />
                <Heading label={titleMessage} />
                <Pad size={16} />
                <Text style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                    <RichText label={warningMessage}
                        callbacks={{onPress: () => setShowCommunityGuidelines(true)}} 
                    />
                    {!comment.minorViolation && parentViolations.map((parentViolation, i) => {
                        return <View key={i} style={{ flexDirection: 'row' }}>
                            <UtilityText weight='medium' label={parentViolation} />
                            {i < parentViolations.length - 1 && <UtilityText text=", " />}
                        </View>
                    })}
                </Text>
                
                <Pad size={16} />
                <UtilityText label={listIntroMessage} />
                <Pad size={8} />
                {target.violations?.map((ruleText, i) => 
                    <BulletText key={i} label={ruleText} />
                )}
                <Pad size={20} />
                {comment.minorViolation && <View>
                    <UtilityText label='You can still post anyway, but rewording may help your post sway more people and lead to more constructive discussion. Without changes, your post will appear in the "All" tab and may be reviewed by moderators.' />
                </View>}
                {!isPerspectiveModeration && !comment.minorViolation && (comment.inReview
                    ? <View style={{flexDirection: 'row'}}>
                        {React.createElement(Send, { style: { color: colorBlackHover } })}
                        <PadBox right={4}/>
                        <UtilityText color={colorBlackHover} type='tiny' label='Sent to moderator: {time}' formatParams={{time: formatDate(comment.time)}}/>
                    </View>
                    : <View>
                        <View style={s.line} />
                        <Pad size={20} />
                        <UtilityText weight='medium' label='Did AI get it wrong?' />
                        {showExtraModerationMessage && <View>
                            <Pad size={8} />
                            <UtilityText label="⚠️ Our moderation filter is currently set to be quite strict in order to ensure the highest level of safety! If you feel that your contribution is being unfairly rejected, please contact our moderation team!" />
                        </View> }
                        <Pad size={12} />                         
                        <SubtleButton type='tiny' icon={Send} color={colorBlackHover} label='Send to a moderator for review' onPress={() => setShowModerationModal(true)}/>
                    </View>
                )}
            </PadBox>
            {showModerationModal && <ModerationModal comment={comment} onCancel={onCancel} onClose={() => setShowModerationModal(false)} />}
            {showCommunityGuidelines && <GuidelinesModal onClose={() => setShowCommunityGuidelines(false)}/>}
        </View>
    }
}
const moderationReportStyles = StyleSheet.create({
    container: {
        backgroundColor: colorSurfaceWarning,
    },
    line: {
        height: 1, 
        backgroundColor: colorBorderPrimary,
        width: '100%',
    },
});

// TODO: Bring back link to transparency dashboard, once we have cross-partner alignment
// and it works better

export function ModerationModal({comment, onClose, onCancel}) {
    const [inProgress, setInProgress] = useState(false);
    const [sent, setSent] = useState(false);
    const [appealText, setAppealText] = useState('');
    const datastore = useDatastore();
    const siloKey = datastore.getSiloKey();
    useLogEvent('moderation-modal', {text: comment.text, commentKey: comment.key});

    async function onReport() {
        setInProgress(true);
        const key = await datastore.callServerAsync('moderation', 'sendToReview', {
            type: 'comment', setInReview: true, source: 'premoderation',
            text: comment.text, key: comment.key, from: comment.from,
            metadata: comment.moderationMetadata ?? null,
            object: comment,
            violations: JSON.stringify(comment.violations), appealText
        });
        
        // Run derived view triggers as comment gets updated serverside
        if (key) {
            await datastore.callServerAsync('derivedviews', 'runTriggers', {
                type: 'comment', key
            });
        }

        setInProgress(false);
        setSent(true);
        logEventAsync(datastore, 'moderation-appeal', {text: appealText, commentKey: comment.key});
    }

    function onDone() {
        onClose();
        if (onCancel) {
            onCancel();
        } else {
            goBack();
        }
    }

    const dashboardUrl = 'https://np-psi-dev.web.app/' + siloKey + '/moderationdash/dash';

    if (sent) {
        return <Modal onClose={onDone}>
            <PadBox horiz={20} vert={20}>
                <Heading type="large" weight="medium" label='Thank you!'/>
                <Pad size={8} />
                <UtilityText type='large' color={colorTextSecondary} label='Your response has been sent to a moderator for review.' />
                <Pad size={32} />
                    <Banner color={colorSurfaceInfo}>
                        <Heading label='📫 What happens next'/>
                        <Pad size={16} />
                        <UtilityText label='You will receive an email notification when your post is approved or rejected by moderators.'/>
                    </Banner>
                <Pad />
            </PadBox>  
        </Modal>
    } else {
        return <Modal onClose={onClose} buttonRow={
            <CTAButton disabled={inProgress || !appealText} wide type='primary' label={inProgress ? 'Sending...' : 'Send to moderator'} onPress={onReport}/>
        }>
            <PadBox horiz={20} vert={20}>
                <Heading type="large" weight="medium" label='Send to moderator'/>
                <Pad />
                <Banner color={colorSurfaceInfo}>
                    <Heading label='✨ We use AI for moderation'/>
                    <Pad size={32} />
                    <RichText label='AI helps us moderate comments, but sometimes it can make a mistake. If AI is preventing you from sharing a respectful opinion, send your comment to a moderator for review.'/>
                </Banner>
                <Pad size={32} />
                <RichText label="**Share details** (required)" type="small" color={colorAmbigousBlack}/>
                <Pad size={12} />
                <TextField testID='appeal' big value={appealText} onChange={setAppealText} placeholder='Message to Moderator...'/>
                <Pad size={12} />
                <UtilityText type='small' label='Provide any details to help us determine if your post follows our Community Guidelines.' color={colorTextSecondary} />
            </PadBox>  
        </Modal>
    }
}
