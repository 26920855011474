export function trackVisibility(ref, onVisibleForDuration, duration = 10000, thresholdTrigger = 0.75) {
    if (!ref?.current) return () => {};

    let timerId = null;
    let isCounting = false;

    const observer = new IntersectionObserver(
        ([entry]) => {
            if (entry.isIntersecting && !isCounting) {
                isCounting = true;
                timerId = setTimeout(() => {
                    onVisibleForDuration();
                    observer.disconnect();
                }, duration);
            } else if (!entry.isIntersecting) {
                clearTimeout(timerId);
                isCounting = false;
            }
        },
        { threshold: thresholdTrigger }
    );

    observer.observe(ref.current);

    return () => {
        clearTimeout(timerId);
        observer.disconnect();
    };
}
