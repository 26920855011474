import React from 'react';
import { useIsAdmin } from "component/admin"
import { ConversationScreen, Pad, PadBox } from "component/basics"
import { CTAButton, TextButton } from "component/button"
import { UtilityText } from "component/text"
import { useConfig } from "util/features"
import { useServersideConstructor } from 'component/constructor'
import { useDatastore, usePersonaKey } from 'util/datastore'
import { FeatureToggles } from 'component/topbar'

export const ArticleStructure = {
    key: 'article',
    name: 'Article',
    screen: ArticleScreen,
    teaser: ArticleTeaser,
    overlayScreen: ArticleOverlayScreen,
    subscreens: {
        admin: () => <ArticleAdminScreen />,
        features: () => <ArticleFeaturesScreen />
    },
    defaultConfig: {
        teaser: null,
        adminScreen: null,
        overlayScreen: null,
        adminButtonLabel: 'Edit questions (admin)'
    }
}

export function ArticleTeaser(params) {
    useServersideConstructor();
    const datastore = useDatastore();
    const {teaser} = useConfig();
    const isAdmin = useIsAdmin();
    if (teaser) {
        return React.createElement(teaser, params);
    } else if (isAdmin) {
        return <TextButton label='Open admin screen' onPress={() => datastore.pushSubscreen('admin', {})} />
    } else {
        return <UtilityText label='No teaser defined' />
    }
}

export function ArticleScreen() {
    const isAdmin = useIsAdmin();
    const datastore = useDatastore();
    const personaKey = usePersonaKey();
    const {adminButtonLabel} = useConfig();

    if (!personaKey) {
        return <ConversationScreen pad>
            <Pad />
            <CTAButton label='Log in' onPress={() => datastore.gotoInstance({structureKey: 'login', instanceKey: 'one'})} />
        </ConversationScreen>
    }

    if (!isAdmin) {
        return <PadBox horiz={20} vert={20}><UtilityText label='You must log in as an admin to view this screen.' /></PadBox>
    }

    return <ConversationScreen pad>
        <Pad />
        <CTAButton type='secondary' text='Configure Teaser Features (Admin)' onPress={() => datastore.pushSubscreen('features', {})} />
        <Pad />
        <CTAButton label={adminButtonLabel} onPress={() => datastore.pushSubscreen('admin', {})} />
    </ConversationScreen>
}
export function ArticleAdminScreen() {
    const isAdmin = useIsAdmin();
    const {adminScreen} = useConfig();

    // if (!isAdmin) {
    //     return <PadBox horiz={20} vert={20}><UtilityText label='You must log in as an admin to view this screen.' /></PadBox>
    // }

    if (adminScreen) {
        return React.createElement(adminScreen);
    } else {
        return <PadBox horiz={20} vert={20}><UtilityText label='No admin screen defined. Use the feature selector to enable a feature.' /></PadBox>
    }
}

export function ArticleFeaturesScreen() {
    return <PadBox horiz={20} vert={20}><FeatureToggles /></PadBox>
}

export function ArticleOverlayScreen(params) {
    useServersideConstructor();
    const {overlayScreen} = useConfig();
    if (overlayScreen) {
        return React.createElement(overlayScreen, params);
    } else {
        return null
    }
}
