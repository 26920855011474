export const nullS : null | string = null;
export const nullN : null | number = null;
export const nullO : null | object = null;
export const nullA : null | any = null;
export const nullComponent : null | React.ComponentType<any> = null;
export const nullElement : null | React.ReactElement = null;

export const undefS : undefined | string = undefined;
export const undefN : undefined | number = undefined;
export const undefO : undefined | object = undefined;
export const undefA : undefined | any = undefined;
export const undefR : undefined | Record<string, any> = undefined;
export const undefB : undefined | boolean = undefined;
export const undefE : undefined | React.ReactElement = undefined;
export const undefC : undefined | React.ComponentType<any> = undefined;

export const emptyR : Record<string, any> = {};

export type Persona = {
    key: string;
    name: string;
    photoUrl?: string;
    hint?: number
}

export type Feature = {
    name: string;
    key: string;
    dev?: boolean;
    config?: Record<string, any>;
    defaultConfig?: Record<string, any>;
    subscreens?: Record<string, React.ComponentType<any>>;
    missingConfigAllowed?: string[];
    parentFeature?: string;
}

export type Structure<T> = {
    key: string;
    name: string;
    screen: React.ComponentType<any>;
    defaultConfig: T;
    hasFocusTrap?: boolean;
    subscreens?: Record<string, React.ComponentType<any>>;
    teaser?: React.ComponentType<any>;
}


