import { UserMultiple } from "@carbon/icons-react";
import { HorizBox, Pad, PadBox } from "component/basics"
import { colorAmbiguousWhite } from "component/color"
import { spacings } from "component/constants";
import { UtilityText } from "component/text"
import { useGlobalProperty } from "util/datastore"

export const FakeTopicParticipantsFeature = {
    key: 'faketopicparticipants',
    name: 'Fake Topic Participants',
    config: {
        topicHeaderBottomWidgets: [FakeTopicParticipantsWidget]
    }
}

export function FakeTopicParticipantsWidget() {
    const count = useGlobalProperty('participantCount') ?? 5;
    
    return <PadBox top={24}>
        <HorizBox>
            <UserMultiple color={colorAmbiguousWhite} />
            <Pad size={spacings.xs} />
            <UtilityText color={colorAmbiguousWhite} label='{count} {noun}' 
                formatParams={{count, singular: 'participant', plural: 'participants'}} 
            />
        </HorizBox>
    </PadBox>
}
