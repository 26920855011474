import { StructureDemo } from "util/instance"

const serverCall = {
    eventlog: {
        logEvent: () => { },
    },
    moderation: {
        // make the regular moderation simply pass through
        moderateComment: () => {
            return { violates: false }
        }
    },
    blacklist: {
        checkBlacklist: ({ text }) => {
            if (text.includes("cat")) {
                return {
                    violates: true
                }
            } else {
                return {
                    violates: false
                }
            }
        }
    }
}

const globals = {
    name: 'Blacklist demo: The word "cat" is not allowed here.',
    questionAuthor: 'a',
    questionTime: Date.now(),
}

// TODO: Pre-moderation seems to be broken in all structure demos because commentModerators is undefined
export function BlacklistFeatureDemo() {
    return (
        <StructureDemo
            structureKey={"question"}
            serverCall={serverCall}
            testState={{globals}}
            features={{ premoderation: true, blacklist: true }}
        />
    );
}