import { useState } from 'react';
import { View } from "react-native";

import { ConversationScreen, Pad, PadBox, Separator } from "component/basics"
import { CTAButton, TextButton } from "component/button"
import { AccordionField, FormField, Toggle } from 'component/form'
import { Heading, TextField, UtilityText } from "component/text"
import { useDatastore, useModulePublicData } from 'util/datastore'

import { STATIC_TOPIC } from "./static";


export const TopicListFeature = {
    name: 'Topic List',
    key: 'topiclist',
    subscreens: {
        topiclist: TopicListScreen,
        createTopic: AdminScreen,
    },
    config: {
        quickLinks: [
            { label: 'Topics', screenKey: 'topiclist' },
        ]
    },
}

export function TopicListScreen() {
    const [search, setSearch] = useState('');
    const datastore = useDatastore();

    return <ConversationScreen pad>
        <Pad size={40} />
        <Heading type='large' label='Topics' />
        <Pad />
        <TextField autoFocus value={search} onChange={setSearch}
            testID='search-topic' label='Search a topic' placeholder='Search a topic...' />
        <Pad />
        <CTAButton label='Create a topic' onPress={() => datastore.pushSubscreen('createTopic')} />
        <Pad />
        <Separator />
        <Pad />
        <TopicList search={search} />
        <Pad />
    </ConversationScreen>
}

function TopicList({ search }) {
    const topics = useGlobalTopics();
    const filteredTopics = topics?.filter(topic => !search || topic.name?.toLowerCase().includes(search.toLowerCase()));
    return <View>
        {filteredTopics.map(topic => <PadBox key={topic.key} vert={10}><Topic topic={topic} /></PadBox>)}
    </View>
}

function Topic({ topic }) {
    const datastore = useDatastore();
    function onPress() {
        datastore.gotoInstance({ structureKey: 'topic', instanceKey: topic.key });
    }
    return <TextButton text={topic.name} onPress={onPress} />
}

export function AdminScreen() {
    return <ConversationScreen>
        <Pad />
        <PadBox horiz={20}>
            <Heading label='Create a topic' />
        </PadBox>
        <PadBox horiz={20} vert={20}>
            <CreateTopicForm />
        </PadBox>
    </ConversationScreen>
}

export function CreateTopicForm() {
    const datastore = useDatastore();

    const [saveInProgress, setSaveInProgress] = useState(false);

    const [topicName, setTopicName] = useState('');
    const [startAt, setStartAt] = useState('');
    const [endAt, setEndAt] = useState('');
    const [conversationStarter, setConversationStarter] = useState('');
    const [active, setActive] = useState(false);
    const [photoUrl, setPhotoUrl] = useState('');

    const [pollObject, setPollObject] = useState({
        question: '',
    });
    const [nextTopicPollObject, setNextTopicPollObject] = useState({
        question: '',
        otherOptionValue: 'Autre',
        otherOptionPlaceholder: 'Rédiger un sujet...',
    });

    const [optionsValues, setOptionsValues] = useState(['']);
    const [upcomingPollOptions, setUpcomingPollOptions] = useState(['']);
    const onAddPollOption = () => {
        const newOptions = [...optionsValues, ''];
        setOptionsValues(newOptions);
    };
    const onRemovePollOption = () => {
        if (optionsValues.length > 0) {
            const newOptions = optionsValues.slice(0, optionsValues.length - 1);
            setOptionsValues(newOptions);
        }
    };

    const onAddUpcomingPollOption = () => {
        const newOptions = [...upcomingPollOptions, ''];
        setUpcomingPollOptions(newOptions);
    };
    const onRemoveUpcomingPollOption = () => {
        if (optionsValues.length > 0) {
            const newOptions = upcomingPollOptions.slice(0, upcomingPollOptions.length - 1);
            setUpcomingPollOptions(newOptions);
        }
    };

    const updatePollOption = ({ index, value }) => {
        const newValues = [...optionsValues];
        newValues[index] = value;
        setOptionsValues(newValues);
    }

    const updateUpcomingPollOption = ({ index, value }) => {
        const newValues = [...upcomingPollOptions];
        newValues[index] = value;
        setUpcomingPollOptions(newValues);
    }

    const topic = STATIC_TOPIC;

    async function onAdd() {
        setSaveInProgress(true);
        const result = await datastore.callServerAsync('topics', 'add', {
            topic: {
                ...topic,
                name: topicName,
                startAt: startAt,
                endAt: endAt,
                photoUrl: photoUrl,
                conversationStarter: conversationStarter,
                preview: {
                    name: topicName,
                },
                poll: {
                    ...pollObject,
                    type: 'MultipleChoice',
                    showDataviz: true,
                    options: optionsValues.map(x => ({ label: x })),
                },
                nextTopicPoll: {
                    ...nextTopicPollObject,
                    type: 'MultipleChoiceOther',
                    showDataviz: false,
                    options: upcomingPollOptions.map(x => ({ label: x })),
                },
                relatedConversations: [],
            }
        });
        setSaveInProgress(false);
        setTopicName('');
        setStartAt('');
        setEndAt('');
        setConversationStarter('');
        setActive(false);
        setOptionsValues(['']);
        setUpcomingPollOptions(['']);
        setPollObject({
            question: '',
        });
        setNextTopicPollObject({
            question: '',
            otherOptionValue: 'Autre',
            otherOptionPlaceholder: 'Rédiger un sujet...',
        });
    }

    return <SpacedArray>
        <FormField label='Topic name'>
            <TextField autoFocus value={topicName} testID="topic-name" onChange={setTopicName}
                label='Ask a question' placeholder='Topic name' />
        </FormField>
        <FormField label='Photo URL'>
            <TextField value={photoUrl} testID="photo-url" onChange={setPhotoUrl}
                placeholder='URL for photo to appear in the teaser'
            />
        </FormField>
        <FormField label='Starts at'>
            <TextField label='startAt' value={startAt} testID="starts-at" onChange={setStartAt} placeholder='(YYYY-MM-DD format)' />
        </FormField>
        <FormField label='Ends at'>
            <TextField
                label='endsAt'
                placeholder='(YYYY-MM-DD format)'
                value={endAt}
                testID="ends-at"
                onChange={setEndAt}
            />
        </FormField>
        <FormField label='Conversation starter'>
            <TextField
                label='conversationStarter'
                placeholder='Conversation starter'
                testID="conversation-starter"
                value={conversationStarter}
                onChange={setConversationStarter}
            />
        </FormField>
        <FormField label="Is active">
            <Toggle label='Active' value={active} onChange={setActive} />
        </FormField>
        <PollFields
            pollObject={pollObject}
            setPollObject={setPollObject}
            sectionLabel={'Topic poll'}
            values={optionsValues}
            onAddValue={onAddPollOption}
            onRemoveValue={onRemovePollOption}
            onUpdateValue={updatePollOption}
        />
        <PollFields
            pollObject={nextTopicPollObject}
            setPollObject={setNextTopicPollObject}
            sectionLabel={'Upcoming topic poll'}
            values={upcomingPollOptions}
            onAddValue={onAddUpcomingPollOption}
            onRemoveValue={onRemoveUpcomingPollOption}
            onUpdateValue={updateUpcomingPollOption}
            hasComment
        />
        <Pad />
        {saveInProgress ?
            <CTAButton disabled label='Adding...' />
            :
            <CTAButton label='Add topic' onPress={onAdd} />
        }
    </SpacedArray>
}

export function PollFields({ pollObject, setPollObject, sectionLabel, values, onAddValue, onRemoveValue, onUpdateValue, hasComment }) {
    return <AccordionField testID={sectionLabel} titleContent={<UtilityText weight='medium' text={sectionLabel} />}>
        <SpacedArray>
            <FormField label='Poll question'>
                <TextField testID={`poll-question-${sectionLabel}`} value={pollObject.question} onChange={(value) => setPollObject({ ...pollObject, question: value })} placeholder='Text Field' />
            </FormField>
            {hasComment &&
                <FormField label='Open answer label'>
                    <TextField testID={`open-answer-label-${sectionLabel}`} value={pollObject.otherOptionValue} onChange={(value) => setPollObject({ ...pollObject, otherOptionValue: value })} placeholder='Text Field' />
                </FormField>}
            {hasComment &&
                <FormField label='Open answer placeholder'>
                    <TextField testID={`open-answer-placeholder-${sectionLabel}`}value={pollObject.otherOptionPlaceholder} onChange={(value) => setPollObject({ ...pollObject, otherOptionPlaceholder: value })} placeholder='Text Field' />
                </FormField>}
            <UtilityText type='small' weight="medium" label={'Poll options'} />
            <SpacedArray horiz>
                <CTAButton type='secondary' label='Remove option' onPress={onRemoveValue} />
                <CTAButton type='primary' label='Add option' onPress={onAddValue} />
            </SpacedArray>
            {values.length > 0 &&
                <>
                    {values.map((x, index) => (
                        <PadBox key={index} vert={12}>
                            <TextField
                                testID={`poll-option-${index}-${sectionLabel}`}
                                key={index}
                                placeholder='Option...'
                                value={x}
                                onChange={(value) => onUpdateValue({ index, value })}
                            />
                        </PadBox>
                    ))
                    }
                </>
            }
        </SpacedArray>
    </AccordionField>
}

function SpacedArray({ pad = 16, horiz = false, children }) {
    if (children.length > 1) {
        return <View style={horiz ? { flexDirection: 'row' } : null}>
            {children.map((c, i) => <View key={i} style={horiz ? { flexDirection: 'row' } : null}>
                {c}
                {i < children.length - 1 ? <Pad size={pad} /> : null}
            </View>)}
        </View>
    } else {
        return children;
    }
}

export function useGlobalTopics() {
    const topicObjs = useModulePublicData('topic');
    const keys = Object.keys(topicObjs || {});
    const topics = keys.map(key => ({ key, ...topicObjs[key] }));
    return topics;
}