import { useMemo } from "react";
import { useVideo } from "../../../contrib/zdf/videoPlayerStateContext";
import { useVideoVotingSessionData } from "../../../contrib/zdf/videovoting/videoVotingSession";
import { useInstanceKey, useModulePublicData } from "../../../util/datastore";

export function useAllVideoVotings(){
    return useModulePublicData("videovoting", ["all"]);
}

export function useVideoVoting(votingKey){

    // Either use the key of the current session or use your own instanceKey
    const session = useVideoVotingSessionData()
    const instanceKey = useInstanceKey();

    return useModulePublicData("videovoting", ["all", votingKey ?? session?.votingKey ?? instanceKey]);
}

export function useVideoVotingTemplate(votingKey){
    const voting = useVideoVoting(votingKey)
    return voting?.template
}

export function useSortedVideoQuestions() {

    const template = useVideoVotingTemplate();

    return useMemo(() => {
        return getSortedVideoQuestions(template?.questions ?? [])
    }, [template]);
}

function getSortedVideoQuestions(templateQuestions) {
    if (!templateQuestions) return [];

    return templateQuestions.map(q => ({
        ...q,
        timestamp: q.initial && q.timestamp == null ? 0 : q.timestamp
    })).sort((q1, q2) => {
        if (q1.initial) return -1; // "Initial" goes first
        if (q2.initial) return 1;
        if (q1.final) return 1;  // "Final" goes last
        if (q2.final) return -1;
        if (q1.timestamp !== null && q2.timestamp !== null) {
            return q1.timestamp - q2.timestamp; // Sort by timestamp
        }
        return 0; // Keep everything else as is
    })
}

export function useCurrentQuestion() {
    const sortedQuestions = useSortedVideoQuestions();
    const video = useVideo();
    const currentQuestion = findQuestionForTimestamp(sortedQuestions, video.currentTime)
    return useMemo(()=>{
        return currentQuestion
    },[currentQuestion?.key])
}

function findQuestionForTimestamp(questions, timestamp) {

    if(!questions || timestamp === null || timestamp === undefined) return null


    for (let i = 0; i < questions.length; i++) { 
        const question = questions[i];

        if(question.type === "connected") {
            for (let j = 0; j < question.subQuestions.length; j++) {
                const subQuestion = question.subQuestions[j];

                if(timestamp >= subQuestion.timestamp) {
                    if (j === question.subQuestions.length - 1 || question.subQuestions[j + 1].timestamp > timestamp) {
                        // Add the options to the question Object to be returned
                        const returnQuestion = {
                            ...question,
                            ...subQuestion,
                        }
                        return returnQuestion;
                    }
                }
            }
            continue;
        }

        if(timestamp >= question.timestamp) {
            // Timestamp must no be after the timestamp of the next question
            if (i === questions.length - 1 || questions[i + 1].timestamp > timestamp) {
                return question;
            }
        }
    }
    return null;
}