import { View } from "react-native";
import { useCollection, useDatastore, useObject } from "util/datastore"
import { useIsAdmin } from "component/admin"
import { CTAButton, SubtleButton } from "component/button"
import { Card, ConversationScreen, HorizBox, Pad, PadBox, Separator } from "component/basics"
import { colorBorderPrimary } from "component/color"
import { EmbeddedInstance } from "util/instance"
import { ChevronRight } from '@carbon/icons-react';
import { Heading, UtilityText } from "component/text";
import { Toggle } from "component/form";
import { Catcher } from "system/catcher";
import { useGlobalQuestions } from "component/question";
import { useState } from "react";

export const QuestionCardsFeature = {
    key: 'questioncards',
    name: 'Question Cards',
    dev: true,
    subscreens: {
        relatedConversationsAdmin: () => <RelatedConversationsAdmin />,
    },
    config: {
        topicContentWidgets: [QuestionCards]
    },
}

export function QuestionCards() {
    const relatedConversations = useCollection('relatedConversations', { filter: { deleted: false } });
    const isAdmin = useIsAdmin();
    const datastore = useDatastore();

    return <PadBox horiz={20}>
        {relatedConversations.map(question => <EmbeddedQuestion key={question.key} question={question} />)}
        {isAdmin && <PadBox top={12}><CTAButton compact type='secondary' label='Edit related conversations' onPress={datastore.needsLogin(() => datastore.pushSubscreen('relatedConversationsAdmin', {}))} /></PadBox>}
    </PadBox>
}

function EmbeddedQuestion({ question }) {
    const s = EmbeddedQuestionStyle;
    const datastore = useDatastore();
    return <View style={s.box}>
        <EmbeddedInstance structureKey='question' 
            instanceKey={question.key} 
            features={{embedded: true}} 
        />
        <PadBox horiz={20} vert={20}>
            <SubtleButton label='Show more' icon={ChevronRight}
                onPress={() => datastore.gotoInstance({structureKey: 'question', instanceKey: question.key})}
            />
        </PadBox>
    </View>
}

function Question({ question }) {
    const datastore = useDatastore();
    const selectedQuestion = useObject('relatedConversations', question.key);
    const isChosen = selectedQuestion && !selectedQuestion.deleted;

    async function onChange(selected) {
        await datastore.callServerAsync('topics', selected ? 'selectRelatedQuestion' : 'unselectRelatedQuestion', {questionKey: question.key });
    }

    return <PadBox bottom={20} horiz={20}>
        <Card>
            <HorizBox spread>
                <UtilityText type='small' paragraph text={question.text ?? question.question} weight="medium" />
                <Pad />
                <Toggle lable='Selected' value={isChosen} onChange={onChange} />
            </HorizBox>
        </Card>
    </PadBox>
}


export function RelatedConversationsAdmin() {
    const questions = useGlobalQuestions();
    const [search, setSearch] = useState('');
    const filteredQuestions = questions?.filter(question => !search || question.question?.toLowerCase().includes(search.toLowerCase()));

    return (
        <ConversationScreen>
            <Pad />
            <PadBox horiz={20}><Heading label='Choose related conversations for this Topic' /></PadBox>
            <Pad />
            <Separator />
            <Pad />
            {/* TODO need a new "Question component?" */}
            {filteredQuestions?.map(question =>
                <Catcher key={question.key}><Question question={question} /></Catcher>
            )}
        </ConversationScreen>
    )
}

const EmbeddedQuestionStyle = {
    box: {
        borderRadius: 12,
        borderColor: colorBorderPrimary,
        borderWidth: 1,
        marginTop: 32,
        overflow: 'hidden'
    }
}
