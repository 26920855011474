import { ContentPromoCard, ConversationPromoCard, RecirculationSlider, TopicPromoCard } from "component/cbcrc/recirculationCards";
import { STATIC_TOPIC } from "feature/cbcrc/admin/static";
import { CLICK } from "system/demo";

export const baseComponentsDemo = [
    { label: 'Recirculation cards', key: 'recirculation-cards', storySets: recirculationCardsStorySets },
];

const recirculationCollections = {
    derived_relatedQuestionPreview: [
        { key: "is-godzilla-good", commentCount: 3, userList: ["a", "b", "c"] },
    ]
}

const { testContents, relatedTopics } = STATIC_TOPIC;

const relatedContents = [
    {
        id: 1,
        picture: "https://placehold.co/250x150",
        published: true,
        text: "A text article",
        type: "text",
        url: 'https://www.google.com',
    },
    {
        id: 2,
        picture: "https://placehold.co/250x150",
        published: true,
        text: "An audio article",
        type: "audio",
        url: 'https://www.google.com',
    },
    {
        id: 3,
        picture: "https://placehold.co/250x150",
        published: true,
        text: "A media article",
        type: "media",
        url: 'https://www.google.com',
    },
]

const relatedConversations = [
    {
        key: 1,
        id: 1,
        from: 'a',
        text: 'Is Godzilla good?',
        picture: 'https://placehold.co/250x150',
    },
    {
        key: 2,
        id: 2,
        from: 'b',
        text: 'Is Kong bad?',
        picture: 'https://placehold.co/250x150',
    },
    {
        key: 3,
        id: 3,
        from: 'c',
        text: 'Would you watch Gozilla vs Kong?',
        picture: 'https://placehold.co/250x150',
    },
];

function recirculationCardsStorySets() {
    return [
        {
            label: 'Topic Promo Card',
            content: <TopicPromoCard />,
        },
        {
            label: 'Conversation Promo Card',
            collections: recirculationCollections,
            content: <ConversationPromoCard
                key={"is-king-kong-bad"}
                text={"Is King Kong Bad?"}
            />
        },
        {
            label: 'Conversation Promo Card - With comments',
            collections: recirculationCollections,
            content: <ConversationPromoCard
                id={"is-godzilla-good"}
                text={"Is Godzilla Good?"}
            />,
            stories: [
                {
                    label: 'Click on card',
                    actions: [
                        CLICK('conversation-card-is-godzilla-good')
                    ]
                }
            ]
        },
        {
            label: 'Content Promo Card',
            content: <ContentPromoCard
                picture={"https://placehold.co/250x150"}
                published={true}
                text={"A text article"}
                type={"text"}
                url={'https://www.google.com'}
            />,
        },
        {
            label: 'Content Promo Card - Audio',
            content: <ContentPromoCard
                picture={"https://placehold.co/250x150"}
                published={true}
                text={"An audio article"}
                type={"audio"}
                url={'https://www.google.com'}
            />,
        },
        {
            label: 'Content Promo Card - Media',
            content: <ContentPromoCard
                picture={"https://placehold.co/250x150"}
                published={true}
                text={"A media article"}
                type={"media"}
                url={'https://www.google.com'}
            />,
        },
        {
            label: 'Thread Promo - large',
            collections: recirculationCollections,
            stories: [
                {
                    label: 'Navigate in the slider',
                    actions: [
                        CLICK('Next'),
                        CLICK('Next'),
                        CLICK('Previous')
                    ],
                },
            ],
            content: <RecirculationSlider cardsData={relatedConversations} cardType='conversation' controls cardSize="large" />
        },
        {
            label: 'Content promo',
            collections: recirculationCollections,
            content: <RecirculationSlider cardsData={relatedContents} cardType='content' controls />
        },
        {
            label: 'Single slide promo',
            collections: recirculationCollections,
            content: <RecirculationSlider cardsData={testContents} cardType='content' />
        },
        {
            label: 'Thread Promo - small',
            collections: recirculationCollections,
            content: <RecirculationSlider cardsData={relatedTopics} cardType='topic' />
        },

    ]
}
