import { useEffect } from "react"

const langMap = {
    'cbc': 'en',
    'rc': 'fr',
    'zdf': 'de',
    'rtbf': 'fr',
}

export const useSetDocumentLang = (siloKey) => {
    // use effect to set the document lang attribute according to a hashmap of silo key to language
    // this will be used to set the lang attribute on the document element for screen readers, default to english by webpack
    useEffect(() => {
        if (langMap[siloKey]) {
            document.documentElement.lang = langMap[siloKey];
        }
    }, [siloKey]);

    return null;
}