import { fbKeyToString, stringToFbKey } from "util/firebase";
import { getIsLocalhost as baseGetIsLocalHost } from "../platform-specific/url";
import { deploymentConfigMap, domainToDeploymentMap } from "psi.config";

const deploymentConfig = getDeploymentConfig();

export function keyToUrl(key) {
    return fbKeyToString(decodeURIComponent(key));
}

export function urlToKey(url) {
    return encodeURIComponent(stringToFbKey(url));
}

export function toBool(arg) {
    if (arg) {
        return true;
    } else {
        return false;
    }
}

export function isEmptyArray(array)  {
    return Array.isArray(array) && array.length > 0;
}

export function sumArray(array) {
    return array.reduce((sum, value) => sum + value, 0);
}

export function removeKey(collection, key) {
    const newCollection = { ...collection };
    delete newCollection[key];
    return newCollection;
}

export function addKey(collection, key, value = true) {
    return { ...collection, [key]: value };
}

export function isNonEmpty(collection) {
    return Object.keys(collection || {}).length > 0;
}

export function removeNullProperties(obj) {
    const clone = { ...obj };
    for (const key in clone) {
        if (!clone[key]) {
            delete clone[key]; // Remove key-value pair if the value is null
        }
    }
    return clone;
}

export function stripSuffix(str, suffix) {
    if (str.endsWith(suffix)) {
        return str.substring(0, str.length - suffix.length);
    } else {
        return str;
    }
}

export async function forEachAsync(array, callback) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}

export function deepClone(obj) {
    return JSON.parse(JSON.stringify(obj));
}

export function formatString(template, values) {
    return template.replace(/{([^}]+)}/g, (match, key) => values[key] ?? '');
}

export function generateRandomKey(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const randomArray = new Uint32Array(length);
    window.crypto.getRandomValues(randomArray);

    let result = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = randomArray[i] % characters.length;
        result += characters.charAt(randomIndex);
    }

    return result;
}


export function boolToString(bool) {
    return bool ? 'true' : 'false';
}

export function stringToBool(string) {
    return string == 'true';
}

export function boolToInt(bool) {
    return bool ? 1 : 0;
}

export function getObjectPropertyPath(object, path) {
    var result = object;
    path.forEach(part => {
        result = result?.[part];
    });
    return result;
}

export function setObjectPropertyPath(object, path, value) {
    const newObject = deepClone(object);
    var subpart = newObject;
    path.forEach((part, index) => {
        if (index == path.length - 1) {
            subpart[part] = value;
        } else {
            if (subpart[part] == undefined) {
                subpart[part] = {};
            }
            subpart = subpart[part];
        }
    });
    return newObject;
}


export function makeAssetUrl(urlSuffix) {
    if (urlSuffix.startsWith('http://') || urlSuffix.startsWith('https://')) {
        return urlSuffix;
    } else {
        return deploymentConfig.host + '/' + urlSuffix;
    }
}

export function getFirstName(name) {
    return (name || '').trim().split(' ')[0];
}

export async function pauseAsync(milliseconds) {
    await new Promise(resolve => setTimeout(resolve, milliseconds));
}

export function requireParams(funcname, params) {
    const paramKeys = Object.keys(params);
    paramKeys.forEach(key => {
        if (!params[key]) {
            throw new Error(`Missing parameter ${key} in ${funcname}`);
        }
    })
}

export function removeUndefinedFields(obj) {
    const clone = { ...obj };
    for (const key in clone) {
        if (clone[key] === undefined) {
            delete clone[key];
        }
    }
    return clone;
}

export function sortBy(array, field) {
    return [...array].sort((a, b) => {
        const fieldA = a[field];
        const fieldB = b[field];

        if (typeof fieldA === 'string' && typeof fieldB === 'string') {
            return fieldA.localeCompare(fieldB); // String comparison
        } else if (typeof fieldA === 'number' && typeof fieldB === 'number') {
            return fieldA - fieldB; // Numeric comparison
        } else {
            return 0; // Fallback for other types or when fields are undefined
        }
    });
}

export function keysToTrueMap(keys) {
    const map = {};
    keys.forEach(key => {
        map[key] = true;
    });
    return map;
}

export function assembleUrl(baseUrl, queryParams) {
    const url = new URL(baseUrl);
    Object.keys(queryParams).forEach(key => url.searchParams.append(key, queryParams[key]));
    return url.toString();
}

export function makeRandomNonce() {
    if (process.env.NODE_ENV === 'test') {
        return 'testnonce';
    } else {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }
}

export async function sleepMillisAsync(time = 1000) {
    await new Promise(resolve => setTimeout(resolve, time));
}

// TODO: Remove this re-export.
export function getIsLocalhost() {
    return baseGetIsLocalHost();
}

export function getDomain() {
    if (process.env.NODE_ENV === 'test') {
        return 'test';
    } else if (getIsLocalhost()) {
        return 'localhost';
    } else {
        return window.location.hostname;
    }
}

export function getDeploymentConfig() {
    const domain = getDomain();
    const deployment = domainToDeploymentMap[domain];
    if (!deployment) {
        console.error(`psi.config.js: domain ${domain} not found in domainToDeploymentMap`);
        console.log('domainToDeploymentMap', domainToDeploymentMap);
        console.error(`psi.config.js: using production config`);
        return deploymentConfigMap.production;
    } else if (!deploymentConfigMap[deployment]) {
        console.error(`psi.config.js: deployment ${deployment} not found in deploymentConfigMap`);
        console.log('deploymentConfigMap', deploymentConfigMap);
        console.error(`psi.config.js: using production config`);
        return deploymentConfigMap.production;
    }
    return deploymentConfigMap[deployment];
}

export function getIsInIOS(window) {
    return !!window?.webkit?.messageHandlers;
}

export function getIsInAndroid(window) {
    return !!window?.Android;
}

export function getIsInIFrame(window) {
    return window?.self !== window?.top;
}

function getLoggableTime() {
    const now = new Date();

    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const milliseconds = String(now.getMilliseconds()).padStart(3, '0');

    // Extract the first two digits of the milliseconds to represent hundredths of a second
    const hundredths = milliseconds.slice(0, 2);

    return `${hours}:${minutes}:${seconds}.${hundredths}`;
}

export function logUnlessTest(message) {
    if (process.env.NODE_ENV !== 'test') {
        const formattedTime = getLoggableTime();
        console.log(formattedTime, message);
    }
}


// Opacities  (opacity is in hex)
function parseHexColor(hexColor) {
    return {
        r: parseInt(hexColor.slice(1, 3), 16),
        g: parseInt(hexColor.slice(3, 5), 16),
        b: parseInt(hexColor.slice(5, 7), 16)
    };
}

function toHexColor(r, g, b) {
    const toHex = (c) => Math.round(c).toString(16).padStart(2, '0').toUpperCase();
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

export function darkenColor(hexColor, percentage) {
    const { r, g, b } = parseHexColor(hexColor);
    
    const rDark = r * (1 - percentage);
    const gDark = g * (1 - percentage);
    const bDark = b * (1 - percentage);

    return toHexColor(rDark, gDark, bDark);
}

export function createMapFromCollection(collection, func) {
    var map = {};
    collection.forEach(item => {
        map[item.key] = func(item);
    });
    return map;
}
