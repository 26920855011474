const onePixelToRem = 0.0625;
const baseLineHeightScale = 1.25;

// HACK: React Native non-web doesn't support non-numeric font sizes, but this works on web
export function getFontSize(baseFontSizeInPixel) : number {
    return `${baseFontSizeInPixel * onePixelToRem}rem` as any;
}

// HACK: React Native non-web doesn't support non-numeric line heights, but this works on web
export function getFontLineHeight(baseFontSizeInPixel, lineHeightScale = baseLineHeightScale) : number {
    return `${baseFontSizeInPixel * lineHeightScale * onePixelToRem}rem` as any;
}
