import React, { useState } from "react";
import { useConfig } from "util/features";

import { Center, ConversationScreen, Pad, PadBox } from "component/basics"
import { REPLACE_CBC_PopularComments } from "../../../component/cbcrc/comments";
import { ActionEdit, ActionReply, BasicComments, CommentsInput, ComposerScreen } from "component/comment"
import { Heading, Paragraph, TextField, UtilityText } from "component/text"
import { Catcher } from "system/catcher"
import { useCollection, useDatastore, useGlobalProperty } from "util/datastore"
import { CTAButton, TextButton } from "component/button"
import { colorTextSecondary } from "component/color"
import { spacings } from "component/constants";
import { useIsAdmin } from "component/admin";
import { FormField } from "component/form";
import { ClickableAITag } from "component/aitag";

export const TopicUserQuestionsFeature = {
    key: 'topicuserquestions',
    name: 'Community Questions',
    dev: true,
    config: {
    },
    subscreens: {
        allQuestions: () => <AllQuestions />,
        composer: ({ commentKey, about }) => <ComposerScreen about={about} commentKey={commentKey} intro={<QuestionHeader />} contentType='Public Response' />,
        conversationStarterAdmin: () => <ConversationStarterAdminScreen />
    },
    defaultConfig: {
        commentActions: [ActionReply],
        commentRightActions: [ActionEdit],
        commentTopWidgets: [],
        commentAboveWidgets: [],
        replyAboveWidgets: [],
        commentAllowEmpty: false,
        commentEditBottomWidgets: [],
        commentEditTopWidgets: [],
        commentPostBlockers: [],
        commentInputPlaceholder: 'Ask us a question...',
        commentReplyPlaceholder: 'Reply to {authorName}...',
        commentInputLoginAction: 'answer',
        commentPostCheckers: [],
        commentFilters: [],
        commentRankers: [],
        replyFilters: [],
        pageTopWidgets: [],
        topBanners: [],
        composerTopBanners: [],
        composerSubtitle: () => 'Public response',
        poll: null,
        noCommentsMessage: 'Be the first to share your thoughts.',
        noMoreCommentsMessage: 'No more questions',
        PollWidget: null,
        visualizationType: 'percent',
        teaser: null,
        adminScreen: null,
    },
}

export const TopicUserQuestionCallToActionFeature = {
    key: 'topicuserctaquestions',
    name: 'Community Questions Call to Action',
    dev: true,
    config: {
        topicContentWidgets: [TopicCallToAction]
    },
}

export const TopicOfTheMomentCallToActionFeature = {
    key: 'tomuserctaquestions',
    name: 'Topic of the moment Call to Action',
    dev: true,
    config: {
        topicContentWidgets: [TopicOfTheMomentCallToAction]
    },
}

export const TopicPopularQuestionsFeature = {
    key: 'topicpopularquestions',
    name: 'Popular Questions',
    dev: true,
    config: {
        topicContentWidgets: [TopicQuestions],
        commentsTopLeftWidgets: [() => <Heading label='Popular questions' />],
    },
}

export const TopicAIModerationTagFeature = {
    key: 'topicaimoderationtag',
    name: 'AI Moderation Tag',
    dev: true,
    config: {
        commentsTopRightWidgets: [() => <ClickableAITag />],
    }
}

function TopicOfTheMomentCallToAction() {
    const endAt = useGlobalProperty('endAt');
    const live = endAt > new Date().toISOString();

    if(!live) {
        return null;
    }

    return <TopicCallToAction />;
}

function TopicCallToAction() {
    const datastore = useDatastore();
    const conversationStarter = useGlobalProperty('conversationStarter');
    const isAdmin = useIsAdmin();
    const { readOnly, composerPreview } = useConfig();

    if (readOnly && composerPreview) {
        return <PadBox top={spacings.xl2} horiz={spacings.lg}>
            {React.createElement(composerPreview)}
        </PadBox>;
    }

    return <PadBox top={spacings.xl2} horiz={spacings.lg}>
        <Heading type="large" weight="medium" text={conversationStarter} />
        {isAdmin && <TextButton type={"tiny"} label={"Edit conversation starter (admin)"} color={colorTextSecondary} onPress={() => datastore.pushSubscreen('conversationStarterAdmin')} />}
        <Pad size={spacings.xl} />
        <CommentsInput />
        <Pad size={spacings.sm} />
        <Paragraph color={colorTextSecondary} label='Your question will be considered for future content pieces. Please note, we may not be able to answer all questions.' />
    </PadBox>
}

function TopicQuestions() {
    const datastore = useDatastore();
    const comments = useCollection('comment', { filter: { about: null, replyTo: null, deleted: null }, sortBy: 'time' });

    return <PadBox vert={spacings.xl2} horiz={spacings.lg}>
        <Catcher>
            <REPLACE_CBC_PopularComments canPost={false} />
        </Catcher>
        {comments?.length > 4 && (
            <>
                <Pad size={spacings.lg} />
                <Center>
                    <CTAButton type='primary' label='View all questions'
                        onPress={datastore.needsLogin(() => datastore.pushSubscreen('allQuestions', {}))} />
                </Center>
            </>
        )}
    </PadBox>
}

function QuestionHeader() {
    const conversationStarter = useGlobalProperty('conversationStarter');

    return <PadBox horiz={spacings.lg} vert={spacings.xl2}>
        <Heading type="large" weight="medium" text={conversationStarter} />
    </PadBox>
}

function AllQuestions() {
    return <PadBox horiz={spacings.lg} vert={spacings.xl2}>
        <Catcher>
            <BasicComments canPost={false} />
        </Catcher>
    </PadBox>
}

function ConversationStarterAdminScreen() {
    const datastore = useDatastore();
    const baseConversationStarter = useGlobalProperty("conversationStarter");
    const [conversationStarter, setConversationStarter] = useState(baseConversationStarter);
    const isAdmin = useIsAdmin();

    const updateConversationStarter = () => {
        datastore.setGlobalProperty('conversationStarter', conversationStarter);
        datastore.goBack();
    }

    if (!isAdmin) {
        return <UtilityText text={"Access denied"} />
    }

    return <ConversationScreen>
        <PadBox horiz={spacings.lg} vert={spacings.xl2}>
            <Heading type="large" weight="medium" label={"Edit conversation starter (admin)"} />
            <PadBox vert={spacings.lg}>
                <FormField label='Conversation starter'>
                    <TextField
                        label='conversationStarter'
                        placeholder='Conversation starter'
                        value={conversationStarter}
                        onChange={setConversationStarter}
                    />
                </FormField>
            </PadBox>
            <CTAButton label='Update' onPress={updateConversationStarter} />
        </PadBox>
    </ConversationScreen>
}