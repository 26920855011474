import { Heading, TextField, UtilityText } from "component/text"
import { TopicLiveStatus } from "../../../component/cbcrc/header";
import { useDatastore, useGlobalProperty } from "util/datastore"
import { useConfig } from "util/features";
import { useIsAdmin } from "component/admin";
import { CTAButton, TextButton } from "component/button";
import { colorTextSecondary } from "component/color";
import { ConversationScreen, Pad, PadBox } from "component/basics";
import { spacings } from "component/constants";
import { View } from "react-native";
import { FormField } from "component/form";
import { useState } from "react";

export const TopicLiveStatusFeature = {
    key: 'topiclivestatusleft',
    name: 'Live Status - Left widget',
    dev: true,
    config: {
        topicHeaderLeftWidgets: [TopicLiveStatusWidget],
        topicDescriptionWidget: TopicLiveStatusDescription
    }
}

export const TopicLiveStatusRightFeature = {
    key: 'topiclivestatusright',
    name: 'Live Status - Right widget',
    dev: true,
    subscreens: {
        publicationStatusAdmin: () => <PublicationStatusAdminScreen />,
    },
    config: {
        topicHeaderRightWidgets: [TopicLiveStatusWidget],
        topicDescriptionWidget: TopicLiveStatusDescription
    }
}

export function TopicLiveStatusWidget() {
    const isAdmin = useIsAdmin();
    const datastore = useDatastore();
    const endAt = useGlobalProperty('endAt');
    const live = endAt > new Date().toISOString();

    return <View styles={{ flexDirection: 'row', alignItems: 'center' }}>
        <TopicLiveStatus live={live} />
        {isAdmin && <TextButton type={"tiny"} label={"Edit live status (admin)"} color={colorTextSecondary} onPress={() => datastore.pushSubscreen('publicationStatusAdmin')} />}
    </View>
}

export function TopicLiveStatusDescription() {
    const status = useGlobalProperty('status');
    const { headerFontColor } = useConfig();
    const label = status === 'live' ? 'Topic of the moment' : 'Discussion topic';

    return <UtilityText color={headerFontColor} label={label} />
}

export function PublicationStatusAdminScreen() {
    const isAdmin = useIsAdmin();
    const datastore = useDatastore();
    const initialStartAt = useGlobalProperty('startAt');
    const initialEndAt = useGlobalProperty('endAt');
    const [startAt, setStartAt] = useState(initialStartAt);
    const [endAt, setEndAt] = useState(initialEndAt);

    if (!isAdmin) {
        return <UtilityText text={"Access denied"} />
    }

    const updateLiveStatus = () => {
        datastore.setGlobalProperty('startAt', startAt);
        datastore.setGlobalProperty('endAt', endAt);
        datastore.goBack();
    }
    return <ConversationScreen>
        <PadBox vert={spacings.xl2} horiz={spacings.lg}>
            <Heading type="large" weight="medium" label={"Edit live status (admin)"} />
            <Pad />
            <FormField label='Starts at'>
                <TextField testID='startsAt' label='startAt' value={startAt} onChange={setStartAt} placeholder='(YYYY-MM-DD format)' />
            </FormField>
            <Pad />
            <FormField label='Ends at'>
                <TextField
                    testID='endsAt'
                    label='endsAt'
                    placeholder='(YYYY-MM-DD format)'
                    value={endAt}
                    onChange={setEndAt}
                />
            </FormField>
            <Pad />
            <CTAButton label='Update' onPress={updateLiveStatus} />
        </PadBox>
    </ConversationScreen >
}

