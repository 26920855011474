import { HorizBox, Pad, Separator } from "component/basics"
import { Heading, UtilityText } from "component/text"
import { useState } from "react";
import { View } from "react-native";
import { RichText } from "component/richtext"
import { IconButton, TextButton } from "component/button"
import { Pause, Play, Upload } from "@carbon/icons-react";
import { colorButtonDisabled, colorSurfaceSecondary, colorTextSuccess } from "component/color"
import { useVideoVotingSessionData } from "../../../contrib/zdf/videovoting/videoVotingSession";
import { useVideo, VideoPlayerStateContext } from "../../../contrib/zdf/videoPlayerStateContext";
import { VideoVoting } from "../../../contrib/zdf/structure/videovoting";
import { PercentageBar, PercentageBarContainer } from "../../../contrib/zdf/videovoting/videovotingPercentageBars";
import { chapterQuestionMixVideoVotingTemplate, chapterVideoVotingTemplate, godzillaVideoVotingTemplateConnected, godzillaVideoVotingTemplateIndividual } from "../../../feature/zdf/videovoting/examples/votingtemplate";
import { getPrettyTimestamp } from "../../../system/videotime";


// Fake video hook
function useFakeVideoPlayer(initialState = {}){
    const [isPlaying, setIsPlaying] = useState(initialState.isPlaying ?? false)
    const [ready, setReady] = useState(initialState.ready ?? false)
    const [currentTime, setCurrentTime] = useState(initialState.currentTime ?? 0)
    const [duration, setDuration] = useState(initialState.duration ?? 10000)
    const [ended, setEnded] = useState(initialState.ended ?? false)

    return {
        isPlaying,
        ready,
        currentTime,
        duration,
        ended,

        play: () => {setIsPlaying(true)},
        pause: () => (setIsPlaying(false)),
        seek: (time) => (setCurrentTime(time)),
        setDuration: (time) => (setDuration(time)),
        endVideo: () => (setEnded(true)),
        loadVideo: () => (setReady(true))
    }
}

// Helper component to make video voting demoable
// It contains a fake video UI und prints the video voting session state
export function DemoVideoVotingContainer({ videoState = {}, showStats }) {
    const fakeVideoState = useFakeVideoPlayer(videoState);

    return (
        <VideoPlayerStateContext.Provider value={fakeVideoState}>
            <View
                style={{ position: "relative", width: "100%", height: 500, backgroundColor: colorSurfaceSecondary }}
            >
                <VideoVoting />
                <View style={{ bottom: 0, position: "absolute", width: "100%" }}>
                    <VideoBar />
                </View>
            </View>
            {showStats && (
                <View>
                    <Separator />
                    <Heading text={"Current Session State Object"} />
                    <VideoVotingSessionInformationBox />
                </View>
            )}
        </VideoPlayerStateContext.Provider>
    );
}

function VideoBar(){
    const video = useVideo();
    
    const seekVideo = (amount) => {
        let newTime = video.currentTime + amount;
        newTime = Math.min(video.duration, Math.max(newTime, 0));
        video.seek(newTime);
    }

    return (
        <View style={{ flex: 1, flexGrow: 1 }}>
            {!video.ready ? (
                <TextButton leftIcon={Upload} label={"Load video element"} onPress={video.loadVideo} />
            ) : (
                <View>
                    <PercentageBarContainer thickness={10} backgroundColor={colorButtonDisabled}>
                        <PercentageBar percentages={[video.currentTime]} totalValue={video.duration} colors={[colorTextSuccess]} />
                    </PercentageBarContainer>
                    <HorizBox spread center>
                        <View>
                            {video.isPlaying ? (
                                <IconButton label={"PAUSE"} icon={Pause} onPress={video.pause} />
                            ) : (
                                <IconButton label={"PLAY"} icon={Play} onPress={video.play} />
                            )}
                        </View>
                        <HorizBox>
                            <TextButton label={"PREV"} onPress={() => { seekVideo(-60) }} />
                            <Pad/>
                            <TextButton label={"FWRD"} onPress={() => { seekVideo(60) }} />
                        </HorizBox>
                        <View style={{paddingHorizontal: 8}}>
                            <UtilityText
                                text={
                                    getPrettyTimestamp(video.currentTime, true) +
                                    "/" +
                                    getPrettyTimestamp(video.duration, true)
                                }
                            />
                        </View>    
                    </HorizBox>
                </View>
            )}
        </View>
    );
}

// Print the video voting session data to verify it using snapshots
function VideoVotingSessionInformationBox() {
    const sessionState = useVideoVotingSessionData()

    return (
        <>
            {sessionState ? (
                <View>
                    <RichText text={"**State:** " + sessionState.state}></RichText>
                    <RichText text={"**Current question key:** " + sessionState.currentQuestionKey}></RichText>
                    <RichText text={"**Anonymous voting:** " + sessionState.anonymous}></RichText>
                    <RichText text={"**Answered questions:** " + JSON.stringify(sessionState.questions)}></RichText>
                </View>
            ) : (
                <RichText text={"**No session initialized**"}></RichText>
            )}
        </>
    );
}


export const videoVotingDemoData = {
    templateIndividual: godzillaVideoVotingTemplateIndividual,
    templateConnected: godzillaVideoVotingTemplateConnected,
    templateChapters: chapterVideoVotingTemplate,
    templateChaptersQuestionsMix: chapterQuestionMixVideoVotingTemplate,
}