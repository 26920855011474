import React from "react"
import { HorizBox, Pad, PadBox } from "component/basics"
import { colorKilauea3, colorMum, colorTextSecondary } from "component/color"
import { UtilityText } from "component/text"
import { StyleSheet, Text, View } from "react-native"
import { useCollection, useDatastore, useGlobalProperty, usePersonaKey, usePersonaObject } from "util/datastore"
import { Byline } from "component/people";
import { PollSurveyThenResults } from "../../../../component/poll";
import { AnswerInput } from "structure/question"
import { ColoredBallPill } from "component/colorpill"

export const DialogueFeature = {
    name: "Dialogue",
    key: "Dialogue",
    config: {
        headerAboveInputWidgets: [Voting],
        commentInputPlaceholder: 'Add an argument...',
        commentAboveWidgets: [YourPosition],
        composerPreview: ComposerIfVoted,
    },
    defaultConfig: {
        pageShowEmptyHelp: false,
        // composerPreview: ComposerIfVoted,
    }
}

export function ComposerIfVoted() {
    const myVote = useCollection('vote', {filter: {from: usePersonaKey()}})[0];
    console.log('myVote', myVote);

    if (myVote && myVote.value) {
        return <AnswerInput />
    } else {
        return null;
    }
}

export function YourPosition({comment}) {
    const vote = useCollection('vote', {filter: {from: comment.from}})?.[0];
    const color = vote?.value?.label === 'Yes' ? colorMum : colorKilauea3;
    if (vote && vote.value) {
        return <PadBox bottom={16}><ColoredBallPill label={vote.value.label} color={color} /></PadBox>
    }
}

function AuthorProfile() {
    const questionAuthor = useGlobalProperty('questionAuthor');
    const persona = usePersonaObject(questionAuthor);
    const personaKey = usePersonaKey();
    const s = DialogueStyle;

    return (
        <View style={s.authorProfileContainer}>
            <HorizBox>
                <View style={s.authorAvatar}>
                    <Byline type='large' userId={personaKey} showMetadata={false}/>
                </View>
                <View style={s.authorInfo}>
                    <UtilityText weight="medium" label={persona.name} style={s.authorName}/>
                    <HorizBox>
                        <UtilityText type="tiny" color={colorTextSecondary} label="Journalist"/>
                        <Text style={s.bulletPoint}>•</Text>
                        <UtilityText type="tiny" color={colorTextSecondary} label="2 hours ago"/>
                    </HorizBox>
                </View>
            </HorizBox>
            <Pad size={16}/>
        </View>
    );
}

export function Voting() {
    const pollKey = 'vote'
    const datastore = useDatastore();
    const personaKey = usePersonaKey();
    const voteObjects = useCollection(pollKey);

    const votes = voteObjects.map(vote => vote?.value?.label);
    const myVoteObject = voteObjects.find(v => v?.from == personaKey);

    const myVote = myVoteObject?.value?.label;
    const myComment = myVoteObject?.value?.comment;

    const onVote = (vote, comment = null) => {
        const existing = datastore.getCollection(pollKey, { filter: { from: personaKey } });
        existing.forEach(r => datastore.deleteObject(pollKey, r.key));
        datastore.addObject(pollKey, { from: personaKey, value: { label: vote, ...(comment && { comment }) } });
    }

    return (
        <>
            {/* <AuthorProfile/> */}
            <Pad size={16}/>
            <Byline type='large' userId={personaKey} showMetadata={false}/>
            <Pad size={16}/>
            <PollSurveyThenResults
                question={null}
                optionLabels={['Yes', 'No']}
                optionTexts={null}
                onVote={datastore.needsLogin(onVote, 'vote')}
                votes={votes}
                myVote={myVote}
                myComment={myComment}
                showButton={false}
                showOtherOption={null}
                otherOptionLabel={null}
                otherOptionText={null}
                otherOptionPlaceholder={null}
                isSubmitted={myVote}
                tagline='Vote now!'
                resultline='Results'
                visualizationType='percent'
            />
        </>
    )
}

const DialogueStyle = StyleSheet.create({
    container: {
        backgroundColor: "#faf8f5",
    },
    authorProfileContainer: {
        paddingTop: 16,
    },
    authorAvatar: {
        width: 40,
        height: 40,
        borderRadius: 20,
        overflow: "hidden",
        marginRight: 12,
        backgroundColor: "#f0f0f0",
    },
    authorInfo: {
        justifyContent: "center",
    },
    authorName: {
        fontSize: 16,
        fontWeight: "600",
        marginBottom: 2,
    },
    bulletPoint: {
        marginHorizontal: 4,
        color: colorTextSecondary,
        fontSize: 10,
    },
    headerText: {
        fontSize: 16,
        marginBottom: 8,
    },
    optionsContainer: {
        gap: 12,
    },
    optionButton: {
        flexDirection: "row",
        alignItems: "center",
        padding: 12,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: "#ddd",
        backgroundColor: "#fff",
    },
    selectedOption: {
        borderWidth: 2,
        backgroundColor: "#fff",
    },
    colorDot: {
        width: 12,
        height: 12,
        borderRadius: 6,
        marginRight: 8,
    },
    optionText: {
        flex: 1,
        fontSize: 16,
        color: "#333",
    },
    selectedOptionText: {
        fontWeight: "500",
    },
    radioButton: {
        width: 20,
        height: 20,
        borderRadius: 10,
        borderWidth: 1,
        borderColor: "#ddd",
        justifyContent: "center",
        alignItems: "center",
    },
    selectedRadioButton: {
        borderColor: "transparent",
    },
    radioButtonInner: {
        width: 10,
        height: 10,
        borderRadius: 5,
    },
    viewResultButton: {
        flexDirection: "row",
        alignItems: "center",
        alignSelf: "flex-start",
        padding: 8,
    },
    viewResultIcon: {
        marginRight: 4,
    },
    viewResultText: {
        fontSize: 14,
    },
    resultsWrapper: {
        paddingVertical: 20,
    },
    responseHeader: {
        marginBottom: 12,
        alignItems: "center",
    },
    responseText: {
        fontSize: 16,
        color: "#333",
    },
    editButton: {
        flexDirection: "row",
        alignItems: "center",
        gap: 4,
    },
    editIcon: {
        marginRight: 4,
        color: colorTextSecondary,
    },
    resultsCard: {
        backgroundColor: "#fff",
        borderRadius: 12,
        padding: 16,
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.05)',
        elevation: 2,
        marginBottom: 12,
    },
    resultOption: {
        marginBottom: 8,
    },
    resultBarContainer: {
        position: "relative",
        width: "100%",
        borderRadius: 8,
        overflow: "hidden",
    },
    resultBarFill: {
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        borderRadius: 8,
    },
    resultBar: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 12,
        zIndex: 1,
    },
    resultContent: {
        flexDirection: "row",
        alignItems: "center",
        flex: 1,
    },
    resultText: {
        fontSize: 16,
        color: "#333",
    },
    resultPercentage: {
        flexDirection: "row",
        alignItems: "center",
    },
    percentageText: {
        fontSize: 16,
        color: "#333",
        fontWeight: "500",
    },
    youBadge: {
        backgroundColor: "#068287",
        borderRadius: 4,
        paddingHorizontal: 4,
        paddingVertical: 2,
        marginRight: 4,
    },
    totalParticipants: {
        fontSize: 14,
        marginTop: 8,
    },
    addArgumentButton: {
        flexDirection: "row",
        alignItems: "center",
        padding: 12,
        borderRadius: 24,
        borderWidth: 1,
        borderColor: "#ddd",
        backgroundColor: "#fff",
    },
    disabledButton: {
        backgroundColor: "#f5f5f5",
        borderColor: "#eee",
    },
    disabledImage: {
        opacity: 0.5,
    },
    userAvatar: {
        width: 32,
        height: 32,
        borderRadius: 16,
        overflow: "hidden",
        marginRight: 12,
        backgroundColor: "#f0f0f0",
    },
    avatarImage: {
        width: "100%",
        height: "100%",
    },
    tab: {
        paddingVertical: 8,
        paddingHorizontal: 16,
        marginRight: 16,
    },
    activeTab: {
        borderBottomWidth: 2,
        borderBottomColor: "#000",
    },
    filterButton: {
        flexDirection: "row",
        alignItems: "center",
    },
    aiModeratedBadge: {
        backgroundColor: "#f0f0f0",
        borderRadius: 12,
        paddingHorizontal: 8,
        paddingVertical: 4,
    },
    argumentContainer: {
        marginBottom: 24,
    },
    voteIndicator: {
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 8,
    },
    userInfo: {
        flexDirection: "row",
        alignItems: "center",
    },
    sourceContainer: {
        flexDirection: "row",
        alignItems: "center",
        gap: 4,
    },
    actionButton: {
        flexDirection: "row",
        alignItems: "center",
    },
    repliesButton: {
        marginTop: 8,
    },
})