import { Narrow } from "component/basics"
import { CLICK, DemoSection } from "system/demo"
import { Datastore } from "util/datastore"
import { VideoVotingOverview, VotingResults } from "../../../feature/zdf/videovoting/VideoVotingOverviewFeature";
import { StructureDemo } from "util/instance"
import { videoVotingDemoTemplates, videoVotingDemoVotes } from "./videovoting.data";


const templateIndividual = videoVotingDemoTemplates.templateIndividual;
const templateConnected = videoVotingDemoTemplates.templateConnected;
const templateSlider = videoVotingDemoTemplates.templateSlider;
const templateChapters = videoVotingDemoTemplates.templateChapters;
const templateChaptersQuestionsMix = videoVotingDemoTemplates.templateChaptersQuestionsMix;

const serverCall = {
    eventlog: {
        logEvent: () => { },
    }
}

const backlinkCollection = [
    {
        title: "Individual questions",
        key: "voting-individual",
        image: "https://new-public-demo.web.app/photos/godzilla_new_york.jpeg"
    },
    {
        title: "Connected questions",
        key: "voting-connected",
        image: "https://new-public-demo.web.app/photos/godzilla_new_york.jpeg"
    },
    {
        title: "Slider questions",
        key: "voting-slider",
        image: "https://new-public-demo.web.app/photos/godzilla_new_york.jpeg",
    },
    {
        title: "!Chapters",
        key: "voting-chapters",
        image: "https://new-public-demo.web.app/photos/godzilla_new_york.jpeg"
    },
    {
        title: "!Mix of chapters and chapterless questions",
        key: "voting-chapters-questions-mix",
        image: "https://new-public-demo.web.app/photos/godzilla_new_york.jpeg"
    }
]

const moduleArticle = {
    articleInfo: {
        "voting-individual": backlinkCollection[0],
        "voting-connected": backlinkCollection[1],
        "voting-slider": backlinkCollection[2],
        "voting-chapters": backlinkCollection[3],
        "voting-chapters-questions-mix": backlinkCollection[4],
    }
}
const modulePublic = {
    videovoting: {
        all: { 
            "voting-individual": { template: templateIndividual }, 
            "voting-connected": { template: templateConnected }, 
            "voting-slider": { template: templateSlider },
            "voting-chapters": { template: templateChapters },
            "voting-chapters-questions-mix": { template: templateChaptersQuestionsMix }
        },
        instanceToVoting: { 
            article: { 
                "voting-individual": "voting-individual", 
                "voting-connected": "voting-connected",
                "voting-slider": "voting-slider",
                "voting-chapters": "voting-chapters",
                "voting-chapters-questions-mix": "voting-chapters-questions-mix",
            } }
    }, 
    article: moduleArticle
}

const globals = {
    name: 'Is Godzilla Good?',
    questionAuthor: 'a',
    questionTime: Date.now(),
}

export function VideoVotingQuestionOverviewFeatureDemo() {
    return (
        <Narrow>
            <DemoSection text="Video Voting results for individual question voting">
                <Datastore
                    testState={{
                        serverCall,
                        modulePublic: modulePublic,
                        collections: {
                            backlink_article: backlinkCollection,
                            derived_votes: [...videoVotingDemoVotes.votesIndividual],
                        },
                    }}
                >
                    <VotingResults votingDataEntry={templateIndividual} votingInstanceKey={"voting-individual"} articleKey={"voting-individual"}/>
                </Datastore>
            </DemoSection>

            <DemoSection text="Video Voting results for connected question voting">
                <Datastore
                    testState={{
                        serverCall,
                        modulePublic: modulePublic,
                        collections: {
                            backlink_article: backlinkCollection,
                            derived_votes: [...videoVotingDemoVotes.votesConnected],
                        },
                    }}
                >
                    <VotingResults votingDataEntry={templateConnected} votingInstanceKey={"voting-connected"} articleKey={"voting-connected"}/>
                </Datastore>
            </DemoSection>

            <DemoSection text="Video Voting results for slider question voting">
                <Datastore
                    testState={{
                        serverCall,
                        modulePublic: modulePublic,
                        collections: {
                            backlink_article: backlinkCollection,
                            derived_votes: [...videoVotingDemoVotes.votesSlider],
                        },
                    }}
                >
                    <VotingResults votingDataEntry={templateSlider} votingInstanceKey={"voting-slider"} articleKey={"voting-slider"}/>
                </Datastore>
            </DemoSection>
            <DemoSection text="Video Voting results with chapters">
                <Datastore
                    testState={{
                        serverCall,
                        modulePublic: modulePublic,
                        collections: {
                            backlink_article: backlinkCollection,
                            derived_videoVote: []
                        }
                    }}
                >
                    <VotingResults votingDataEntry={templateChapters} votingInstanceKey={"voting-chapters"} articleKey={"voting-chapters"} />
                </Datastore>
            </DemoSection>
            <DemoSection text="Video Voting results with chapters and chapterless questions">
                <Datastore
                    testState={{
                        serverCall,
                        modulePublic: modulePublic,
                        collections: {
                            backlink_article: backlinkCollection,
                            derived_videoVote: []
                        }
                    }}
                >
                    <VotingResults votingDataEntry={templateChaptersQuestionsMix} votingInstanceKey={"voting-chapters-questions-mix"} articleKey={"voting-chapters-questions-mix"} />
                </Datastore>
            </DemoSection>
        </Narrow>
    );
}


export function VideoVotingQuestionOverviewFeatureStorySets() {
    return [
        {
            label: "Video Voting Result Overview",
            instanceKey: "a",
            personaKey: "a",
            content: <VideoVotingOverview/>,
            modulePublic: modulePublic,
            serverCall: serverCall,
            collections: {
                backlink_article: backlinkCollection,
                derived_videoVote: [
                    ...videoVotingDemoVotes.votesConnected,
                    ...videoVotingDemoVotes.votesIndividual,
                    ...videoVotingDemoVotes.votesSlider,
                ],
            },
            stories: [
                {
                    label: "Open accordions of individual questions",
                    actions: [
                        CLICK(templateIndividual.questions[0].text),
                        CLICK(templateIndividual.questions[1].text),
                        CLICK(templateIndividual.questions[2].text),
                        CLICK(templateIndividual.questions[3].text),
                    ],
                },
                {
                    label: "Open accordions of connected question",
                    actions: [CLICK(templateConnected.questions[0].text)],
                },
                {
                    label: "Open accordions of slider questions",
                    actions: [
                        CLICK(templateSlider.questions[0].text),
                        CLICK(templateSlider.questions[1].text),
                        CLICK(templateSlider.questions[2].text),
                        CLICK(templateSlider.questions[3].text),
                        CLICK(templateSlider.questions[4].text),
                        CLICK(templateSlider.questions[5].text),
                        CLICK(templateSlider.questions[6].text),
                    ],
                },
                {
                    label: "Open accordions of chapters and chapterless questions",
                    actions: [
                        CLICK(templateChaptersQuestionsMix.questions[4].text),
                        CLICK(templateChaptersQuestionsMix.chapters[1].name),
                        CLICK(templateChaptersQuestionsMix.chapters[0].name),
                    ],
                },
            ],
        },
    ];
}

export function VideoVotingQuestionOverviewFullStructureDemo() {
    return (
        <StructureDemo
            structureKey={"question"}
            testState={{
                serverCall,
                modulePublic: modulePublic,
                collections: {
                    backlink_article: backlinkCollection,
                    derived_videoVote: [
                        ...videoVotingDemoVotes.votesConnected,
                        ...videoVotingDemoVotes.votesIndividual,
                        ...videoVotingDemoVotes.votesSlider,
                    ],
                },
                globals,
            }}
            features={{ votingoverview: true }}
        />
    );
}