import { ModDashboardCommentCard } from "../../../component/zdf/moddashboard/moddashboardcommentcard";
import { useState } from "react";
import { modDashboardDemoData } from "./moddashboard.data";
import { Datastore } from "../../../util/datastore";
import { defaultPersonaList, personaListToMap } from "../../../util/testpersonas";
import { CLICK, DemoSection, INPUT, POPUP } from "../../../system/demo";

export function ModDashboardCommentCardDemo() {
    return (
        <Datastore
            personaPreview={personaListToMap(defaultPersonaList)}
            isAdmin={true}
            roles={["Moderator"]}
            testState={{
                serverCall: modDashboardDemoData.serverCalls,
                modulePublic: {
                    moderation: {
                        queue: modDashboardDemoData.modTaskQueues.thread1,
                        moderator: modDashboardDemoData.demoAuthors,
                    },
                },
            }}
        >
            <DemoSection text="No rule violations">
                <ModDashboardCommentCard
                    modAuthor={modDashboardDemoData.demoModAuthors.c_t1_approved_auto}
                    modTask={modDashboardDemoData.modTaskQueues.thread1.c_t1_approved_auto}
                    comment={modDashboardDemoData.commentsThread1.c_t1_approved_auto}
                    highlighted={true}
                />
            </DemoSection>

            <DemoSection text="AI flag">
                <ModDashboardCommentCard
                    modAuthor={modDashboardDemoData.demoModAuthors.c_t1_awaiting_ai}
                    modTask={modDashboardDemoData.modTaskQueues.thread1.c_t1_awaiting_ai}
                    comment={modDashboardDemoData.commentsThread1.c_t1_awaiting_ai}
                    highlighted={true}
                />
            </DemoSection>

            <DemoSection text="Blocklist flag">
                <ModDashboardCommentCard
                    modAuthor={modDashboardDemoData.demoModAuthors.c_t1_awaiting_blocklist}
                    modTask={modDashboardDemoData.modTaskQueues.thread1.c_t1_awaiting_blocklist}
                    comment={modDashboardDemoData.commentsThread1.c_t1_awaiting_blocklist}
                    highlighted={true}
                />
            </DemoSection>

            <DemoSection text="Reported by one person">
                <ModDashboardCommentCard
                    modAuthor={modDashboardDemoData.demoModAuthors.c_t1_awaiting_report}
                    modTask={modDashboardDemoData.modTaskQueues.thread1.c_t1_awaiting_report}
                    comment={modDashboardDemoData.commentsThread1.c_t1_awaiting_report}
                    highlighted={true}
                />
            </DemoSection>

            <DemoSection text="Approved by moderator">
                <ModDashboardCommentCard
                    modAuthor={modDashboardDemoData.demoModAuthors.c_t1_approved_human}
                    modTask={modDashboardDemoData.modTaskQueues.thread1.c_t1_approved_human}
                    comment={modDashboardDemoData.commentsThread1.c_t1_approved_human}
                    highlighted={true}
                />
            </DemoSection>

            <DemoSection text="Rejected">
                <ModDashboardCommentCard
                    modAuthor={modDashboardDemoData.demoModAuthors.c_t1_rejected}
                    modTask={modDashboardDemoData.modTaskQueues.thread1.c_t1_rejected}
                    comment={modDashboardDemoData.commentsThread1.c_t1_rejected}
                    highlighted={true}
                />
            </DemoSection>
            
            <DemoSection text="Deleted">
                <ModDashboardCommentCard
                    modAuthor={modDashboardDemoData.demoModAuthors.c_t1_awaiting_deleted}
                    modTask={modDashboardDemoData.modTaskQueues.thread1.c_t1_awaiting_deleted}
                    highlighted={true}
                />
            </DemoSection>

            <DemoSection text="Bookmarked">
                <ModDashboardCommentCard
                    modAuthor={modDashboardDemoData.demoModAuthors.c_t1_awaiting_ai}
                    modTask={modDashboardDemoData.modTaskQueues.thread1.c_t1_awaiting_ai}
                    comment={modDashboardDemoData.commentsThread1.c_t1_awaiting_ai}
                    isBookmarked={true}
                />
            </DemoSection>
        </Datastore>
    );
}

function CollapsedCardDemo() {

    const [expanded, setExpanded] = useState(false)
    const onClickCard = (item) => {
        setExpanded(true)
    }

    const onExpandCard = (item) => {
        setExpanded(true)
    }

    const onCollapseCard = (item) => {
        setExpanded(false)
    }

    return <ModDashboardCommentCard 
                onClickCard={onClickCard}
                onExpand={onExpandCard}
                onCollapse={onCollapseCard}
                highlighted={expanded}
                modTask={modDashboardDemoData.modTaskQueues.thread1.c_t1_awaiting_ai}
                modAuthor={modDashboardDemoData.demoModAuthors.c_t1_awaiting_ai}
    />
}

function NotesFromColleaguesDemo() {

    return <ModDashboardCommentCard 
                highlighted={true}
                modTask={modDashboardDemoData.modTaskQueues.thread1.c_t1_awaiting_moderator_note}
                modAuthor={modDashboardDemoData.demoModAuthors.c_t1_awaiting_moderator_note}
    />
}

function MultipleReportersDemo() {

    return <ModDashboardCommentCard 
                highlighted={true}
                modTask={modDashboardDemoData.modTaskQueues.thread1.c_t1_awaiting_report_multiple}
                modAuthor={modDashboardDemoData.demoModAuthors.c_t1_awaiting_report_multiple}
    />
}

export function ModDasboardCommentCardStorySet() {


    return [
        {
            label: "Collapse Card Demo",
            serverCall: modDashboardDemoData.serverCalls,
            modulePublic: {
                moderation: {
                    queue: modDashboardDemoData.modTaskQueues.thread1
                }
            },
            content: <CollapsedCardDemo/>,
            stories: [
                {
                    label: "Expand and collapse",
                    actions: [
                        CLICK("Show more"),
                        CLICK("Show less")
                    ],
                }
            ],
        },
        {
            label: "Notes from colleagues demo",
            content: <NotesFromColleaguesDemo/>,
            serverCall: modDashboardDemoData.serverCalls,
            modulePublic: {
                moderation: {
                    queue: modDashboardDemoData.modTaskQueues.thread1
                }
            },
            stories: [
                {
                    label: "Add a note",
                    actions: [
                        CLICK("Add your notes..."),
                        INPUT("moderatorNoteInput", "Demo note"),
                        CLICK("Post"),
                    ],
                },
                {
                    label: "Add a note, but cancel",
                    actions: [
                        CLICK("Add your notes..."),
                        INPUT("moderatorNoteInput", "Demo note"),
                        CLICK("Cancel"),
                    ],
                },
            ],
        },
        {
            label: "Comment with multiple reporters",
            content: <MultipleReportersDemo/>,
            serverCall: modDashboardDemoData.serverCalls,
            modulePublic: {
                moderation: {
                    queue: modDashboardDemoData.modTaskQueues.thread1
                }
            },
            stories: [
                {
                    label: "Open reporters and close",
                    actions: [
                        CLICK("reporters"),
                        POPUP(CLICK("close-modal")),
                    ],
                }
            ],
        },
    ];
}