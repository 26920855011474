import { Heading, TextField, UtilityText } from "component/text"
import { ClickThroughTag, CTAButton, PopupPanel, ReactionButton, Tag } from "component/button"
import { useIsAdmin } from "component/admin"
import { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { useCollection, useDatastore, useGlobalProperty, usePersonaKey, useSessionData } from "util/datastore"
import { Center, FlowBox, HorizBox, HoverView, Pad, PadBox } from "component/basics"
import { colorAmbiguousWhite, colorBorderPrimary, colorGreyHover, colorPurplishBlue, colorSurfaceDiscoveryShade, colorSurfaceSecondary, colorSurfaceWarning, colorTextSecondary } from "component/color"
import { useConfig, useIsReadOnly } from "util/features"
import { addKey, removeKey, sleepMillisAsync } from "util/util"
import { toBool } from "../../util/util";
import { Modal } from "component/modal"
import { Checkbox } from "component/form"
import Svg, { Path } from 'react-native-svg';
import { CheckmarkFilled, CheckmarkOutline } from '@carbon/icons-react';
import { checkPerspectiveAsync, ModerationReport } from "../moderation/AIPreModeratorFeature"
import { useLanguage, useTranslation } from "component/translation";
import { logEventAsync } from "util/eventlog"
import { HelpBubble } from "component/help"

export const RepresentingPerspectivesFeature = {
    name: 'Representing Perspectives',
    key: 'representing_perspectives',
    config: {},
    defaultConfig: {
        perspectiveShowFilter: true
    }
}

export const RepresentingPerspectivesCardFeature = {
    name: 'Card',
    key: 'representing_perspectives_card',
    dev: true,
    config: {
        commentAboveWidgets: [PerspectivesForComment],
        composerTopWidgets: [ComposerPerspectiveSelector],
        commentEditTopWidgets: [PerspectivesForComment],
        commentRankers: [{label: 'Boost by perspective', ranker: boostSelectedPerspectives}],
        pageTopWidgets: [MissingPerspectivesCard],
        pageTopLeftWidgets: [PerspectiveFilter],
        pageShowEmptyHelp: false,
    }
}

export const RepresentingPerspectivesSpeechBubbleFeature = {
    name: 'Speech Bubble',
    key: 'representing_perspectives_speech_bubble',
    dev: true,
    config: {
        commentAboveWidgets: [PerspectivesForComment],
        headerAboveInputWidgets: [PerspectivesCallOut],
        composerTopWidgets: [ComposerPerspectiveSelectorWithWriteIn],
        commentEditTopWidgets: [PerspectivesForComment],
        commentRankers: [{label: 'Boost by perspective', ranker: boostSelectedPerspectives}],
        pageTopWidgets: [() => <PadBox top={20} left={20}><AdminPerspectiveEditorButton/></PadBox>],
        pageTopLeftWidgets: [PerspectiveFilter],
        pageShowEmptyHelp: false,
    }
}

export const RepresentingPerspectivesSubtleBubbleFeature = {
    name: 'Subtle Bubble',
    key: 'representing_perspectives_subtle_bubble',
    dev: true,
    config: {
        commentAboveWidgets: [PerspectivesForComment],
        headerBelowInputWidgets: [PerspectivesSubtleBubble],
        composerTopWidgets: [ComposerPerspectiveSelectorWithWriteIn],
        commentEditTopWidgets: [PerspectivesForComment],
        commentRankers: [{label: 'Boost by perspective', ranker: boostSelectedPerspectives}],
        pageTopWidgets: [() => <PadBox top={20} left={20}><AdminPerspectiveEditorButton/></PadBox>],
        pageTopLeftWidgets: [PerspectiveFilter],
        pageShowEmptyHelp: false,
    }
}


export function AdminPerspectiveEditorButton() {
    const [modalShown, setModalShown] = useState(false);
    const isAdmin = useIsAdmin();
    const readOnly = useIsReadOnly();

    if (!isAdmin || readOnly) {
        return null;
    }
 
    return <View>
        <CTAButton size='compact' type='secondary' label='Edit perspectives (Admin)' onPress={() => setModalShown(true)} />
        {modalShown && <AdminPerspectiveEditorModal onClose={() => setModalShown(false)} />}
    </View>
}

export function AdminPerspectiveEditorModal({onClose}) {
    const datastore = useDatastore();
    const perspectives = useGlobalProperty('perspectives');
    const [editedPerspectives, setEditedPerspectives] = useState(perspectives);

    function onApply() {
        datastore.setGlobalProperty('perspectives', editedPerspectives);
        onClose();
    }

    return <Modal onClose={onClose} buttonRow={<CTAButton wide label='Apply' onPress={onApply} />}>
        <TextField label='Perspective List' big testID='perspectives-editor'
            value={editedPerspectives} 
            placeholder='What perspectives are important to this conversation? (newline separated)'
            onChange={setEditedPerspectives} />
    </Modal>
}

const emojiPattern = /^(\p{Extended_Pictographic}|\p{Emoji_Presentation})(\u200D(\p{Extended_Pictographic}|\p{Emoji_Presentation}))*/u;
 
function parseEmojiLabelString(inputStr) {  
    const match = inputStr.match(emojiPattern);  
    if (match) {
      const emoji = match[0];
      const text = inputStr.slice(emoji.length).trim();
      return { emoji, text };
    } else {
      return { emoji: null, text: inputStr.trim() };
    }
}

export function PerspectivesForComment({comment}) {
    const commentPerspectives = Object.keys(comment.perspectives || {}).filter(p => 
        comment.perspectives[p] && !(p === 'writeIn' && comment.perspectives[p].violates));
    if (!commentPerspectives.length) {
        return null;
    }
    return <PadBox bottom={8}><FlowBox>
        {commentPerspectives.map(perspectiveKey =>
            <PadBox key={perspectiveKey} right={12} bottom={12}>
                <Tag compact dashed={perspectiveKey === 'writeIn'}
                    color={colorSurfaceSecondary} borderColor={perspectiveKey === 'writeIn' ? colorBorderPrimary : 'transparent'}
                    text={comment.perspectives[perspectiveKey]?.text} 
                    emoji={comment.perspectives[perspectiveKey]?.emoji} />
            </PadBox>
        )}
    </FlowBox></PadBox>
}

function findPerspectivesPresentInComments(comments) {
    const presentPerspectives = {};
    comments.forEach(comment => {
        Object.keys(comment.perspectives || {}).forEach(perspectiveKey => {
            presentPerspectives[perspectiveKey] = true;
        });
    });
    return presentPerspectives;
}

export function PerspectivesSubtleBubble() {
    const s = PerspectivesSubtleBubbleStyle;
    const perspectives = useGlobalProperty('perspectives');
    const datastore = useDatastore();
    const personaKey = usePersonaKey();
    const readOnly = useIsReadOnly();
    const perspectiveList = (perspectives ?? '').split('\n').filter(x => x);
    const parsedPerspectives = perspectiveList.map(p => parseEmojiLabelString(p));
    const myAnswer = useCollection('comment', {filter: {from: personaKey, replyTo: null, deleted: null}})[0];

    if (myAnswer || readOnly) {
        return null;
    }

    function handlePerspectiveClick(selectedPerspectiveText) {
        datastore.needsLogin(() => {
            datastore.pushSubscreen('composer', {preselectedPerspectiveText: selectedPerspectiveText});
        })();
    }
    
    return <View style={s.container}>
        <View style={s.bubble}> 
            <PadBox vert={20} horiz={12}>
                <UtilityText label="We'd particularly like to hear from:" />
                <Pad size={12} />
                <FlowBox stretch>
                    {parsedPerspectives.map(pp => <PadBox key={pp.text} right={12} bottom={8}>
                        <ClickThroughTag text={pp.text} emoji={pp.emoji} onPress={() => handlePerspectiveClick(pp.text)} />
                    </PadBox>)}
                    <ClickThroughTag label="Add your own" emoji="+" onPress={() => handlePerspectiveClick('Add your own')} />
                </FlowBox>
            </PadBox>
        </View>    
    </View>
}
const PerspectivesSubtleBubbleStyle = StyleSheet.create({
    container: {
        position: 'relative',
        alignItems: 'stretch',
        marginTop: 32,
    },
    triangle: {
        position: 'absolute',
        top: -22,
        left: 12,
    },
    bubble: {
        borderRadius: 10,
        borderColor: 'transparent',
        borderWidth: 1,
        backgroundColor: colorSurfaceDiscoveryShade,
    },
})

export function PerspectivesCallOut() {
    const s = PerspectivesCallOutStyle;
    const perspectives = useGlobalProperty('perspectives');
    const datastore = useDatastore();
    const personaKey = usePersonaKey();
    const readOnly = useIsReadOnly();
    const perspectiveList = (perspectives ?? '').split('\n').filter(x => x);
    const parsedPerspectives = perspectiveList.map(p => parseEmojiLabelString(p));
    const myAnswer = useCollection('comment', {filter: {from: personaKey, replyTo: null, deleted: null}})[0];

    if (myAnswer || readOnly) {
        return null;
    }

    function handlePerspectiveClick(selectedPerspectiveText) {
        datastore.needsLogin(() => {
            datastore.pushSubscreen('composer', {preselectedPerspectiveText: selectedPerspectiveText});
        })();
    }
    
    return <View style={s.container}>
        <SpeechBubbleTriangle style={s.triangle} />
        <View style={s.bubble}> 
            <PadBox vert={20} horiz={12}>
                <UtilityText mono italic type='tiny' color={colorAmbiguousWhite} label="We'd particularly like to hear from:" />
                <Pad size={12} />
                <FlowBox stretch>
                    {parsedPerspectives.map(pp => <PadBox key={pp.text} right={12} bottom={8}>
                        <ClickThroughTag text={pp.text} emoji={pp.emoji} onPress={() => handlePerspectiveClick(pp.text)} />
                    </PadBox>)}
                    <ClickThroughTag label="Add your own" emoji="+" onPress={() => handlePerspectiveClick('Add your own')} />
                </FlowBox>
            </PadBox>
        </View>    
    </View>
}
const PerspectivesCallOutStyle = StyleSheet.create({
    container: {
        position: 'relative',
        alignItmers: 'center',
        marginTop: 32,
    },
    triangle: {
        position: 'absolute',
        top: -22,
        left: 12,
    },
    bubble: {
        borderRadius: 10,
        borderColor: 'transparent',
        borderWidth: 1,
        backgroundColor: colorPurplishBlue,
    },
})

function SpeechBubbleTriangle({style}) {
    return <Svg width="20" height="37" viewBox="0 0 20 37" style={style}>
        <Path fill={colorPurplishBlue} d="M19.211 33.114L7.361 1.036C7.004.071 5.44.141 5.305 1.128L.442 36.684l18.77-3.57z"/>
    </Svg>
}

export function MissingPerspectivesCard() {
    const s = MissingPerspectivesStyle;
    const comments = useCollection('comment', {sortBy: 'time'});
    const perspectives = useGlobalProperty('perspectives');
    const {commentInputLoginAction} = useConfig();
    const personaKey = usePersonaKey();
    const isAdmin = useIsAdmin();
    const datastore = useDatastore();

    const hasCommentByMe = comments.some(c => c.from == personaKey && c.replyTo == null);

    const perspectiveList = (perspectives ?? '').split('\n').filter(x => x);
    const parsedPerspectives = perspectiveList.map(p => parseEmojiLabelString(p));
    const presentPerspectives = findPerspectivesPresentInComments(comments);
    const missingPerspectives = parsedPerspectives.filter(pp => !presentPerspectives[pp.text])
    const allMissing = Object.keys(presentPerspectives).length == 0;
    const noneMissing = missingPerspectives.length == 0;

    return <View style={(allMissing || noneMissing) ? s.genericBox : s.missingBox}>
        <PadBox horiz={16} vert={16}>
            <Center>
                {allMissing ?
                    <Heading center label="Add your perspective" />
                : noneMissing ?
                    <Heading center label="Perspectives" />
                :
                    <Heading center label="We haven't heard from these perspectives yet" />
                }
            </Center>
            <Pad/>
            <FlowBox center>
                {(noneMissing ? parsedPerspectives : missingPerspectives).map(pp =>
                    <PadBox key={pp.text} right={8} bottom={8}>
                        <Tag color={colorSurfaceSecondary} strqong text={pp.text} emoji={pp.emoji} />
                    </PadBox>
                )}
            </FlowBox>
            {!noneMissing && !hasCommentByMe && <PadBox top={12}><Center>
                <CTAButton label='Share your thoughts' size='compact' onPress={datastore.needsLogin(
                    () => datastore.pushSubscreen('composer'), 
                    commentInputLoginAction)} />
                </Center></PadBox>
            }
            {isAdmin && <PadBox top={12}><Center><AdminPerspectiveEditorButton/></Center></PadBox>}
        </PadBox>
    </View>
}
const MissingPerspectivesStyle = StyleSheet.create({
    missingBox: {
        marginHorizontal: 20,
        marginVertical: 32,
        marginTop: 32,
        marginBottom: 28,
        borderRadius: 8,
        backgroundColor: colorSurfaceWarning
    },
    genericBox: {
        marginHorizontal: 20,
        marginTop: 32,
        marginBottom: 28,
        borderRadius: 8,
        borderColor: colorBorderPrimary,
        borderWidth: 1,
    }
})

export function ComposerPerspectiveSelector({comment, setComment}) {
    const perspectives = useGlobalProperty('perspectives');
    const perspectiveList = perspectives.split('\n').filter(x => x).map(p => parseEmojiLabelString(p));
    if (comment.replyTo) {
        return null;
    }
    return <View>
        <FlowBox>
            {perspectiveList.map(perspective => 
                <PadBox right={12} bottom={12} key={perspective.text}>
                    <PerspectiveToggle comment={comment} setComment={setComment} perspective={perspective} />
                </PadBox>
            )}
        </FlowBox>
        <Pad size={8} />
    </View>
} 

export function ComposerPerspectiveSelectorWithWriteIn({comment, setComment, screenParams={}}) {
    const perspectives = useGlobalProperty('perspectives');
    const perspectiveList = perspectives?.split('\n').filter(x => x).map(p => parseEmojiLabelString(p)) ?? [];
    const existingWriteIn = comment?.perspectives?.writeIn?.text || '';
    const [writeInSelected, setWriteInSelected] = useState(!!existingWriteIn);
    const [writeInText, setWriteInText] = useState(existingWriteIn);

    useEffect(() => {
        const preselectedPerspectiveText = screenParams?.preselectedPerspectiveText;
        if (preselectedPerspectiveText === 'Add your own') {
            setWriteInSelected(true);
        } else if (preselectedPerspectiveText && !comment.perspectives) {
            const selectedPerspective = perspectiveList.find(p => p.text === preselectedPerspectiveText);
            setComment({...comment, perspectives: {[preselectedPerspectiveText]: {emoji: selectedPerspective.emoji, text: selectedPerspective.text}}});
        }
    }, [screenParams]);

    if (comment.replyTo) {
        return null;
    }

    function handleWriteInButtonToggle() {
        if (writeInSelected) {
            if (comment.perspectives?.writeIn) {
                const updatedPerspectives = {...comment.perspectives};
                delete updatedPerspectives.writeIn;
                setComment({...comment, perspectives: updatedPerspectives});
                setWriteInText('');
            }
        }
        setWriteInSelected(!writeInSelected);
    }
    
    return <PadBox bottom={32}>
        <Heading label='How do you relate to this issue?' />
        <Pad size={16} />
        <FlowBox>
            {perspectiveList.map(perspective => 
                <PadBox right={12} bottom={8} key={perspective.text}>
                    <PerspectiveToggle comment={comment} setComment={setComment} perspective={perspective} usePerspectiveButton={true} />
                </PadBox>
            )}
            <PadBox right={12} bottom={8}>
                <PerspectiveButton label={'Add your own'} selected={writeInSelected} onPress={handleWriteInButtonToggle} />
            </PadBox>
        </FlowBox>
        {writeInSelected && <WriteInPerspective comment={comment} setComment={setComment} 
            writeInText={writeInText} setWriteInText={setWriteInText} />}
    </PadBox>
}

export function WriteInPerspective({comment, setComment, writeInText, setWriteInText, sleep=sleepMillisAsync}) {
    const datastore = useDatastore();
    const language = useLanguage();
    const questionText = useGlobalProperty('name');
    const [showModerationReport, setShowModerationReport] = useState(false);
    const [showHelpBubble, setShowHelpBubble] = useState(false);

    async function handleAddWriteInPerspective() {
        setShowHelpBubble(true);
        logEventAsync(datastore, 'perspective-written-in', {perspectiveWriteInText: writeInText, questionText, commentKey: comment.key, comment});
        const result = await checkPerspectiveAsync({datastore, questionText, perspectiveText: writeInText, language});
        await sleep(4000);
        setShowHelpBubble(false);
        if (result.violates) {
            logEventAsync(datastore, 'moderation-block-perspective', {perspectiveWriteInText: writeInText, questionText, commentKey: comment.key, comment});
            setShowModerationReport(true);
        } else {
            setShowModerationReport(false);
        }
        setComment({...comment, perspectives: {...comment.perspectives, writeIn: { 
            text: writeInText, violates: result.violates, violations: result.violations ?? []}}});
    }

    return <View>
        <Pad size={4} />
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <View style={{flex: 1, alignSelf: 'stretch'}}>
                <TextField value={writeInText} placeholder='Enter a perspective label' multiline={false} testID='write-in-perspective'
                onChange={(text) => {
                    setWriteInText(text);
                    setShowModerationReport(false);
                }} />
            </View>
            <Pad size={12}/>
            <PadBox vertical={5}>
                <CTAButton compact label='Add' onPress={handleAddWriteInPerspective} disabled={!(writeInText?.length > 0) || showModerationReport} />
                <View style={{ position: 'absolute', right: 0, width: 290}}>
                    <HelpBubble above helpKey="perspectiveModeration"
                        condition={showHelpBubble}
                        label="Reviewing your perspective label with AI to ensure it follows our Community Guidelines"
                    />
                </View>
            </PadBox>
        </View>
        {showModerationReport && <View>
            <Pad size={12} />
            <ModerationReport comment={comment} isPerspectiveModeration={true}/>
        </View>}
    </View>    
}

export function PerspectiveToggle({comment, setComment, perspective, usePerspectiveButton=false}) {
    const {emoji, text} = perspective;
    const selected = comment?.perspectives?.[text];
    const datastore = useDatastore();

    function onPress() {
        if (selected) {
            setComment({...comment, perspectives: {...comment.perspectives, [text]: null}});
            logEventAsync(datastore, 'perspective-unselected', {perspectiveText: text, comment});
        } else {
            setComment({...comment, perspectives: {...comment.perspectives, [text]: {emoji, text}}});
            logEventAsync(datastore, 'perspective-selected', {perspectiveText: text, comment});
        }
    }

    return usePerspectiveButton 
        ? <PerspectiveButton text={text} emoji={emoji} selected={selected} onPress={onPress} /> 
        : <ReactionButton text={text} emoji={emoji} selected={selected} onPress={onPress} />
}

export function PerspectiveButton({emoji, label, text, selected, onPress}) {
    const s = PerspectiveButtonStyle;
    const [pressed, setPressed] = useState(false);
    const tSelected = useTranslation(selected ? 'selected' : 'not selected');
    const tLabel = useTranslation(label) ?? text;       
    return <HoverView style={[s.button, selected && s.pressed]} pressedStyle={s.pressed} hoverStyle={s.hover} 
            onPress={onPress} setPressed={setPressed} testID={label ?? text} ariaText = {tLabel + ' (' + tSelected+ ')'}>
        {emoji && <PadBox right={6}>
        <UtilityText text={emoji} type='tiny' weight='strong' ariaHidden /></PadBox>}
        <UtilityText label={label} text={text} weight='medium' type='tiny' ariaHidden/>
        <PadBox right={6}/>
        {selected ? <CheckmarkFilled/> : <CheckmarkOutline color={colorBorderPrimary} />}
    </HoverView>
}
const PerspectiveButtonStyle = StyleSheet.create({
    button: {
        height: 28,
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'flex-start',
        borderColor: colorBorderPrimary,
        borderRadius: 32,
        borderWidth: 1,
        paddingHorizontal: 12
    },
    hover: {
        backgroundColor: colorGreyHover,
    },
    pressed: {       
        backgroundColor: colorSurfaceSecondary,
        borderColor: 'transparent'
    },
});

export function PerspectiveFilterPopup({savedSelected, onClose}) {
    const perspectives = useGlobalProperty('perspectives');
    const datastore = useDatastore();
    const [currentSelected, setCurrentSelected] = useState(savedSelected);
    const [changed, setChanged] = useState(false);
    const perspectiveList = (perspectives ?? '').split('\n').filter(x => x).map(p => parseEmojiLabelString(p));

    function togglePerspective(text) {
        setCurrentSelected(currentSelected?.[text] ? removeKey(currentSelected, text) : addKey(currentSelected, text));
        setChanged(true);
    }

    function onApply() {
        datastore.setSessionData(['representingPerspectives', 'selected'], currentSelected);
        onClose();
    }

    return <View>
        <UtilityText label='Sort by perspective' type='tiny' caps />
        <Pad size={12}/>
        <UtilityText label='What perspectives do you want to see first?' type='small' color={colorTextSecondary} />
        <Pad size={12} />
        {perspectiveList.map(p => 
            <Checkbox emoji={p.emoji} text={p.text} key={p.text}
                value={currentSelected?.[p.text]}
                onChange={() => togglePerspective(p.text)} />
            )}
        <Pad />
        <CTAButton wide label='Apply' onPress={onApply} disabled={!changed} />
    </View>
}

export function PerspectiveFilter() {
    const {perspectiveShowFilter} = useConfig();
    const savedSelected = useSessionData(['representingPerspectives', 'selected']);
    const comments = useCollection('comment');
    const [hover, setHover] = useState(false);
    const count = Object.keys(savedSelected || {}).length || 0;

    if (!perspectiveShowFilter) {
        return null;
    }

    if (comments.length == 0) {
        return null;
    }

    function popupContent({onClose}) {
        return <PerspectiveFilterPopup savedSelected={savedSelected} onClose={onClose} />;
    }

    return <PopupPanel testID='Sort by perspective' popupContent={popupContent} hover={hover} setHover={setHover}>
        {/* <PadBox vert={8}> */}
            <HorizBox center>
                <UtilityText weight='medium' label='Sort by perspective' underline={hover} />
                {toBool(count) && <PadBox left={8}><CircleCount count={count} /></PadBox>}
            </HorizBox>
        {/* </PadBox> */}
    </PopupPanel>
}

// TODO: Use the new version in components
export function CircleCount({count}) {
    const s = CircleCountStyle;
    return <View style={s.circle}>
        <UtilityText weight='medium' text={String(count)} />
    </View>
}
const CircleCountStyle = StyleSheet.create({
    circle: {
        backgroundColor: colorGreyHover,
        borderRadius: 100,
        width: 20,
        height: 20,
        justifyContent: 'center',
        alignItems: 'center'
    }
});

function boostSelectedPerspectives({datastore, comments}) {
    const allSelected = datastore.getSessionData(['representingPerspectives', 'selected']);
    const selectedLabels = Object.keys(allSelected || {});

    const topComments = comments.filter(comment => selectedLabels.some(text => comment.perspectives?.[text]));
    const otherComments = comments.filter(comment => !selectedLabels.some(text => comment.perspectives?.[text]));

    return [...topComments, ...otherComments];
}