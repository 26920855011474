import { IconButton } from "component/button"
import { UtilityText } from "component/text"
import { useEffect, useRef, useState } from "react";
import { StyleSheet, View } from "react-native";
import { getPrettyTimestamp } from "../../system/videotime";

export function ClipPlayer({clip}) {
    const videoRef = useRef(null);

    const [paused, setPaused] = useState(true);
    const [muted, setMuted] = useState(false);
    const [endReached, setEndReached] = useState(false);
    const [time, setTime] = useState(0);

    function togglePlay() {
        if (videoRef.current.paused) {
            resumePlayback();
        } else {
            pausePlayback();
        }
    }

    function resumePlayback() {
        videoRef.current.play();
        setPaused(false);
    }

    function pausePlayback() {
        videoRef.current.pause();
        setPaused(true);
    }

    function restartClip() {
        videoRef.current.currentTime = clip.start;

        // We need a break here to wait for the time to be reset properly
        if (videoRef.current.paused) {
            setTimeout(() => {
                setEndReached(false);
                resumePlayback();
            }, 500);
        }
    }

    function toggleSound() {
        if (videoRef.current.muted) {
            videoRef.current.muted = false;
            setMuted(false);
        }
        else {
            videoRef.current.muted = true;
            setMuted(true);
        }
    }

    useEffect(() => {
        const handleTimeUpdate = (event) => {
            setTime(videoRef.current?.currentTime.toFixed(1));
        };
    
        if (videoRef.current) {
            videoRef.current.addEventListener('timeupdate', handleTimeUpdate);
        }
    
        return () => {
            if (videoRef.current) {
                videoRef.current.removeEventListener('timeupdate', handleTimeUpdate);
            }
        };
    }, [clip]);

    useEffect(() => {
        // We've reached the end of the clip and of the entire video
        const handleVideoEnded = (event) => {
            console.log("Reached end of video");
            setEndReached(true);
            pausePlayback();
        };

        if (videoRef.current) {
            videoRef.current.addEventListener('ended', handleVideoEnded);
        }

        return () => {
            if (videoRef.current) {
                videoRef.current.removeEventListener('ended', handleVideoEnded);
            }
        };
    }, [endReached]);

    function getCurrentClipTime() {
        if (!videoRef?.current) return 0;

        // We've reached the end of the clip (but not the end of the entire video)
        if (time >= clip.end && !videoRef.current.paused) {
            console.log("Reached end of clip");
            setEndReached(true);
            pausePlayback();
        }

        return time - clip.start || 0;
    }

    // For the timestamps, we need to check for null specifically because 0 is a valid timestamp
    if (!clip || !clip.source || clip.start === null || clip.start === undefined || clip.end === null || clip.end === undefined) {
        return <View style={ClipPlayerStyle.noPlayer}>
                <UtilityText label="This clip could not be loaded." color="white"/>
            </View>
    }
    else {
        return <View style={ClipPlayerStyle.playerContainer}>
            <video src={clip.url} ref={videoRef}></video>
            <View style={ClipPlayerStyle.playerControlsContainer}>
                <div style={ClipPlayerStyle.playerControlsLeft}>
                    {!endReached ?
                        <IconButton label={paused ? "▶️ Play" : "⏸️ Pause"} onPress={togglePlay} />
                        : <IconButton label="🔄 Restart" onPress={restartClip} />
                    }
                    <div style={{ width: "10px" }} />
                    <UtilityText label={getPrettyTimestamp(getCurrentClipTime()) + " | " + getPrettyTimestamp(clip.end - clip.start)} color="white" />
                </div>
                <div style={ClipPlayerStyle.playerControlsRight}>
                    <IconButton label={muted ? "🔈 Unmute" : "🔇 Mute"} onPress={toggleSound}/>
                </div>
            </View>
        </View>
    }
}

const ClipPlayerStyle = StyleSheet.create({
    playerContainer: {
        width: "543px"
    },
    playerControlsContainer: {
        width: "100%",
        position: "absolute",
        bottom: "10px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    playerControlsLeft: {
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: "10px"
    },
    playerControlsRight: {
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
        paddingRight: "10px"
    },
    noPlayer: {
        backgroundColor: "gray",
        height: "fit-content",
        width: "543px",
        alignContent: "center",
        flexWrap: "wrap",
        padding: "20px"
    }
})