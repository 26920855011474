import { AskMeAnythingLiveStatusWidget, AskMeAnythingStatusDescription } from "feature/cbcrc/topic/AskMeAnythingHeaderFeature";
import { PublicationStatusAdminScreen, TopicLiveStatusDescription, TopicLiveStatusWidget } from "feature/cbcrc/topic/LiveStatusFeature";
import { CLICK, INPUT } from "system/demo";

export const livestatusDemo = [
    { label: 'Live status', key: 'live-status', storySets: liveStatusStorySets },
];

function liveStatusStorySets() {
    return [
        {
            label: 'Ask us anything - Live status',
            globals: {
                endAt: '2030-01-01',
            },
            content: <AskMeAnythingLiveStatusWidget />,
        },
        {
            label: 'Ask us anything - Closed status',
            globals: {
                endAt: '2010-01-01',
            },
            content: <AskMeAnythingLiveStatusWidget />,
        },
        {
            label: 'Ask us anything - Admin role',
            roles: ['Owner'],
            stories: [
                {
                    label: 'Open admin panel',
                    actions: [
                        CLICK('Edit live status (admin)')
                    ]
                },
            ],
            globals: {
                endAt: '2030-01-01',
            },
            content: <AskMeAnythingLiveStatusWidget />,
        },
        {
            label: 'Ask us anything live description',
            content: <AskMeAnythingStatusDescription />,
        },
        {
            label: 'Topic of the moment - Live status',
            globals: {
                endAt: '2030-01-01',
            },
            content: <TopicLiveStatusWidget />,
        },
        {
            label: 'Topic of the moment - Closed status',
            globals: {
                endAt: '2010-01-01',
            },
            content: <TopicLiveStatusWidget />,
        },
        {
            label: 'Topic of the moment - Admin role',
            roles: ['Owner'],
            stories: [
                {
                    label: 'Open admin panel',
                    actions: [
                        CLICK('Edit live status (admin)')
                    ]
                },
            ],
            globals: {
                endAt: '2030-01-01',
            },
            content: <TopicLiveStatusWidget />,
        },
        {
            label: 'Topic of the moment description - Live',
            globals: {
                status: 'live',
            },
            content: <TopicLiveStatusDescription />,
        },
        {
            label: 'Topic of the moment description - Closed',
            content: <TopicLiveStatusDescription />,
        },
        {
            label: 'Live status admin screen - No access',
            content: <PublicationStatusAdminScreen />,
        },
        {
            label: 'Live status admin screen',
            roles: ['Owner'],
            stories: [
                {
                    label: 'Update live status',
                    actions: [
                        INPUT('startsAt', '2022-01-01'),
                        INPUT('endsAt', '2022-01-02'),
                        CLICK('Update')
                    ]
                }
            ],
            content: <PublicationStatusAdminScreen />,
        }
    ];
}