import { ModDashboardThreadCard, ModDashboardThreadDetails, ModDashboardThreadsOverview } from "../../../component/zdf/moddashboard/moddashboardthreads";
import { modDashboardDemoData } from "./moddashboard.data";
import { defaultPersonaList } from "../../../util/testpersonas";
import { Datastore } from "../../../util/datastore";
import { CLICK, DemoSection, POPUP } from "../../../system/demo";
import { EmbeddedInstance } from "../../../util/instance";
import { EnableModDashboardThreadDetailsFeature } from "../../../feature/zdf/question/EnableThreadModDashboardViewFeature";

const demoThread = modDashboardDemoData.modulePublicQuestion.thread1

    const author = defaultPersonaList[0]

    const modulePublic = {
        question: {
            ...modDashboardDemoData.modulePublicQuestion
        },
        moderation: {
            queue: {
                ...modDashboardDemoData.modTaskQueues.thread1
            },
            thread_queue_count: {
                ...modDashboardDemoData.threadQueueCounts
            }
        }
    }

    const embeddedInstanceData = {
        question: {
            [demoThread.key] : modDashboardDemoData.instanceData.thread1
        }
    }


export function ModDashboardThreadsDemo() {

    return <Datastore modulePublic={modulePublic} serverCall={modDashboardDemoData.serverCalls}>
        <DemoSection text={"Thread Card"}>
            <ModDashboardThreadCard
                thread={modDashboardDemoData.modulePublicQuestion.thread2}
                mainTag={"Active"}
                author={author}
            />
        </DemoSection>
        <DemoSection text={"Thread Card with secondary tags"}>
            <ModDashboardThreadCard
                thread={modDashboardDemoData.modulePublicQuestion.thread2}
                mainTag={"Active"}
                secondaryTags={["Conversation Helper", "Video Voting"]}
                author={author}
            />
        </DemoSection>
        <DemoSection text={"Thread Card of a heated thread"}>
            <ModDashboardThreadCard
                thread={modDashboardDemoData.modulePublicQuestion.thread1}
                mainTag={"Active"}
                secondaryTags={["Conversation Helper", "Video Voting"]}
                author={author}
            />
        </DemoSection>
    </Datastore>
}

export function ModDashboardThreadsStorySets() {

    return [
        {
            label: "Thread Details",
            serverCall: modDashboardDemoData.serverCalls,
            modulePublic: modulePublic,
            embeddedInstanceData: embeddedInstanceData,
            roles: ["Moderator"],
            content: (
                <EmbeddedInstance
                    structureKey={"question"}
                    instanceKey={demoThread.key}
                    screenKey={"moderationDetails"}
                    features={{ [EnableModDashboardThreadDetailsFeature.key]: true }}
                >
                    <ModDashboardThreadDetails />
                </EmbeddedInstance>
            ),
            stories: [
                {
                    label: "Open linked stories",
                    actions: [
                        CLICK("{numArticles} linked stories"),
                        POPUP(CLICK("close-modal")),
                    ],
                },
            ],
        },
        {
            label: "Thread Overview",
            serverCall: modDashboardDemoData.serverCalls,
            modulePublic: modulePublic,
            content: <ModDashboardThreadsOverview />,
            stories: [
                {
                    label: "Show only open threads",
                    actions: [CLICK("Open Threads")],
                },
                {
                    label: "Show only closed threads",
                    actions: [CLICK("Closed Threads")],
                },
                {
                    label: "Show only heated threads",
                    actions: [CLICK("Heated Threads")],
                },
                {
                    label: "Show only threads with flagged comments",
                    actions: [CLICK("Threads with flagged comments")],
                },
            ],
        },
    ];
}