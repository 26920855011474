import { useEffect, useState } from "react";

var global_videoTime = null;
var global_videoUrl = null;
var global_videoDuration = null;
var global_pauseState = null;

export function useVideoTime() {
    const [time, setTime] = useState(0);
    useEffect(() => {
        if (!global_videoUrl) {
            window.parent.postMessage({type: 'psi-get-video'}, '*');
        }
    }, [global_videoUrl]);
    useEffect(() => {
        const callback = window.addEventListener('message', (event) => {
            if (event.data.type === 'psi-video-timeupdate') {
                setTime(event.data.time.toFixed(1));
                global_videoUrl = event.data.url;
            }
        });
        return () => {
            window.removeEventListener('message', callback);
        }
    }, []);
    return time || global_videoTime || 0;
}

/**
 * @typedef {Object} VideoPlayerState
 * @property {boolean} paused - Indicates if the video is currently paused
 * @property {number} currentTime - The current playback time of the video in seconds.
 * @property {number} duration - The total duration of the video in seconds.
 * @property {boolean} ended - Whether the video has ended
 * @property {string} src - The URL of the video.
 * @property {boolean} ready - The video has loaded and is ready to play.
 * @property {Function} play - Function to start the video playback.
 * @property {Function} pause - Function to pause the video playback.
 * @property {Function} seek - Function to change the current time of the video.
 */

/** @returns {VideoPlayerState} */
export function usePlayedVideo() {
    const [src, setSrc] = useState(null);
    const [time, setTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [paused, setPaused] = useState(true);
    const [ended, setEnded] = useState(false);
    const [ready, setReady] = useState(false);

    useEffect(() => {
        const callback = window.addEventListener('message', (event) => {
            if (event.data.type === 'psi-video-state-update') {
                setSrc(event.data.src);
                setTime(event.data.currentTime?.toFixed(1));
                setPaused(event.data.paused)
                setReady(event.data.ready)

                if (event.data.duration !== null && event.data.duration !== undefined && !isNaN(event.data.duration)) {
                    setDuration(event.data.duration);
                }
            }

            if (event.data.type === 'psi-video-ended') {
                setEnded(true);
            }
        });

        window.parent.postMessage({type: 'psi-video-get-state'}, '*');

        return () => {
            window.removeEventListener('message', callback);
        }

    }, []);
    return {
        currentTime: time,
        url: src,
        duration: duration,
        paused: paused,
        ended: ended,
        ready: ready,
        play: playVideo,
        pause: pauseVideo,
        seek: setVideoTime
    }
}

export function getVideoTime() {
    return global_videoTime;
}   

export function getVideoUrl() {
    return global_videoUrl;
}

export function getVideoDuration() {
    return global_videoDuration;
}

export function setVideoTime(videoTime) {
    window.parent.postMessage({ type: 'psi-video-seek', time: videoTime }, '*');
}

function pauseVideo() {
    window.parent.postMessage({ type: 'psi-video-pause' }, '*');
}

function playVideo() {
    window.parent.postMessage({ type: 'psi-video-play' }, '*');
}

/** Returns a nicely formatted version of the given video time, e.g. 66.5 turns into 00:01:06 */
export function getPrettyTimestamp(videoTime, omitHours = false) {
    if (videoTime === null || videoTime === undefined) {
        return undefined;
    }

    const hours = Math.trunc(videoTime / 60 / 60).toString().padStart(2, "0");
    const minutes = Math.trunc(videoTime / 60).toString().padStart(2, "0");
    const seconds = Math.trunc(videoTime % 60).toString().padStart(2, "0");
    
    const prettyTimestamp = (omitHours && hours === "00" ? "" : (hours + ":")) + minutes + ":" + seconds;
    return prettyTimestamp;
}

/** Converts a "pretty" timestamp back into seconds, e.g. 00:01:06 turns into 66 */
export function getSecondsFromPrettyTimestamp(timestamp) {
    const timestampFragments = timestamp.split(":");
    let seconds = 0;

    if (timestampFragments.length === 1) {
        seconds = parseInt(timestampFragments[0]);
    } else if (timestampFragments.length === 2) {
        seconds = parseInt(timestampFragments[0]) * 60 + parseInt(timestampFragments[1]);
    } else if (timestampFragments.length === 3) {
        seconds = parseInt(timestampFragments[0]) * 60 * 60 + parseInt(timestampFragments[1]) * 60 + parseInt(timestampFragments[2]);
    }

    return seconds;
}

/** Checks if the given string is formatted like a valid timestamp. Valid formats include `hh:mm:ss`, `mm:ss`, `ss`, and single-digit variations. */
export function validateTimestamp(timestamp) {
    const timestampRegex = /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/g;

    const valid = (timestamp.match(timestampRegex) !== null);
    return valid;
}
