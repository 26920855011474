
export const PostAnywayFilterFeature = {
    name: 'Post Anyway Filter',
    key: 'post_anyway_filter',
    dev: true,
    config: {
        modFilters: [
            {
                label: 'Posted Anyway',
                filter: item => item?.object?.minorViolation,
            }
        ]
    }
}

