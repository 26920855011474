import { View } from "react-native-web";
import { CTAButton, IconButton } from "../../button";
import { Close, Edit, Information, TrashCan } from "@carbon/icons-react";
import { Modal } from "../../modal";
import { Heading, TextField, UtilityText } from "../../text";
import { HorizBox, Pad } from "../../basics";
import { colorAmbigousBlack, colorAmbiguousWhite, colorBorderPrimary, colorTextSecondary, colorTextWarning } from "../../color";
import { useDatastore } from "../../../util/datastore";
import { callServerApiAsync } from "../../../system/servercall";
import { useState } from "react";
import { Checkbox } from "../../form";
import { DocumentLevelTooltip } from "../tooltip";
import { StyleSheet } from "react-native";
import { BLACKLIST_CHARACTER_LIMIT } from "./blacklist";

const BlacklistModalStyle = StyleSheet.create({
    modalPadding: {
        paddingTop: 40,
        paddingHorizontal: 20,
    },
    modalContent: {
        display: "flex",
        gap: 10,
        width: "100%",
    },
    settingWithTooltip: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    settingItem: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: 8,
    },
});

export function BlacklistAddTermModal({ onClose, onSave, terms = [] }) {
    const datastore = useDatastore();
    const [blacklistTermSettings, setBlacklistTermSettings] = useState({ hasAlternativeSpellings: false, isSingularTerm: true });
    const s = BlacklistModalStyle;

    async function onClickSave() {
        let blacklistEntries = [];
        terms.map((term) => {
            blacklistEntries = [
                ...blacklistEntries,
                {
                    term: term,
                    hasAlternativeSpellings: blacklistTermSettings.hasAlternativeSpellings,
                    isSingularTerm: blacklistTermSettings.isSingularTerm,
                },
            ];
        });

        await datastore.callServerAsync("blacklist", "addToBlacklist", { blacklistEntries: blacklistEntries });

        onSave();
        onClose();
    }

    return (
        <Modal onClose={onClose} buttonRow={<CTAButton label={"Save"} onPress={() => onClickSave()} wide />}>
            <View style={[s.modalContent, s.modalPadding]}>
                <Heading label={"Settings for the term"} type="large" weight="medium" />
                <UtilityText
                    label={
                        "Select whether terms are to be adopted exactly as your input is, or whether they are to be included in expanded form."
                    }
                    color={colorTextSecondary}
                    type="large"
                />
                <View>
                    <View style={s.settingWithTooltip}>
                        <View style={s.settingItem}>
                            <Checkbox
                                value={blacklistTermSettings.isSingularTerm}
                                onChange={(v) => setBlacklistTermSettings({ ...blacklistTermSettings, isSingularTerm: v })}
                            />
                            <UtilityText label={"Only singular term"} type="large" />
                        </View>
                        <DocumentLevelTooltip tooltipWidget={<BlacklistSingularTermTooltipWidget />}>
                            <Information />
                        </DocumentLevelTooltip>
                    </View>
                    <View style={s.settingWithTooltip}>
                        <View style={s.settingItem}>
                            <Checkbox
                                value={blacklistTermSettings.hasAlternativeSpellings}
                                onChange={(v) => setBlacklistTermSettings({ ...blacklistTermSettings, hasAlternativeSpellings: v })}
                            />
                            <UtilityText label={"Include alternative spellings"} type="large" />
                        </View>
                        <DocumentLevelTooltip tooltipWidget={<BlacklistAlternativeSpellingTooltipWidget />}>
                            <Information />
                        </DocumentLevelTooltip>
                    </View>
                </View>
            </View>
        </Modal>
    );
}

export function BlacklistEditModal({ onClose, term, hasAlternativeSpellings, isSingularTerm }) {
    const datastore = useDatastore();
    const [blacklistEntry, setBlacklistEntry] = useState({
        term: term,
        hasAlternativeSpellings: hasAlternativeSpellings,
        isSingularTerm: isSingularTerm,
    });
    const [errorMessage, setErrorMessage] = useState("");

    const s = BlacklistModalStyle;

    function onChange(newObject) {
        setBlacklistEntry(newObject);
        setErrorMessage("");
    }

    async function onSave() {
        const newTerm = blacklistEntry.term.trim();

        if (newTerm.length > BLACKLIST_CHARACTER_LIMIT) {
            setErrorMessage("Terms may not be longer than " + BLACKLIST_CHARACTER_LIMIT + " characters");
            return;
        }

        if (newTerm.length > 0) {
            const isTermInBlacklist = await datastore.callServerAsync("blacklist", "findTermInBlacklist",{
                term: newTerm,
            });
            
            // The term itself hasn't changed but additional settings might have or it is a new term that is not yet covered by the blacklist
            if (newTerm === term || !isTermInBlacklist) {
                await callServerApiAsync({
                    datastore,
                    component: "blacklist",
                    funcname: "removeFromBlacklist",
                    params: { terms: [term] },
                });

                await callServerApiAsync({
                    datastore,
                    component: "blacklist",
                    funcname: "addToBlacklist",
                    params: { blacklistEntries: [blacklistEntry] },
                });

                onClose();
            } else {
                setErrorMessage("This term is already in the list");
            }
        } else {
            setErrorMessage("A term must be entered");
        }
    }

    return (
        <Modal onClose={onClose} buttonRow={<CTAButton label={"Save"} wide onPress={() => onSave()} />}>
            <View style={[s.modalContent, s.modalPadding]}>
                <Heading label={"Edit Term"} type="large" weight="medium" />
                <TextField
                    value={blacklistEntry.term}
                    onChange={(newTerm) => onChange({ ...blacklistEntry, term: newTerm })}
                    error={errorMessage !== ""}
                />
                {errorMessage !== "" ? <UtilityText label={errorMessage} color={colorTextWarning} /> : null}
                <View>
                    <View style={s.settingWithTooltip}>
                        <View style={s.settingItem}>
                            <Checkbox
                                value={blacklistEntry.isSingularTerm}
                                onChange={(newIsSingularTerm) =>
                                    onChange({ ...blacklistEntry, isSingularTerm: newIsSingularTerm })
                                }
                            />
                            <UtilityText label={"Only singular term"} />
                        </View>
                        <View>
                            <DocumentLevelTooltip tooltipWidget={<BlacklistSingularTermTooltipWidget />}>
                                <Information />
                            </DocumentLevelTooltip>
                        </View>
                    </View>
                    <View style={s.settingWithTooltip}>
                        <View style={s.settingItem}>
                            <Checkbox
                                value={blacklistEntry.hasAlternativeSpellings}
                                onChange={(newHasAlternativeSpellings) =>
                                    onChange({
                                        ...blacklistEntry,
                                        hasAlternativeSpellings: newHasAlternativeSpellings,
                                    })
                                }
                            />
                            <UtilityText label={"Include alternative spellings"} />
                        </View>
                        <DocumentLevelTooltip tooltipWidget={<BlacklistAlternativeSpellingTooltipWidget />}>
                            <Information />
                        </DocumentLevelTooltip>
                    </View>
                </View>
            </View>
        </Modal>
    );
}

export function BlacklistDeleteModal({ onClose, termsToDelete, onTermsDeleted }) {
    const datastore = useDatastore();

    const s = BlacklistModalStyle;

    async function onDelete() {
        await callServerApiAsync({
            datastore,
            component: "blacklist",
            funcname: "removeFromBlacklist",
            params: { terms: termsToDelete },
        });

        if (onTermsDeleted) {
            onTermsDeleted();
        }

        onClose();
    }

    return (
        <Modal
            onClose={onClose}
            testID="delete-confirmation-modal" 
            buttonRow={
                <CTAButton
                    onPress={() => onDelete()}
                    type="delete"
                    wide
                    label={"Delete"}
                    icon={<TrashCan color={colorTextWarning} />}
                />
            }
        >
            <View style={[s.modalContent, s.modalPadding]}>
                <Heading label={"Delete Terms"} type="large" />
                <UtilityText
                    label={"Should these terms really be deleted? You can add them again later."}
                    color={colorTextSecondary}
                    type="large"
                />
            </View>
        </Modal>
    );
}

export function BlacklistHelpModal({ onClose }) {
    const s = BlacklistModalStyle;

    return (
        <Modal
            onClose={onClose}
            buttonRow={<CTAButton onPress={() => onClose()} type="primary" wide label={"All right"} />}
        >
            <View style={s.modalPadding}>
                <Heading label={"Info"} type="large" />
                <Pad size={8} />
                <UtilityText
                    label={
                        "The blocklist allows you to define terms that are automatically prevented from being published in community posts. You can add terms to this pre-moderation yourself. Add all terms that should not be published without human review to the list."
                    }
                    color={colorTextSecondary}
                    type="large"
                />
                <Pad size={16} />
                <UtilityText label={"Singular term:"} weight="strong" color={colorAmbigousBlack} type="large" />
                <UtilityText
                    label={
                        "You can decide whether only the singular term should be considered or also terms that contain the entered term."
                    }
                    color={colorTextSecondary}
                    type="large"
                />
                <Pad size={16} />
                <UtilityText
                    label={
                        "Example: “person” (singular term) → “persons”. “persons” would also be taken into account if the “singular term” checkbox is not activated for “person”. This also applies to the string “personality”, for example."
                    }
                    color={colorTextSecondary}
                    type="large"
                />
                <Pad size={16} />
                <UtilityText label={"Alternative spellings:"} weight="strong" color={colorAmbigousBlack} type="large" />
                <UtilityText
                    label={
                        "You can decide whether alternative spellings are taken into account. The following spellings, numbers and special characters are also taken into account:"
                    }
                    color={colorTextSecondary}
                    type="large"
                />
                <Pad size={8} />
                <UtilityText
                    label={
                        "A → a  | a → A | a → @ | a → 4 | i → 1 | e → 3 | o → 0 | s → $ | s → 5 | t → 7 | b → 8 | g → 9"
                    }
                    color={colorTextSecondary}
                    type="large"
                />
                <Pad size={8} />
                <UtilityText label={"Upper and lower case are taken into account"} type="small" color={colorTextSecondary} />
            </View>
        </Modal>
    );
}

const BlacklistMultiSelectionDialogStyle = StyleSheet.create({
    outer: {
        width: 490,
        height: 56,
        borderRadius: 8,
        borderWidth: 1,
        paddingHorizontal: 16,
        backgroundColor: colorAmbiguousWhite,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        zIndex: 1000,
        boxShadow: `0px 4px 20px ${colorBorderPrimary}`
    },
});

export function BlacklistMultiSelectionDialog({ selectedTerms, deselectTerms }) {
    const s = BlacklistMultiSelectionDialogStyle;
    
    const [shouldShowSelectionModal, setShouldShowSelectionModal] = useState(true);
    const [shouldShowDeleteModal, setShouldShowDeleteModal] = useState(false);

    function handleClearSelection() {
        setShouldShowSelectionModal(false);
        deselectTerms({ termsToDeselect: selectedTerms });
    }

    return (
        shouldShowSelectionModal && (
            <View style={s.outer}>
                <View>
                    <CTAButton
                        size="compact"
                        icon={<Close />}
                        type="secondary"
                        onPress={() => handleClearSelection()}
                    />
                </View>
                <HorizBox center gap={8}>
                    <UtilityText
                        formatParams={{ keyCount: selectedTerms.length }}
                        type="large"
                        label={"{keyCount} selected items"}
                    />
                    <CTAButton
                        type="delete"
                        label={"Delete"}
                        icon={<TrashCan color={colorTextWarning} />}
                        onPress={() => setShouldShowDeleteModal(true)}
                    />
                </HorizBox>
                {shouldShowDeleteModal && (
                    <BlacklistDeleteModal
                        onTermsDeleted={() => handleClearSelection()}
                        termsToDelete={selectedTerms}
                        onClose={() => setShouldShowDeleteModal(false)}
                    />
                )}
            </View>
        )
    );
}

export function BlacklistItemEditDeleteMenu({ onEditPressed, onDeletePressed }) {
    return (
        <View>
            <IconButton icon={Edit} label={"Edit"} onPress={() => onEditPressed()} />
            <IconButton icon={TrashCan} label={"Delete"} onPress={() => onDeletePressed()} />
        </View>
    );
}

const BlacklistTooltipWidgetStyle = StyleSheet.create({
    tooltip: {
        boxShadow: "0px 2px 10px 0px #0000001A",
        width: 350,
        padding: 20,
        backgroundColor: colorAmbiguousWhite,
        borderRadius: 8,
        zIndex: 2000,
        display: "flex",
        justifyContent: "space-evenly",
    },
});

function BlacklistSingularTermTooltipWidget() {
    const s = BlacklistTooltipWidgetStyle;
    return (
        <View style={s.tooltip}>
            <UtilityText
                label={
                    "You can decide whether only the singular term should be considered or also terms that contain the entered term."
                }
                type="large"
            />
            <Pad size={16} />
            <UtilityText label={"Example:"} type="large" weight="strong" />
            <UtilityText
                label={
                    "“person” (singular term) → “persons”. “persons” would also be taken into account if the “singular term” checkbox is not activated for “person”. However, this also applies to the string “personality”, for example."
                }
                type="large"
            />
        </View>
    );
}

function BlacklistAlternativeSpellingTooltipWidget() {
    const s = BlacklistTooltipWidgetStyle;
    return (
        <View style={s.tooltip}>
            <UtilityText
                label={
                    "You can decide whether alternative spellings are taken into account. The following spellings, numbers and special characters are also taken into account:"
                }
                type="large"
            />
            <Pad size={16} />
            <UtilityText
                label={"A → a | a → A | a → @ | a → 4 | i → 1 |e → 3 \n|o → 0 | s → $ | s → 5 | t → 7 | b → 8 | g → 9"}
                type="large"
            />
            <Pad size={8} />
            <UtilityText label={"Upper and lower case are taken into account"} color={colorTextSecondary} type="small" />
        </View>
    );
}
