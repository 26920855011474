import { useEffect, useMemo, useState } from 'react';
import { ConversationScreen, HorizBox, Pad, PadBox } from "component/basics";
import { BreadCrumb, CTAButton, TextButton } from "component/button";
import { DataVizText, Heading, Paragraph, UtilityText } from "component/text";
import { ConfigContext, useCollection, useDatastore, useGlobalProperty, useInstanceKey, useModuleUserGlobalData, usePersonaKey } from 'util/datastore';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { Banner, ClickableBanner } from 'component/banner';
import { useVideo, VideoPlayerStateContextProvider } from '../videoPlayerStateContext';
import { ForceVideoPauseComponent } from '../videoPlayer';
import { OverlayScreen } from '../overlay';
import { setVideoVotingSessionData, updateVideoVotingSessionData, useVideoVotingSessionData, VIDEO_VOTING_SESSION_STATES } from '../videovoting/videoVotingSession';
import { VideoVotingCountdownElement } from '../countdown';
import { QuestionResultConnected } from '../../../feature/zdf/videovoting/VideoVotingOverviewFeature';
import { VideoVotingOption } from '../videovoting/videoVotingQuestionElements';
import { logEventAsync } from 'util/eventlog';
import { generateRandomKey } from 'util/util';
import { Toggle } from 'component/form';
import { RichText } from 'component/richtext';
import { Close, SkipForward } from '@carbon/icons-react';
import { VideoVotingEditor } from '../../../component/zdf/videovoting/videovotingEditor';
import { useCurrentQuestion, useVideoVoting, useVideoVotingTemplate } from '../../../component/zdf/videovoting/videovoting.util';
import { QuestionVideoOverlayPromo, teaserPalette } from '../../../feature/teaser/EditorialQuestionFeature';
import { EstimationSlider } from '../videovoting/estimationSlider';
import { useConfig } from '../../../util/features';
import { colorAmbiguousWhite } from '../../../component/color';

/*
Figma: https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=20-5&t=5DeU2AZDvMXzjf7G-0
*/

export const VideoVotingStructure = {
    key: 'videovoting',
    name: 'Video Voting',
    screen: VideoVotingSidebarScreen,
    overlayScreen: VideoVotingTeaser,
    subscreens: {
        edit: EditVideoVotingScreen,
    },
    defaultConfig: {
    }
}

function VideoVotingSidebarScreen() {
    return <UtilityText text='No video voting main screen defined.' />
}

export function VideoVotingTeaser() {
    return <View>
        <VideoPlayerStateContextProvider useOriginalVideo={true}>
            <VideoVoting repositionIframe={true}></VideoVoting>
        </VideoPlayerStateContextProvider>
    </View>
}

export function VideoVoting({repositionIframe, votingKey}) {
    // Set this to true: the original video will be used. Voting elements are shown in the teaser
    // Set this to false: a mirrored video is used with voting elements on top and the original video will be hidden
    const useOriginalVideo = true;
    const instanceKey = useInstanceKey();

    const currentSession = useVideoVotingSessionData();

    return (<>
        <VideoVotingInitializer votingKey={votingKey ?? instanceKey}></VideoVotingInitializer>
        {currentSession && <>
            <VideoVotingSessionController></VideoVotingSessionController>
            <VideoVotingScreen></VideoVotingScreen>
        </>}
    </>
    );
}


function VideoVotingScreen() {
    const currentSession = useVideoVotingSessionData();
    const videoVotingTemplate = useVideoVotingTemplate(currentSession.votingKey);
    const datastore = useDatastore();
    const currentQuestion = useCurrentQuestion();
    const { play } = useVideo()

    const onCloseQuestionDialog = () => {
        const question = getQuestionFromTemplate(videoVotingTemplate, currentQuestion.key)
        const questionSessionState = currentSession.questions[question.key];
        const updatedQuestions = {...currentSession.questions}
        updatedQuestions[question.key] = {...questionSessionState, answered: true}

        if(question.type === "connected" && question.final) {
            updateVideoVotingSessionData(datastore, {
                state: VIDEO_VOTING_SESSION_STATES.showQuestionResults,
                questions: updatedQuestions
            })
            return
        }

        updateVideoVotingSessionData(datastore, {
            state: VIDEO_VOTING_SESSION_STATES.waitForQuestion,
            questions: updatedQuestions
        })

        play()
    }

    const onCloseQuestionResultsDialog = () => {
        updateVideoVotingSessionData(datastore, {
            state: VIDEO_VOTING_SESSION_STATES.waitForQuestion,
        })
    }

    if(!videoVotingTemplate || !currentSession) {
        return <Banner>
            <Heading label={"The video voting template is missing"}></Heading>
        </Banner>
    }

    if(currentSession.state == VIDEO_VOTING_SESSION_STATES.initVideo) {
        return <VotingWindowPositioner visible={false} pointerEvents={false}><UtilityText text="Waiting for video to load!"></UtilityText></VotingWindowPositioner>
        
    }

    if(currentSession.state == VIDEO_VOTING_SESSION_STATES.initSession){
        return <VideoVotingIntroScreen></VideoVotingIntroScreen>
    }

    if(currentSession.state == VIDEO_VOTING_SESSION_STATES.showQuestionResults){
        const question = getQuestionFromTemplate(videoVotingTemplate, currentQuestion.key)
        return <VideoVotingQuestionResultDialog question={question} onFinishQuestionResults={onCloseQuestionResultsDialog}></VideoVotingQuestionResultDialog>
    }
    if(currentSession.state == VIDEO_VOTING_SESSION_STATES.waitForQuestion && currentQuestion && !currentSession?.questions?.[currentQuestion?.key]?.answered){
        const question = getQuestionFromTemplate(videoVotingTemplate, currentQuestion.key);

        return (
            <VideoVotingQuestionDialog
                key={question.key}
                question={question}
                onFinishQuestionVoting={onCloseQuestionDialog}
                headerText={question.subQuestionIndex > 0 ? "What do you think about it now?" : null}
            />
        );
    }

    if((currentSession.state == VIDEO_VOTING_SESSION_STATES.waitForQuestion || currentSession.state == VIDEO_VOTING_SESSION_STATES.endScreen) && !currentSession.teaserDismissed){
        return <VideoVotingEndScreen></VideoVotingEndScreen>
    }
    return <VotingWindowPositioner visible={false} pointerEvents={false}><UtilityText text="No question overlay shown"></UtilityText></VotingWindowPositioner>
}
const notLoggedInText = "You have to be logged in to use this feature.\nThe video will play as usual and you won't see any votings.\n**Click here to log in**.";

const introText = 'This video contains audience questions that you can participate in. You can vote anonymously, skip individual questions, or disable this feature. In the '
function VideoVotingIntroScreen() {

    const datastore = useDatastore();
    const personaKey = usePersonaKey();
    const session = useVideoVotingSessionData()
    const questionThreadKey = useGlobalProperty("questionThreadKey");
    const video = useVideo();

    const startSession = () => {
        logEventAsync(datastore, 'videovoting-start-video', {
            votingDisabled: votingDisabledLocally.toString(),
            anonymous: anonymityVoting.toString(),
            videoVotingSessionId: session.id
        });

        if (!votingDisabledLocally && personaKey) {
            updateVideoVotingSessionData(datastore, { state: VIDEO_VOTING_SESSION_STATES.waitForQuestion, personaKey: personaKey});
            video.play()

        }
        else {
            updateVideoVotingSessionData(datastore, { state: VIDEO_VOTING_SESSION_STATES.skippedVoting });
            video.play()
        }
    }

    const [anonymityVoting, setAnonymityVoting] = useState(false)
    const setAnonymity = () => {
        const newAnonymityVoting = !anonymityVoting;

        setAnonymityVoting(newAnonymityVoting);
        updateVideoVotingSessionData(datastore, { anonymous: newAnonymityVoting });

        logEventAsync(datastore, 'videovoting-change-anonymity', {
            anonymous: newAnonymityVoting.toString(),
            videoVotingSessionId: session.id
        });
    }

    const [votingDisabledLocally, setVotingDisabledLocally] = useState(false);
    const toggleVideoVoting = () => {
        const newVotingDisabledLocally = !votingDisabledLocally;

        setVotingDisabledLocally(newVotingDisabledLocally);

        logEventAsync(datastore, 'videovoting-change-local-setting', {
            votingDisabledLocally: newVotingDisabledLocally.toString(),
            videoVotingSessionId: session.id
        });
    }

    const gotoUserSettings = () => {
        datastore.gotoInstance({structureKey: "account", instanceKey: personaKey})
        logEventAsync(datastore, 'videovoting-navigate-to-user-settings', {
            from: "intro-screen",
            videoVotingSessionId: session.id
        })
    }

    useEffect(()=>{
        video.pause()
    }, [])

    const s = IntroScreenStyle;

    return <VotingWindowPositioner dimension={{width: '70%'}}>
        <View style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
        <View style={{backgroundColor: "white", pointerEvents:"auto"}}>
            <PadBox horiz={32} vert={32}>
                <Heading label={"Video Voting"}></Heading>
                <Pad size={24}></Pad>
                <Text>      
                <Paragraph label={introText}></Paragraph>
                <Pressable testID='videoVotingSettings' onPress={gotoUserSettings}>
                    <Paragraph strong label={"settings"}></Paragraph>
                </Pressable>
                <Paragraph label={' you can deactivate it for all videos.'}></Paragraph>
                </Text>

                <Pad size={24}></Pad>
                
                {personaKey ? <div style={s.optionsContainer}>
                    <Toggle spread label={"Enable voting"} value={!votingDisabledLocally} onChange={toggleVideoVoting}/>

                    <View style={{
                        opacity: !votingDisabledLocally ? 1 : 0,
                        cursor: !votingDisabledLocally ? "auto" : "default"
                        }}>
                        <Pad size={10}/>
                            <Toggle spread label={"Vote anonymously"} value={anonymityVoting} onChange={setAnonymity}/>
                    </View>
                </div> :
                <View>
                    <ClickableBanner testID={'videoVotingLogin'} onPress={()=>{datastore.gotoInstance({structureKey: "login", instanceKey: "login" })}}>
                        <RichText label={notLoggedInText}/>
                    </ClickableBanner>
                </View>}

                <Pad size={40}></Pad>
                <CTAButton label={"Start Video"} onPress={startSession}></CTAButton>  
            </PadBox>  
        </View>
    </View>
    </VotingWindowPositioner>
}

const IntroScreenStyle = StyleSheet.create({
    optionsContainer: {
        width: "fit-content"
    }
});

export function VideoVotingQuestionDialog({question, onFinishQuestionVoting, headerText = null, timer = 60}) {

    const datastore = useDatastore();
    const personaKey = usePersonaKey();
    const session = useVideoVotingSessionData();
    const videoVoting = useVideoVoting()
    const { pause } = useVideo();

    // Keeps track of whether the user interacts with the voting options while this dialog is visible. Voting or revoking a vote both count as interaction. Users can revoke their votes by clicking the option they voted for a second time.
    const [userInteractedWithOptions, setUserInteractedWithOptions] = useState(false);

    // Get all votes for this question, then filter out revoked votes.
    // TODO: We should probably implement a way to delete revoked votes so they don't stay in the database.
    let allVotes = useCollection("videoVote");
    allVotes = allVotes
        .filter((vote) => vote.questionKey === question.key)
        // vote.value = 0 is a valid value so we need a type-sensitive null check
        .filter((vote) => vote.optionKey || (vote.value !== undefined && vote.value !== null && !isNaN(vote.value)));

    const ownVote = allVotes.find((vote) => (vote.from === personaKey && vote.questionKey == question.key));

    const openDiscussion = () => {
        try {
            logEventAsync(datastore, 'videovoting-navigate-to-discussion', {
                from: "question-dialog",
                questionKey: question.key,
                questionText: question.text,
                videoVotingSessionId: session.id
            });
    
            datastore.gotoInstance({ structureKey: videoVoting.linkedThread.structureKey, instanceKey: videoVoting.linkedThread.instanceKey });
        } catch (error) {
            console.log("Failed to open discussion.");
        }
    }

    const onPressContinueButton = () => {
        try {
            logEventAsync(datastore, "videovoting-skip-remaining-time", {
                questionKey: question.key,
                questionText: question.text,
                interactedWithOptions: userInteractedWithOptions.toString(),
                ownVoteExists: (ownVote !== null && ownVote !== undefined).toString(),
                videoVotingSessionId: session.id,
            });
            onFinishQuestionVoting(question);
        } catch (error) {
            console.log("Failed to skip remaining time.");
        }
    }

    const onTimeUp = () => {
        try {
            logEventAsync(datastore, "videovoting-time-up", {
                questionKey: question.key,
                questionText: question.text,
                interactedWithOptions: userInteractedWithOptions.toString(),
                ownVoteExists: (ownVote !== null && ownVote !== undefined).toString(),
                videoVotingSessionId: session.id,
            });
            onFinishQuestionVoting(question);
        } catch (error) {
            console.log("Failed to continue after time was up.");
        }
    }

    // If the question has stopsVideo property pause the current video playback
    useEffect(()=>{
        if(question.stopsVideo) {
            pause()
        }
    }, [])

    return <VotingWindowPositioner position={question.position} dimension={{width: "70%"}}>
        <SkippableDiscussContainer
            skipAction={onPressContinueButton}
            timeUpAction={onTimeUp}
            openDiscussion={openDiscussion}
            timer={timer}
        >
            <View style={{ gap: 8 }}>
                {headerText && <DataVizText type={"heading2"} label={headerText} />}
                <Heading text={question.text} />
            </View>
            <Pad size={24} />

            {question.type !== "slider" && (
                <VideoVotingPollQuestion
                    question={question}
                    allVotes={allVotes}
                    ownVote={ownVote}
                    userInteractedWithOptions={userInteractedWithOptions}
                    setUserInteractedWithOptions={setUserInteractedWithOptions}
                />
            )}

            {question.type === "slider" && (
                <VideoVotingSliderQuestion
                    question={question}
                    ownVote={ownVote}
                    setUserInteractedWithOptions={setUserInteractedWithOptions}
                />
            )}
        </SkippableDiscussContainer>
        </VotingWindowPositioner>;
}

function VideoVotingPollQuestion({
    question,
    allVotes,
    ownVote,
    userInteractedWithOptions,
    setUserInteractedWithOptions,
}) {
    const datastore = useDatastore();
    const session = useVideoVotingSessionData();

    const voteForOption = async (option) => {
        setUserInteractedWithOptions(true);

        if (!ownVote) {
            datastore.addObject("videoVote", {
                optionKey: option.key,
                questionKey: question.key,
                votingKey: session.votingKey,
                anonymous: session?.anonymous ? session.anonymous : false,
            });

            logEventAsync(datastore, "videovoting-vote", {
                questionKey: question.key,
                questionText: question.text,
                optionKey: option.key,
                optionText: option.text,
                anonymous: session?.anonymous?.toString(),
                videoVotingSessionId: session.id,
            });
        } else {
            datastore.updateObject("videoVote", ownVote.key, {
                optionKey: option.key === ownVote.optionKey ? null : option.key,
                questionKey: question.key,
                anonymous: session.anonymous ? session.anonymous : false,
            });

            // User has clicked the same option again, thus revoking their vote
            if (option.key === ownVote.optionKey) {
                logEventAsync(datastore, "videovoting-revoke-vote", {
                    questionKey: question.key,
                    questionText: question.text,
                    optionKey: option.key,
                    optionText: option.text,
                    anonymous: session.anonymous.toString(),
                    videoVotingSessionId: session.id,
                });
            }
            // User casts a valid vote
            else {
                logEventAsync(datastore, "videovoting-vote", {
                    questionKey: question.key,
                    questionText: question.text,
                    optionKey: option.key,
                    optionText: option.text,
                    anonymous: session.anonymous.toString(),
                    videoVotingSessionId: session.id,
                });
            }
        }
    };

    return question.options.map((item, index) => {
        const votesOfOption = allVotes.filter((v) => v.optionKey === item.key);
        return (
            <VideoVotingOption
                key={"videovotingoption-" + index}
                votingEnabled
                optionText={item.text}
                selected={ownVote?.optionKey === item.key}
                showResults={userInteractedWithOptions || (ownVote !== null && ownVote !== undefined)}
                voterKeys={votesOfOption.map((v) => (v.anonymous ? null : v.from))}
                voteCount={votesOfOption.length}
                allVoteCount={allVotes.length}
                onSelect={() => voteForOption(item)}
            />
        );
    });
}

function VideoVotingSliderQuestion({ question, ownVote, setUserInteractedWithOptions }) {
    const datastore = useDatastore();
    const session = useVideoVotingSessionData();

    /** @param {number} value */
    const voteForSliderValue = async (value) => {
        setUserInteractedWithOptions(true);

        if (!ownVote) {
            datastore.addObject("videoVote", {
                value: value,
                questionKey: question.key,
                anonymous: session?.anonymous ? session.anonymous : false,
            });
        } else {
            datastore.updateObject("videoVote", ownVote.key, {
                value: value,
                questionKey: question.key,
                anonymous: session.anonymous ? session.anonymous : false,
            });
        }

        logEventAsync(datastore, "videovoting-vote", {
            questionKey: question.key,
            questionText: question.text,
            value: value.toString(),
            anonymous: session?.anonymous?.toString(),
            videoVotingSessionId: session.id,
        });
    };

    return (
        // TODO: isUnitBeforeValue, stepSize, and possibly snapOnMove should be configurable via the video voting editor but they currently aren't
        <EstimationSlider
            min={question.minValue}
            max={question.maxValue}
            stepSize={question.stepSize}
            value={ownVote ? ownVote.value : 0}
            unit={question.unit}
            isUnitBeforeValue={question.isUnitBeforeValue}
            onChangeValue={(value) => voteForSliderValue(value)}
            snapOnMove={false}
        />
    );
}

export function VideoVotingEndScreen() {
    const videovoting = useVideoVoting()

    // Show the teaser only at the end of the video
    const { currentTime, duration } = useVideo()
    const shouldShowTeaser = duration - currentTime < 60;

    const questions = useCollection('question')
    const questionPreview = questions.find(q => q.key === videovoting?.linkedThread?.instanceKey)

    const config = useConfig()
    const newConfig = useMemo(() => ({
        ...config,
        teaserColorPalette: teaserPalette.darkTransparent,
        teaserQuestionLabel: 'Join the discussion',
        promoTopRightWidgets: [VideoCommentPreviewTeaserCloseButton],
        additionalAdminTeaserWidgets: [],

    }), [config])

    return <VotingWindowPositioner position={{top: "5%"}} dimension={{ width: '80%' }} visible={shouldShowTeaser}>
            <ConfigContext.Provider value={newConfig}>
                {questionPreview ? <QuestionVideoOverlayPromo question={questionPreview} /> : null}
            </ConfigContext.Provider>
        </VotingWindowPositioner>
    }

function VideoCommentPreviewTeaserCloseButton(){
    const datastore = useDatastore();
    const closeWindow = () => {
        updateVideoVotingSessionData(datastore, {teaserDismissed: true})
    }
    return <BreadCrumb icon={Close} onPress={closeWindow} iconProps={{size:32, color:colorAmbiguousWhite}}/>
}

function findQuestionForTimestamp(timestamp, templateQuestions) {
    const filteredQuestions = templateQuestions.filter(q=>(q.timestamp !== null)).sort((q1, q2) => q1.timestamp - q2.timestamp)
    for (let i = 0; i < filteredQuestions.length; i++) { 
        const question = filteredQuestions[i];

        // Skip final questions
        if(question.final) continue

        if(question.type === "connected") {
            for (let j = 0; j < question.subQuestions.length; j++) {
                const subQuestion = question.subQuestions[j];

                // Skip final questions
                if(subQuestion.final) continue

                if(timestamp >= subQuestion.timestamp) {
                    if (j === question.subQuestions.length - 1 || question.subQuestions[j + 1].timestamp > timestamp) {
                        // Add the options to the question Object to be returned
                        const returnQuestion = {
                            ...question,
                            ...subQuestion,
                        }
                        return returnQuestion;
                    }
                }
            }
            continue;
        }

        if(timestamp >= question.timestamp) {
            // Timestamp must no be after the timestamp of the next question
            if (i === filteredQuestions.length - 1 || filteredQuestions[i + 1].timestamp > timestamp) {

                return question;
            }
        }
    }
    return null;
}

function getQuestionFromTemplate(template, questionKey) {
    for (const question of template.questions) {
        if (question.key === questionKey) {
            return question;
        }

        // If Question is connected, search the subquestions
        if (question.type === "connected") {
            const foundSubQuestion = question.subQuestions.find((subQuestion) => subQuestion.key === questionKey);
            
            if (foundSubQuestion) {
                const subQuestionIndex = question.subQuestions.findIndex((subQuestion) => subQuestion.key === questionKey);

                return {
                    ...question,
                    ...foundSubQuestion,
                    subQuestionIndex
                };
            }
        }
    }
}

function getFinalQuestionFromTemplate(template) {
    for(let i = 0; i < template.questions.length; i++) {
        const question = template.questions[i];

        // If Question is connected, search the subquestions
        if(question.type === "connected") {
            const foundSubQuestion = question.subQuestions.find(subQuestion => (subQuestion.final === true))
            if(foundSubQuestion) {
                return foundSubQuestion;
            }
            continue
        }

        if(question.final){
            return question
        }
    }
}

function VideoVotingQuestionResultDialog({question, onFinishQuestionResults}) {
    const session = useVideoVotingSessionData();
    const datastore = useDatastore();
    const videoVoting = useVideoVoting();

    let allVotes = useCollection("videoVote");

    let results = <></>;
    if(question.type === "connected"){
        const firstConnectedQustionFromTemplate = session.template.questions.find(q => q.type === "connected")
        const allSubQuestionKeys = firstConnectedQustionFromTemplate.subQuestions.map(q=>q.key)
        allVotes = allVotes.filter(vote => (vote.optionKey && allSubQuestionKeys.includes(vote.questionKey)));
        results = <QuestionResultConnected question={firstConnectedQustionFromTemplate} publicVotes={allVotes} showTotalVotes></QuestionResultConnected>
    }

    const openDiscussion = () => {
        logEventAsync(datastore, 'videovoting-navigate-to-discussion', {
            from: "question-result-dialog",
            questionKey: question.key,
            questionText: question.text,
            videoVotingSessionId: session.id
        });

        datastore.gotoInstance({ structureKey: videoVoting.linkedThread.structureKey, instanceKey: videoVoting.linkedThread.instanceKey });
    }
    
    return <SkippableDiscussContainer timeUpAction={onFinishQuestionResults} openDiscussion={openDiscussion} skipManually={false}>
        <ForceVideoPauseComponent></ForceVideoPauseComponent>
        {results}
    </SkippableDiscussContainer>
}

function SkippableDiscussContainer({ children, skipAction, timeUpAction, openDiscussion, timer = 60, skipManually = true }) {
    return <View>
        {(timeUpAction && timer) && <View style={{ position: "absolute", right: 0, zIndex: 10 }}>
            <VideoVotingCountdownElement size={48} circleRadius={18} strokeWidth={4} timer={timer} callback={timeUpAction}></VideoVotingCountdownElement>
        </View>}
        <View style={{ paddingRight: 16, paddingTop: 16 }}>
            <View style={{ backgroundColor: "white" }}>
                <PadBox horiz={32} vert={32}>
                    {children}
                    <Pad size={24}></Pad>
                    <HorizBox center spread>
                        <CTAButton label={"Join the discussion"} onPress={openDiscussion}></CTAButton>
                        {(skipAction && skipManually) && <TextButton leftIcon={SkipForward} type='secondary' label={"Continue"} onPress={skipAction}></TextButton>}
                    </HorizBox>
                </PadBox>
            </View>
        </View>
    </View>
}

// This container handles that each voting window has its correct position and size
function VotingWindowPositioner({children, position, dimension, visible=true, showBackdrop=false, pointerEventsEnabled=true}) {
    return <OverlayScreen position={position} dimension={dimension} useIframe={true} repositionIframe={true} showBackdrop={showBackdrop} visible={visible} pointerEvents={pointerEventsEnabled}>
        {children}
    </OverlayScreen>
}

// This component sets up the video voting session data
// It handles cleanups and resets in case the user changes, etc
function VideoVotingInitializer({votingKey}){
    const datastore = useDatastore()
    const videoVotingSession = useVideoVotingSessionData();
    const videoVotingTemplate = useVideoVotingTemplate(votingKey);
    const personaKey = usePersonaKey()

    let votingDisabledGlobally = useModuleUserGlobalData("videovoting", ["disabledGlobally"]);
    if (votingDisabledGlobally === null || votingDisabledGlobally === undefined) {
        votingDisabledGlobally = false;
    }

    const initializeSession = () => {
        const initialSessionData = {
            state: votingDisabledGlobally ? VIDEO_VOTING_SESSION_STATES.disabled : VIDEO_VOTING_SESSION_STATES.initVideo,
            anonymous: false,
            questions: {},
            personaKey: personaKey,
            votingKey,
            template: videoVotingTemplate,
            id: generateRandomKey(10),
        }
        setVideoVotingSessionData(datastore, initialSessionData)
    };

    useEffect(() => {
        // Initialize with an empty session
        // When the user changes, we have to reset the session
        if (videoVotingTemplate && (!videoVotingSession || (videoVotingSession.personaKey && videoVotingSession.personaKey != personaKey))) {   
            initializeSession()
            return
        }

        // Case: user changes or the global setting changes -> Reset session
        if(videoVotingSession &&
            (videoVotingSession.personaKey != personaKey || 
                votingDisabledGlobally && videoVotingSession.state !== VIDEO_VOTING_SESSION_STATES.disabled || 
                !votingDisabledGlobally && videoVotingSession.state === VIDEO_VOTING_SESSION_STATES.disabled
            )
        ) {
            initializeSession()
        }
    }, [videoVotingSession, personaKey, videoVotingTemplate, votingDisabledGlobally]);

    return null
}


// This component handles the control flow of the video voting
// It reacts to session changes 
function VideoVotingSessionController({children}){
    const datastore = useDatastore()
    const videoVotingSession = useVideoVotingSessionData();
    const videoVotingTemplate = useVideoVotingTemplate(videoVotingSession.votingKey);
    const video = useVideo();
    
    // State machine: React to changing states
    useEffect(() => {

        if(videoVotingSession.state === VIDEO_VOTING_SESSION_STATES.initVideo && video.ready) {
            updateVideoVotingSessionData(datastore, { state: VIDEO_VOTING_SESSION_STATES.initSession });
        }
    }, [videoVotingSession.state, video.ready])

    return<>{children}</>
}

function EditVideoVotingScreen() {
    const datastore = useDatastore()
    const instanceKey = useInstanceKey();
    const myVideoVoting = useVideoVoting(instanceKey);

    return <>{myVideoVoting && <ConversationScreen pad>
        <Heading label={"Edit Video voting"} type='large' />
        <Pad />
        {myVideoVoting && <VideoVotingEditor voting={myVideoVoting.template} questionKey={myVideoVoting.linkedThread?.instanceKey} />}
    </ConversationScreen>}</>
}