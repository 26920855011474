export const ui_human_translations_german = {
    // Reaction Types
    "Empathetic": "Einfühlsam",
    "Funny": "Witzig",
    "Enjoyable": "Wohltuend",
    "Playful": "Frech",
    "upvote": "upvote",


    // Mod Dashboard
    "My bookmarks": "Meine Markierten",
    "Manually rejected ": "Manuell abgelehnt ",
    "You are reviewing": "Du prüfst gerade",
    "{viewerName} is reviewing": "{viewerName} prüft gerade",
    "Only one moderator at a time can work on a comment.": "Es kann immer nur eine Person an einem Kommentar arbeiten.",
    "User Profile": "Profil",
    "Unblock user": "Person freischalten",
    "Show user profile": "Profil anzeigen",
    "Block user": "Person blockieren",
    "User has already been warned {count} {noun} and was blocked by {blockedByName}.": "Person wurde bereis {count} {noun} verwarnt und von {blockedByName} blockiert.",
    "A user must be warned 2 times before they can be blocked.": "Personen müssen 2 Mal verwarnt werden, bevor sie blockiert werden können.",
    "Block this user?": "Person wirklich blockieren?",
    "Do you really want to block this user? You can unblock the user via the profile page.": "Möchtest du wirklich die Person blockieren? Du kannst die Person über ihre Profilseite wieder freischalten.",
    "Unblock this user?": "Person wirklich freischalten?",
    "Do you really want to unblock this user?": "Möchtest du die Person wirklich freischalten?",
    "Warn user": "Person verwarnen",
    "The user receives an automatic message by e-mail after the warning. It is possible to cancel the warning.": "Die Person erhält nach der Verwarnung eine automatische Nachricht per E-Mail. Es ist möglich, die Verwarnung zu löschen.",
    "Warned":"Verwarnt",
    "User has already been warned {count} {noun}.": "Person wurde bereits {count} Mal verwarnt.",
    "Which Community Guideline does this comment violate?": "Gegen welche Community-Richtlinie verstößt dieser Kommentar?",
    "comment & reply": "Kommentar & Antwort",
    "comments & replies": "Kommentare & Antworten",
    "rejected comment": "Abgelehnter Kommentar",
    "reported comment": "Gemeldeter Kommentar",
    "Want to do anything else?" : "Möchtest du noch etwas tun?",
    "No thanks":"Nein danke",
    "flagged comment": "KI-Meldung",
    "user warning": "Verwarnung",
    "user warnings":"Verwarnungen",
    "rejected comments": "Abgelehnte Kommentare",
    "reported comments": "Gemeldete Kommentare",
    "flagged comments": "KI-Meldungen",
    "AI flag": "KI-Meldung",
    "Pre-moderation flag": "Meldung der Vor-Moderation",
    "⚠️ Blocklist words": "⚠️ Blocklist Wörter", 
    "{count} users": "{count} Personen",
    "User information": "Profil-Informationen",
    "Reported by users": "Gemeldet von",
    "User report": "Community-Meldung",
    "📌 Community guideline violation": "📌 Verstoß gegen die Community-Richtlinien",
    "⚠️ Community guideline violation": "⚠️ Verstoß gegen die Community-Richtlinien",
    "View in thread": "Im Thread ansehen",
    "Message to moderator": "Nachricht an das Moderations-Team",
    "Comment information": "Information zum Kommentar",
    "Submitted": "Abgeschickt",
    "Comment ID": "Kommentar-ID",
    "Awaiting decision": "Zu prüfen",
    "Automatically approved": "Automatisch freigegeben",
    "Manually approved": "Manuell freigegeben",
    "Origin:": "Gepostet in:",
    "Open Threads": "Aktive",
    "Closed Threads": "Inaktive",
    "Heated Threads": "Hitzige",
    "Threads with flagged comments": "Mit Meldung",
    "Open": "Aktiv",
    "Closed": "Inaktiv",
    "Heated": "Hitzig",
    "Read article": "Inhalt ansehen",
    'Just now': 'In diesem Moment',
    "User Settings": "Benutzereinstellungen",
    "This feature is not implemented yet." : "Diese Funktion ist noch nicht implementiert.",
    "Author: {author}": "Autor: {author}",
    "AI explanation": "Begründung der KI",

    // Other
    "AI review found that your response may be incompatible with the following [Community Guidelines]($onPress): ":"Die Systemprüfung hat ergeben, dass dein Beitrag möglicherweise folgende [Community-Richtlinie(n)]($onPress) missachtet: ",
    "AI review found that your perspective label may be incompatible with the following [Community Guidelines]($onPress): ":"Die KI-Überprüfung hat ergeben, dass die vorgeschlagene Perspektive möglicherweise folgende [Community-Richtlinie(n)]($onPress) missachtet: ",
    "Your response was rejected by a moderator":"Dein Beitrag wurde von einer/m Moderator/in abgelehnt.",
    "Your response is under review":"Dein Beitrag wird überprüft.",
    "Your response has been sent to a moderator for review.":"Der Beitrag wurde zur Überprüfung an unser Moderations-Team gesendet.",
    "You will receive an email notification when your post is approved or rejected by moderators.":"Du erhältst eine E-Mail-Benachrichtigung, wenn dein Beitrag genehmigt oder abgelehnt wird.",
    "Yes with reservations":"Eher ja",
    "What guideline does this violate? Select all that apply.":"Gegen welche Community-Richtlinie verstößt der Beitrag? Wähle alle zutreffenden Optionen aus.",
    "We'd particularly like to hear from:":"Aus welcher Perspektive trägst du zum Thema bei?",
    "We strive for a kind and constructive discussion space where everyone feels welcome to share their perspectives and experiences. You can contribute to a discussion with responses that are on-topic and relevant to the subject matter, including meaningful debate.":"Wir möchten einen freundlichen und konstruktiven Austausch ermöglichen. Dabei sollen sich alle willkommen fühlen, ihre Perspektiven und Erfahrungen zu teilen. Du kannst durch themenbezogene und relevante Beiträge, einschließlich konstruktiver Kritik, zur Diskussion beitragen.",
    "Visible to admins":"Für unsere Admins einsehbar",
    "Updating...":"Wird aktualisiert...",
    "Update your previous response with fresh insights":"Aktualisiere deinen Beitrag mit weiteren Gedanken.",
    "To maintain a welcoming and respectful environment, this space uses AI to help moderate comments. AI may flag your post for your additional review if it does not follow the [Community Guidelines]($onPress)": "Um eine einladende und respektvolle Atmosphäre aufrechtzuerhalten, nutzen wir auch KI zur Unterstützung der Moderation. Das Moderations-System wird dich auffordern, Beiträge erneut zu überprüfen oder zu überarbeiten, wenn sie nicht den [Community-Richtlinien]($onPress) entsprechen",
    "This user's history is private.":"Der Verlauf dieses Profils ist privat.",
    "This discussion space uses only essential cookies and cookies for analytics. We do not use cookies for advertising purposes.":"Der Public Spaces Incubator verwendet nur essentielle Cookies und Analyse-Cookies, keine Cookies für Werbezwecke.",
    "This discussion is temporarily unavailable. Check back for updates.":"Diese Unterhaltung ist vorübergehend nicht verfügbar. Bitte versuche es später noch einmal.",
    "This custom name is incompatible with our Community Guidelines.":"Der gewünschte Name verstößt möglicherweise gegen unsere Community-Richtlinien.",
    "Support Your Claims":"Behauptungen belegen",
    "Strongly yes":"Ja",
    "Strongly no":"Nein",
    "Slide to respond:":"Verschiebe den Regler, um zu antworten.",
    "Sent to moderator: {time}":"An Moderations-Team gesendet: {time}",
    "Send to moderator":"An Moderations-Team senden",
    "Send to a moderator for review":"An Moderations-Team zur Überprüfung senden",
    "Send an email to [privacy@newpublic.org](mailto:privacy@newpublic.org) to request a copy of your data associated with this discussion space. It may take up to 30 days to process your request.":"Eine Kopie deiner mit dem Public Spaces Incubator verbundenen Daten kannst du bei uns anfordern. Schreibe dazu eine E-Mail mit der bei uns angegebenen Mailadresse an psi-privacy@zdf.digital - es kann bis zu 14 Tage dauern, bis du deine Daten erhältst. ",
    "Reviewing your perspective label with AI to ensure it follows our Community Guidelines":"Das System prüft, ob die vorgeschlagene Perspektive den Community-Richtlinien entspricht.",
    "Respect the privacy of others, no personal information sharing":"Respektiere die Privatsphäre anderer, gib keine persönlichen Daten preis.",
    "Relatable":"Fühl ich",
    "Provide sources for factual claims":"Belege Tatsachenbehauptungen mit Quellen.",
    "Provide any details to help us determine if your post follows our Community Guidelines.":"Bitte erläutere, warum du glaubst, dass dein Beitrag den Community-Richtlinien entspricht.",
    "Posts are manually moderated to meet the ":"Beiträge werden moderiert, um die Community-Richtlinien einzuhalten.",
    "Once you log in, enter a display name":"Wenn du dich einloggst, kannst du auswählen, welcher Name angezeigt werden soll.",
    "No with reservations":"Eher nein",
    "No violent content or calls for harm":"Keine Inhalte, die zu Gewalt aufrufen oder Gewalt verherrlichen",
    "No spam or commercial promotion":"Kein Spam oder kommerzielle Inhalte wie Werbung",
    "No off-topic posts":"Bleib beim Thema der Diskussion, keine off-topic-Beiträge",
    "No illegal content":"Keine unanständigen, hasserfüllten oder illegalen Inhalte",
    "No Harmful Content":"Schädliche Inhalte",
    "No Disruptive Behavior":"Störendes Verhalten",
    "Message to Moderator...":"Nachricht an das Moderations-Team...",
    "Joined from":"kommt von",
    "Join the discussion":"Beteilige dich am Gespräch.",
    "I agree to the Community Guidelines, Privacy Notice, and Terms of Service.":"Ich habe die [Datenschutz-Hinweise]({privacyNoticeLink}) zur Kenntnis genommen.",
    "I agree to the [Community Guidelines]({communityGuidelinesLink}), [Privacy Notice]({privacyNoticeLink}), and [Terms of Service]({termsOfServiceLink}).":"Ich stimme den [Community-Richtlinien]({communityGuidelinesLink}) und den [Nutzungsbedingungen]({termsOfServiceLink}) zu. Ich habe die [Datenschutz-Hinweise]({privacyNoticeLink}) zur Kenntnis genommen.",
    "Focus on ideas, rather than character judgments":"Konzentriere dich auf Ideen und Argumente statt Charakter-Urteile.",
    "Express your ideas with civility":"Kommuniziere auch in hitzigen Unterhaltungen stets respektvoll",
    "Explain your response":"Erläutere deine Antwort.",
    "Explain disagreements constructively":"Versuche Kritik konstruktiv zu äußern.",
    "Engage Respectfully":"Respektvolles Verhalten",
    "Discussion History":"Gesprächsverlauf",
    "Did AI get it wrong?":"Hat das Moderations-System einen Fehler gemacht? ⚠️Aktuell ist unser Moderations-Filter eher streng eingestellt, um möglichst große Sicherheit zu gewährleisten! Falls du das Gefühl hast, dass dein Beitrag zu Unrecht nicht veröffentlicht wird, wende dich bitte an unser Moderations-Team!",
    "⚠️ Our moderation filter is currently set to be quite strict in order to ensure the highest level of safety! If you feel that your contribution is being unfairly rejected, please contact our moderation team!":"⚠️ Aktuell ist unser Moderations-Filter eher streng eingestellt, um möglichst große Sicherheit zu gewährleisten! Falls du das Gefühl hast, dass dein Beitrag zu Unrecht nicht veröffentlicht wird, wende dich bitte an unser Moderations-Team!",
    "Deleting your profile cannot be undone. Any data and activity associated with your profile may take up to 72 hours to be removed.":"Das Löschen deines Profils kann nicht rückgängig gemacht werden. Die Löschung aller mit dem Profil verbundenen Daten und Aktivitäten kann bis zu 72 Stunden dauern.",
    "Controversial":"Umstritten",
    "Continue with Radio Canada":"Mit Login fortfahren",
    "Continue with Radio Canada Internal":"Mit Login fortfahren",
    "Continue with Google":"Do not display",
    "Consider rewording your response:":"Kannst du deinen Beitrag konstruktiver formulieren?",
    "Consider rewording your perspective:":"Kannst du die Perspektive umformulieren?",
    "Community Guidelines":"Community-Richtlinien",
    "Choose a name and photo that appears when you post in discussions.":"Wähle einen Namen und ein Profilbild aus, die angezeigt werden, wenn du zu Diskussionen beiträgst.",
    "By participating in our beta, you may be invited to submit feedback on your experience using this product.":"Wenn du unsere Beta-Version nutzt, kann es sein, dass wir dich um Feedback zu deinen Erfahrungen bitten.",
    "Avoid sarcasm and dismissive responses":"Vermeide Sarkasmus und respektlose Antworten",
    "Avoid derogatory labels":"Keine Beleidigungen oder verbale Aggression oder verletzende Kommunikation",
    "Are you sure you want to delete your profile data?":"Bist du sicher, dass deine Profildaten endgültig gelöscht werden sollen?",
    "AI helps us moderate comments, but sometimes it can make a mistake. If AI is preventing you from sharing a respectful opinion, send your comment to a moderator for review.":"Unser Moderations-Team wird auch von KI unterstützt, um die Sicherheit aller zu gewährleisten. Doch das System ist nicht perfekt. Wenn es dich daran hindert, einen konstruktiven Beitrag zu teilen, schicke deinen Beitrag zur Überprüfung an unsere Moderation.",
    "Admins and Moderators can view your history.":"Unsere Redaktion und Admins können deinen Verlauf einsehen.",
    "Add your display name":"Angezeigten Namen wählen",
    "🚫 Rejected by Human Moderator":"🚫 Von einer/m Moderator/in abgelehnt",
    "🔍 Under Review":"🔍 Wird überprüft",
    "📫 What happens next":"📫 Was als nächstes passiert",
    "🍪 This discussion space uses only essential cookies. We do not use cookies for advertising purposes. [Learn more]({privacyNoticeLink}).":"Der Public Spaces Incubator verwendet nur essentielle Cookies und keine Cookies für Werbezwecke.",
    "✨ We use AI for moderation":"✨ Unser Moderations-Team wird von KI unterstützt.",
    "⚠️ Check your response":"⚠️ Bitte überprüfe deine Antwort!",
    "⚠️ Check your perspective":"⚠️ Überprüfe deine Perspektive",    
    "Learn more.":"Mehr erfahren.",
    "Add your own":"Eigene Perspektive hinzufügen",
    "Enter a perspective label":"Deine Perspektive",
    "Topic of the moment": "Schwerpunkt-Thema",
    "participant": "Teilnehmende",
    "participants": "Teilnehmende",
    "Ask us a question...": "Welche Frage hast du an uns?",
    "Be the first to share your thoughts.": "Teile als erste Personen deinen Gedanken.",
    "Discuss this topic with other citizens": "Diskutiere dieses Thema mit anderen.",
    "Previous topics covered": "Frühere Themen",
    "Upcoming": "Nächstes",
    "Select one option": "Wähle eine Option.",
    "Your response": "Dein Beitrag",
    "Submit my vote": "Meine Stimme abgeben.",
    "Change my vote": "Meine Auswahl anpassen.",
    "You":"du",
    "Write in a topic...": "Schlag ein Thema vor …",
    "Topic": "Thema",
    "Topics": "Themen",
    "Search a topic...": "Themen durchsuchen…",
    "URL for photo to appear in the teaser": "URL für Teaser-Bild:",
    "Create a topic": "Schwerpunkt-Thema anlegen:",
    "Topic name": "Thema:",
    "Starts at": "Beginnt am:",
    "Ends at": "Endet am:",
    "(YYYY-MM-DD format)": "(Format: JJJJ-MM-TT)",
    "Conversation starter": "Frage an die Community:",
    "Is active": "ist aktiv",
    "Active": "aktiv",
    "Add topic": "Thema hinzufügen",
    "Topic poll": "Umfrage",
    "Upcoming topic poll": "Umfrage zum nächsten Thema",
    "Poll question": "Frage",
    "Open answer label": "Titel für Freitext-Feld",
    "Open answer placeholder": "Platzhalter für Freitext-Titel",
    "Remove option": "Option entfernen",
    "Add option": "Option hinzufügen",
    "Option...": "Option ...",

    // Agree/disagre
    'Strongly agree': 'Stimme zu',
    'Strongly disagree': 'Stimme nicht zu',
    'Somewhat agree': 'Stimme eher zu',
    'Somewhat disagree': 'Stimme eher nicht zu',
 
    "This guidance is generated by AI.":"Diese Hinweise werden von einer KI generiert.",             
    "About Conversation Helper":"Konstruktiver durch KI?",      
    "To maintain a welcoming and respectful atmosphere, we also use AI to support moderation. In this conversation, an AI gives hints for a more constructive conversation atmosphere and summarizes the conversation.":"Um eine einladende und respektvolle Atmosphäre aufrechtzuerhalten, nutzen wir auch KI zur Unterstützung der Moderation. In dieser Unterhaltung gibt eine KI Hinweise für eine konstruktivere Gesprächsatmosphäre und fasst die Unterhaltung zusammen.",                    
    "Open Admin Dashboard": "Admin-Dashboard öffnen",

    // Video Voting Tool - Estimation Slider
    "Community average": "Durchschnitt Community",
    
    //Blocklist-Feature
    "Blocklist": "Blocklist",
    "Manage terms that should be reported automatically.": "Verwalte kritische Begriffe, die automatisch geblockt werden sollen.",
    "Add terms - separate multiple terms with ;": "Begriffe hinzufügen - trenne mehrere Begriffe mit ;",
    "Search": "Suche",
    "The blocklist is empty.": "Blocklist ist leer.",
    "Add term": "Begriff hinzufügen",
    "Info": "Info",
    "The blocklist allows you to define terms that are automatically prevented from being published in community posts. You can add terms to this pre-moderation yourself. Add all terms that should not be published without human review to the list.": "Mit der Blocklist kannst du Begriffe festlegen, die automatisch von der Veröffentlichung ausgeschlossen werden. Füge alle Begriffe, die nicht ohne menschliche Überprüfung veröffentlicht werden sollen, der Liste hinzu.",
    "Singular term:": "Einzelbegriff:",
    "You can decide whether only the singular term should be considered or also terms that contain the entered term.": "Du kannst entscheiden, ob nur der Einzelbegriff oder auch Begriffe, die den eingegebenen Begriff enthalten, berücksichtigt werden sollen.",
    "Example: “person” (singular term) → “persons”. “persons” would also be taken into account if the “singular term” checkbox is not activated for “person”. This also applies to the string “personality”, for example.": "Beispiel: \"Person\" (Einzelbegriff) → Ist das Kontrollkästchen \"Einzelbegriff\" gewählt, wird nur \"Person\" erkannt. Ist das Kästchen nicht aktiviert, wird auch \"Personen\" oder \"Persönlichkeit\" erkannt.",
    "Alternative spellings:": "Alternative Schreibweisen:",
    "You can decide whether alternative spellings are taken into account. The following spellings, numbers and special characters are also taken into account:": "Du kannst entscheiden, ob alternative Schreibweisen berücksichtigt werden sollen. Die folgenden Schreibweisen, Zahlen und Sonderzeichen werden ebenfalls berücksichtigt:",
    "A → a  | a → A | a → @ | a → 4 | i → 1 | e → 3 | o → 0 | s → $ | s → 5 | t → 7 | b → 8 | g → 9": "A → a  | a → A | a → @ | a → 4 | i → 1 | e → 3 | o → 0 | s → $ | s → 5 | t → 7 | b → 8 | g → 9",
    "Upper and lower case are taken into account": "Groß- und Kleinschreibung wird berücksichtigt",
    "All right": "Okay",
    "Settings for the term": "Einstellungen für den Begriff",
    "Select whether terms are to be adopted exactly as your input is, or whether they are to be included in expanded form.": "Wählen Sie aus, ob die Begriffe genau so übernommen werden sollen, wie Sie sie eingegeben haben, oder ob sie in erweiterter Form enthalten sein sollen.",
    "Only singular term": "Nur Einzelbegriff",
    "Example:": "Beispiel:",
    "“person” (singular term) → “persons”. “persons” would also be taken into account if the “singular term” checkbox is not activated for “person”. However, this also applies to the string “personality”, for example.": " \"Person\" (Einzelbegriff) → Ist das Kontrollkästchen \"Einzelbegriff\" gewählt, wird nur \"Person\" erkannt. Ist das Kästchen nicht aktiviert, wird auch \"Personen\" oder \"Persönlichkeit\" erkannt.",
    "Include alternative spellings": "Alternative Schreibweisen einbeziehen",
    "A → a | a → A | a → @ | a → 4 | i → 1 |e → 3 \n|o → 0 | s → $ | s → 5 | t → 7 | b → 8 | g → 9": "A → a | a → A | a → @ | a → 4 | i → 1 |e → 3 \n|o → 0 | s → $ | s → 5 | t → 7 | b → 8 | g → 9",
    "Singular Term": "Einzelbegriff",
    "Edit Term": "Begriff bearbeiten",
    "Delete Terms": "Begriffe löschen",
    "Should these terms really be deleted? You can add them again later.": "Sollen diese Begriffe wirklich gelöscht werden? Du kannst sie später wieder einzeln hinzufügen.",
    "This term is already in the list": "Dieser Begriff ist bereits in der Liste enthalten",
    "{termsCount} terms were successfully added": "{termsCount} Begriffe wurden erfolgreich hinzugefügt",
    "The term \"{singleTerm}\" was added": "Der Begriff {singleTerm} wurde hinzugefügt",
    "Something went wrong, try again later.": "Etwas ist schief gelaufen, versuche es später noch einmal.",
    "Terms may not be longer than 150 characters.": "Die Begriffe dürfen nicht länger als 150 Zeichen sein.",
    "Terms longer than 150 characters have been removed.": "Begriffe, die länger als 150 Zeichen sind, wurden entfernt.",
    
    "A-Z": "A-Z",
    "Z-A": "Z-A",
    "{keyCount} selected items": "{keyCount} ausgewählte Elemente",
    "Delete": "Löschen",
    "Back": "Zurück",
    "Close": "Schließen",

    // Surveys
    "Neither agree nor disagree": "Bin unsicher",
    "This conversation platform meets my needs": "Das Konversationsangebot erfüllt meine Bedürfnisse",
    "This conversation platform is easy to use": "Das Konversationsangebot ist einfach zu bedienen",
    "How satisfied are you with this conversation platform?": "Wie zufrieden bist du mit dem Konversationsangebot?",
    "We'd love your feedback!": "Wir freuen uns über dein Feedback!",
    "Share your feedback to help improve our Beta experience.": "Teile dein Feedback mit uns, um unsere Beta-Erfahrung zu verbessern.",
    "What influenced your answer? Choose all that apply.": "Was hat deine Antwort beeinflusst? Wähle alle zutreffenden Optionen aus.",
    "Needing to log in to participate": "Anmeldepflicht zur Teilnahme",
    "Quality of the question guiding the conversation": "Qualität der Frage, die diskutiert wird",
    "Please indicate how much you agree or disagree with each statement": "Wie sehr stimmst du folgenden Aussagen zu oder nicht zu?",
    "Disagree": "Stimme eher nicht zu",
    "Agree": "Stimme eher zu",  

    // AI moderation
    "AI-moderated": "KI-gestützte Moderation",
}