import React, { useEffect, useMemo, useRef, useState } from "react";
import { useCollection, useDatastore, useGlobalProperty, useIsInSidebar, useObject, usePersonaKey, useSessionData } from "../util/datastore";
import { Byline } from "./people";
import { ConversationScreen, HorizBox, Pad, PadBox, Separator, ShadowBox } from "./basics";
import { Heading, TextField, TextFieldButton, UtilityText } from "./text";
import { CTAButton, ExpandButton, SubtleButton, TextButton } from "./button";
import { Edit, Flag, Reply, TrashCan } from "@carbon/icons-react";
import { StyleSheet, View } from "react-native";
import { getFirstName } from "../util/util";
import { colorSurfaceInfo, colorTextSecondary, colorTextWarning } from "./color";
import { RichText } from "./richtext";
import { Catcher, CatchList } from "../system/catcher";
import { TopBarActionProvider } from "./topbar";
import { useConfig, useIsReadOnly } from "../util/features";
import { Banner } from "./banner";
import { logEventAsync } from "../util/eventlog";
import { NoCommentsHelp } from "./help";
import { useIsAdmin } from "./admin";
import { getIsMobileWeb } from '../platform-specific/deviceinfo';
import { Modal } from "./modal";
import { DeleteIcon } from "./icon";
import { trackVisibility } from '../platform-specific/trackvisibility';
import { nullElement, undefS } from "../util/stdtypes";

export type Comment = {
    key: string;
    text: string;
    from?: string;
    time?: number;
    edited?: number;
    replyTo?: string;
    deleted?: boolean;
    about?: string;
}

export function Comment({commentKey}) {
    const comment = useObject('comment', commentKey);
    const editing = useSessionData(['editComment', commentKey]);
    const {commentAboveWidgets, commentBelowWidgets, commentMiddleWidgets, commentStylers} = useConfig();
    const style = getCombinedStyle({comment, stylers:commentStylers});
    const datastore = useDatastore();

    const commentRef = useRef(null);
    useEffect(() => {
        const stopTracking = trackVisibility(commentRef, () => {logEventAsync(datastore, 'comment-long-view', { commentKey })});
        return stopTracking;
    }, []);

    if (!comment) return null;

    return <View ref={commentRef} testID={commentKey} id={commentKey} style={style}>
        <PadBox top={20}>
            <Catcher>
                {commentAboveWidgets?.map((Widget,i) => <Widget key={i} comment={comment}/>)}
            </Catcher>
            <Byline type='large' userId={comment.from} time={comment.time} edited={comment.edited} />
            <Pad size={20} />
            <Catcher>
                <CommentBody commentKey={commentKey} />
            </Catcher>
            <Catcher>
                {commentMiddleWidgets?.map((Widget,i) => <Widget key={i} comment={comment}/>)}
            </Catcher>                
            <Catcher>
                {commentBelowWidgets?.map((Widget,i) => <Widget key={i} comment={comment}/>)}
            </Catcher>
            {!editing && <PadBox top={20}><Catcher><CommentActions commentKey={commentKey} depth={0} /></Catcher></PadBox>}
            <MaybeCommentReply replyToCommentKey={commentKey} />
            <CommentReplies commentKey={commentKey} />
        </PadBox>
        <PadBox><Separator /></PadBox>
    </View>
}

export function ReplyComment({commentKey, depth=1, isFinal=false}) {
    const s = ReplyCommentStyle;
    const comment = useObject('comment', commentKey);
    const editing = useSessionData(['editComment', commentKey]);
    const {replyAboveWidgets, commentStylers} = useConfig();
    const style = getCombinedStyle({comment, stylers:commentStylers});
    if (!comment) return null;

    return <View testID={commentKey} id={commentKey} style={[depth == 1 ? s.firstLevel : s.secondLevel, style]}>
        <Catcher>{replyAboveWidgets?.map((Widget,i) => <Widget key={i} comment={comment}/>)}</Catcher>
        <Byline type={depth == 1 ? 'small' : 'tiny'} userId={comment.from} time={comment.time} edited={comment.edited} />
        <Pad size={20} />
        <PadBox left={depth == 1 ? 40 : 32}>
            <CommentBody commentKey={commentKey} />
            <Pad size={20} />
            {!editing && <Catcher><CommentActions commentKey={commentKey} depth={depth} /></Catcher>}
            <MaybeCommentReply replyToCommentKey={commentKey} />
            <CommentReplies commentKey={commentKey} depth={depth+1} />
        </PadBox>
        {!isFinal && <Separator />}
    </View>    
}

const ReplyCommentStyle = StyleSheet.create({
    firstLevel: {
        paddingTop: 20
    },
    secondLevel: {
        paddingTop: 10,
    }
})


export function CommentBody({commentKey}) {
    const comment = useObject('comment', commentKey);
    const editing = useSessionData(['editComment', commentKey]);
    const [editedComment, setEditedComment] = useState(null);
    const [expanded, setExpanded] = useState(false);
    const datastore = useDatastore();
    const {commentTopWidgets, commentBodyStylers} = useConfig();
    const commentBodyStyle = getCombinedStyle({comment, stylers:commentBodyStylers});

    if (!comment) return null;

    const text = comment.text || '';
    const isLong = guessNumberOfLines(text) > 8;

    
    function onEditingDone(finalComment) {
        setEditedComment(null);
        datastore.setSessionData(['editComment', comment?.key], false);
    }

    function onCancel() {
        logEventAsync(datastore, 'edit-cancel', {commentKey});
        setEditedComment(null);
        datastore.setSessionData(['editComment', comment?.key], false);
    }

    function onExpand() {
        setExpanded(true);
        logEventAsync(datastore, 'comment-expanded', {commentKey});
    }

    if (editing) {
        return <EditComment comment={editedComment ?? comment} 
                setComment={setEditedComment} 
                onCancel={onCancel} onEditingDone={onEditingDone} />
    } else {
        return <View style={commentBodyStyle}>
            {commentTopWidgets?.map((Widget,i) => <Widget key={i} comment={comment}/>)}
            <RichText numberOfLines={(isLong && !expanded) ? 8 : undefined} 
                text={text.trim()} color={commentBodyStyle.color} type='large'
            />
            {isLong && !expanded && <PadBox top={14}><TextButton underline type='large' label='Read more' onPress={onExpand} /></PadBox>}
        </View>
    }
}

export function getCombinedStyle({comment, stylers}) : Record<string, any> {
    var style = {};
    stylers?.forEach(styler => {
        style = {...style, ...styler({comment})};
    })
    return style;
}

function guessNumberOfLines(text) {
    const lines = text.split('\n');
    const linesPerLine = lines.map(line => Math.floor(line.length / 60) + 1);
    const sum = linesPerLine.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    return sum;
}

function MaybeCommentReply({replyToCommentKey}) {
    const replyEnabled = useSessionData(['replyToComment', replyToCommentKey]);
    const personaKey = usePersonaKey();
    const datastore = useDatastore();
    const [comment, setComment] = useState(() => ({text: '', key: datastore.getNewKey(), replyTo: replyToCommentKey}));
    useEffect(() => {
        if (replyEnabled) {
            logEventAsync(datastore, 'reply-start', { replyToCommentKey, commentKey: comment.key });
        }
    }, [replyEnabled]);
    if (!replyEnabled) return null;

    function onEditingDone() {
        datastore.setSessionData(['replyToComment', comment.replyTo], false);
        datastore.setSessionData(['showReplies', comment.replyTo], true);
        setComment({text: '', key: datastore.getNewKey(), replyTo: replyToCommentKey})
    }

    function onCancel() {
        datastore.setSessionData(['replyToComment', comment.replyTo], false);
        setComment({text: '', key: datastore.getNewKey(), replyTo: replyToCommentKey})
    }

    return <View>
        <Pad size={20} />
        <Byline type='small' userId={personaKey} showMetadata={false} />
        <Pad size={20} />
        <PadBox left={24}>
            <EditComment comment={comment} onCancel={onCancel}
                setComment={setComment} onEditingDone={onEditingDone} />
        </PadBox>
    </View>
}

export function EditComment({comment, big=false, setComment, topLevel=false, onEditingDone, onCancel, min=100, max=1000}) {
    const personaKey = usePersonaKey();
    const datastore = useDatastore();
    const questionText = useGlobalProperty('name');
    const replyToComment = useObject('comment', comment.replyTo);
    const author = useObject('persona', replyToComment?.from);
    const [inProgress, setInProgress] = useState(false);
    const [shownModalElement, setShownModalElement] = useState(nullElement);
    const [showDeleteModal, setShowDeleteModal] = useState(false); 
    const [isCommentError, setIsCommentError] = useState(false);
    const {commentReplyPlaceholder, commentInputPlaceholder, 
        commentPostBlockers, commentPostCheckers,
        commentPostTriggers, commentPostActionLabel,
        commentEditBottomWidgets, commentEditTopWidgets,
        commentAllowEmpty, 
        commentDeleteTriggers
        } = useConfig();
    const isInSidebar = useIsInSidebar();

    const isBlocked = commentPostBlockers?.some(blocker => blocker({datastore, comment}));
    const canPost = (comment.text || commentAllowEmpty) && !isBlocked;
    const isNewComment = !useObject('comment', comment.key);
    const computedActionLabel = commentPostActionLabel && commentPostActionLabel({datastore, inProgress, isNewComment, comment});
    const action = computedActionLabel ?? (isNewComment ? 
          (inProgress ? 'Posting...' : 'Post') 
        : (inProgress ? 'Updating...' : 'Update'));
    const placeholder = comment.replyTo ? commentReplyPlaceholder : commentInputPlaceholder;
    
    async function storeCommentAndRunTriggersAsync(comment) {
        if (isNewComment) {
            const key = await datastore.addObjectWithKey('comment', comment.key, {...comment});
            if (replyToComment) {
                logEventAsync(datastore, 'reply-finish', {replyToCommentKey: replyToComment.key, commentKey: comment.key, text: comment.text, comment});
            } else {
                logEventAsync(datastore, 'post-finish', {commentKey: comment.key, text: comment.text, comment});
            }
        } else {
            logEventAsync(datastore, 'edit-finish', {commentKey: comment.key, text: comment.text, comment});
            await datastore.updateObject('comment', comment.key, {...comment, edited: Date.now()});
        }
        if (commentPostTriggers?.length) {
            // Don't await the promise, since some triggers may be slow
            Promise.all(commentPostTriggers.map(trigger => trigger({datastore, comment, commentKey: comment.key})));
        }
    }

    async function showModalsAndFinish(modals : React.ComponentType<{onClose: () => void}>[]) {
        function onClose() {
            showModalsAndFinish(modals.slice(1));
        }

        if (modals?.length) {
            const modalElement = React.createElement(modals[0], {onClose});
            setShownModalElement(modalElement);
        } else {
            setShownModalElement(null);
            onEditingDone(comment);
        }
    }

    async function onPost() {
        logEventAsync(datastore, 'post-button-click', {commentKey: comment.key, text: comment.text});
        if (commentPostCheckers?.length) {
            setInProgress(true);
            const checkResults = await Promise.all(commentPostCheckers.map(checker =>
                checker({datastore, comment, questionText})
            ))
            var finalComment = {...comment};
            checkResults.forEach(judgement => {
                finalComment = {...finalComment, ...judgement.commentProps}
            })
            var modals : React.ComponentType<{onClose: () => void}>[] = [];
            checkResults.forEach(judgement => {
                if (judgement.modal) {
                    modals = [...modals, judgement.modal]
                }
            })
            if (checkResults.every(x => x.allow)) {
                await storeCommentAndRunTriggersAsync(finalComment);
                if (modals?.length) {
                    showModalsAndFinish(modals);
                } else {
                    onEditingDone(finalComment);
                }
            } else {
                setIsCommentError(true);
                setComment(finalComment);
            }
            setInProgress(false);
        } else {
            await storeCommentAndRunTriggersAsync(comment);
            onEditingDone(comment);
        }
    }

    async function onDelete() {
        const deletionUpdate = {text: '', deleted: true}

        // Await them to not risk an incomplete deletion process
        if (commentDeleteTriggers?.length) {
            await Promise.all(commentDeleteTriggers.map(trigger => trigger({datastore, comment: {...comment, ...deletionUpdate}, commentKey: comment.key})));
        }

        datastore.updateObject('comment', comment.key, deletionUpdate);

        if (topLevel) {
            datastore.goBack();
        }
    }

    const isMobile = getIsMobileWeb();

    return <View>
        {shownModalElement}
        {showDeleteModal && <DeleteModal onDelete={onDelete} onClose={() => setShowDeleteModal(false)} />}
        {topLevel && <TopBarActionProvider label={action} disabled={!canPost || inProgress} onPress={onPost} />}
        {topLevel && !isNewComment && <TopBarActionProvider secondary icon={<DeleteIcon />}
            label='Delete' type='delete' onPress={() => setShowDeleteModal(true)} 
        />}
        <EditWidgets widgets={commentEditTopWidgets} comment={comment} setComment={setComment} onCancel={onCancel} />
        <TextField value={comment.text} onChange={text => setComment({...comment, text})} 
            placeholder={placeholder} autoFocus={!isMobile} big={big} testID='comment-edit'
            placeholderParams={{authorName: getFirstName(author?.name)}} multiline
            error={isCommentError} />
        <Pad size={12} />
        <EditWidgets widgets={commentEditBottomWidgets} comment={comment} setComment={setComment} onCancel={onCancel} />
        {personaKey &&
            <PadBox top={20} >
                {(isMobile || !topLevel) ? 
                    <HorizBox center spread>
                        {(!isNewComment && comment.key && !topLevel) ? 
                            <CTAButton icon={<DeleteIcon />} testID='delete' type='delete' onPress={() => setShowDeleteModal(true)} />
                        : <Pad />}
                        <HorizBox center right>
                            {onCancel && <PadBox right={20}><TextButton color={colorTextSecondary} label='Cancel' onPress={onCancel} /></PadBox>}
                            <CTAButton label={action} disabled={!canPost || inProgress} type='primary' onPress={onPost} />
                        </HorizBox>
                    </HorizBox>
                : 
                    <CTAButton wide testID='post-button' label={action} disabled={!canPost || inProgress} type='primary' onPress={onPost} />
                }
            </PadBox>
        }
    </View>
}


function DeleteModal({onDelete, onClose}) {
    function onPress() {
        onDelete();
        onClose();
    }
    return <Modal onClose={onClose}>
        <PadBox horiz={20} vert={40}>
            <Heading type="large" weight="medium" label='Delete this post?' />
            <Pad size={8} />
            <UtilityText label="This action can't be undone"/>
            <Pad size={32} />
            <CTAButton wide icon={<TrashCan style={{fill: colorTextWarning}}/>} testID='confirm-delete' label='Delete' type='delete' onPress={onPress} />
        </PadBox>
    </Modal>
}

function EditWidgets({widgets, comment, setComment, screenParams={}, onCancel,}) {
    return <View>
        {widgets?.map((Widget, idx) => <View key={idx}>
            <Catcher>
                <Widget comment={comment} setComment={setComment} onCancel={onCancel} screenParams={screenParams} />
            </Catcher>
        </View>)} 
    </View>
}

function CommentReplies({commentKey, depth=1}) {
    const {replyFilters, replyBoosters, commentRankers} = useConfig();
    const datastore = useDatastore();
    const isAdmin = useIsAdmin();
    const sortMode = useSessionData(['ranking', 'sortMode']);
    var replies = useCollection('comment', {filter: {replyTo: commentKey, deleted: null}, sortBy: 'time', reverse: true});
    replies = filterComments({datastore, comments: replies, isAdmin, commentFilters: replyFilters});
    replies = rankComments({datastore, comments: replies, commentRankers, sortMode, isReplies: true});
    const boostedComment = replyBoosters?.map(booster => booster({comments: replies, datastore}))[0];
    const replyUsers = replies.map(reply => reply.from);
    const expanded = useSessionData(['showReplies', commentKey]);

    function setExpanded(expanded) {
        datastore.setSessionData(['showReplies', commentKey], expanded);
        if (expanded) {
            logEventAsync(datastore, 'showReplies', {commentKey});
        }
    }

    if (replies.length == 0) return <Pad />;

    return <View>
        
        {boostedComment && !expanded && <PadBox top={20}><ShadowBox>
            <PadBox horiz={20}>
                <ReplyComment isFinal commentKey={boostedComment.key} depth={depth} />
            </PadBox>
        </ShadowBox></PadBox>}
        <Pad />
        <ExpandButton userList={replyUsers} label='{count} {noun}' 
                expanded={expanded} setExpanded={setExpanded} testID='toggle-replies'
                formatParams={{count: replies.length, singular: 'reply', plural: 'replies'}} />
        <Pad />
        {expanded && <Separator />}
        {expanded && <CatchList items={replies} 
            renderItem={(reply,isFinal) =>
                <ReplyComment commentKey={reply.key} depth={depth} isFinal={isFinal} />
            } 
        />}
    </View>
}


function CommentActions({commentKey, depth}) {
    const s = CommentActionsStyle;
    const {commentActions, commentRightActions} = useConfig();
    return <View style={s.actionBar}>
        <View style={s.mainActions}>
            {commentActions?.map((Action, idx) => <Action key={idx} commentKey={commentKey} depth={depth} />)}
        </View>
        <View style={s.rightActions}>
            {commentRightActions?.map((Action, idx) => <Action key={idx} commentKey={commentKey} depth={depth} />)}
        </View>
    </View>
}
const CommentActionsStyle = StyleSheet.create({
    actionBar: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    mainActions: {
        flexDirection: 'row',
    },
    rightActions: {
        flexDirection: 'row',
    },
    leftAction: {
        marginRight: 20,
    },
    rightAction: {
        marginLeft: 20
    }
})

export function ActionReplyExceptToSelf({commentKey, depth}) {
    const comment = useObject('comment', commentKey);
    const parent = useObject('comment', comment?.replyTo);
    const personaKey = usePersonaKey();
    if (comment?.from == personaKey) return null;
    if (depth == 1 && parent?.from != personaKey) return null;
    return <ActionReply commentKey={commentKey} depth={depth} />
}

export function ActionReply({commentKey, depth}) {
    const datastore = useDatastore();
    const readOnly = useIsReadOnly();
    
    function onReply() {
        const oldReply = datastore.getSessionData(['replyToComment', commentKey]);
        datastore.setSessionData(['replyToComment', commentKey], !oldReply);
    }

    if (readOnly || depth > 1) return null;

    return <SubtleButton icon={Reply} label='Reply' onPress={datastore.needsLogin(onReply, 'reply')} padRight />
}

export function ActionEdit({commentKey}) {
    const datastore = useDatastore();
    const personaKey = usePersonaKey();
    const comment = useObject('comment', commentKey)
    const readOnly = useIsReadOnly();
    function onEdit() {
        if (!comment?.replyTo) {
            logEventAsync(datastore, 'edit-start-post', {commentKey});
            datastore.pushSubscreen('composer', {commentKey});
        } else {
            logEventAsync(datastore, 'edit-start-reply', {commentKey});
            const old = datastore.getSessionData(['editComment', commentKey]);
            datastore.setSessionData(['editComment', commentKey], !old);
        }
    }

    if (comment?.from != personaKey || readOnly) return null;
    return <SubtleButton icon={Edit} label='Edit' onPress={onEdit} />
}

export function ActionReport({commentKey}) {
    const personaKey = usePersonaKey();
    const comment = useObject('comment', commentKey);
    const datastore = useDatastore();

    function onReport() {
        logEventAsync(datastore, 'report-start', {commentKey});
        datastore.pushSubscreen('report', {commentKey});
    }

    if (comment?.from == personaKey) return null;
    return <SubtleButton ariaLabel='Report' icon={Flag} onPress={onReport}/>
}
 
export function Composer({about=undefS, commentKey, goBackAfterPost=false, topLevel=false, screenParams={}}) {
    const fetchedComment = useObject('comment', commentKey);
    const isNewComment = !fetchedComment;
    const [editedComment, setEditedComment] = useState(null as Comment | null);
    const personaKey = usePersonaKey();
    const datastore = useDatastore();
    const {composerSubtitle, composerTopWidgets} = useConfig();
    const newComment = useMemo(() => ({text: '', key: datastore.getNewKey()}), []); 
    const comment = editedComment ?? fetchedComment ?? newComment;
    useEffect(() => {
        logEventAsync(datastore, 'post-start', {commentKey: comment.key});
    }, []);
    const subtitle = composerSubtitle ? composerSubtitle({datastore, comment}) : 'Public Comment';

    function onEditingDone() {
        setEditedComment({text: '', about, key: datastore.getNewKey()});
        if (goBackAfterPost) {
            datastore.goBack();
        }
    }
    function onCancel() {
        logEventAsync(datastore, isNewComment ? 'post-cancel' : 'edit-cancel', {commentKey: comment.key});
        datastore.goBack();
    }

    return <View>
        <EditWidgets widgets={composerTopWidgets} 
            comment={comment} 
            setComment={setEditedComment} 
            screenParams={screenParams}
            onCancel={goBackAfterPost && onCancel} />
        <Byline type='large' userId={personaKey} subtitleLabel={subtitle} />
        <Pad size={24} />
        <EditComment big comment={comment} topLevel={topLevel}
            onCancel={goBackAfterPost && onCancel}
            setComment={setEditedComment} onEditingDone={onEditingDone} />
    </View>
}

function filterComments({datastore, comments, isAdmin, commentFilters}) {
    if (commentFilters) {
        return comments.filter(comment => 
            commentFilters.every(filter => filter({datastore, isAdmin, comment}))
        )
    } else {
        return comments;
    }
}

export function CommentsInput({about=null}) {
    const {commentInputPlaceholder, commentInputLoginAction} = useConfig();
    const datastore = useDatastore();
    return <TextFieldButton placeholder={commentInputPlaceholder} testID='comment-input'
                onPress={datastore.needsLogin(
                    () => datastore.pushSubscreen('composer', {about}), 
                    commentInputLoginAction)} 
    />
}

function rankComments({datastore, comments, commentRankers, sortMode, isReplies=false}) {
    if (!commentRankers?.length || !comments?.length) {
        return comments;
    }

    const ranker = sortMode 
        ? commentRankers.find(ranker => ranker.label == sortMode)
        : commentRankers[0];

    if (!ranker) {
        return comments;
    }

    return ranker.ranker({datastore, comments});
}

export function CommentPageTopWidgets({about=null}) {
    const {pageTopWidgets} = useConfig();
    const comments = useCollection('comment', {filter: {about, replyTo: null, deleted: null}, sortBy: 'time', reverse: true});
    return <View>
        {pageTopWidgets?.map((Widget,i) => 
            <Catcher key={i}><Widget comments={comments} /></Catcher>
        )}
    </View>
}

export function BasicComments({about=null, showInput=true, canPost=true}) {
    const datastore = useDatastore();
    const {noMoreCommentsMessage, commentRankers, pageTopWidgets, pageShowEmptyHelp,
        pageBottomWidgets, commentFilters, commentLimit, pageTopLeftWidgets, 
        pageTopRightWidgets, pageHiddenWidgets} = useConfig();
    const comments = useCollection('comment', {filter: {about, replyTo: null, deleted: null}, sortBy: 'time', reverse: true});
    const isAdmin = useIsAdmin();
    const sortMode = useSessionData(['ranking', 'sortMode']);
    const limit = commentLimit ? commentLimit() : comments.length;
    const filteredComments = filterComments({datastore, comments, isAdmin, commentFilters});    

    const rankedComments = rankComments({datastore, comments: filteredComments, commentRankers, sortMode});
    const shownComments = rankedComments.slice(0, limit); 
    const s = BasicCommentsStyles;

    return <View>
        <View style={s.container}>
            <View style={s.leftContainer}>
                {pageTopLeftWidgets?.map((Widget, i) => <Catcher key={i}><Widget comments={comments} /></Catcher>)}
            </View>
            <View style={s.rightContainer}>
                {pageTopRightWidgets?.map((Widget, i) => <Catcher key={i}><Widget comments={comments} /></Catcher>)}
            </View>
        </View>        
        {comments?.length == 0 && pageShowEmptyHelp &&
            <NoCommentsHelp />
        }
        <CatchList items={shownComments} renderItem={comment =>
            <Comment commentKey={comment.key} />
        } />
        {comments?.length > 0 && !(pageBottomWidgets?.length > 0) &&
            <PadBox top={40} bottom={20}><Banner color={colorSurfaceInfo}><RichText label={noMoreCommentsMessage} /></Banner></PadBox>
        }
        <View>
            {pageBottomWidgets?.map((Widget,i) => 
                <Catcher key={i}><Widget comments={comments} /></Catcher>
            )}
        </View>
        {pageHiddenWidgets?.map((Widget,i) => 
            <Catcher key={i}><Widget comments={comments} /></Catcher>
        )}
    </View>
}

const BasicCommentsStyles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    leftContainer: {
        flex: 1,
        alignItems: 'flex-start',
    },
    rightContainer: {
        flex: 1,
        alignItems: 'flex-end',
    },
});




export function ComposerScreen({about, commentKey=null, intro=null, screenParams={}}) {
    const {composerTopBanners} = useConfig();
    return <ConversationScreen>
        {composerTopBanners?.map((Banner, i) => <Banner key={i} about={about} />)}
        {intro}
        {/* <Pad size={20} /> */}
        <PadBox horiz={20} vert={20}>
            <Composer about={about} commentKey={commentKey} goBackAfterPost topLevel screenParams={screenParams} />
        </PadBox>  
    </ConversationScreen>
}