import { Narrow } from "component/basics";
import { EstimationSlider } from "../../../contrib/zdf/videovoting/estimationSlider";
import { DemoSection } from "system/demo";
import { CoinSplash } from "../../../contrib/zdf/videovoting/videoVotingAnimations";

export function EstimationSliderDemo() {
    const Animation = () => <CoinSplash />;

    return (
        <Narrow>
            <DemoSection text={"Default slider"}>
                <EstimationSlider value={0} min={0} max={50} onChangeValue={(c) => console.log("c", c)} />
            </DemoSection>
            <DemoSection text={"Custom step size"}>
                <EstimationSlider value={0} min={0} max={50} stepSize={5} onChangeValue={(c) => console.log("c", c)} />
            </DemoSection>
            <DemoSection text={"Snap on move"}>
                <EstimationSlider
                    snapOnMove
                    value={0}
                    min={0}
                    max={50}
                    stepSize={5}
                    onChangeValue={(c) => console.log("c", c)}
                />
            </DemoSection>
            <DemoSection text={"Custom unit"}>
                <EstimationSlider
                    value={0}
                    min={0}
                    max={2000}
                    stepSize={100}
                    unit="cm"
                    onChangeValue={(c) => console.log("c", c)}
                />
            </DemoSection>
            <DemoSection text={"Unit before value"}>
                <EstimationSlider
                    value={0}
                    min={0}
                    max={2000}
                    stepSize={100}
                    unit="$"
                    isUnitBeforeValue={true}
                    onChangeValue={(c) => console.log("c", c)}
                />
            </DemoSection>
            <DemoSection text={"Set inital position to custom value"}>
                <EstimationSlider
                    value={900}
                    min={0}
                    max={2000}
                    stepSize={100}
                    unit="€"
                    onChangeValue={(c) => console.log("c", c)}
                />
            </DemoSection>
            <DemoSection text={"With Animation"}>
                <EstimationSlider
                    value={0}
                    min={0}
                    max={2000}
                    stepSize={100}
                    unit="€"
                    onChangeValue={(c) => console.log("c", c)}
                    AnimationComponent={Animation}
                />
            </DemoSection>
        </Narrow>
    );
}