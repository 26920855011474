import { FunReactionsFeature, PersonalReactionsFeature, ReactionCircle, ReactionList, ReactionListPopup, ReactionListPopupPanel, ReactionPopup, ReactionPopupButton, ReactionPopupSelector, ReactionTypesFeature, ReactionTypesMultipleWidget, ReactionTypesPopupWidget, SeriousReactionsFeature } from "../../feature/question/ReactionTypesFeature";
import { StructureDemo } from "util/instance";
import { CLICK, POPUP, POPUP_CLOSE } from "system/demo";
import { FlowBox } from "component/basics";


const oldReactions = ReactionTypesFeature.defaultConfig.reactionTypes;
const seriousReactions = SeriousReactionsFeature.config.reactionTypes.REPLACE;
const personalReactions = PersonalReactionsFeature.config.reactionTypes.REPLACE;
const funReactions = FunReactionsFeature.config.reactionTypes.REPLACE;
const allReactions = [...seriousReactions, ...personalReactions, ...funReactions];

const configAll = {
    reactionTypes: allReactions,
}

const config = {
    reactionTypes: seriousReactions,
}


const collections = {
    reaction: [
        {reactTo: 1, reactionType: 'Relatable', from: 'b'},
        {reactTo: 1, reactionType: 'Insightful', from: 'a'},
    ],
    upvote: [
        {comment: 1, from: 'a'},
    ]
}

const reactionCounts = {
    'Insightful': 1,
    'Empathetic': 2,
    'Nuanced': 0,
}

export function reactionTypesStorySets() {return [
    {
        label: 'Reaction Types Widget', 
        config: configAll, collections,
        content: <ReactionTypesMultipleWidget comment={{key: 1}} />,
        stories: [
            {label: 'Remove Reaction', actions: [
                CLICK('Insightful')
            ]},
            {label: 'Add Reactions', actions: [
                CLICK('Relatable'),
                CLICK('Empathetic'),
            ]}
        ]
    },
    {
        label: 'Reaction Circle',
        config, collections,
        content: <FlowBox rowGap={10} columnGap={10}>
            <ReactionCircle emoji='🙏' />
            <ReactionCircle emoji='💡' />
            <ReactionCircle emoji='🤔' />
        </FlowBox>,
    },
    {
        label: 'Reaction Button',
        config, collections,
        content: <FlowBox rowGap={10} columnGap={10}>
            <ReactionPopupButton emoji='🙏' selected={true} onPress={() => {}}/>
            <ReactionPopupButton selected={false} onPress={() => {}} />
            <ReactionPopupButton emoji='🤔' hover={true} onPress={() => {}} />
        </FlowBox>,
    },
    {
        label: 'Reaction Popup Selector',
        config, collections,
        content: <ReactionPopupSelector onReact={() => {}} />,
    },
    {
        label: 'Reaction Popup',
        config,
        content: <ReactionPopup comment={{key: 1}} />,
        stories: [
            {label: 'Upvote', actions: [
                CLICK('reaction-popup-button'),
            ]},
            {label: 'React', actions: [
                CLICK('reaction-popup-button'),
                POPUP(CLICK('Insightful'))
            ]},
            {label: 'React and Undo', actions: [
                CLICK('reaction-popup-button'),
                POPUP(CLICK('Insightful')),
                CLICK('reaction-popup-button'),
            ]}

        ]
    },
    {
        label: 'Reaction List',
        config,
        content: <ReactionList emojiList={['🙏', '💡', '🤔']} totalCount={4} />,
    },
    {
        label: 'Reaction List Popup Panel',
        config,
        content: <ReactionListPopupPanel reactionCounts={reactionCounts} totalCount={3} />,
    },
    {
        label: 'Reaction List Popup',
        config, collections,
        content: <ReactionListPopup comment={{key: 1}} />,
    },
    {
        label: 'Reaction Types Popup Widget',
        config, collections,
        content: <ReactionTypesPopupWidget comment={{key: 1}} />,
        stories: [
            {label: 'Undo and React', actions: [
                CLICK('reaction-popup-button'),
                CLICK('reaction-popup-button'),
                POPUP(CLICK('Insightful')),
                CLICK('reaction-list-popup')
            ]}
        ]
    },
    {
        label: 'Reaction Types Popup Widget (first reaction)',
        config,
        content: <ReactionTypesPopupWidget comment={{key: 1}} />,
        stories: [
            {label: 'React', actions: [
                CLICK('reaction-popup-button'),
                POPUP(CLICK('Insightful')),
                CLICK('reaction-list-popup')
            ]},
            {label: 'Upvote', actions: [
                CLICK('reaction-popup-button'),
                POPUP_CLOSE()
            ]}
        ]
    }
]}

const comments = [
    {key: 1, from: 'a', text: 'Godzilla sucks'},
    {key: 2, from: 'b', text: 'Godilla rules'},
    {key: 3, from: 'c', text: 'Godzilla does not exist'},
]

const reactions = [
    {from: 'a', reactTo: 2, reactionType: 'Thank you'},
    {from: 'c', reactTo: 2, reactionType: 'Thank you'},
    {from: 'c', reactTo: 2, reactionType: 'New to me'},
    {from: 'b', reactTo: 1, reactionType: 'Disagree'},
]

const globals = {
    name: 'Is Godzilla Good?',
    questionAuthor: 'a',
    questionTime: Date.now(),
}

export function ReactionTypesFullDemo() {
    const collections = {comment: comments, reaction: reactions}
    return <StructureDemo testState={{globals, collections}} structureKey='question' 
        features={{reactiontypes: true, reactiontypesmultiplebuttons: true}} 
    />
}