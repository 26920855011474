import React, { useState } from "react";
import { Heading, LinkText, TextField, UtilityText } from "../component/text";
import { DropDownSelector, IconButton } from "../component/button";
import { ConversationScreen, FlowBox, HeaderBox, HorizBox, LoadingScreen, Narrow, Pad, PadBox } from "../component/basics";
import { useConfig } from "../util/features";
import { colorSurfaceSecondary, colorTextWarning } from "../component/color";
import { View } from "react-native";
import { TestState, useDatastore } from "../util/datastore";
import { DemoStorySet } from "./demo";
import { AccordionField } from "../component/form";
import { Catcher } from "./catcher";
import { sortBy, toBool } from "../util/util";
import { Structure } from "util/stdtypes";

export const ComponentDemoStructure : Structure<ComponentDemoConfig> = {
    key: 'componentdemo',
    name: 'Component Demo',
    screen: ComponentDemoScreen,
    hasFocusTrap: true,
    subscreens: {
        page: ComponentPageScreen,
        structure: StructureScreen,
        feature: FeatureScreen,
    },
    defaultConfig: {
        componentSections: [] as DemoSection[],
        structureSections: [] as DemoSection[],
        featureSections: [] as DemoSection[],

        openLinksInNewTab: false,
    }
}

export type ComponentDemoConfig = {
    componentSections: DemoSection[];
    structureSections: DemoSection[];
    featureSections: DemoSection[];
    openLinksInNewTab: boolean;
}

export type DemoSection = {
    label: string;  
    key: string;
    designUrl?: string;
    pages: DemoPage[];
}

export type DemoPage = {
    label: string;
    key: string;
    designUrl?: string;
    storySets?: () =>  DemoStorySet[];
    screen?: React.ComponentType<{}>;
}

export type DemoStorySet = TestState & {
    label: string;
    key: string;
    content: React.ReactNode;
    stories?: DemoStory[];
}

export type DemoStory = {
    label: string;
    key: string;
    actions?: DemoAction[];
}

export type DemoAction = {
    label: string;
    key: string;
    actions?: DemoAction[];
}


function mergeSectionsWithSameKey(sectionLists) {
    const mergedSections = {};

    sectionLists.forEach(section => {
        const { label, key, pages } = section;
        
        if (!mergedSections[key]) {
            mergedSections[key] = { label, key, pages: [...pages] };
        } else {
            mergedSections[key].pages = [
                ...mergedSections[key].pages,
                ...pages
            ];
        }
    });

    return Object.values(mergedSections);
}

function findPage({sections, pageKey}) : {section: DemoSection | null, page: DemoPage | null} {
    var foundPage = null;
    var foundSection = null;
    sections.forEach(section => {
        section.pages.forEach(page => {
            if (page.key == pageKey) {
                foundSection = section;
                foundPage = page;
            }
        })
    })
    return {section: foundSection, page:foundPage};
}

const languageOptions = [
    {key: 'english', label: 'English'},
    {key: 'french', label: 'French'},
    {key: 'german', label: 'German'},
    {key: 'french_belgian', label: 'French Belgian'},
    {key: 'french_canadian', label: 'French Canadian'},
]

function ComponentPageScreen({pageKey}) {
    const {componentSections} = useConfig() as ComponentDemoConfig;
    const {page, section} = findPage({sections:componentSections, pageKey});
    const [language, setLanguage] = useState('english');

    if (!page || !section) {
        return <LoadingScreen label='!No such page'/>
    }

    const designUrl = page?.designUrl ?? section.designUrl;

    return <ConversationScreen noWrap>
        <HeaderBox backgroundColor={colorSurfaceSecondary}>
            <Heading type="large" weight="medium" text={page.label} />
            <Pad size={8} />
            <HorizBox spread center>
                {designUrl && <LinkText text='Design Link' url={designUrl} />}
                {!designUrl && <UtilityText color={colorTextWarning} text='No design link' />}
                <DropDownSelector options={languageOptions} value={language} onChange={setLanguage} />
            </HorizBox>
        </HeaderBox>
        <Pad />
        <Narrow>
            {page.screen && React.createElement(page.screen)}
            {page.storySets && page.storySets()?.map((storySet, i) =>
                <Catcher key={i}>
                    <DemoStorySet language={language} storySet={storySet} />
                </Catcher>
            )}
        </Narrow>
    </ConversationScreen>
}

function StructureScreen({pageKey}) {
    const {structureSections} = useConfig();
    const {page} = findPage({sections:structureSections, pageKey});

    return <View style={{position: 'absolute', left:0, right:0, top:56, bottom:0, backgroundColor: 'red'}}>
        {page?.screen && React.createElement(page.screen)}
    </View>
}

function FeatureScreen({pageKey}) {
    const {featureSections} = useConfig();
    const {page, section} = findPage({sections:featureSections, pageKey});
    console.log('featureScreen', {page, section, pageKey,featureSections});
    
    return <View style={{position: 'absolute', left:0, right:0, top:56, bottom:0, backgroundColor: 'red'}}>
        {page?.screen && React.createElement(page.screen)}
    </View>
}



function DemoPageSection({search, text, screenKey, sections}) {
    const datastore = useDatastore();

    return <View>
        <Heading type="large" weight="medium" text={text} />
        {sections.map(section => 
            (!search || section.pages.some(page => page.label.toLowerCase().includes(search.toLowerCase()))) &&
                <AccordionField key={section.label} forceOpen={toBool(search)}
                    titleContent={<UtilityText weight='medium' text={section.label} />} >
                    <FlowBox>
                        {sortBy(section.pages, 'label').map((page, j) => 
                            page.label.toLowerCase().includes(search.toLowerCase()) &&
                            <PadBox vert={10} horiz={10} key={page.key}>
                                <IconButton text={page.label} onPress={() => 
                                    datastore.pushSubscreen(screenKey, {pageKey: page.key})
                                }  />
                            </PadBox>                    
                        )}
                    </FlowBox>
                </AccordionField>
        )}
    </View>
}


function ComponentDemoScreen() {
    const {componentSections, structureSections, featureSections} = useConfig();
    const [search, setSearch] = useState('');

    const mergedCommentSections = mergeSectionsWithSameKey(componentSections);
    const mergedStructureSections = mergeSectionsWithSameKey(structureSections);
    const mergedFeatureSections = mergeSectionsWithSameKey(featureSections);

    return <ConversationScreen noWrap>
        <Narrow>
            <TextField multiline={false} text='Search' placeholder='Search...' value={search} onChange={setSearch} />
            <Pad />
            <DemoPageSection search={search} text='Components' screenKey='page' sections={mergedCommentSections} />
            <Pad />
            <DemoPageSection search={search} text='Structures' screenKey='structure' sections={mergedStructureSections} />
            <Pad />
            <DemoPageSection search={search} text='Features' screenKey='feature' sections={mergedFeatureSections} />
        </Narrow>
    </ConversationScreen>
}
