export const AutoApproveFeature = {
    name: 'Log all comments in Mod Dashboard',
    key: 'autoapprovecomments',
    dev: true,
    config: {
        commentPostTriggers: [createAutoApprovedModTask],
    }
}

async function createAutoApprovedModTask({ datastore, commentKey, comment }) {
    if (!comment.violates) {
        await datastore.callServerAsync('moderationZdf', 'autoApprove', {
            type: 'comment', 
            key: commentKey
        });
    }
}