import { QuestionStructure } from "./question";
import { ModerationDashStructure } from "./moderationdash";
import { ArticleStructure } from "./article";
import { VideoVotingStructure } from "../contrib/zdf/structure/videovoting";
import { TopicStructure } from "./topic";
import { ComponentDemoStructure } from "../system/componentdemo";
import { ProfileStructure } from "./profile";
import { SimpleCommentsStructure } from "./simplecomments";
import { MigrationsStructure } from "../system/migrations";
import { LoginStructure } from "./login";
import { AdminStructure } from "./admin";
import { ModDashboardStructure } from "./zdf/moddashboard";
import { AccountStructure } from "./account";

export var structures = [
    QuestionStructure,
    ModerationDashStructure,
    ArticleStructure,
    VideoVotingStructure,
    TopicStructure,
    SimpleCommentsStructure,
    ComponentDemoStructure,
    ProfileStructure,
    MigrationsStructure,
    LoginStructure,
    AdminStructure,
    AccountStructure,
    ModDashboardStructure
]

export const specialScreens = ['teaser', 'fragmentRedirect', 'overlayScreen'];
