import { UtilityText } from "../../../component/text";
import { JudgementCard } from "../../../component/zdf/moddashboard/judgementcard";
import { CLICK, DemoSection, POPUP, WITHIN } from "../../../system/demo";
import { Datastore } from "../../../util/datastore";
import { defaultPersonaList, personaListToMap } from "../../../util/testpersonas";
import { modDashboardDemoData } from "./moddashboard.data";

export function JudgementCardDemo() {

    const modTask_awaiting = modDashboardDemoData.modTaskQueues.thread1.c_t1_awaiting_ai;
    const modTask_approved = modDashboardDemoData.modTaskQueues.thread1.c_t1_approved_auto;
    const modTask_rejected = modDashboardDemoData.modTaskQueues.thread1.c_t1_rejected;

    const myWatchState = {...modDashboardDemoData.watchStates.c_t1_approved_auto, amIViewing:true};
    const otherWatchState = modDashboardDemoData.watchStates.c_t1_approved_auto;

    return (
        <Datastore
            personaPreview={personaListToMap(defaultPersonaList)}
            testState={{
                modulePublic: modDashboardDemoData.modulePublic,
                serverCall: modDashboardDemoData.serverCalls,
            }}
        >
            <DemoSection text="Judgement Card - Approved comment">
                <JudgementCard
                    modTask={modTask_approved}
                    modAuthor={modDashboardDemoData.demoModAuthors[modTask_approved.key]}
                    isSelected={true}
                />
            </DemoSection>
            <DemoSection text="Judgement Card - Rejected comment">
                <JudgementCard
                    modTask={modTask_rejected}
                    modAuthor={modDashboardDemoData.demoModAuthors[modTask_rejected.key]}
                    isSelected={true}
                />
            </DemoSection>
            <DemoSection text="Judgment Card - Awaiting decision">
                <JudgementCard
                    modTask={modTask_awaiting}
                    modAuthor={modDashboardDemoData.demoModAuthors[modTask_awaiting.key]}
                    isSelected={true}
                />
            </DemoSection>
            <DemoSection text="View of different reviewing moderators">
                <UtilityText weight="strong" text="Comment card is selected"/>
                <JudgementCard
                    modTask={modTask_approved}
                    taskWatchState={myWatchState}
                    isSelected={true}
                    modAuthor={modDashboardDemoData.demoModAuthors[modTask_approved.key]}
                />
                <JudgementCard
                    modTask={modTask_approved}
                    taskWatchState={otherWatchState}
                    isSelected={true}
                    modAuthor={modDashboardDemoData.demoModAuthors[modTask_approved.key]}
                />
                <UtilityText weight="strong" text="Comment card is not selected"/>
                <JudgementCard
                    modTask={modTask_approved}
                    taskWatchState={myWatchState}
                    isSelected={false}
                    modAuthor={modDashboardDemoData.demoModAuthors[modTask_approved.key]}
                />
                <JudgementCard
                    modTask={modTask_approved}
                    taskWatchState={otherWatchState}
                    isSelected={false}
                    modAuthor={modDashboardDemoData.demoModAuthors[modTask_approved.key]}
                />
            </DemoSection>
        </Datastore>
    );
}


const modTaskNoWarning = modDashboardDemoData.modTaskQueues.thread1.c_t1_awaiting_ai;
const modTaskOneWarning = modDashboardDemoData.modTaskQueues.thread1.c_t1_approved_first_warning;
const modTaskTwoWarnings = modDashboardDemoData.modTaskQueues.thread1.c_t1_approved_second_warning_authorH;
const modTaskAutoApprove = modDashboardDemoData.modTaskQueues.thread2.c_t2_approved_auto;

function JudgementFlowDemo() {
    return <JudgementCard modTask={modTaskNoWarning} modAuthor={modDashboardDemoData.demoModAuthors[modTaskNoWarning.key]} isSelected={true} />
}

function JudgementFlowDemoOneWarning() {
    return <JudgementCard modTask={modTaskOneWarning} modAuthor={modDashboardDemoData.demoModAuthors[modTaskOneWarning.key]} isSelected={true} />
}

function JudgementFlowDemoTwoWarnings() {
    return (
        <JudgementCard
            modTask={modTaskTwoWarnings}
            modAuthor={modDashboardDemoData.demoModAuthors[modTaskTwoWarnings.key]}
            isSelected={true}
        />
    );
}

function JudgementFlowBlockDemo() {
    const modTask_blockable = modDashboardDemoData.modTaskQueues.thread2.c_t2_approved_auto;
    return (
        <JudgementCard
            modTask={modTask_blockable}
            modAuthor={modDashboardDemoData.demoModAuthors[modTask_blockable.key]}
            isSelected={true}
        />
    );
}

function JudgementFlowWarnBlockDisabledDemo() {
    const modTask_awaiting = modDashboardDemoData.modTaskQueues.thread1.c_t1_awaiting_ai;
    return (
        <JudgementCard
            modTask={modTask_awaiting}
            modAuthor={modDashboardDemoData.demoModAuthors[modTask_awaiting.key]}
            isSelected={true}
            isWarnDisabled={true}
        />
    );
}

function JudgementFlowUserAlreadyBlockedDemo() {
    const modTask_blockedUser = modDashboardDemoData.modTaskQueues.thread1.c_t1_awaiting_blocked;
    return (
        <JudgementCard
            modTask={modTask_blockedUser}
            modAuthor={modDashboardDemoData.demoModAuthors[modTask_blockedUser.key]}
            isSelected={true}
        />
    );
}

export function ModDashboardJudgementCardStorySets() {
    return [
        {
            label: "Judgement Card Flow No Warnings",
            serverCall: modDashboardDemoData.serverCalls,
            content: <JudgementFlowDemo/>,
            stories: [
                {
                    label: "Approve",
                    actions: [
                        CLICK("Approve")
                    ],
                },
                {
                    label: "Reject",
                    actions: [
                        CLICK("Reject"),
                        CLICK("Reject"),
                    ],
                },
                {
                    label: "Reject and change violations",
                    actions: [
                        CLICK("Reject"),
                        CLICK("Express your ideas with civility"),
                        CLICK("No violent content or calls for harm"),
                        CLICK("Avoid sarcasm and dismissive responses"),
                        CLICK("Reject"),
                    ],
                },
                {
                    label: "Reject and warn",
                    actions: [
                        CLICK("Reject"),
                        CLICK("Express your ideas with civility"),
                        CLICK("Avoid sarcasm and dismissive responses"),
                        WITHIN("judgementCard-" + modTaskNoWarning.key, CLICK("warn-toggle")),
                        CLICK("Reject"),
                    ],
                }
            ],
        }, 
        {
            label: "Judgement Card Flow One Warning",
            serverCall: modDashboardDemoData.serverCalls,
            content: <JudgementFlowDemoOneWarning/>,
            stories: [
                {
                    label: "Reject and warn",
                    actions: [
                        WITHIN("judgementCard-" + modTaskOneWarning.key, CLICK("Reject")),
                        WITHIN("judgementCard-" + modTaskOneWarning.key, CLICK("Express your ideas with civility")),
                        WITHIN("judgementCard-" + modTaskOneWarning.key, CLICK("No violent content or calls for harm")),
                        WITHIN("judgementCard-" + modTaskOneWarning.key, CLICK("warn-toggle")),
                        WITHIN("judgementCard-" + modTaskOneWarning.key, CLICK("Reject")),
                    ],
                }
            ],
        },
        {
            label: "Judgement Card Flow Two Warnings",
            serverCall: modDashboardDemoData.serverCalls,
            content: <JudgementFlowDemoTwoWarnings/>,
            stories: [
                {
                    label: "Reject and warn",
                    actions: [
                        WITHIN("judgementCard-" + modTaskTwoWarnings.key, CLICK("Reject")),
                        WITHIN("judgementCard-" + modTaskTwoWarnings.key, CLICK("Express your ideas with civility")),
                        WITHIN("judgementCard-" + modTaskTwoWarnings.key, CLICK("No violent content or calls for harm")),
                        WITHIN("judgementCard-" + modTaskTwoWarnings.key, CLICK("warn-toggle")),
                        WITHIN("judgementCard-" + modTaskTwoWarnings.key, CLICK("Reject")),
                    ],
                },
                {
                    label: "Reject, warn and block",
                    actions: [
                        WITHIN("judgementCard-" + modTaskTwoWarnings.key, CLICK("Reject")),
                        WITHIN("judgementCard-" + modTaskTwoWarnings.key, CLICK("Express your ideas with civility")),
                        WITHIN("judgementCard-" + modTaskTwoWarnings.key, CLICK("No violent content or calls for harm")),
                        WITHIN("judgementCard-" + modTaskTwoWarnings.key, CLICK("warn-toggle")),
                        WITHIN("judgementCard-" + modTaskTwoWarnings.key, CLICK("block-toggle")),
                        POPUP(CLICK("Block user")),
                        WITHIN("judgementCard-" + modTaskTwoWarnings.key, CLICK("Reject")),
                    ],
                }
            ],
        },
        {
            label: "Judgement Card Flow - User with multiple warnings",
            serverCall: modDashboardDemoData.serverCalls,
            content: <JudgementFlowBlockDemo/>,
            stories: [
                {
                    label: "Reject and block",
                    actions: [
                        CLICK("Reject"),
                        CLICK("Other"),
                        CLICK("No violent content or calls for harm"),
                        CLICK("block-toggle"),
                        POPUP(CLICK("Block user")),
                        CLICK("Reject"),
                    ],
                },
                {
                    label: "Reject and open user profile",
                    actions: [
                        CLICK("Reject"),
                        CLICK("Express your ideas with civility"),
                        CLICK("No violent content or calls for harm"),
                        CLICK("Avoid sarcasm and dismissive responses"),
                        CLICK("Show user profile"),
                        CLICK("Reject"),
                    ],
                },
            
                {
                    label: "Reject and warn",
                    actions: [
                        CLICK("Reject"),
                        CLICK("Express your ideas with civility"),
                        CLICK("Avoid sarcasm and dismissive responses"),
                        WITHIN("judgementCard-" + modTaskAutoApprove.key, CLICK("warn-toggle")),
                        CLICK("Reject"),
                    ],
                },
            ],
        },
        {
            label: "Judgement Card Flow - Blocked user",
            serverCall: modDashboardDemoData.serverCalls,
            content: <JudgementFlowUserAlreadyBlockedDemo/>,
            stories: [
                {
                    label: "Reject",
                    actions: [
                        CLICK("Reject"),
                        CLICK("Other"),
                        CLICK("Avoid sarcasm and dismissive responses"),
                        CLICK("Reject"),
                    ],
                }
            ],
        }
    ];
}



