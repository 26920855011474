import { ActionUpvote } from "feature/general/UpvoteFeature"
import { ActionReportComment, ReportPopup } from "../../feature/moderation/ReportCommentFeature"
import { ActionReply, Comment, Composer } from "component/comment"
import { CHECK_TEXT, CLICK, INPUT, POPUP } from "system/demo"
import { ModerationInfoBanner, ModerationReport } from "../../feature/moderation/AIPreModeratorFeature"
import { GUIDELINES } from "../../feature/question/CommunityGuidelinesFeature"

export const DemoModerationFeature = {
    key: 'demo_moderation',
    name: 'Demo - Moderation Features',
    config: {
        componentSections: [
            {label: 'Moderation', key: 'moderation', pages: [
                {
                    label: 'Report Comment', key: 'report_comment', 
                    designUrl: 'https://www.figma.com/design/MX0AcO8d0ZlCBs4e9vkl5f/PSI-Design-System?node-id=5220-2966&t=dlCMQ4bQ0Ij7PDHZ-1', 
                    storySets: reportCommentStorySets
                },
                {
                    label: 'AI Pre-Moderation', key: 'ai_premoderation',
                    designUrl: 'https://www.figma.com/design/F9OCIwB0YSBYvH0TTJ4LbP/AI-Moderation---Testing?node-id=525-4049&node-type=canvas&t=72IdegenOfhw86hI-0',
                    storySets: aiPreModerationStorySets
                },
            ]},
        ]
    }
}

const collections = {
    comment: [
        {key: 1, from: 'b', text: 'People who disagree with me should be tortured.'},
    ]
}

const config = {
    commentActions: [ActionReply, ActionUpvote],
    commentRightActions: [ActionReportComment]
}

const serverCall = {moderation: {
    sendToReview: () => {}
}}


function reportCommentStorySets() {return [
    {
        label: 'Report Comment',
        collections, config, serverCall,
        content: <Comment commentKey={1} />,
        stories: [
            {
                label: 'Report Comment', actions: [
                CLICK('report'), 
                POPUP(CLICK(GUIDELINES.NO_UNCIVIL)), 
                POPUP(CLICK(GUIDELINES.NO_HARM)), 
                POPUP(CLICK('Report'))
            ]}
        ]
    },
    {
        label: 'Admin Remove Comment',
        collections, config,
        roles: ['Moderator'],
        content: <Comment commentKey={1} />,
        serverCall: {moderation: {
            adminSetCommentViolates: () => {}
        }},
        stories: [
            {label: 'Remove Comment', actions: [
                CLICK('report'), 
                POPUP(CLICK('Remove')), 
            ]},
        ]
    },

    {
        label: 'Report Popup',
        collections, config, serverCall,
        content: <ReportPopup commentKey={1} onClose={() => {}} />,
        stories: [
            {label: 'Report Comment', actions: [
                CLICK(GUIDELINES.NO_SPAM), 
                CLICK('Report')
            ]}
        ]
    }
]}

const comment = {
    text: 'People who disagree with me should be tortured.',
    key: 1,
    violates: true,
    violations: ['No comments that call for or celebrate harm']
}

const commentWithMinorViolation = {
    text: 'People who disagree with me should be tortured.',
    key: 1,
    violates: true,
    violations: ['No comments that call for or celebrate harm'],
    minorViolation: true,
}

const commentInReview = {
    text: 'Vegetables are gross.',
    key: 2,
    violates: true,
    violations: ['No comments that call for or celebrate harm'],
    inReview: true
}

const commentWithHumanJudgement = {
    text: 'Vegetables are healthy.',
    key: 3,
    violates: true,
    violations: ['No comments that call for or celebrate harm'],
    humanJudgement: true
}

function aiPreModerationStorySets() {return [
    {
        label: 'Moderation Info Banner',
        content: <ModerationInfoBanner comment={{}} onCancel={() => {}} />,
    },
    {
        label: 'Moderation Report with no error',
        content: <ModerationReport comment={{}} onCancel={() => {}} />,
    },
    {
        label: 'Moderation Report',
        content: <ModerationReport comment={comment} onCancel={() => {}} />,
        serverCall: {moderation: {sendToReview: () => {}}, derivedviews: {runTriggers: ()=>{}}},
        stories: [
            {label: 'Appeal', actions: [
                CLICK('Send to a moderator for review'),
                POPUP(INPUT('appeal', 'This was counterspeech. They were mean first.')),
                POPUP(CLICK('Send to moderator')),
                POPUP(CLICK('close-modal'))
            ]},
            {label: 'Open Community Guidelines', actions: [
                CLICK('Community Guidelines'),
                // CHECK_TEXT("Community Guidelines"),
            ]}            
        ]
    },
    {  
        label: 'Moderation with Minor Violation',
        content: <ModerationReport comment={commentWithMinorViolation} onCancel={() => {}} />,
    },  
    {
        label: 'Moderation of Violating Comment in Review',
        collections: {comment: [commentInReview]},
        content: <Composer commentKey={2} />,
        structureKey: 'question',
        serverCall: {moderation: {
            checkCommentWithGpt: () => ({violates: true, rulesBroken: [2]}),
        }},
        features: ['premoderation', 'aimoderation', 'hide_rejected'],
        stories: [
            {label: 'Can Send To Moderator After Updating Comment', actions: [
                CHECK_TEXT("🔍 Under Review"),
                INPUT('comment-edit', `${commentInReview.text} - edited but still violates community guidelines`), 
                CLICK('Update'),
                CLICK('Send to a moderator for review'),
            ]},
        ]
    },
    {
        label: 'Violating Comment With Human Judgement',
        collections: {comment: [commentWithHumanJudgement]},
        content: <Composer commentKey={3} />,
        structureKey: 'question',
        features: ['hide_rejected'],
    },
    {
        label: 'Moderate with GPT and Jigsaw',
        content: <Composer />,
        structureKey: 'question',
        features: ['premoderation', 'aimoderation', 'jigsawmoderation'],
        serverCall: {moderation: {
            checkCommentWithGpt: ({commentText}) => ({violates: commentText.includes('Elvis'), rulesBroken: [7, 4]}),
            checkCommentWithJigsaw: ({text}) => ({violates: text.includes('silly') ? true : false})  
        }},
        stories: [
            {label: 'Moderate with GPT', actions: [
                INPUT('comment-edit', 'Elvis killed Kennedy because he was a lizard person'), 
                CLICK('Post'), 
            ]},
            {label: 'Moderate with Jigsaw', actions: [
                INPUT('comment-edit', 'You are silly'), 
                CLICK('Post'), 
            ]},
            {label: 'Good Comment', actions: [
                INPUT('comment-edit', 'I like cats'), 
                CLICK('Post'), 
            ]}
        ]
    },
    {
        label: 'Moderate with GPT Post anyway',
        content: <Composer />,
        structureKey: 'question',
        features: ['premoderation', 'aimoderation', 'top-tab'],
        serverCall: {moderation: {
            checkCommentWithGpt: ({commentText}) => ({
                violates: commentText.includes('Elvis'), 
                rulesBroken: [7, 4], 
                severe: commentText.includes('kill')
            }),
        }},
        stories: [
            {label: 'Minor violation', actions: [
                INPUT('comment-edit', 'Elvis controls the media'), 
                CLICK('Post'), 
            ]},
            {label: 'Severe violation', actions: [
                INPUT('comment-edit', 'Elvis controls the media, so we should kill him'), 
                CLICK('Post'), 
            ]},

            {label: 'Post anyway', actions: [
                INPUT('comment-edit', 'Elvis controls the media'), 
                CLICK('Post'),
                CLICK('Post anyway'),
            ]},
        ]
    },

    {
        label: 'Moderate Replies Strictly',
        collections: {comment: [
            {key: 'a', from: 'b', text: 'I like cats'},
        ]},
        content: <Comment commentKey='a' />,
        structureKey: 'question',
        serverCall: {moderation: {
            moderateReplyStrictly: ({text}) => ({allow: !text.includes('idiot')}),
        }},
        features: ['premoderation', 'strictreplymoderation'],
        stories: [
            {label: 'Good Reply', actions: [
                CLICK('Reply'),
                INPUT('comment-edit', 'I like cats'), 
                CLICK('Post'), 
            ]},
            {label: 'Bad Reply', actions: [
                CLICK('Reply'),
                INPUT('comment-edit', 'Only idiots like cats'), 
                CLICK('Post'), 
            ]}
        ]
    }
]}
