import { Pad, PadBox } from "component/basics"
import { Toggle } from "component/form"
import { Heading } from "component/text"
import { useDatastore, useModuleUserGlobalData } from "util/datastore"
import { logEventAsync } from "util/eventlog"

export const AccountVideoVotingSettingFeature = {
    name: 'Video Voting Setting',
    key: 'accountvideovotingsetting',
    config: {
        accountWidgets: [VideoVotingSetting]
    }
}

// TODO I am not sure if the setting makes sense as a feature. If you dont enable it by default, you would have to enable it for each user manually
export function VideoVotingSetting() {
    const datastore = useDatastore();

    const votingDisabledGloballyFromDatabase = useModuleUserGlobalData("videovoting", ["disabledGlobally"]);

    const toggleVideoVoting = async () => {
        const newVotingDisabledGlobally = !votingDisabledGloballyFromDatabase;
        
        logEventAsync(datastore, 'videovoting-change-global-setting', {
            votingDisabledGlobally: newVotingDisabledGlobally.toString()
        });

        datastore.setModuleUserGlobal("videovoting", ["disabledGlobally"], newVotingDisabledGlobally)
    }
    return <PadBox top={40}>
        <Heading label={"Video Voting"} />
        <Pad size={8} />
        <Toggle value={!votingDisabledGloballyFromDatabase} label='Enable voting for all videos' onChange={toggleVideoVoting}/>
    </PadBox>
}