import { DeviceInfo, getDeviceInfo } from "../platform-specific/deviceinfo";
import { Datastore, useDatastore } from "./datastore";
import { firebaseNewKey, getFirebaseUser, onFbUserChanged } from "./firebase";
import { callServerApiAsync } from "system/servercall";
import { getEnabledFeatures } from "./features";
import { useEffect, useState } from "react";

const hourMillis = 60*60*1000;


onFbUserChanged((userId) => {
    if (userId) {
        console.log('User changed', userId.uid);
    }
});

window.addEventListener('error', async (event) => {
    if (global_in_error_handler) {
        return; // avoid infinite error loop if this fails
    }
    global_in_error_handler = true;
    console.log('Caught an error', {error: event.error});
    logEventAsync(null, 'error', {
        name: event.error?.name || "Error",
        message: event.error?.message || event.message, 
        stack: event.error?.stack || null
    });
    global_in_error_handler = false;
})

export function logError({datastore, error, props}) {
    logEventAsync(datastore, 'error', {
        name: error?.name || "Error",
        message: error?.message, 
        stack: error?.stack, ...props});
}

window.addEventListener('unhandledrejection', async event => {
    if (global_in_error_handler) {
        return; // avoid infinite error loop if this fails
    }
    global_in_error_handler = true;
    const error = event.reason;
    console.log('Caught an unhandled promise rejection', {error});
    await logEventAsync(null, 'error', {
        name: error?.name || "Error",
        message: error instanceof Error ? error.message : String(error),
        stack: error instanceof Error ? error.stack : null
    });
    global_in_error_handler = false;
})

var global_last_event = null;
var global_in_error_handler = false;


let globalSessionKey : string | null = null;
let globalSessionTime : number | null = null;
let globalDeviceInfo : DeviceInfo | null = null;

export async function logEventAsync(datastore : Datastore | null, eventType, params) {
    try {
        let isNewSession = false;
        const preFirebaseUser = getFirebaseUser();
        const siloKey = datastore?.getSiloKey();
        const structureKey = datastore?.getStructureKey() || null;
        const instanceKey = datastore?.getInstanceKey() || null;
        const roles = datastore?.sessionData?.roles;
        const isAdmin = roles && roles.length > 0;
        const enabledFeatures = getEnabledFeatures(datastore);
    
        if (!globalSessionKey || !globalSessionTime || Date.now() - globalSessionTime > hourMillis){
            globalSessionKey = firebaseNewKey();
            globalSessionTime = Date.now();
            globalDeviceInfo = getDeviceInfo();
            isNewSession = true;      
            console.log('New session', globalSessionKey, globalDeviceInfo);
        }

        // IMPORTANT: We can't use datastore.callServerAsync here because datastore will
        // be null if we are called from an unhandled promise rejection
        const result = await callServerApiAsync({datastore, component: 'eventlog', funcname: 'logEvent', params: {
            eventType, sessionKey: globalSessionKey, structureKey, instanceKey, isNewSession, params, deviceInfo: globalDeviceInfo, siloKey, 
            isAdmin, enabledFeatures
        }});
        global_last_event = result?.eventKey ?? null;

        const postFirebaseUser = getFirebaseUser();

    } catch (e) {
        // Exceptions during logging need to be swallowed, otherwise we'll get stuck in an infinite loop
        console.error('Error logging event', e);
    }
}

export async function useLogEvent(eventKey, params, skip=false) {
    const datastore = useDatastore();
    useEffect(() => {
        if (!skip) {
            logEventAsync(datastore, eventKey, params);
        }
    }, [eventKey, JSON.stringify(params), skip]);
}


export function useStartTime(){
    const [startTime, setStartTime] = useState(Date.now());
    useEffect(() => {
        setStartTime(Date.now());
    }, []);
    return startTime;
}
