import { TabNavigationDemo, TabNavigationStorySets } from "../../demo/zdf/tabnavigationdemo"
import { VideoVotingOptionDemo } from "../../demo/zdf/videovoting/videovotingoptiondemo"
import { PercentageBarDemo } from "../../demo/zdf/percentagebardemo"
import { CountdownDemo } from "../../demo/zdf/countdowndemo"
import { CircularBarDemo } from "../../demo/zdf/circularbardemo"
import { VideoPlayerStateDemo } from "../../demo/zdf/customvideocontroldemo"
import { PositionableVideoOverlayDemo } from "../../demo/zdf/positionablevideooverlaydemo"
import { QuestionResultConnectedDemo, QuestionResultIndividualDemo, QuestionResultSliderDemo } from "../../demo/zdf/videovoting/videovotingquestionresultdemo"
import { VideoVotingQuestionDialogDemo, videovotingQuestionDialogStorySets } from "../../demo/zdf/videovoting/videovotingquestiondialogdemo"
import { ProfileSettingsFullDemo } from "../../demo/zdf/profilesettingsdemo"
import { VideoLinkDemo } from "../../demo/zdf/videolinkdemo"
import { PrivateCommentFeedbackFullDemo, PrivateThankYouMessageDemo } from "../../demo/zdf/privatecommentfeedbackdemo"
import { ConversationHelperFullDemo, ThoughtProvokingPromptAdminDemo, ThoughtProvokingPromptDemo } from "../../demo/zdf/conversationhelper/conversationhelperdemo"
import { VideoVotingIntroScreenStorySets } from "../../demo/zdf/videovoting/videovotinginitsessiondemo"
import { VideoVotingQuestionScreenStorySets } from "../../demo/zdf/videovoting/videovotingquestionscreendemo"
import { VideoVotingQuestionOverviewFeatureDemo, VideoVotingQuestionOverviewFeatureStorySets, VideoVotingQuestionOverviewFullStructureDemo } from "./videovoting/videovotingquestionoverviewdemo"
import { EstimationSliderDemo } from "./videovoting/videovotingestimationsliderdemo"
import { TypewriterDemo } from "../../demo/zdf/typewriterdemo"
import { BlacklistFeatureDemo } from "./moderation/blacklistdemo"
import { ModDashboardFullDemo, ModDashboardSidebarDemo } from "./moddashboard/moddashboarddemo"
import { JudgementCardDemo, ModDashboardJudgementCardStorySets } from "./moddashboard/judgementcarddemo"
import { ModDasboardCommentCardStorySet, ModDashboardCommentCardDemo } from "./moddashboard/moddashboardcommentsdemo"
import { ModDashboardThreadsDemo, ModDashboardThreadsStorySets } from "./moddashboard/moddashboardthreadsdemo"
import { FilterButtonStorySets } from "./filterbuttondemo"
import { TooltipDemo } from "./tooltipdemo"
import { ModDashboardUserProfileDemo, ModDashboardUserProfileStorySets } from "./moddashboard/moddashboarduserprofiledemo"
import { ModDashboardQueueStorySets } from "./moddashboard/moddashboardqueuedemo"
import { ToastDemo, toastStorySets } from "./toastdemo"
import { BlacklistDemo, blacklistStorySets } from "./blacklist/blacklistdemo"
import { CTAButtonWithLoadingSpinnerDemo } from "./ctabuttondemo"
import { toggleWithOptionalTextStorySets } from "./togglewithlabelwidgetdemo"


export const ZDFDemoFeature = {
    key: "demo_zdf",
    name: "Demo - ZDF",
    config: {
        componentSections: [
            {
                label: "ZDF",
                key: "zdf",
                pages: [
                    {
                        label: "Custom Video Control",
                        key: "customvideocontrol",
                        screen: VideoPlayerStateDemo,
                        designUrl: null,
                    },
                    {
                        label: "Positionable Video Overlay",
                        key: "positionablevideooverlay",
                        screen: PositionableVideoOverlayDemo,
                        designUrl:
                            "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=63-14800&t=xWo4yvIu6txN2nCu-0",
                    },
                    {
                        label: "Circular Progress Bar",
                        key: "circularbar",
                        screen: CircularBarDemo,
                        designUrl:
                            "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=20-13201&t=xWo4yvIu6txN2nCu-0",
                    },
                    {
                        label: "Countdown Hook",
                        key: "countdown",
                        screen: CountdownDemo,
                        designUrl:
                            "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=20-13201&t=xWo4yvIu6txN2nCu-0",
                    },
                    {
                        label: "Percentage Bar",
                        key: "percentagebar",
                        screen: PercentageBarDemo,
                        designUrl:
                            "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=20-13201&t=sfCmlnUlQ3yQYkyv-0",
                    },
                    {
                        label: "Video Voting Option",
                        key: "videovotingoption",
                        screen: VideoVotingOptionDemo,
                        designUrl:
                            "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=20-13201&t=sfCmlnUlQ3yQYkyv-0",
                    },
                    {
                        label: "Video Voting Question Dialog",
                        key: "videovotingquestiondialog",
                        screen: VideoVotingQuestionDialogDemo,
                        storySets: videovotingQuestionDialogStorySets,
                        designUrl:
                            "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=20-13201&t=T5gkaOoaJHS80PQM-0",
                    },
                    {
                        label: "Video Voting Intro Screen Flow",
                        key: "videovoting-flow-intro",
                        storySets: VideoVotingIntroScreenStorySets,
                        designUrl:
                            "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=63-37040&t=hLGgVovB0YEHjRM1-4",
                    },
                    {
                        label: "Video Voting Question Screen Flow",
                        key: "videovoting-flow-question",
                        storySets: VideoVotingQuestionScreenStorySets,
                        designUrl:
                            "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=20-2031&t=hLGgVovB0YEHjRM1-4",
                    },
                    {
                        label: "Video Voting Overview Feature",
                        key: "videovoting-feature-overview",
                        screen: VideoVotingQuestionOverviewFeatureDemo,
                        storySets: VideoVotingQuestionOverviewFeatureStorySets,
                        designUrl:
                            "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=181-57174&t=hLGgVovB0YEHjRM1-4",
                    },
                    {
                        label: "Voting Result - Individual Question",
                        key: "videovotingquestionresultindividual",
                        screen: QuestionResultIndividualDemo,
                        designUrl:
                            "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=63-14962&t=mr2JGbayv3pyFz0T-0",
                    },
                    {
                        label: "Voting Result - Connected Question",
                        key: "videovotingquestionresultconnected",
                        screen: QuestionResultConnectedDemo,
                        designUrl:
                            "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=20-13201&t=Hbdjgdaa66pDfl0e-0",
                    },
                    {
                        label: "Voting Result - Slider Question",
                        key: "videovotingquestionresultslider",
                        screen: QuestionResultSliderDemo,
                        designUrl:
                            "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=993-32082&t=R6IwBg0jSuAYXwPF-0",
                    },
                    {
                        label: "Tab Navigation",
                        key: "tabnavigation",
                        screen: TabNavigationDemo,
                        storySets: TabNavigationStorySets,
                        designUrl:
                            "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=63-14854&t=ZLRDLJDAgLQ3gDiB-0",
                    },
                    {
                        label: "Video Link",
                        key: "videolink",
                        screen: VideoLinkDemo,
                        designUrl:
                            "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=63-14854&t=mr2JGbayv3pyFz0T-0",
                    },
                    {
                        label: "Thought-Provoking Prompt",
                        key: "thoughtprovokingprompt",
                        screen: ThoughtProvokingPromptDemo,
                        designUrl:
                            "https://www.figma.com/design/VDCebWSu7lU0GynG1qmtF5/Conversation-Helper---Testing?node-id=368-19878&t=AJVqkONSwh2ew3R4-0",
                    },
                    {
                        label: "Thought-Provoking Prompt Admin",
                        key: "thoughtprovokingpromptadmin",
                        screen: ThoughtProvokingPromptAdminDemo,
                    },
                    {
                        label: "Private Thank-You Message",
                        key: "thankyoumessage",
                        screen: PrivateThankYouMessageDemo,
                        designUrl:
                            "https://www.figma.com/design/VDCebWSu7lU0GynG1qmtF5/Conversation-Helper---Testing?node-id=368-19878&t=AJVqkONSwh2ew3R4-0",
                    },
                    {
                        label: "Video Voting Estimation Slider",
                        key: "videovotingestimatorslider",
                        screen: EstimationSliderDemo,
                        designUrl:
                            "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=63-45952&t=69hcYnlspFGfbwli-0",
                    },
                    {
                        label: "Typewriter",
                        key: "typewriter",
                        screen: TypewriterDemo,
                    },
                    {
                        label: "Mod Dashboard Sidebar",
                        key: "moddashboardsidebar",
                        screen: ModDashboardSidebarDemo,
                        designUrl:"https://www.figma.com/design/ywh2aOQyFKATNi0oJMAePn/Moderation-Dashboard---Testing?node-id=492-4348&node-type=frame&t=MeMDyHA4ZiEmmIOp-0"
                    },
                    {
                        label: "Mod Dashboard Threads",
                        key: "moddashboardthreads",
                        screen: ModDashboardThreadsDemo,
                        storySets: ModDashboardThreadsStorySets,
                        designUrl:"https://www.figma.com/design/ywh2aOQyFKATNi0oJMAePn/Moderation-Dashboard---Testing?node-id=1727-19629&node-type=section&t=cSMfxtLTHrv4aOeS-0"
                    },
                    {
                        label: "Mod Dashboard Judgement Card",
                        key: "moddashboardjudgementcard",
                        screen: JudgementCardDemo,
                        storySets: ModDashboardJudgementCardStorySets,
                        designUrl: "https://www.figma.com/design/ywh2aOQyFKATNi0oJMAePn/Moderation-Dashboard---Testing?node-id=764-7744&t=Y8Cf8lrD1Ut6Aw5O-4"
                    },
                    {
                        label: "Mod Dashboard Queue",
                        key: "moddashboardqueue",
                        storySets: ModDashboardQueueStorySets,
                        designUrl: "https://www.figma.com/design/ywh2aOQyFKATNi0oJMAePn/Moderation-Dashboard---Testing?node-id=5219-162473&t=PkIzt4LdjjteVYd6-4"
                    },
                    {
                        label: "Mod Dashboard Comment Card",
                        key: "moddashboardcommentcard",
                        screen: ModDashboardCommentCardDemo,
                        storySets: ModDasboardCommentCardStorySet,
                        designUrl: "https://www.figma.com/design/ywh2aOQyFKATNi0oJMAePn/Moderation-Dashboard---Testing?node-id=764-7011&t=XhWPbsdsy1C7Y0ZE-4"
                    },
                    {
                        label: "Mod Dashboard User Profile",
                        key: "moddashboarduserprofile",
                        screen: ModDashboardUserProfileDemo,
                        storySets: ModDashboardUserProfileStorySets,
                        designUrl: "https://www.figma.com/design/ywh2aOQyFKATNi0oJMAePn/Moderation-Dashboard---Testing?node-id=764-7011&t=XhWPbsdsy1C7Y0ZE-4"
                    },
                    {
                        label: "Filter Button",
                        key: "filterbutton",
                        storySets: FilterButtonStorySets,
                        designUrl: "https://www.figma.com/design/MX0AcO8d0ZlCBs4e9vkl5f/PSI-Design-System?node-id=6295-30850&node-type=frame&t=20KG9DOYaEhzgPAI-0"
                    },
                    {
                        label: "Tooltip",
                        key:"tooltip",
                        screen: TooltipDemo,
                        designUrl: "https://www.figma.com/design/MX0AcO8d0ZlCBs4e9vkl5f/PSI-Design-System?node-id=9187-10638&t=w6l8M7VhyJfaBTd1-0"
                    },
                    {
                        label: "Toast",
                        key:"toast",
                        screen: ToastDemo,
                        designUrl: "https://www.figma.com/design/MX0AcO8d0ZlCBs4e9vkl5f/PSI-Design-System?node-id=9187-10638&t=w6l8M7VhyJfaBTd1-0",
                        storySets: toastStorySets
                    },
                    {
                        label: "Blocklist",
                        key: "blacklist",
                        screen: BlacklistDemo,
                        storySets: blacklistStorySets,
                        designUrl:"https://www.figma.com/design/ywh2aOQyFKATNi0oJMAePn/Moderation-Dashboard---Testing?node-id=7044-112598&t=Jj8CT8oBNkb7chS7-4",

                    },
                    {
                        label: "CTA Button with Loading Spinner",
                        key: "ctabuttonwithloadingspinner",
                        screen: CTAButtonWithLoadingSpinnerDemo,
                        designUrl: "https://www.figma.com/design/MX0AcO8d0ZlCBs4e9vkl5f/PSI-Design-System?node-id=1104-5678&t=hTUq5WLOvQ5wZg7B-0"
                    },
                    {
                        label: "Toggle with Label Widget",
                        key: "togglewithlabelwidget",
                        designUrl: "https://www.figma.com/design/MX0AcO8d0ZlCBs4e9vkl5f/PSI-Design-System?node-id=1415-5890&t=t38JZZUIMiNW4eHY-0",
                        storySets: toggleWithOptionalTextStorySets,
                    }
                ],
            },
        ],
        structureSections: [
            {
                label: "ZDF",
                key: "zdf",
                pages: [
                    { label: "Conversation Helper", key: "conversationhelper", screen: ConversationHelperFullDemo },
                    {
                        label: "Private Comment Feedback",
                        key: "privatecommentfeedback",
                        screen: PrivateCommentFeedbackFullDemo,
                    },
                    {
                        label: "Profile Settings",
                        key: "profilesettingsfull",
                        screen: ProfileSettingsFullDemo,
                    },
                    {
                        label: "Mod Dashboard",
                        key: "moddashboardfull",
                        screen: ModDashboardFullDemo,
                    },
                ],
            },
        ],
        featureSections: [
            {
                label: "ZDF",
                key: "zdf",
                pages: [
                    {
                        label: "Question - Video Voting Overview",
                        key: "question-feature-videovoting-overview",
                        screen: VideoVotingQuestionOverviewFullStructureDemo,
                    },
                    {
                        label: 'Question - Blacklist',
                        key: 'blacklistdemo',
                        screen: BlacklistFeatureDemo,
                    },
                ],
            },
        ],
    },
};