import { View } from "react-native";
import { TextButton } from "component/button"
import { gotoInstance } from "util/navigate"
import { Card, HorizBox, Pad, PadBox } from "component/basics"
import { ProfilePhoto } from "component/people"
import { Heading, Paragraph, UtilityText } from "component/text"
import { useDatastore, useDerivedCollection } from "util/datastore"
import { formatMiniDate } from "component/date"
import { Toggle } from "component/form"
import { useEditableField, useProfileField } from "structure/profile"
import { useIsAdmin } from "component/admin"
import { colorTextSecondary } from "component/color"
import { useLanguage } from "component/translation";

export const ProfileAnswersFeature = {
    name: 'Discussion History',
    key: 'profileanswers',
    config: {
        profileModules: [{
            label: 'Discussion History', key: 'profileanswers',
            view: ViewProfileAnswers,
            edit: EditProfileAnswers,
            checkForErrors: () => null,
            makePreview: () => {},
        }],
        // profileWidgets: [ProfileAnswersWidget]
    }
}

export function DerivedAnswer({answer}) {
    const datastore = useDatastore();
    function onGotoQuestion() {
        gotoInstance({structureKey: 'question', instanceKey: answer.instanceKey});
    }
    const language = useLanguage();
    const time = formatMiniDate(answer.time, language);
    return <PadBox top={20}>
        <Card>
            <HorizBox spread center>
                <HorizBox shrink>
                    <ProfilePhoto userId={datastore.getInstanceKey()} />
                    <Pad size={12} />
                    <View style={{flex: 1}}>
                        <TextButton type='small' text={answer.question} strong onPress={onGotoQuestion} />
                        <Pad size={4} />
                        {answer.inReview ? 
                            <UtilityText label='Your response is under review' />
                        : answer.violates ? 
                            <UtilityText label='Your response was rejected by a moderator' />
                        :
                            <Paragraph numberOfLines={1} text={answer.text} />
                        }   
                    </View>
                </HorizBox>
                <Pad size={12} />
                <UtilityText color='grey' text={time} />

            </HorizBox>
        </Card>
    </PadBox>
}

export function ViewProfileAnswers() {
    const answers = useDerivedCollection('answer');
    const isAdmin = useIsAdmin();
    const visible = useProfileField('answersVisible', true);

    if (!isAdmin && !visible) {
        return <PadBox top={20}>
            <Heading label='Discussion History' />
            <Pad />
             <UtilityText label="This user's history is private." />
        </PadBox>
    }

    return <PadBox top={20}>
        <Heading label='Discussion History' />
        {!visible && <PadBox top={8}><UtilityText color={colorTextSecondary} label='Visible to admins' /></PadBox>}
        <Pad size={8} />
        {answers.map(answer => <DerivedAnswer key={answer.key} answer={answer} />)}
    </PadBox>    
}

export function EditProfileAnswers() {
    const [visible, setVisible] = useEditableField('answersVisible', true);
    return <View>
        <Heading label='Discussion History' />
        <Pad size={12} />
        <Toggle label='Visible to everyone' value={visible} onChange={setVisible} />
        <Pad size={12} />
        <UtilityText 
            color={colorTextSecondary}
            label='Admins and Moderators can view your history.' />
    </View>
}