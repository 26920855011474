import { TeaserScreen } from "system/teaser";
import { useCollection, useInstanceKey } from "util/datastore";
import { EmbeddedInstance } from "util/instance";
import { keyToUrl } from "util/util";

export const EmbeddedQuestionTeaserFeature = {
    name: 'Embedded Question Teaser',
    key: 'embedded-question-teaser',
    dev: true,
    config: {
        teaser: EmbeddedQuestionTeaser
    }
}

export function EmbeddedQuestionTeaser() {
    const instanceKey = useInstanceKey();
    const entryUrl = keyToUrl(instanceKey);
    const questions = useCollection('question').filter(q => !q.deleted);

    if (questions.length == 1) {
        return <EmbeddedInstance structureKey='question' instanceKey={questions[0].key} screenKey='teaser' globalParams={{entryUrl}}/>
    } else if (questions.length > 1) {
        return <TeaserScreen><UtilityText type='small' label='Multiple questions not supported by this teaser format' /></TeaserScreen>
    } else {
        return <TeaserScreen><UtilityText type='small' label='No questions found' /></TeaserScreen>
    }
}