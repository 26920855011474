import React, { createContext, useContext, useEffect, useRef } from "react";
import { usePlayedVideo } from "../../system/videotime";
import { useDatastore, useSessionData } from "../../util/datastore";
import { View } from "react-native-web";

/**
 * @typedef {Object} VideoPlayerState
 * @property {boolean} paused - Indicates if the video is currently paused
 * @property {number} currentTime - The current playback time of the video in seconds.
 * @property {number} duration - The total duration of the video in seconds.
 * @property {boolean} ended - Whether the video has ended
 * @property {string} src - The URL of the video.
 * @property {boolean} ready - The URL of the video.
 * @property {Function} play - Function to start the video playback.
 * @property {Function} pause - Function to pause the video playback.
 * @property {Function} seek - Function to change the current time of the video.
 */

// Custom VideoContext. It should provide all necessary data from a played video
export const VideoPlayerStateContext = createContext({})

/**
 * Custom hook to access the video player state and actions.;
 *
 * @returns {VideoPlayerState} The current state and actions of the video player.
 * @throws {Error} If the hook is not used within a VideoPlayerStateContextProvider.
 */
export const useVideo = () => {
    const context = useContext(VideoPlayerStateContext)
    if (!context) {
        throw new Error('useVideo must be used within a VideoPlayerStateContextProvider');
    }
    return context;
};


export function VideoPlayerStateContextProvider({children, key, useVideoFromArticle=true}) {
    const externalVideoState = usePlayedVideo();
    const registeredVideoState = useRegisteredVideo(key)

    return <VideoPlayerStateContext.Provider id={"VideoPlayerStateContextProvider"} value={useVideoFromArticle ? externalVideoState : registeredVideoState}>
        {children}
    </VideoPlayerStateContext.Provider>
}


/**
 * Custom hook to access the video player state and actions.
 *
 * @returns {VideoPlayerState} The current state and actions of the video player.
 */
export function useRegisteredVideo(key = 'default') {
    return useSessionData('videoState' + key) ?? {}
}

// Simple video component, which uses the video state context
export function SimpleVideo({src, controls, key="default"}) {
    const datastore = useDatastore()
    const videoRef = useRef(null)

    useEffect(() => {
        if (!videoRef.current) return;
        const video = videoRef.current;

        const updateVideoState = () => {
            const play = () => video.play();
            const pause = () => video.pause();
            const seek = (time) => (video.currentTime = time);

            const playerInfo = {
                currentTime: video.currentTime,
                paused: video.paused,
                ended: video.ended,
                ready: video.readyState >= HTMLMediaElement.HAVE_CURRENT_DATA,
                duration: video.duration,
                src: video.src,
                play,
                pause,
                seek
            };

            datastore.setSessionData('videoState' + key, playerInfo);
        };

        // Event listeners
        video.addEventListener('play', updateVideoState);
        video.addEventListener('pause', updateVideoState);
        video.addEventListener('timeupdate', updateVideoState);
        video.addEventListener('ended', updateVideoState);
        video.addEventListener('canplaythrough', updateVideoState);

        // Initial update
        updateVideoState();

        return () => {
            if (!video) return; // Ensure video exists before removing listeners

            video.removeEventListener('play', updateVideoState);
            video.removeEventListener('pause', updateVideoState);
            video.removeEventListener('timeupdate', updateVideoState);
            video.removeEventListener('ended', updateVideoState);
            video.removeEventListener('canplaythrough', updateVideoState);
        };

    }, [videoRef.current])

    return <View>
        <video src={src} controls={controls} ref={videoRef}> </video>
    </View>
}