import { View } from 'react-native';
import { useDatastore, useSessionData } from 'util/datastore';
import { DropDownSelector } from 'component/button';
import { useConfig } from 'util/features';
import { logEventAsync } from 'util/eventlog';

export const RankingSelectorFeature = {
    name: 'Ranking Selector',
    key: 'ranking-selector',
    dev: true,
    config: {
        pageTopLeftWidgets: [() => <RankingSelector />]
    }
}

export function RankingSelector() {
    const {commentRankers} = useConfig();
    const firstRanker = commentRankers[0];
    const sortMode = useSessionData(['ranking', 'sortMode']) || firstRanker?.label;
    const datastore = useDatastore();

    console.log('commentRankers', commentRankers);
    console.log('sortMode', sortMode);

    if (commentRankers.length <= 1) {
        return null;
    }

    const sortOptions = commentRankers.map(ranker => ({ 
        label: ranker.label,
        key: ranker.label
    }));

    function onChange(value) {
        console.log('onChange', value);
        datastore.setSessionData(['ranking', 'sortMode'], value);
        logEventAsync(datastore, 'ranking-changed', {sortMode: value});
    }

    return <View>
        <DropDownSelector label="Sort by" options={sortOptions} value={sortMode} onChange={onChange} />
    </View>
}
