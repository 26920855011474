import { StructureDemo } from "util/instance"

export function ProfileSettingsFullDemo() {
    const globals = { initialized: true };

    return (
        <StructureDemo
            testState={{globals}}
            structureKey="profile"
            instanceKey='a'
            features={{ profileeditname: true, profilesettings: true, profilevideovotingsetting: true }}
        />
    );
}