import { Pad } from "component/basics";
import { CTAButton } from "component/button";
import { maybeEnableUsabilitySurveyAfterTopLevelComment, SatisfactionSurveyModal, ShowUsabilitySurveyIfEnabled, UsabilitySurveyModal } from "feature/general/SurveyFeature"
import { View } from "react-native";
import { CLICK, POPUP, WITHIN } from "system/demo"
import { useDatastore } from "util/datastore";

export const SurveyDemoFeature = {
    key: 'demo_survey',
    name: 'Survey Demo',
    config: {
        componentSections: [
            {label: 'General', key: 'general', pages: [
                {
                    designUrl: 'https://www.figma.com/design/vuLRIbXjvhyYBXnNoT6CV3/CSAT-and-UMUX?node-id=30-750&t=WplnqHTvJZ9Ysfs1-0',
                    label: 'Survey', key:'survey', 
                    storySets: surveyStorySets
                }
            ]}
        ]
    }
}

function ShowSurveyIfEnabledDemo() {
    const datastore = useDatastore();

    return <View>
        <CTAButton label='!Show Survey' onPress={() => datastore.setSessionData('usabilitySurveyEnabled', true)} />
        <Pad />
        <CTAButton label='!Maybe show survey' onPress={() => maybeEnableUsabilitySurveyAfterTopLevelComment({datastore, comment: {}})} />
        <ShowUsabilitySurveyIfEnabled/>
    </View>
}

function surveyStorySets() {return [
    {
        label: 'Satisfaction Survey',
        content: <SatisfactionSurveyModal/>,
        stories: [{
            label: 'Very satisfied',
            actions: [
                CLICK('Very satisfied'),
                CLICK('Quality of comments and replies'),
                CLICK('Ease of use'),
                CLICK('Interest in the topic'),
                CLICK('Needing to log in to participate'),
                CLICK('Quality of the question guiding the conversation'),
                CLICK('Submit'),
            ]
        }]
    },
    {
        label: 'Usability Survey',
        content: <UsabilitySurveyModal/>,
        stories: [{
            label: 'Easy and meets needs',
            actions: [
                WITHIN('survey-easy-to-use', CLICK('Agree')),
                WITHIN('survey-meets-needs', CLICK('Strongly agree')),
                CLICK('Submit'),
            ]
        }]
    },
    {
        label: 'Show Usability Survey if Enabled',
        content: <ShowSurveyIfEnabledDemo/>,
        stories: [
            {label: 'Show Survey', actions: [
                CLICK('!Show Survey'),
                POPUP(CLICK('close-modal')),
            ]},
            {label: 'Maybe show survey', actions: [
                CLICK('!Maybe show survey'),
                POPUP(CLICK('close-modal')),
            ]},
        ]
    }
]}