import { Chat, Flag, IdManagement, Link, Time } from "@carbon/icons-react";
import { useEffect, useMemo, useState } from "react";
import { Image, StyleSheet, View } from "react-native";
import { TabBar, TabContent, TabNavigation } from "../../../contrib/zdf/tabnavigation";
import { ModDashboardQueue, ModerationFilters } from "./moddashboardqueue";
import { keyToUrl } from "../../../util/util";
import { REPLACE_ZDF_LinkText } from "../text";
import { ParentDatastoreProvider } from "../parentdatastore";
import { usePersonasByIds } from "../moderation";
import { REPLACE_ZDF_FilterButton } from "../button";
import { useCollection, useDatastore, useInstanceKey, useModulePublicData, useModuleUserGlobalData, usePersonaObject } from "../../../util/datastore";
import { EmbeddedInstance } from "../../../util/instance";
import { colorAmbiguousHighlight, colorAmbiguousWhite, colorLightGreen, colorSurfaceSecondary, colorTagEmphasized, colorTextSecondary, colorTextWarning } from "../../color";
import { Center, HorizBox, HoverView, LoadingScreen, Pad, PadBox } from "../../basics";
import { Heading, Paragraph, UtilityText } from "../../text";
import { SpacedArray } from "../../../system/demo";
import { gotoInstance } from "../../../util/navigate";
import { CTAButton, DropDownSelector, Tag, TextButton } from "../../button";
import { Modal } from "../../modal";
import { getQueueSubsetCounts, useFilteredQueues } from "./moddashboardqueuehooks";
import { useInstanceParams } from "../../../util/params";
import { useHasCapability } from "../../admin";
import { Banner } from "../../banner";
import { ModDashboardDateTime } from "./datetime";

const useModerationThreads = ({ count = 1, sortOrder = "newest", filters = {} }) => {

    const questions = useModulePublicData("question");
    const questionsWithKeys = Object.entries(questions ?? {}).map(([key, value]) => ({ key, structureKey: "question", ...value }));
    const topics = useModulePublicData("topic");
    const topicsWithKeys = Object.entries(topics ?? {}).map(([key, value]) => ({ key, structureKey: "topic", ...value }));

    const awaitingDecisionCounts = useModulePublicData("moderation", ["thread_queue_count"], {defaultValue: {}});

    const [allThreads, setAllThreads] = useState(null);
    const [sortedThreads, setSortedThreads] = useState(null);
    const [remainingThreadCount, setRemainingThreadCount] = useState(0);
    const [newThreadCount, setNewThreadCount] = useState(0);
    const [time, setTime] = useState(sortOrder === "newest" ? Date.now() : 0);

    useEffect(() => {
        if (!questions && !topics) {
            return ;
        }

        // Convert the questions and topics into a list of thread objects with their keys
        let threadObjects = [...questionsWithKeys, ...topicsWithKeys];
        threadObjects = threadObjects.map((thread) => {
            const awaitingDecisionCount = awaitingDecisionCounts?.[thread?.key]?.awaiting_decision_count ??  0;
            return { awaitingDecisionCount, ...thread };
          })
        setAllThreads(threadObjects);

        // Count new threads (those newer than the given timestamp)
        const newThreads =
            sortOrder === "newest"
                ? threadObjects.filter((thread) =>
                      sortOrder === "newest" ? thread.time > time : thread.time < time
                  )
                : [];
        // Set the new thread count
        setNewThreadCount(newThreads.length);

        // Applying filters

        // Multiple filters can be selected at once. In that case, we need to combine the lists of threads.
        let combinedFilteredThreads = [];

        if (filters && filters.open) {
            combinedFilteredThreads = combinedFilteredThreads.concat(
                Object.values(threadObjects).filter(
                    (thread) => thread.readonly === false || thread.readonly === undefined
                )
            );
        }

        if (filters && filters.closed) {
            combinedFilteredThreads = combinedFilteredThreads.concat(
                Object.values(threadObjects).filter((thread) => thread.readonly === true)
            );
        }

        if (filters && filters.heated) {
            combinedFilteredThreads = combinedFilteredThreads.concat(
                Object.values(threadObjects).filter((thread) => thread.heated === true)
            );
        }

        if (filters && filters.flagged) {
            combinedFilteredThreads = combinedFilteredThreads.concat(
                Object.values(threadObjects).filter((thread) => thread.awaitingDecisionCount != 0)
            );
        }
        
        // No filter selected = show all threads
        if (!filters || (!filters.open && !filters.closed && !filters.heated && !filters.flagged)) {
            combinedFilteredThreads = Object.values(threadObjects);
        }

        // Remove duplicate threads because some threads can appear under multiple filters, e.g. "heated" and "open".
        const threadsStringified = combinedFilteredThreads.map((thread) => JSON.stringify(thread));
        const threadsStringifiedWithoutDupes = threadsStringified.filter(
            (stringifiedThread, idx) => threadsStringified.indexOf(stringifiedThread) === idx
        );
        combinedFilteredThreads = threadsStringifiedWithoutDupes.map((stringifiedThread) =>
            JSON.parse(stringifiedThread)
        );

        // Sorting threads

        // Sort by time and filter out threads older than or equal to the timestamp
        const newSortedThreads = combinedFilteredThreads
            .filter((thread) => (sortOrder === "oldest" ? thread.time >= time : thread.time <= time ))
            .sort((a, b) => {
                if (sortOrder === "newest") {
                    // Sort newest first
                    return b.time - a.time;
                } else if (sortOrder === "oldest") {
                    // Sort oldest first
                    return a.time - b.time;
                }
                // Fallback (by newest)
                return b.time - a.time;
            });
        setRemainingThreadCount(newSortedThreads.length - count);
        setSortedThreads(newSortedThreads.slice(0, count));
    }, [questions, topics, awaitingDecisionCounts, time, count, filters]);

    useEffect(() => {
        setTime(sortOrder === "oldest" ? 0 : Date.now() );
    }, [sortOrder]);

    return {
        allThreads: allThreads,
        threads: sortedThreads,
        remainingThreadCount: remainingThreadCount,
        newThreadCount: newThreadCount
    }
};

export function ModDashboardThreadsOverview() {
    const datastore = useDatastore();

    const savedSortingOrder = useModuleUserGlobalData("moderation", ["sortOrder", "threads"], {once: true});
    const [sortingOrder, setSortingOrder] = useState(null)

    const [loadAmount, setLoadAmount] = useState(20);
    const [loadTimestamp, setLoadTimestamp] = useState(Date.now());
    const [selectedFilters, setSelectedFilters] = useState({ open: false, closed: false, heated: false, flagged: false });
    const params = useInstanceParams();

    const sortingOrderOptions = [
        { key: "newest", label: "Newest" },
        { key: "oldest", label: "Oldest" },
    ];

    const { threads, allThreads, newThreadCount, remainingThreadCount } = useModerationThreads({
        timestamp: loadTimestamp,
        sortOrder: sortingOrder ?? savedSortingOrder,
        count: loadAmount,
        filters: selectedFilters,
    });

    const activeThreadsCount = allThreads?.filter((thread) => !thread.readonly).length;
    const inactiveThreadsCount = allThreads?.filter((thread) => thread.readonly).length;
    const threadInDetail = allThreads?.find(thread=>thread.key === params?.threadId);
    const heatedThreads = allThreads?.filter((thread) => thread.heated).length;
    const flaggedThreads = allThreads?.filter((thread) => thread.awaitingDecisionCount > 0).length;

    const authorIds = useMemo(() => {
        return threads?.map(thread => thread.from) || [];
    }, [threads]);
    const authors = usePersonasByIds(authorIds);

    const loadMoreThreads = () => {
        setLoadAmount(loadAmount + 20)
    }

    const loadNewThreads = () => {
        setLoadAmount(loadAmount + newThreadCount)
        setLoadTimestamp(Date.now())
    }

    useEffect(() => {
        // Recalculate the heated flag for the threads on initial render
        datastore.callServerAsync("moderationZdf", "calculateHeated", {})
    }, [])

    const handleSortingOrderChange = (newSortingOrder) => {
        datastore.setModuleUserGlobal("moderation", ["sortOrder","threads"], newSortingOrder)
        setSortingOrder(newSortingOrder)
    };

    const renderedContent = (
        <>
            {threads && threadInDetail && params?.threadId && (
                <ParentDatastoreProvider>
                    <EmbeddedInstance
                        structureKey={threadInDetail?.structureKey}
                        instanceKey={params.threadId}
                        screenKey={"moderationDetails"}
                        features={{enablemoddashboardthreaddetailview: true}}
                    />
                </ParentDatastoreProvider>
            )}
            {!params?.threadId && (
                <View style={{ backgroundColor: colorSurfaceSecondary, flexGrow: 1, flexShrink: 1 }}>
                    <PadBox vert={80}>
                        <Heading level={1} label={"Threads"} type="large" weight="medium"/>
                        <Pad size={26} />
                        <SpacedArray pad={8} horiz>
                            <REPLACE_ZDF_FilterButton
                                selected={selectedFilters.open}
                                label={"Open Threads"}
                                count={activeThreadsCount || "0"}
                                countColor={colorTextSecondary}
                                onPress={() => {
                                    selectedFilters.open
                                        ? setSelectedFilters({ ...selectedFilters, open: false })
                                        : setSelectedFilters({ ...selectedFilters, open: true });
                                }}
                            />

                            <REPLACE_ZDF_FilterButton
                                selected={selectedFilters.closed}
                                label={"Closed Threads"}
                                count={inactiveThreadsCount || "0"}
                                countColor={colorTextSecondary}
                                onPress={() => {
                                    selectedFilters.closed
                                        ? setSelectedFilters({ ...selectedFilters, closed: false })
                                        : setSelectedFilters({ ...selectedFilters, closed: true });
                                }}
                            />

                            <REPLACE_ZDF_FilterButton
                                selected={selectedFilters.heated}
                                label={"Heated Threads"}
                                count={heatedThreads || "0"}
                                countColor={colorTextSecondary}
                                onPress={() => {
                                    selectedFilters.heated
                                        ? setSelectedFilters({ ...selectedFilters, heated: false })
                                        : setSelectedFilters({ ...selectedFilters, heated: true });
                                }}
                            />

                            <REPLACE_ZDF_FilterButton
                                selected={selectedFilters.flagged}
                                label={"Threads with flagged comments"}
                                count={flaggedThreads || "0"}
                                countColor={colorTextSecondary}
                                onPress={() => {
                                    selectedFilters.flagged
                                        ? setSelectedFilters({ ...selectedFilters, flagged: false })
                                        : setSelectedFilters({ ...selectedFilters, flagged: true });
                                }}
                            />

                        </SpacedArray>
                        <View>
                            <Pad size={26} />
                            <DropDownSelector
                                options={sortingOrderOptions}
                                value={sortingOrder ?? savedSortingOrder}
                                onChange={handleSortingOrderChange}
                            />
                            <View>
                                <Pad size={16} />
                                {threads ? (
                                    <ModDashboardThreadsOverviewList
                                        threads={threads}
                                        authors={authors}
                                        newThreadCount={newThreadCount}
                                        remainingThreadCount={remainingThreadCount}
                                        onLoadMore={loadMoreThreads}
                                        onLoadNew={loadNewThreads}
                                    />
                                ) : (
                                    <NoThreadsBanner />
                                )}
                            </View>
                        </View>
                    </PadBox>
                </View>
            )}
        </>
    );

    return <>
        {renderedContent}
    </>
}

export function NoThreadsBanner() {
    return (
        <View style={{ width: "fit-content" }}>
            <Banner color={colorAmbiguousWhite}>
                <UtilityText label={"No threads"} />
            </Banner>
        </View>
    );
}

export function ModDashboardThreadsOverviewList({
    threads,
    authors,
    newThreadCount = 0,
    remainingThreadCount = 0,
    onLoadMore,
    onLoadNew,
}) {
    const datastore = useDatastore();

    const params = useInstanceParams();
    const instanceKey = useInstanceKey();

    const openThreadDetails = (threadId) => {
        params.threadId = threadId;
        gotoInstance({structureKey: "moddashboard", instanceKey, params: params})
    };

    const loadMoreItems = () => {
        onLoadMore && onLoadMore();
    };

    return (
        <View style={{ gap: 16 }}>
            {threads && authors ? (
                threads.length > 0 ? (
                    <View style={{ gap: 16 }}>
                        {threads.map((thread, i) => (
                            <ModDashboardThreadCard
                                author={authors?.[thread?.from]}
                                thread={thread}
                                key={i.toString() + thread.question}
                                onPressCard={() => {
                                    openThreadDetails(thread.key);
                                }}
                                secondaryTags={[thread.structureKey === "topic" ? "Topic" : null]}
                            />
                        ))}
                        {remainingThreadCount > 0 && (
                            <Center>
                                <CTAButton label={"Load more"} onPress={loadMoreItems}></CTAButton>
                            </Center>
                        )}
                    </View>
                ) : (
                    <NoThreadsBanner />
                )
            ) : (
                <LoadingScreen />
            )}
        </View>
    );
}

export function ModDashboardThreadCard({ thread, secondaryTags=[], author, onPressCard}) {

    const threadIsClosed = thread?.readonly ? true : false
    const isHeated = thread.heated || false

    const s = ThreadCardStyle;

    return <HoverView onPress={onPressCard}>
        <View style={s.cardContainer}>
            <HorizBox spread>
                <View style={s.tagRow}>
                    <Tag compact label={threadIsClosed ? "Closed" : "Open"} color={threadIsClosed ? colorTagEmphasized : colorLightGreen} />
                    {secondaryTags.filter(tag=>tag!=null).map((tag, i) => <Tag key={tag} compact label={tag} />)}
                </View>
                <View style={{ display: "flex", flexDirection: "row-reverse" }}>
                    {isHeated && 
                        <PadBox left={8}>
                            <Tag compact emoji={"🔥"} label={"Heated"} color={"#FCEBEA"}/>
                        </PadBox>
                    }
                </View>
            </HorizBox>
            <View style={s.mainContentRow}>
                <View style={s.titleBlock}>
                    <Paragraph text={thread.question ?? thread.name} strong numberOfLines={1}/>
                    <UtilityText type="tiny" color={colorTextSecondary} label={"Author: {author}"} formatParams={{author: author?.name}}/>
                </View>
            </View>
            <ModDashboardThreadMetaData thread={thread} threadKey={thread.key} userId={author?.from} />
        </View>
    </HoverView>
}

const ThreadCardStyle = StyleSheet.create({
    cardContainer: {
        borderRadius: 10,
        padding: 16,
        gap: 16,
        backgroundColor: colorAmbiguousWhite,
        boxShadow: " 0px 2px 10px rgba(0, 0, 0, 0.1)",
        overflow: "hidden"
    },
    tagRow: {
        flexDirection: "row",
        gap: 12
    },
    image: {
        width: 56,
        height: 56
    },
    mainContentRow: {
        flexDirection: "row",
        gap: 12,
        alignItems: "center"
    },
    titleBlock: {
        gap: 4,
        width:"100%"
    },
    detailRow: {
        gap: 16,
        flexDirection: "row"
    },
    detailItem: {
        gap: 4,
        flexDirection: "row",
        color: colorTextSecondary,
    }
})

export function ModDashboardThreadDetails() {
    const threadKey = useInstanceKey();
    const canView = useHasCapability('moderation/view')

    // Determine if you are a question or topic
    const question = useModulePublicData("question", [threadKey])
    const topic = useModulePublicData("topic", [threadKey])
    const thread = question ?? topic

    const threadAuthor = usePersonaObject(thread?.from)

    const articles = useCollection("backlink_article")

    if(!canView) {
        return <Banner><UtilityText label='You do not have access to this feature'/></Banner>
    }

    if (!thread) {
        return <LoadingScreen />
    }

    return <PadBox top={80}>
        <SpacedArray pad={8}>
            <Heading text={thread?.question ?? thread.name} level={1} type={"large"} weight="medium"/>
            <UtilityText type="tiny" color={colorTextSecondary} label={"Author: {author}"} formatParams={{author: threadAuthor?.name}}/>
        </SpacedArray>
        <Pad size={24} />
        <ModDashboardThreadMetaData thread={thread} threadKey={threadKey} articles={articles} />
        <Pad size={56} />
        <ModDashboardThreadDetailTabs thread={thread} threadKey={threadKey} />
    </PadBox>
}

function ModDashboardThreadMetaData({ thread, threadKey, articles }) {
    const commentCount = thread.commentCount
    const awaitingDecisionCount = useModulePublicData("moderation",["thread_queue_count",threadKey,"awaiting_decision_count"])
    const [display, shouldDisplay] = useState(false)
    const s = ThreadCardStyle;

    return <View style={s.detailRow}>
        {thread.time && <View style={s.detailItem}>
            <Time />
            <ModDashboardDateTime timestamp={thread.time} color={colorTextSecondary} type="tiny" />
        </View>}
        {commentCount !== null && <View style={s.detailItem}>
            <Chat />
            <UtilityText type="tiny" text={commentCount?.toString()} color={colorTextSecondary} />
        </View>}
        <View style={s.detailItem}>
            <Flag color={awaitingDecisionCount ? colorTextWarning : colorTextSecondary } />
            <UtilityText 
                type="tiny" 
                text={awaitingDecisionCount !== null ? awaitingDecisionCount : "0"} 
                color={awaitingDecisionCount ? colorTextWarning : colorTextSecondary} />
        </View>
        {thread.id && <View style={s.detailItem}>
            <IdManagement />
            <UtilityText type="tiny" text={thread.id} color={colorTextSecondary} />
        </View>}
        {articles && <View style={s.detailItem}>
            <Link color={colorAmbiguousHighlight} />
            <TextButton te formatParams={{numArticles:articles.length}} type="tiny" color={colorAmbiguousHighlight} label={"{numArticles} linked stories"} onPress={() => shouldDisplay(true)} />
            {display && articles.length > 0 && <ModDashboardThreadArticleModal articles={articles} onClose={() => shouldDisplay(false)} />}
        </View>}
    </View>
}

export function ModDashboardThreadArticleModal({ onClose, articles }) {
    return <Modal onClose={onClose}>
        <View style={{ paddingVertical: 40, paddingHorizontal: 20, gap: 8 }}>
            <View style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                <UtilityText type="small" text='Linked stories' weight="strong"/>
                <PadBox horiz={8} />
                <View style={{ width: 25, height: 25, borderRadius: "50%", backgroundColor: "#E9E9E9", justifyContent: "center", alignItems: "center" }}>
                    <UtilityText text={articles.length} type="small" center weight="strong" />
                </View>
            </View>
            <Pad />
            <View style={{gap:8}}>
                {articles.map((article, key) => <ModDashboardThreadArticleCard key={key} article={article} />)}
            </View>
        </View>
    </Modal>
}

const monthAbbreviations = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
]

function ModDashboardThreadArticleCard({ article = {} }) {
    const s = ArticleCardThreadStyle;

    return <View style={s.outer}>
        <View style={{ display: "flex", justifyContent: "flex-start" }}>
            <HorizBox center>
                <Image style={s.image} source={article.image} />
                <PadBox horiz={4} />
                <View style={s.articleList}>
                    <Paragraph text={article.title} color={colorTextSecondary} />
                    <REPLACE_ZDF_LinkText label='Read article' type='small' url={keyToUrl(article.key)} testID={`Read ${article.title}`} strong />
                </View>
            </HorizBox>
        </View>
    </View>
}

const ArticleCardThreadStyle = StyleSheet.create({
    outer: {
        backgroundColor: "#F7F7F7", 
        borderRadius: 8, 
        padding: 12 
    },
    articleList: {
        gap: 4, 
        flexShrink: 1 
    },
    image: {
        width: 48,
        height: 48,
        borderRadius: 8
    }
})

function ModDashboardThreadDetailTabs({ thread, threadKey }) {
    const tabs = useMemo(
        () => [
            {
                id: "0",
                label: "Comments",
                component: () => (
                    <View>
                        <ModDashboardQueue
                            structureKey={"question"}
                            instanceKey={threadKey}
                            useAllFilteredQueues={useFilteredQueues}
                            useQueueCounts={getQueueSubsetCounts}
                            additionalFilters={[ModerationFilters.Bookmarked]}
                        />
                    </View>
                ),
            },
        ],
        [thread.key]
    );

    return (
        <View style={{flexGrow: 1 }}>
            <TabNavigation initialTabId={0} tabs={tabs}>
                <TabBar />
                <TabContent />
            </TabNavigation>
        </View>
    );
}