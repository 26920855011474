import { View } from "react-native";

import { Datastore } from "util/datastore";
import { Pad } from "component/basics";
import { DemoSection } from "system/demo";
import { PollScreen, pollWidgetStories as pollWidgetStorySets } from './polldemo';
import { TopicStructureFullDemo } from "./topicstructuredemo";
import { TopicTeaserScreen } from "./topicteaserdemo";
import { Byline } from "component/people";
import { Badge } from "../../component/cbcrc/badge";
import { REPLACE_CBC_Tag } from "../../component/cbcrc/tag";
import { colorAmbigousBlack, colorSurfaceWarning } from "component/color";
import { AskMeAnythingDemo } from "./askmeanythingstructuredemo";
import { RCConversationThreadWithCommentSliderDemo, RCConversationThreadWithPerspectiveDemo } from "./rcconversationthreaddemo";
import { profileTitleWriterFeatureDemo } from "./features/profiletitlewriter-demo";
import { livestatusDemo } from "./features/livestatus-demo";
import { baseComponentsDemo } from "./component/cbcrc-components-demo";
import { relatedConversationsDemo } from "./features/relatedconversations-demo";
import { topicListFeatureDemo } from "./admin/topiclistfeature-demo";


export const CBCRCDemoFeature = {
    key: 'demo_cbcrc',
    name: 'Demo CBCRC',
    config: {
        componentSections: [
            {
                label: 'CBC-RC Components', key: 'cbcrc', pages: [
                    { label: 'Poll', key: 'poll', designUrl: 'https://www.figma.com/design/MX0AcO8d0ZlCBs4e9vkl5f/PSI-Design-System?node-id=6311-22005&t=2tD3gEuK5FbZOslE-0', screen: PollScreen },
                    { label: 'PollWidget', key: 'pollWidget', designUrl: 'https://www.figma.com/design/MX0AcO8d0ZlCBs4e9vkl5f/PSI-Design-System?node-id=6311-22005&t=2tD3gEuK5FbZOslE-0', storySets: pollWidgetStorySets },
                    { label: 'Titled Writer', key: 'titledWriter', designUrl: 'https://www.figma.com/design/MX0AcO8d0ZlCBs4e9vkl5f/PSI-Design-System?node-id=3-32&node-type=canvas&t=EGaU0LgfaowklmF5-0', screen: TitledWriterScreen },
                    { label: 'Topic Teaser', key: 'topicTeaser', designUrl: 'https://www.figma.com/design/MX0AcO8d0ZlCBs4e9vkl5f/PSI-Design-System?node-id=6376-16667&node-type=FRAME&t=Qi3NQID2OPtk4FfL-0', screen: TopicTeaserScreen },
                    ...profileTitleWriterFeatureDemo,
                    ...livestatusDemo,
                    ...baseComponentsDemo,
                    ...relatedConversationsDemo,
                    ...topicListFeatureDemo,
                ]
            }
        ],
        structureSections: [
            {
                label: 'CBC-RC Structures', key: 'cbcstruct', pages: [
                    { label: 'Topic of the moment', key: 'topicofthemoment', screen: TopicStructureFullDemo },
                    { label: 'Ask me anything', key: 'askmeanything', screen: AskMeAnythingDemo },
                    { label: 'RC Conversation with perspectives', key: 'rc-conversations-perspective', screen: RCConversationThreadWithPerspectiveDemo },
                    { label: 'RC Conversation with slider', key: 'rc-conversations-slider', screen: RCConversationThreadWithCommentSliderDemo },
                ]
            },
        ],
        featureSections: [
        ]
    }
}

function TitledWriterScreen() {
    return <View>
        <Datastore
            config={{
                bylineTags: [(persona) => <REPLACE_CBC_Tag color={colorSurfaceWarning} textColor={colorAmbigousBlack} type="tiny" text={"Journalist"} />,],
                profilePhotoLayers: [(persona) => <Badge imageUrl={'https://images.radio-canada.ca/q_auto,w_1200/v1/ici-info/perso/logo-radio-canada-pizza-rouge-archives.jpg'} />],
            }}>

            <DemoSection text='Role tag for a Titled Writer'>
                <REPLACE_CBC_Tag color={colorSurfaceWarning} textColor={colorAmbigousBlack} type="tiny" text={"Journalist"} />
            </DemoSection>
            <Pad />

            <DemoSection text='Badge for a Titled Writer'>
                <View style={{
                    width: 48,
                    height: 48,
                    backgroundColor: 'transparent'
                }}>
                    <Badge imageUrl={'https://images.radio-canada.ca/q_auto,w_1200/v1/ici-info/perso/logo-radio-canada-pizza-rouge-archives.jpg'} />
                </View>
            </DemoSection>
            <DemoSection text='Badge for a Titled Writer without a picture'>
                <View style={{
                    width: 48,
                    height: 48,
                    backgroundColor: 'transparent'
                }}>
                    <Badge />
                </View>
            </DemoSection>

            <DemoSection text='Byline with Titled Writer widgets'>
                <Byline userId='aa' time={Date.now()} edited={Date.now()} />
                <Byline userId='bb' type='large' time={Date.now()} edited={Date.now()} />
                <Byline userId='c' type='large' time={Date.now()} edited={Date.now()} />
            </DemoSection>

        </Datastore>
    </View>
}
