import { ui_human_translations_french } from './ui_human_french';

export const ui_human_translations_french_belgian = {
    ...ui_human_translations_french,
    "You will receive an email notification when your post is approved or rejected by moderators.":"Vous recevrez une notification par e-mail lorsque votre publication sera approuvée ou rejetée par les modérateurs.",
    "Yes with reservations":"Plutôt oui",
    "We'd particularly like to hear from:":"Exemples de points de vue qu'il serait intéressant de représenter dans cette conversation :",
    "We strive for a kind and constructive discussion space where everyone feels welcome to share their perspectives and experiences. You can contribute to a discussion with responses that are on-topic and relevant to the subject matter, including meaningful debate.":"Nous nous efforçons de créer un espace de conversation respectueux et constructif où chacun est libre de partager ses points de vue et expériences. Vous pouvez contribuer à une discussion avec des réponses pertinentes en lien avec le sujet, y compris des débats constructifs.",
    "Update your response":"Modifier ma réponse",
    "Update your previous response with fresh insights":"Mettez à jour votre réponse si votre avis a changé.",
    "To maintain a welcoming and respectful environment, this space uses AI to help moderate comments. AI may flag your post for your additional review if it does not follow the [Community Guidelines]($onPress)":"Pour maintenir un environnement accueillant et respectueux, cet espace utilise l'intelligence artificielle (IA) pour modérer les commentaires. L'IA peut signaler votre commentaire pour une révision supplémentaire si elle ne respecte pas les [règles de la communauté]($onPress)",
    "This discussion space uses only essential cookies and cookies for analytics. We do not use cookies for advertising purposes.":"Cet espace de conversation utilise uniquement des cookies essentiels et des cookies d'analyse. Nous n'utilisons pas de cookies à des fins publicitaires.",
    "This custom name is already in use.":"Ce pseudonyme existe déjà.",
    "Thank you!":"Merci !",
    "Support Your Claims":"Expliquez vos propos",
    "Slide to respond:":"Déplacez le curseur pour répondre : ",
    "Share your thoughts":"Partagez votre avis",
    "Share your thoughts...":"Partagez votre avis...",
    "Reviewing your perspective label with AI to ensure it follows our Community Guidelines":"Votre point de vue est en cours de vérification par l'IA afin d'assurer sa conformité avec les règles de la communauté",
    "Provide any details to help us determine if your post follows our Community Guidelines.":"Fournissez tous les détails pour nous aider à déterminer si votre commentaire respecte les règles de la communauté.",
    "Once you log in, enter a display name":"Une fois connecté, choisissez un nom d'affichage.",
    "No with reservations":"Plutôt non",
    "No off-topic posts":"Pas de commentaires hors sujet",
    "No more responses":"Fin des commentaires",
    "Log in":"Se connecter",
    "How do you relate to this issue?":"Comment vous identifiez-vous par rapport au sujet ?",
    "Engage Respectfully":"Soyez respectueux",
    "Custom names can only include lowercase letters and numbers.":"Les pseudonymes doivent contenir uniquement des lettres minuscules et des chiffres.",
    "Consider rewording your perspective:":"Pensez à reformuler votre point de vue :",
    "By participating in our beta, you may be invited to submit feedback on your experience using this product.":"En participant à cette version de test (bêta), vous pourriez être invité à donner votre avis sur votre expérience",
    "Beta":"Bêta",
    "Avoid derogatory labels":"Évitez les propos dénigrants",
    "Are you sure you want to delete your profile data?":"Êtes-vous sûr de vouloir supprimer les données associées à votre profil ?",
    "📫 What happens next":"📫 Quelle est la prochaine étape ?",
    "🍪 This discussion space uses only essential cookies. We do not use cookies for advertising purposes. [Learn more]({privacyNoticeLink}).":"🍪 Cet espace de conversation utilise uniquement des cookies essentiels. Nous n'utilisons pas de cookies à des fins publicitaires. [En savoir plus]({privacyNoticeLink}).",    
    "Enter a perspective label":"Votre perspective",
    "This discussion space is hosted by New_ Public, in collaboration with {psmName}.": "Cet espace de conversation est hébergé par New_ Public en collaboration avec la {psmName}.",
    "This guidance is generated by AI.":"Conseils générés par l'IA",             
    "About Conversation Helper":"A propos de l'assistant de conversation",      
    "To maintain a welcoming and respectful atmosphere, we also use AI to support moderation. In this conversation, an AI gives hints for a more constructive conversation atmosphere and summarizes the conversation.":"Afin de maintenir une atmosphère accueillante et respectueuse, nous utilisons une IA pour soutenir la modération. Dans cette conversation, une IA donne des indications pour favoriser une discussion constructive et résume les sujets abordés.",
    
    // Blocklist
    "Manage terms that should be reported automatically.": "Gérer les termes qui doivent être signalés automatiquement.",

    "We'd love your feedback!": "Nous aimerions collecter votre feed-back.",
    "Share your feedback to help improve our Beta experience.": "Partagez votre feed-back pour nous aider à améliorer l'expérience de l'outil de conversation.",
    "How satisfied are you with this conversation platform?": "Quel est votre niveau de satisfaction de l'outil de conversation?",
    "Please indicate how much you agree or disagree with each statement": "Êtes-vous d'accord ou en désaccord avec les affirmations suivantes?"
      
}