import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { default_fbUser, defaultPersonaList, personaListToMap } from './testpersonas';
import { firebaseNewKey, firebaseSignOut, firebaseWatchValue, firebaseWriteAsync, getFirebaseDataAsync, getFirebaseUser, onFbUserChanged, signInWithTokenAsync, useFirebaseData } from './firebase';
import { deepClone, getDeploymentConfig, getObjectPropertyPath, logUnlessTest, setObjectPropertyPath } from './util';
import { LoadingScreen } from '../component/basics';
import { SharedData, SharedDataContext } from './shareddata';
import { callServerApiAsync } from 'system/servercall';
import { closeWindow, getGlobalParams, goBack, gotoInstance, gotoInstanceScreen } from './navigate';
import { getFragment, getIsInSidebar } from '../platform-specific/url';
import { useIsLocalhostAdmin } from 'component/admin';
import { DatastoreContext } from './datastoreContext';
import { logEventAsync } from './eventlog';
import { Persona, undefA, undefB, undefN, undefR, undefS } from './stdtypes';
import { User } from 'firebase/auth';

export const ConfigContext = React.createContext(undefA);

const deploymentConfig = getDeploymentConfig();

// TODO: Make this more efficient: Currently every data update updates everything.

export type TestState = {
    collections: any;
    globals: any;
    moduleUserGlobal: any;
    moduleUserLocal: any;
    modulePublic: any;
    firebaseUser: any;
    sessionData: any;
    personaKey: string;
    roles: string[];
    onServerCall?: ({component, funcname, params}: {component: string, funcname: string, params: any}) => Promise<any>;
    serverCall?: Record<string, Record<string, (params: any) => Promise<any>>>;
    time?: number;
    isInSidebar?: boolean;
    globalParams?: any;
    embeddedInstanceData?: any;
    pushSubscreen?: (screenKey: string, params: any) => void;
    gotoInstance?: (params: any) => void;
    gotoInstanceScreen?: (params: any) => void;
    goBack?: () => void;
    closeWindow?: () => void;
    gotoUrl?: (url: string) => void;
    openUrl?: (url: string, target: string | undefined, features: string | undefined) => void;
    urlFragment?: string;
}

type DatastoreProps = {
    isLive: boolean;
    testState: TestState | null;
    isEmbedded: boolean;
    globalParams?: Record<string, any>;
    language: string;
    config: any;
    siloKey: string;
    structureKey: string;
    instanceKey: string;
    readOnly: boolean;
    personaPreview: any;
    children: React.ReactNode;
}

type DatastoreState = {
    loaded: boolean;
}


export class Datastore extends React.Component<DatastoreProps, DatastoreState> {
    state = {loaded: false}
    sharedData: SharedData;
    userGlobalData: Record<string, any> = {};
    userLocalData: Record<string, any> = {};

    // dataTree = {};
    sessionData: Record<string, any> = {};

    // dataWatchers = [];
    fbUserWatchReleaser: null | (() => void) = null;
    fbDataWatchReleaser: null | (() => void) = null;

    constructor(props) {
        super(props);
        logUnlessTest('Datastore constructing');

        this.sharedData = new SharedData({});
        this.resetData();
        this.resetSessionData();
    }

    componentDidMount() {
        this.resetData();
        this.resetSessionData();
        logUnlessTest('Datastore mounted');
        if (this.props.isLive) {
            logUnlessTest('About to set up firebase watchers');
            this.setupFirebaseWatchers();
        } else {
            this.setState({loaded: true});
        }
    }
    componentWillUnmount() {
        this.fbUserWatchReleaser && this.fbUserWatchReleaser();
        this.fbDataWatchReleaser && this.fbDataWatchReleaser();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.instanceKey != this.props.instanceKey || 
            prevProps.structureKey != this.props.structureKey ||
            prevProps.readOnly != this.props.readOnly
        ) {
            this.resetData();
            if (this.props.isLive) {
                this.setupFirebaseWatchers();
            }
        } 
        if (prevProps.structureKey != this.props.structureKey ||
            prevProps.instanceKey != this.props.instanceKey
        ) {
            this.resetSessionData();
        }
    }

    async loadFirebaseDataOnceAsync() {
        const {siloKey, structureKey, instanceKey} = this.props;
        logUnlessTest('Fetching read-only data from database...');
        const data = await getFirebaseDataAsync(['silo', siloKey, 'structure', structureKey, 'instance', instanceKey]);
        this.setData({...data?.collection, ...data?.global});
        this.setState({loaded: true})
        logUnlessTest('DONE: Read-only data received');
    }

    setupFirebaseWatchers() {
        const {siloKey, structureKey, instanceKey, readOnly, config} = this.props;
        logUnlessTest('Setting up watchers');
        this.fbUserWatchReleaser && this.fbUserWatchReleaser();
        this.fbDataWatchReleaser && this.fbDataWatchReleaser();
        logUnlessTest('Old watchers released');

        if (readOnly) {
            logUnlessTest('Read-only. Fetching data once.');
            this.loadFirebaseDataOnceAsync();
            logUnlessTest('DONE: Made fetch request');
            this.fbDataWatchReleaser = null;
        } else {
            logUnlessTest('Fetching instance data from database...');
            this.fbDataWatchReleaser = firebaseWatchValue(['silo', siloKey, 'structure', structureKey, 'instance', instanceKey], data => {
                this.setData({...data?.collection, ...data?.global});
                this.setState({loaded: true})
                logUnlessTest('DONE: Database data received');
            });
        }

        this.fbUserWatchReleaser = onFbUserChanged(async user => {
            this.setSessionData('personaKey', user?.uid);
            this.refreshUserDataAsync(user);
        })
    }

    async refreshUserDataAsync(user) {
        if (user) {
            this.setSessionData('roles', null);
            const roles = await this.callServerAsync('admin', 'getMyRoles', {
                email: user?.email
            });
            this.setSessionData('roles', roles);
        } else {
            this.setSessionData('roles', []);
        }
    }

    resetSessionData() {
        if (this.props.isLive) {
            const personaKey = getFirebaseUser()?.uid || null;
            this.sessionData = {personaKey}
        } else {
            const {roles=[], sessionData, personaKey='a'} = this.props.testState ?? {};

            this.sessionData = {personaKey, roles, ...sessionData}     
        }
    }


    resetData() {
        const {isLive} = this.props;
        const {collections, globals} = this.props.testState ?? {};
        if (isLive) {
            this.refreshUserDataAsync(getFirebaseUser());
        } else {
            this.userGlobalData = {...this.props.testState?.moduleUserGlobal};
            this.userLocalData = {...this.props.testState?.moduleUserLocal};
            this.setData({
                persona: personaListToMap(defaultPersonaList),
                ...deepClone(globals || {}), 
                ...expandDataListMap(collections || {})
            })
        }
    }

    // Delegate local data storage and notification to SharedDataContext
    // This allows the side-by-side view to work for role play instances.
    static contextType = SharedDataContext;
    watch(watchFunc) {this.sharedData.watch(watchFunc)}
    unwatch(watchFunc) {this.sharedData.unwatch(watchFunc)}
    notifyWatchers() {this.sharedData.notifyWatchers()}
    getData() {return this.sharedData.getData()}
    setData(data) {return this.sharedData.setData(data)}

    setSessionData(path, value) {
        this.sessionData = {...this.sessionData, [pathToName(path)]: value};
        this.notifyWatchers();
    }
    getSessionData(path : string | string[]) {
        return this.sessionData[pathToName(path)];
    }
    getPersonaKey() {
        return this.getSessionData('personaKey');
    }

    getObject(typeName, key) {
        return this.getData()[typeName]?.[key];
    }
    async setObject(typeName, key, value) {
        const {siloKey, structureKey, instanceKey, isLive, readOnly} = this.props;
        const personaKey = this.getPersonaKey();
        if (!personaKey) {
            throw new Error('Cannot set object when not logged in');
        }
        if (!key || !typeName) {
            throw new Error(`Missing key or typeName: ${key} ${typeName}`);
        }
        const typeData = {...this.getData()[typeName], [key]: value};
        if (value == null) {
            delete typeData[key]
        }
        this.setData({...this.getData(), [typeName]: typeData});

        if (readOnly) {
            console.error(`Attempt to write to read-only datastore: ${typeName} ${key} ${value}`);
        } else if (isLive) {
            var pAddCurrentUser;
            if (typeName != 'persona') {
                pAddCurrentUser = this.addCurrentUser(); // don't call on persona or you get a loop
            }
            await firebaseWriteAsync(['silo', siloKey, 'structure', structureKey, 'instance', instanceKey, 'collection', typeName, key], value);
            await this.callServerAsync('derivedviews', 'runTriggers', {type: typeName, key});
            await pAddCurrentUser;
        }
    }
    getNewKey() : string{
        const {isLive} = this.props;
        return (isLive ? firebaseNewKey() : newLocalKey()) as string;
    }
    async addObject(typeName, value) {
        const {isLive} = this.props;
        const key = isLive ? firebaseNewKey() : newLocalKey();
        const personaKey = this.getPersonaKey();
        if (!personaKey) {
            throw new Error('Cannot add object when not logged in');
        }
        const objectData = {key, from: personaKey, time: Date.now(), ...value};
        await this.setObject(typeName, key, objectData);
        return key;
    }
    async modifyObject(typename, key, modFunc) {
        const object = this.getObject(typename, key);
        const newObject = modFunc(object);
        await this.setObject(typename, key, newObject);
    }
    async updateObject(typename, key, value) {
        const object = this.getObject(typename, key);
        this.addCurrentUser();
        const newObject = {...object, ...value};
        await this.setObject(typename, key, newObject);
    }
    async addObjectWithKey(typeName, key, value) {
        const personaKey = this.getPersonaKey();
        this.addCurrentUser();
        const objectData = {key, from: personaKey, time: Date.now(), ...value};
        await this.setObject(typeName, key, objectData);
        return key;
    }
    async deleteObject(typeName, key) {
        await this.setObject(typeName, key, null);
    }
    getOrGenerateIndex(typeName, indexFields) {
        const indexName = indexFields.join('-');
        const index = this.sharedData.getIndex(typeName, indexName);
        if (index) {
            return index;
        } else {
            const newIndex = makeIndex(indexFields, this.getData()[typeName]);
            this.sharedData.setIndex(typeName, indexName, newIndex);
            return newIndex;
        }
    }
    getCollection(typeName : string, props: CollectionProps = {}) : Record<string, any>[] {
        var items = this.getData()[typeName];
        if (props.filter) {
            const indexFields = Object.keys(props.filter);
            const index = this.getOrGenerateIndex(typeName, indexFields);
            items = lookupFromIndex(indexFields, index, props.filter);
        }
        return sortObjectList(items, props);
    }

    getFirebaseUser() : User | null {
        if (this.props.isLive) {
            return getFirebaseUser();
        } else {
            return this.props.testState?.firebaseUser ?? default_fbUser;
        }
    }

    getPersonaPreview() {
        return this.props.personaPreview;
    }

    async addCurrentUser() {
        if (this.props.isLive) {
            const personaKey = this.getPersonaKey();
            const personaPreview = this.getPersonaPreview();
            const myPersona = this.getObject('persona', personaKey);
            if (!myPersona || !myPersona.linked || myPersona.photoUrl != personaPreview.photoURL || myPersona.name != personaPreview.displayName) {
                await this.callServerAsync('profile', 'linkInstance');
                await this.setObject('persona', personaKey, {...personaPreview, key: personaKey});
            }
        }    
    }

    getGlobalProperty(key) {
        return this.getData()[key];
    }
    setGlobalProperty(key, value) {
        this.setData({...this.getData(), [key]: value});
        
        if (this.props.isLive) {
            this.callServerAsync('global', 'setGlobalProperty', {key, value});
        }
    }
    updateGlobalProperty(key, value) {
        const oldValue = this.getGlobalProperty(key);
        const newValue = {...oldValue, ...value};
        this.setGlobalProperty(key, newValue);
    }
    getModulePublicAsync(moduleKey, path) {
        if (this.props.isLive) {
            return getFirebaseDataAsync(['silo', this.getSiloKey(), 'module-public', moduleKey, ...path]);
        } else {
            return getObjectPropertyPath(this.props.testState?.modulePublic, [moduleKey, ...path]);
        }
    }

    getModuleUserGlobalAsync(modulekey, path) {
        const personaKey = this.getPersonaKey();
        if (!personaKey) {
            return null;
        } else if (this.props.isLive) {
            return getFirebaseDataAsync(['silo', this.getSiloKey(), 'module-user', personaKey, 'global', modulekey, ...path]);
        } else {
            return getObjectPropertyPath(this.userGlobalData, [modulekey, ...path]);
        }
    }
    setModuleUserGlobal(modulekey, path, value) {
        const personaKey = this.getPersonaKey();
        if (!personaKey) {
            throw new Error('Cannot set module user global when not logged in');
        } else if (this.props.isLive) {
            return firebaseWriteAsync(['silo', this.getSiloKey(), 'module-user', personaKey, 'global', modulekey, ...path], value);
        } else {
            this.userGlobalData = setObjectPropertyPath(this.userGlobalData, [modulekey, ...path], value);
        }
        this.notifyWatchers();
    }
    getModuleUserLocalAsync(modulekey, path) {
        const personaKey = this.getPersonaKey();
        if (!personaKey) {
            return null;
        } else if (this.props.isLive) {
            return getFirebaseDataAsync(['silo', this.getSiloKey(), 'module-user', personaKey, 
                'local', modulekey, this.props.structureKey, this.props.instanceKey, ...path]);
        } else {
            return getObjectPropertyPath(this.props.testState?.moduleUserGlobal, [modulekey, ...path]);
        }
    }
    setModuleUserLocal(modulekey, path, value) {
        const personaKey = this.getPersonaKey();
        if (!personaKey) {
            throw new Error('Cannot set module user local when not logged in');
        } else if (this.props.isLive) {
            return firebaseWriteAsync(['silo', this.getSiloKey(), 'module-user', personaKey, 'global', modulekey, ...path], value);
        } else {
            this.userLocalData = setObjectPropertyPath(this.userLocalData, [modulekey, ...path], value);
        }
        this.notifyWatchers();
    }

    async callServerAsync(component, funcname, params={}) {
        if (this.props.testState?.onServerCall) {
            this.props.testState?.onServerCall({component, funcname, params});
        }
        if (this.props.testState?.serverCall) {
            const mockServerCall = this.props.testState?.serverCall;
            if (mockServerCall?.[component]?.[funcname]) {
                return await mockServerCall[component][funcname]({datastore: this, ...params});
            } else {
                throw new Error('No mock server call for ' + component + '/' + funcname);
            }
        } else {
            return await callServerApiAsync({datastore: this, component, funcname, params});
        }
    }

    getSiloKey() {return this.props.siloKey ?? (this.props.isLive ? null : 'demo')}
    getStructureKey() {return this.props.structureKey}
    getInstanceKey() {return this.props.instanceKey}
    getConfig() {return this.props.config ?? {}}
    getIsLive() {return this.props.isLive}
    getIsEmbedded() {return this.props.isEmbedded}
    getLanguage() {return this.props.language}
    getLoaded() {return this.state.loaded}
    getEmbeddedInstanceData() {return this.props.testState?.embeddedInstanceData}
    getMockServerCall() {return this.props.testState?.serverCall}
    getGlobalParams() {
        if(this.props.testState?.globalParams) {
            return this.props.testState?.globalParams;
        } else if (this.props.globalParams) {
            return this.props.globalParams;
        } else {
            return getGlobalParams(window.location.href) ?? {};
        }
    }

    getTime() {
        if (this.props.isLive) {
            return Date.now();
        } else {
            return this.props.testState?.time ?? Date.now();
        }
    }
    getIsInSidebar() {
        if (this.props.isLive) {
            return getIsInSidebar();
        } else {
            return this.props.testState?.isInSidebar ?? true;
        }
    }
    pushSubscreen(screenKey, params) {
        if (this.props.testState?.pushSubscreen) {
            this.props.testState?.pushSubscreen(screenKey, params);
        } else {
            gotoInstanceScreen({structureKey: this.getStructureKey(), instanceKey: this.getInstanceKey(), screenKey, params});
        }
    }
    gotoInstance({structureKey, instanceKey='one', params={}, globalParams={}}) {
        if (this.props.testState?.gotoInstance) {
            this.props.testState?.gotoInstance({structureKey, instanceKey, params, globalParams});
        } else {
            gotoInstance({structureKey, instanceKey, params, globalParams: {...this.getGlobalParams(), ...globalParams}});
        }
    }
    gotoInstanceScreen({structureKey, instanceKey='one', screenKey, params={}, globalParams={}}) {
        if (this.props.testState?.gotoInstance) {
            this.props.testState?.gotoInstance({structureKey, instanceKey, screenKey, params, globalParams});
        } else {
            gotoInstanceScreen({structureKey, instanceKey, screenKey, params, globalParams: {...this.getGlobalParams(), ...globalParams}});
        }
    }
    needsLogin(callback, action) {
        return (...params) => {
            logEventAsync(this, 'login-required', { action });
            const personaKey = this.getPersonaKey();
            if (personaKey) {
                callback(...params);
            } else {
                this.gotoInstance({structureKey: 'login', instanceKey: 'one', params: {action}});
            }
        }
    }
    goBack() {
        if (this.props.testState?.goBack) {
            this.props.testState?.goBack();
        } else {
            goBack();
        }
    }
    closeWindow() {
        if (this.props.testState?.closeWindow) {
            this.props.testState?.closeWindow();
        } else {
            closeWindow();
        }
    }
    gotoUrl(url) {
        if (this.props.testState?.serverCall) {
            return this.callServerAsync('local', 'gotoUrl', {url});
        } else {
            window.location.href = url;
        }
    }
    openUrl(url : string, target = undefS, features = undefS) {
        if (this.props.testState?.openUrl) {
            this.props.testState?.openUrl(url, target, features);
        } else {
            window.open(url, target, features);
        }
    }
    getUrlFragment() {
        if (this.props.testState?.urlFragment) {
            return this.props.testState?.urlFragment;
        } else {
            return getFragment();
        }
    }
    async signInWithTokenAsync(loginToken) {
        if (this.props.testState?.serverCall) {
            // HACK: this isn't actually a server call, but this is a convenient way to mock it
            return this.callServerAsync('local', 'signInWithToken', {loginToken})
        } else {
            const userCredential = await signInWithTokenAsync(loginToken);
            const email = userCredential?.user?.email ?? 'unknown';
            logEventAsync(this, 'login-success', { email });
        }
    }
    async signOut() {
        if (this.props.testState?.serverCall) {
            return this.callServerAsync('local', 'signOut');
        } else {
            firebaseSignOut();
        }
    }
    render() {
        return <DatastoreContext.Provider value={this}>
            <ConfigContext.Provider value={this.props.config ?? {}}>
                {this.props.children}
            </ConfigContext.Provider>
        </DatastoreContext.Provider>
    }
}

export function WaitForData({children}) {
    const loaded = useLoaded();

    if (loaded) {
        return children;
    } else {
        return <LoadingScreen />
    }
}

export function useDatastore() : Datastore{
    return React.useContext(DatastoreContext) as Datastore;
}


export function useLoaded() {
    const datastore = useDatastore();
    return datastore.getLoaded();
}

export function useIsInSidebar() {
    const datastore = useDatastore();
    return datastore.getIsInSidebar();
}

export function useData() {
    const datastore = useDatastore();

    const [dataTree, setDataTree] = useState(datastore.getData());
    const [sessionData, setSessionData] = useState(datastore.sessionData);
    useEffect(() => {
        setDataTree(datastore.getData());
        setSessionData(datastore.sessionData);

        const watchFunc = () => {
            setDataTree(datastore.getData());
            setSessionData(datastore.sessionData);
        }
        datastore.watch(watchFunc);
        return () => {
            datastore.unwatch(watchFunc);
        }
    }, [datastore])
    return {dataTree, sessionData};
}

export function useUserData() {
    const datastore = useDatastore();
    const [userGlobalData, setUserGlobalData] = useState(datastore.userGlobalData);
    const [userLocalData, setUserLocalData] = useState(datastore.userLocalData);

    useEffect(() => {
        setUserGlobalData(datastore.userGlobalData);
        setUserLocalData(datastore.userLocalData);

        const watchFunc = () => {
            setUserGlobalData(datastore.userGlobalData);
            setUserLocalData(datastore.userLocalData);
        }
        datastore.watch(watchFunc);
        return () => {
            datastore.unwatch(watchFunc);
        }
    }, [datastore]);

    return {userGlobalData, userLocalData};
}

export function usePersonaPreview() {
    const datastore = useDatastore();
    return datastore.getPersonaPreview();
}

export function useSessionData(path) {
    const {sessionData} = useData();
    return sessionData[pathToName(path)];
}

export function usePersonaKey() : string | null{
    return useSessionData('personaKey');
}

export function useMyRoles() : string[] | null {
    const isLocalhostAdmin = useIsLocalhostAdmin();
    const roles = useSessionData('roles');
    if (isLocalhostAdmin) {
        return ['Owner', 'Developer']
    } else {
        return roles;
    }

}

export function usePersonaObject(key) : Persona | null {
    const persona = useObject('persona', key);
    const meKey = usePersonaKey();
    const preview = usePersonaPreview();
    const isLive = useIsLive();

    if (key == meKey && isLive && !persona) {
        return {...preview, key};
    } else {
        return persona as Persona | null;
    }
}


export function useObject(typeName : string, key : string) : Record<string, any> | null {
    const {dataTree} = useData();
    return dataTree[typeName]?.[key];
}

type CollectionProps = {
    sortBy?: string;
    reverse?: boolean;
    limit?: number;
    filter?: Record<string, any>;
}

// We have to be careful with memoization here, or we end up creating a new
// result object every time, which messes up dependencies elsehere
export function useCollection(typeName, props: CollectionProps = {}) {
    const {dataTree} = useData();
    const datastore = useDatastore();
    const collection = dataTree[typeName];
    // const result = useMemo(() => processObjectList(collection, props),
    //     [collection, JSON.stringify(props)]
    // );
    const result = useMemo(() => datastore.getCollection(typeName, props),
        [collection, JSON.stringify(props)]
    )
    return result;
}

export function useDerivedCollection(typeName, props = {}) {
    return useCollection('derived_' + typeName, props);
}

// TODO: Remove filter from this, since it's done elsewhere
function sortObjectList(collection, {sortBy=undefS, reverse=undefB, limit=undefN}) {
    var result = sortMapValuesByProp(collection ?? [], sortBy || 'key');
    if (reverse) {
        result = result.reverse();
    } if (limit) {
        result = result.slice(0, limit);
    }
    return result;
}

export function useGlobalProperty(key) {
    const {dataTree} = useData();
    return dataTree?.[key];
}

function sortMapValuesByProp(obj, prop) {
    return sortArrayByProp(Object.values(obj), prop);
}

function sortArrayByProp(array, prop) {
    return array.sort((a, b) => {
        const valueA = a[prop];
        const valueB = b[prop];
    
        if (valueA < valueB) {
            return -1;
        }
        if (valueA > valueB) {
            return 1;
        }
        return 0;
    });
}



var global_nextKey = 0;
export function newLocalKey() : string {
    global_nextKey++;
    return global_nextKey.toString();
}

export function resetLocalKeys() {
    global_nextKey = 0;
}

export function ensureNextLocalKeyGreater(key) {
    if (typeof(key) == 'number') {
        if (global_nextKey <= key) {
            global_nextKey = key + 1;
        }
    }
}

function pathToName(path : string | string[]) {
    if (typeof(path) == 'string') {
        return path;
    } else {
        return path.join('/');
    }
}

function makeFirebasePath(path) {
    return path.map(encodeURIComponent).join('%2F');
}

export function makeStorageUrl({datastore, userId, fileKey, extension}) {
    const structureKey = datastore.getStructureKey();
    const instanceKey = datastore.getInstanceKey();
    const path = ['user', userId, structureKey, instanceKey, fileKey + '.' + extension];
    const pathString = makeFirebasePath(path);
    return deploymentConfig.storagePrefix + pathString + '?alt=media';
}

export function makeIndex(fields, itemMap) {
    var index = {};
    const objectKeys = Object.keys(itemMap || {});
    for (const key of objectKeys) {
        const item = itemMap[key];
        const indexKey = fields.map(field => item[field]).join('-');
        if (!index[indexKey]) {
            index[indexKey] = [];
        }
        index[indexKey].push(item);
    }
    return index;
}

export function lookupFromIndex(fields, index, filter) {
    const key = fields.map(field => filter[field]).join('-');
    return index[key] || [];
}

type NullablePath = (string | null)[]

export function useModulePublicData(moduleKey : string, path : NullablePath = [], options : Record<string, any> = {}) {
    const datastore = useDatastore();
    if (!datastore) {
        return options?.defaultValue ?? null;
    } else if (datastore.getIsLive()) {
        return useFirebaseData(['silo', datastore.getSiloKey(), 'module-public', moduleKey, ...path], options)
    } else {
        return getObjectPropertyPath(datastore.props.testState?.modulePublic, [moduleKey, ...path]);
    };
}

export function useMemoizedModulePublicData(moduleKey, path = []) {
    return useModulePublicData(moduleKey, path, {memoized: true});
}

export function useModuleUserGlobalData(moduleKey : string, path = [] as string[], options = undefR) {
    const {userGlobalData} = useUserData();
    const datastore = useDatastore();
    const personaKey = usePersonaKey();
    if (datastore.getIsLive()) {
        return useFirebaseData(['silo', datastore.getSiloKey(), 'module-user', personaKey, 'global', moduleKey, ...path], options)
    } else {
        return getObjectPropertyPath(userGlobalData, [moduleKey, ...path]) ?? options?.defaultValue ?? null;
    };
}

export function useModuleUserLocalData(moduleKey, path = [], options) {
    const {userLocalData} = useUserData();
    const datastore = useDatastore();
    const personaKey = usePersonaKey();
    const structureKey = useStructureKey();
    const instanceKey = useInstanceKey();
    if (!personaKey) {
        return null;
    } else if (datastore.getIsLive()) {
        return useFirebaseData(['silo', datastore.getSiloKey(), 'module-user', personaKey, 
            'local', moduleKey, structureKey, instanceKey, ...path], options)
    } else {
        return getObjectPropertyPath(userLocalData, [moduleKey, ...path]);
    };
}


export function useSiloKey() : string {
    const datastore = useDatastore();
    return datastore.getSiloKey();
}

export function useIsLive() : boolean {
    const datastore = useDatastore();
    return datastore.getIsLive();
}

export function useIsEmbedded() : boolean {
    const datastore = useDatastore();
    return datastore.getIsEmbedded();
}

export function useInstanceKey() : string {
    const datastore = useDatastore();
    return datastore.getInstanceKey();
}

export function useStructureKey() : string {
    const datastore = useDatastore();
    return datastore.getStructureKey();
}

export function useGlobalParams() : Record<string, any> {
    const datastore = useDatastore();
    return datastore.getGlobalParams();
}

export function expandDataList(list) {
    const date = new Date();
    date.setHours(date.getHours() - 1);

    var collection = {};

    list.forEach(item => {
        date.setMinutes(date.getMinutes() + 1);

        const key = item.key || newLocalKey();
        // ensureNextKeyGreater(key);
        ensureNextLocalKeyGreater(key);
        collection[key] = {
            ...item,
            key,
            time: date.getTime()
        };
    });

    return collection;
}

export function expandDataListMap(map) {
    var newMap = {};
    Object.keys(map).forEach(key => {
        newMap[key] = expandDataList(map[key]);
    });
    return newMap;
}

// useStableCallback allows you to pass a callback to a child component 
// without causing it to re-render when the callback changes
export function useStableCallback(callback : (...args: any[]) => void) {
    const ref = useRef<(...args: any[]) => void>(callback);

    useEffect(() => {
        ref.current = callback
    }, [callback]);

    return useCallback((...args) => ref.current(...args), []);
}


export function useServerCallResult(component : string, funcname : string, params : Record<string, any> = {}) : any {
    const datastore = useDatastore();
    const [result, setResult] = useState(null);
    useEffect(() => {
        datastore.callServerAsync(component, funcname, params).then(setResult);
    }, [component, funcname, JSON.stringify(params)]);
    return result;
}

