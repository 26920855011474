import { PuppetToolsScreen } from "feature/admin/PuppetTestSupportFeature"
import { CLICK, INPUT } from "system/demo"

export const PuppetDemoFeature = {
    key: 'demo_puppet',
    name: 'Puppet Demo',
    config: {
        componentSections: [
            {label: 'Admin', key: 'internal', pages: [
                {label: 'Puppet Test Tools', key: 'puppettest', storySets: puppetTestStorySets}]
            }
        ]
    }
}

function puppetTestStorySets() {
    return [
        {
            siloKey: 'puppet-test',
            label: 'Puppet Test Tools',
            serverCall: {
                puppet: {
                    login: ({puppetSecret, testUserKey}) => 
                        puppetSecret == 'secret' ? ({loginToken: '123'}) : ({loginToken: null}),
                    reset: () => null
                },
                local: {
                    signInWithToken: () => null
                }
            },
            content: <PuppetToolsScreen />,
            stories: [
                {label: 'Login with good secret', actions: [
                    INPUT('puppet-secret', 'secret'),
                    CLICK('Login as User A')
                ]},
                {label: 'Login with bad secret', actions: [
                    INPUT('puppet-secret', 'bad'),
                    CLICK('Login as User A')
                ]},
                {label: 'Click all the buttons', actions: [
                    INPUT('puppet-secret', 'secret'),
                    CLICK('Reset test silo'),
                    CLICK('Login as User A'),
                    CLICK('Login as User B'),
                    CLICK('Login as User C'),  
                ]}
            ]
        },
        {
            label: 'Hidden on non-test silo',
            content: <PuppetToolsScreen />
        }
    ]
}
