import { Card, LoadingScreen, Pad, PadBox, Separator } from "component/basics"
import { Heading } from "component/text"
import { useDatastore, usePersonaKey } from "util/datastore"
import { useEffect, useState } from "react";
import { ArticleCard } from "../../structure/question";

export const ProfileArticleRecommendationsFeature = {
    name: 'Article Recommendations',
    key: 'profilearticlerecommendations',
    config: {
        profileWidgets: [ProfileArticleRecommendationWidget]
    }
}

function ProfileArticleRecommendationWidget() {
    return <ProfileArticleRecommendationBox></ProfileArticleRecommendationBox>
}

function ProfileArticleRecommendationBox(){

    const datastore = useDatastore();
    const myInterests = datastore.getGlobalProperty("interests")
    const personaKey = usePersonaKey();

    const [loaded, setLoaded] = useState(false)
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        async function fetchArticles(){
            const loadedArticles = await datastore.callServerAsync(
                'interests', 'getInterestingArticles', {
                    type: "article", personaKey: personaKey
            });
            setArticles(loadedArticles)
            setLoaded(true)
        }
        fetchArticles()
        
        
    },[])

    if(!loaded){
        return <LoadingScreen></LoadingScreen>
    }

    if(!articles || articles.length === 0){
        return <></>
    }


    return <Card>
        <Separator></Separator>
        <Pad></Pad>
        <Heading label={"These articles might interest you:"}></Heading>
        {articles.map( article => (
            <PadBox vert={4} key={"recommended-article-" + article.key}>
                <ArticleCard article={article} tags={article.tags} key={article.key}/>
            </PadBox>
        ))}

    </Card>
    
}