import { DropDownSelector } from "component/button";
import { BasicComments } from "component/comment";
import { View } from "react-native-web";
import { useDatastore, useSessionData } from "util/datastore";
import { logEventAsync } from "util/eventlog";
import { REPLACE, useConfig } from "util/features";
import { createMapFromCollection } from "util/util";

export const TopTabFeature = {
    name: 'Top Tab / Post Anyway',
    key: 'top-tab',
    missingConfigAllowed: ['gptCheckSeverity', 'perspectiveShowFilter'],
    config: {
        commentRankers: REPLACE([
            {label: 'Best', ranker: mostReactionsRanker},
            {label: 'Newest', ranker: mostRecentRanker}
        ]),
        tabs: REPLACE([
            {
                key: 'top',
                label: 'Top',
                component: TopCommentsTab,
                onShow: ({datastore}) => {
                    datastore.setSessionData(['showBadComments'], false);
                    datastore.setSessionData(['ranking', 'sortMode'], 'Best');

                }
            },
            {
                key: 'all',
                label: 'All',
                component: AllCommentsTab,
                onShow: ({datastore}) => {
                    datastore.setSessionData(['showBadComments'], true);
                    datastore.setSessionData(['ranking', 'sortMode'], 'Newest');
                }
            }
        ]),
        gptCheckSeverity: true,
        perspectiveShowFilter: false,
        commentFilters: [filterFunc],
        replyFilters: [filterFunc],
        pageTopLeftWidgets: [() => <RankingSelector />]
    }
}

function mostReactionsRanker({datastore, comments}) {
    const reactions = datastore.getCollection('reaction');
    const reactionCounts = createMapFromCollection(comments, comment => reactions.filter(reaction => reaction.reactTo === comment.key).length);
    console.log('mostReactionsRanker', {comments, reactionCounts, reactions});

    return comments.sort((a, b) => reactionCounts[b.key] - reactionCounts[a.key]);
}

export function mostRecentRanker({datastore, comments}) {
    return comments.sort((a, b) => b.time - a.time);
}

function filterFunc({datastore, isAdmin, comment}) {
    const showBadComments = datastore.getSessionData('showBadComments');
    if (showBadComments) {  
        return true;
    } else {
        return !comment.minorViolation;
    }
}

function TopCommentsTab() {
    return <BasicComments canPost={false} />
}

function AllCommentsTab() {
    return <BasicComments canPost={false} />
}

export function RankingSelector() {
    const {commentRankers} = useConfig();
    const firstRanker = commentRankers[0];
    const sortMode = useSessionData(['ranking', 'sortMode']) || firstRanker?.label;
    const showBadComments = useSessionData(['showBadComments']);
    const datastore = useDatastore();

    if (showBadComments) {
        return null;
    }

    if (commentRankers.length <= 1) {
        return null;
    }

    const sortOptions = commentRankers.map(ranker => ({ 
        label: ranker.label,
        key: ranker.label
    }));

    function onChange(value) {
        console.log('onChange', value);
        datastore.setSessionData(['ranking', 'sortMode'], value);
        logEventAsync(datastore, 'ranking-changed', {sortMode: value});
    }

    return <View>
        <DropDownSelector options={sortOptions} value={sortMode} onChange={onChange} />
    </View>
}
