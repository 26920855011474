import { HorizBox, Narrow } from "../../component/basics";
import { setToastMessage, Toast, ToastRenderer } from "../../component/zdf/toast";
import { CLICK, DemoHeader, DemoSection } from "../../system/demo";
import { CTAButton } from "../../component/button";
import { Datastore, useDatastore } from "../../util/datastore";

export function ToastDemo() {
    return (
        <Narrow>
            <DemoSection>
                <DemoHeader text={"Info toast"} />
                <Toast text={"Info Message"} visible={true} />
            </DemoSection>
            <DemoSection>
                <DemoHeader text={"Error toast"} />
                <Toast text={"This is an error!"} type={"error"} visible={true} />
            </DemoSection>
        </Narrow>



    )
}

function DemoToastButtons() {
    const datastore = useDatastore()
    return <HorizBox gap={12}>
        <CTAButton text={"Info"} onPress={() => {setToastMessage({datastore, text: "Info", visible: true })}} />
        <CTAButton text={"Error"} onPress={() => {setToastMessage({datastore, text: "Error", visible: true, type: "error"})}} />
        <CTAButton text={"Disappear after 1 second"} onPress={() => {setToastMessage({datastore, text: "Peekaboo", visible: true, duration: 1000})}} />
    </HorizBox> 
}

export function InteractiveToastDemo() {
    return <Datastore>
        <DemoToastButtons />
        <ToastRenderer />
    </Datastore>

}

export function toastStorySets() {
    return [
        {
            label: 'Interactive Toast',
            content: <InteractiveToastDemo />,
            stories: [
                {
                    label: 'Display Info Toast', actions: [
                        CLICK('Info'),
                    ]
                },
                {
                    label: 'Display Error Toast', actions: [
                        CLICK('Error'),
                    ]
                },
                {
                    label: "Display Info Toast & Close", actions: [
                        CLICK("Info"),
                        CLICK("toast-close")
                    ]
                }
            ]
        }
    ]
}