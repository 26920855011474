import { useIsAdmin } from "component/admin";
import { FlowBox, PadBox } from "component/basics";
import { Tag } from "component/button";
import { colorGreyHover, colorLightGreen } from "component/color";
import { UtilityText } from "component/text";
import { useEffect } from "react";
import { useCollection, useDatastore, useObject } from "util/datastore";
import { sumArray } from "util/util";

export const AIQualityRankingFeature = {
    name: 'AI Quality Ranking',
    key: 'ai-quality-ranking',
    dev: true,
    config: {
        commentRankers: [
            {label: '!AI Quality Ranking', ranker: rankByCommentQuality},
            {label: '!AI Bridging Ranking', ranker: rankByCommentBridging},
            {label: '!AI Quality & Bridging Ranking', ranker: rankByCommentQualityAndBridging}
        ],
        pageHiddenWidgets: [MissingQualityScoreGenerator, MissingBridgingScoreGenerator]
    }
}

export const ShowRankingFeaturesFeature = {
    name: 'Show Ranking Features',
    key: 'show-ranking-features',
    dev: true,
    config: {
        commentBelowWidgets: [DisplayQualityScores, DisplayBridgingScores]
    }
}

function DisplayQualityScores({comment}) {
    return <DisplayScores comment={comment} scoreType='comment_quality' />;
}

function DisplayBridgingScores({comment}) {
    return <DisplayScores comment={comment} scoreType='comment_bridging' />;
}

function DisplayScores({comment, scoreType}) {
    const scoreFields = useObject(scoreType, comment.key);
    const isAdmin = useIsAdmin();
    const datastore = useDatastore();
    if (!isAdmin || !scoreFields) {
        return null;
    }
    const score = scoreComment({datastore, comment, scoreType});
    return <PadBox top={10}><FlowBox rowGap={4} columnGap={4}>        
        {Object.entries(scoreFields).map(([key, value]) => 
            key == 'key' ? null : <Tag type='tiny' key={key} text={key} color={value ? colorLightGreen : colorGreyHover}/>) 
        }
        <PadBox vert={3}><UtilityText type='tiny' text={`${score} / ${Object.keys(scoreFields).length}`} /></PadBox>
    </FlowBox></PadBox>;
}

function MissingQualityScoreGenerator() {
    return <MissingCommentScoreGenerator scoreType='comment_quality' />;
}

function MissingBridgingScoreGenerator() {
    return <MissingCommentScoreGenerator scoreType='comment_bridging' />;
}

function MissingCommentScoreGenerator({scoreType}) {
    const comments = useCollection('comment');
    const commentScores = useCollection(scoreType);
    const datastore = useDatastore();

    console.log('MissingCommentScoreGenerator', scoreType, commentScores);

    const commentsMissingScores = comments.filter(c => !commentScores.find(q => q.key == c.key));
    const firstCommentMissingScore = commentsMissingScores[0];

    useEffect(() => {
        if (firstCommentMissingScore) {
            console.log('scoring', firstCommentMissingScore);
            datastore.callServerAsync('ranking', 'scoreAllComments', {scoreType});
        }
    }, [firstCommentMissingScore]);

    return null;
}

function scoreComment({datastore, comment, scoreType}) {
    const scoreFields = {...datastore.getObject(scoreType, comment.key), key: null};
    if (!scoreFields) {
        return 0;
    }
    return sumArray(Object.values(scoreFields).map(v => v ? 1 : 0));
}

function rankByCommentQuality({datastore, comments}) {
    return comments.sort((a, b) => scoreComment({datastore, comment: b, scoreType: 'comment_quality'}) - scoreComment({datastore, comment: a, scoreType: 'comment_quality'}));
}

function rankByCommentBridging({datastore, comments}) {
    return comments.sort((a, b) => scoreComment({datastore, comment: b, scoreType: 'comment_bridging'}) - scoreComment({datastore, comment: a, scoreType: 'comment_bridging'}));
}

function rankByCommentQualityAndBridging({datastore, comments}) {
    return comments.sort((a, b) => {
        const qualityA = scoreComment({datastore, comment: a, scoreType: 'comment_quality'});
        const qualityB = scoreComment({datastore, comment: b, scoreType: 'comment_quality'});
        const bridgingA = scoreComment({datastore, comment: a, scoreType: 'comment_bridging'});
        const bridgingB = scoreComment({datastore, comment: b, scoreType: 'comment_bridging'});
        return (qualityB - qualityA) + (bridgingB - bridgingA);
    });
}
