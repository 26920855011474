import { HorizBox, Narrow, Pad } from "component/basics"
import { DemoSection, SpacedArray } from "system/demo"
import { Paragraph } from "component/text"
import { SimpleVideo, useVideo, VideoPlayerStateContextProvider } from "../../contrib/zdf/videoPlayerStateContext";
import { View } from "react-native-web";
import { CTAButton } from "component/button"

export function VideoPlayerStateDemo() {
    return (
        <Narrow>
            <DemoSection text="video controls for video outside of Iframe (Only working when embedded)">
                <SpacedArray pad={10}>
                    <VideoPlayerStateContextProvider useVideoFromArticle={true}>
                        <SimpleVideoControls />
                    </VideoPlayerStateContextProvider>
                </SpacedArray>
            </DemoSection>
            <DemoSection text="useRegisteredVideo hook">
                <SpacedArray pad={10}>
                    <SimpleVideo
                        src={"https://archive.org/download/BigBuckBunny_124/Content/big_buck_bunny_720p_surround.mp4"}
                    />
                    <Pad size={10} />
                    <VideoPlayerStateContextProvider useVideoFromArticle={false}>
                        <SimpleVideoControls />
                    </VideoPlayerStateContextProvider>
                </SpacedArray>
            </DemoSection>
        </Narrow>
    );
}

function SimpleVideoControls() {
    const video = useVideo();
    return (
        <View>
            <HorizBox>
                <CTAButton text="PLAY" onPress={video.play}></CTAButton>
                <Pad size={10} />
                <CTAButton text="PAUSE" onPress={video.pause}></CTAButton>
                <Pad size={10} />
                <CTAButton text="SEEK TO 60 SECONDS" onPress={()=>video.seek(60)}></CTAButton>
            </HorizBox>
            <Pad size={10} />
            <Paragraph text={JSON.stringify(video, null, " ")}/>
        </View>
    );
}