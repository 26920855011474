import React from "react";

import { CommentsInput } from "component/comment";
import { Paragraph } from "component/text";
import { useDatastore, useGlobalProperty } from "util/datastore";
import { colorHydrangea3, colorTextPrimary, colorTextSecondary, colorTextSecondaryOnLightColorBg } from "component/color";

import { AskMeAnythingLiveStatus, TopicDate } from "../../../component/cbcrc/header";
import { formatToDateRangeString, useClientLocale } from "../../../component/cbcrc/date";

import { spacings } from "component/constants";
import { Pad, PadBox } from "component/basics";
import { useConfig } from "util/features";
import { PublicationStatusAdminScreen } from "./LiveStatusFeature";
import { TextButton } from "component/button";
import { useIsAdmin } from "component/admin";
import { View } from "react-native";


export const AskMeAnythingHeaderFeature = {
    key: 'amaheader',
    name: 'Ask me anything header',
    dev: true,
    subscreens: {
        publicationStatusAdmin: () => <PublicationStatusAdminScreen />,
    },
    config: {
        topicHeaderRightWidgets: [AskMeAnythingLiveStatusWidget],
        topicDescriptionWidget: AskMeAnythingStatusDescription,
        topicHeaderBottomWidgets: [AskMeAnythingHeaderBottomWidget],
        headerBackgroundColor: colorHydrangea3,
        headerFontColor: colorTextPrimary,
    }
}

export function AskMeAnythingLiveStatusWidget() {
    const datastore = useDatastore();
    const endAt = useGlobalProperty('endAt');
    const isAdmin = useIsAdmin();
    const live = endAt > new Date().toISOString();

    return <View styles={{ flexDirection: 'row', alignItems: 'center' }}>
        <AskMeAnythingLiveStatus live={live} />
        {isAdmin && <TextButton type={"tiny"} label={"Edit live status (admin)"} color={colorTextSecondary} onPress={() => datastore.pushSubscreen('publicationStatusAdmin')} />}
    </View>
}

export function AskMeAnythingStatusDescription() {
    return null;
}

function AskMeAnythingHeaderBottomWidget() {
    const startAt = useGlobalProperty('startAt');
    const endAt = useGlobalProperty('endAt');
    const locale = useClientLocale();
    const { readOnly, composerPreview } = useConfig();
    const live = endAt > new Date().toISOString();

    if (readOnly && composerPreview) {
        return <PadBox top={spacings.xl} bottom={spacings.sm}>
            {React.createElement(composerPreview)}
        </PadBox>
    }
    return live ?
        <PadBox top={spacings.xl} bottom={spacings.sm}>
            <CommentsInput />
            <Pad size={spacings.sm} />
            <Paragraph color={colorTextSecondaryOnLightColorBg} label='Your question will be considered for future content pieces. Please note, we may not be able to answer all questions.' />
        </PadBox>
        :
        <PadBox vert={spacings.xl} bottom={spacings.sm}>
            <TopicDate date={formatToDateRangeString({ start: new Date(startAt), end: new Date(endAt), locale })} />
        </PadBox>
}