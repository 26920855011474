import { CLICK, POPUP } from "system/demo"
import React from "react";
import { ClickableAITag } from "component/aitag";

export function aiTagStorySets() {
    return [
        {
            label: 'Composer View',
            modulePublic: {'charterUrl': 'https://foo.com'},
            content: <ClickableAITag />,
            stories: [
                {
                    label: 'Click on tag and open Community Guidelines',
                    actions: [
                        CLICK('ai-moderated-tag'),
                        POPUP(CLICK('Community Guidelines'))
                    ]
                }
            ],
        },
    ];
}
