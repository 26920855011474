import { auctionVideoVotingTemplateSlider, chapterQuestionMixVideoVotingTemplate, chapterVideoVotingTemplate, godzillaVideoVotingTemplateConnected, godzillaVideoVotingTemplateIndividual, } from "../../../feature/zdf/videovoting/examples/votingtemplate";


export const videoVotingDemoTemplates = {
    templateIndividual: godzillaVideoVotingTemplateIndividual,
    templateConnected: godzillaVideoVotingTemplateConnected,
    templateSlider: auctionVideoVotingTemplateSlider,
    templateChapters: chapterVideoVotingTemplate,
    templateChaptersQuestionsMix: chapterQuestionMixVideoVotingTemplate
};

// Individual Questions

const votesCollectionIndividual = [
    {
        key: "vi0",
        questionKey: videoVotingDemoTemplates.templateIndividual.questions[0].key,
        optionKey: videoVotingDemoTemplates.templateIndividual.questions[0].options[0].key,
        instanceKey: "voting-individual",
        from: "b",
    },
    {
        key: "vi1",
        questionKey: videoVotingDemoTemplates.templateIndividual.questions[0].key,
        optionKey: videoVotingDemoTemplates.templateIndividual.questions[0].options[0].key,
        instanceKey: "voting-individual",
        from: "a",
    },
    {
        key: "vi2",
        questionKey: videoVotingDemoTemplates.templateIndividual.questions[0].key,
        optionKey: videoVotingDemoTemplates.templateIndividual.questions[0].options[1].key,
        instanceKey: "voting-individual",
        from: "c",
    },
    {
        key: "vi3",
        questionKey: videoVotingDemoTemplates.templateIndividual.questions[1].key,
        optionKey: videoVotingDemoTemplates.templateIndividual.questions[1].options[0].key,
        instanceKey: "voting-individual",
        from: "b",
    },
    {
        key: "vi4",
        questionKey: videoVotingDemoTemplates.templateIndividual.questions[1].key,
        optionKey: videoVotingDemoTemplates.templateIndividual.questions[1].options[1].key,
        instanceKey: "voting-individual",
        from: "c",
    },
];

// Connected Questions

const votesCollectionConnected = [
    {
        key: "vc0",
        questionKey: videoVotingDemoTemplates.templateConnected.questions[0].subQuestions[0].key,
        optionKey: videoVotingDemoTemplates.templateConnected.questions[0].options[0].key,
        instanceKey: "voting-connected",
        from: "b",
    },
    {
        key: "vc1",
        questionKey: videoVotingDemoTemplates.templateConnected.questions[0].subQuestions[0].key,
        optionKey: videoVotingDemoTemplates.templateConnected.questions[0].options[0].key,
        instanceKey: "voting-connected",
        from: "a",
    },
    {
        key: "vc2",
        questionKey: videoVotingDemoTemplates.templateConnected.questions[0].subQuestions[0].key,
        optionKey: videoVotingDemoTemplates.templateConnected.questions[0].options[1].key,
        instanceKey: "voting-connected",
        from: "c",
    },
    {
        key: "vc3",
        questionKey: videoVotingDemoTemplates.templateConnected.questions[0].subQuestions[1].key,
        optionKey: videoVotingDemoTemplates.templateConnected.questions[0].options[0].key,
        instanceKey: "voting-connected",
        from: "b",
    },
    {
        key: "vc4",
        questionKey: videoVotingDemoTemplates.templateConnected.questions[0].subQuestions[1].key,
        optionKey: videoVotingDemoTemplates.templateConnected.questions[0].options[1].key,
        instanceKey: "voting-connected",
        from: "c",
    },
    {
        key: "vc5",
        questionKey: videoVotingDemoTemplates.templateConnected.questions[0].subQuestions[2].key,
        optionKey: videoVotingDemoTemplates.templateConnected.questions[0].options[1].key,
        instanceKey: "voting-connected",
        from: "c",
    },
    {
        key: "vc6",
        questionKey: videoVotingDemoTemplates.templateConnected.questions[0].subQuestions[3].key,
        optionKey: videoVotingDemoTemplates.templateConnected.questions[0].options[1].key,
        instanceKey: "voting-connected",
        from: "c",
    },
];

// Slider Questions

const votesSliderQuestion0 = [
    {
        key: "slider-question0-vote0",
        questionKey: videoVotingDemoTemplates.templateSlider.questions[0].key,
        instanceKey: "voting-slider",
        value: 100,
        from: "a",
    },
    {
        key: "slider-question0-vote1",
        questionKey: videoVotingDemoTemplates.templateSlider.questions[0].key,
        instanceKey: "voting-slider",
        value: 100,
        from: "b",
    },
    {
        key: "slider-question0-vote2",
        questionKey: videoVotingDemoTemplates.templateSlider.questions[0].key,
        instanceKey: "voting-slider",
        value: 150,
        from: "c",
    },
    {
        key: "slider-question0-vote3",
        questionKey: videoVotingDemoTemplates.templateSlider.questions[0].key,
        instanceKey: "voting-slider",
        value: 300,
        from: "d",
    },
];

const votesSliderQuestion1 = [
    {
        key: "slider-question1-vote0",
        questionKey: videoVotingDemoTemplates.templateSlider.questions[1].key,
        instanceKey: "voting-slider",
        value: 100,
        from: "b",
    },
    {
        key: "slider-question1-vote1",
        questionKey: videoVotingDemoTemplates.templateSlider.questions[1].key,
        instanceKey: "voting-slider",
        value: 200,
        from: "c",
    },
    {
        key: "slider-question1-vote2",
        questionKey: videoVotingDemoTemplates.templateSlider.questions[1].key,
        instanceKey: "voting-slider",
        value: 500,
        from: "d",
    },
];

const votesSliderQuestion2 = [
    {
        key: "slider-question2-vote0",
        questionKey: videoVotingDemoTemplates.templateSlider.questions[2].key,
        instanceKey: "voting-slider",
        value: 1400,
        from: "a",
    },
    {
        key: "slider-question2-vote1",
        questionKey: videoVotingDemoTemplates.templateSlider.questions[2].key,
        instanceKey: "voting-slider",
        value: 400,
        from: "b",
    },
    {
        key: "slider-question2-vote2",
        questionKey: videoVotingDemoTemplates.templateSlider.questions[2].key,
        instanceKey: "voting-slider",
        value: 1000,
        from: "c",
    },
    {
        key: "slider-question2-vote3",
        questionKey: videoVotingDemoTemplates.templateSlider.questions[2].key,
        instanceKey: "voting-slider",
        value: 1200,
        from: "d",
    },
];

const votesSliderQuestion3 = [
    {
        key: "slider-question3-vote0",
        questionKey: videoVotingDemoTemplates.templateSlider.questions[3].key,
        instanceKey: "voting-slider",
        value: 1400,
        from: "a",
    },
    {
        key: "slider-question3-vote1",
        questionKey: videoVotingDemoTemplates.templateSlider.questions[3].key,
        instanceKey: "voting-slider",
        value: 400,
        from: "b",
    },
    {
        key: "slider-question3-vote2",
        questionKey: videoVotingDemoTemplates.templateSlider.questions[3].key,
        instanceKey: "voting-slider",
        value: 1000,
        from: "c",
    },
    {
        key: "slider-question3-vote3",
        questionKey: videoVotingDemoTemplates.templateSlider.questions[3].key,
        instanceKey: "voting-slider",
        value: 1200,
        from: "d",
    },
];

const votesSliderQuestion4 = [
    {
        key: "slider-question4-vote0",
        questionKey: videoVotingDemoTemplates.templateSlider.questions[4].key,
        instanceKey: "voting-slider",
        value: 99,
        from: "a",
    },
    {
        key: "slider-question4-vote1",
        questionKey: videoVotingDemoTemplates.templateSlider.questions[4].key,
        instanceKey: "voting-slider",
        value: 120,
        from: "b",
    },
];

const votesSliderQuestion5 = [
    {
        key: "slider-question5-vote0",
        questionKey: videoVotingDemoTemplates.templateSlider.questions[5].key,
        instanceKey: "voting-slider",
        value: 2000,
        from: "a",
    },
    {
        key: "slider-question5-vote1",
        questionKey: videoVotingDemoTemplates.templateSlider.questions[5].key,
        instanceKey: "voting-slider",
        value: 500,
        from: "b",
    },
];

const votesCollectionSlider = [
    ...votesSliderQuestion0,
    ...votesSliderQuestion1,
    ...votesSliderQuestion2,
    ...votesSliderQuestion3,
    ...votesSliderQuestion4,
    ...votesSliderQuestion5,
];

export const videoVotingDemoVotes = {
    votesIndividual: votesCollectionIndividual,
    votesConnected: votesCollectionConnected,
    votesSlider: votesCollectionSlider,
};