import { colorAmbigousBlack, colorSurfaceWarning } from "component/color";
import { REPLACE_CBC_Tag } from "../../../component/cbcrc/tag";
import { Badge } from "../../../component/cbcrc/badge";
import { getPSMBadgeImageForSilo } from "./psmBadgeImages";
import { useSiloKey } from "util/datastore";

export const TitledWriterFeature = {
    key: 'titledWriter',
    name: 'Titled Writer',
    dev: true,
    config: {
        bylineTags: [TitledWriterTag],
        profilePhotoLayers: [TitledWriterBadge],
    }
}

function TitledWriterTag(persona) {
    if(!persona?.isAffiliatedToPSM || !persona?.title) return null;

    return <REPLACE_CBC_Tag color={colorSurfaceWarning} textColor={colorAmbigousBlack} type="tiny" text={persona.title} />;
}

function TitledWriterBadge({persona}) {
    const siloKey = useSiloKey();
    if(!persona?.isAffiliatedToPSM) return null;

    const imageUrl = getPSMBadgeImageForSilo(siloKey);
    
    if(!imageUrl) return null;

    return <Badge imageUrl={imageUrl} />
}
