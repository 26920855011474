import { ConversationScreen, Pad, PadBox, WindowTitle } from "component/basics";
import { useConfig } from "util/features";
import { Heading } from "component/text";
import { Catcher } from "system/catcher";

export const AccountStructure = {
    key: 'account',
    name: 'Account',
    screen: AccountScreen,
    hasFocusTrap: true,
    defaultConfig: {
        accountWidgets: [],
    }
}

export function AccountScreen() {
    const {accountWidgets} = useConfig();

    return <ConversationScreen>
        <WindowTitle label='Your Account' />
        <PadBox horiz={20} vert={20}>
            <Heading type='large' label='Your Account' />
            {accountWidgets.map((Widget, i) => <Catcher key={i}><Widget /></Catcher>)}
            <Pad />
        </PadBox>
    </ConversationScreen>
}

