import React, { useState } from 'react';
import { StyleSheet, View } from "react-native";
import { Edit, Help, Star } from "@carbon/icons-react";

import { HorizBox, Pad, PadBox } from "component/basics"
import { CTAButton, TextButton } from "component/button"
import { UtilityText } from "component/text"
import { useCollection, useDatastore, useGlobalProperty, useInstanceKey, useObject } from "util/datastore"
import { keyToUrl } from "util/util"
import { useIsAdmin } from "component/admin"
import { colorSurfaceDiscovery, colorTeaserBackground, colorTextSecondary } from "component/color"
import { useConfig } from "util/features"

import { toBool } from "../../../util/util";
import { TeaserScreen } from "../../../system/teaser";
import { CommentPreview, TeaserButton } from '../../teaser/EditorialQuestionFeature';
import { useGlobalTopics } from "../admin/TopicListFeature";
import { AdminTeaserAction, TopicChooserAdminScreen } from '../../../feature/teaser/TopicPhotoTeaser';
import { getFontSize } from 'platform-specific/font';

export const TopicTeaserFeature = {
    name: 'Topic of the Moment',
    key: 'topicteaser',
    config: {
        teaser: EditorialTopicTeaser,
        teaserSingleQuestion: AdaptiveLayoutTopicTeaserWithComment,
        teaserMultiQuestion: DefaultTopicTeaser,
        adminScreen: TopicChooserAdminScreen,
        adminButtonLabel: 'Select topics for article (admin)'
    }
}

export const AskMeAnythingTeaserFeature = {
    name: 'Ask Me Anything',
    key: 'askmeanythingteaser',
    config: {
        teaser: EditorialTopicTeaser,
        teaserSingleQuestion: AdaptiveLayoutAskMeAnythingTeaserWithComment,
        teaserMultiQuestion: DefaultTopicTeaser,
        adminScreen: TopicChooserAdminScreen,
        adminButtonLabel: 'Select topics for article (admin)'
    }
}

export function EditorialTopicTeaser() {
    const isAdmin = useIsAdmin();
    const topics = useCollection('topic').filter(q => !q.deleted);
    const { teaserSingleQuestion } = useConfig();

    if (topics.length === 0) {
        return <TeaserScreen color={colorTeaserBackground}>
            <UtilityText label='No topic for this content' />
            {toBool(isAdmin) && <PadBox top={20}><AdminTeaserAction /></PadBox>}
        </TeaserScreen>;
    }
    const topic = topics[0];

    return <TeaserScreen>
        {React.createElement(teaserSingleQuestion, { topic })}
    </TeaserScreen>;
}

export function AdaptiveLayoutAskMeAnythingTeaserWithComment({ topic }) {
    const derivedTopics = useCollection('derived_topicPreview');
    const [narrow, setNarrow] = useState(false);
    const isAdmin = useIsAdmin();
    const { key, text } = topic;
    const topicPreview = useObject('derived_topicPreview', key);


    if (derivedTopics.length === 0) {
        return <TeaserScreen color={colorTeaserBackground}>
            <UtilityText label='No topic for this content - No derived topics' />
            {toBool(isAdmin) && <PadBox top={20}><AdminTeaserAction /></PadBox>}
        </TeaserScreen>;
    }


    function onLayout(event) {
        const { width } = event.nativeEvent.layout;
        setNarrow(width < 550);
    }

    return <TeaserScreen>
        <View onLayout={onLayout}>
            {narrow ?
                <NarrowTopicTeaserWithComment header={'Ask us anything'} icon={'help'} emptyState={'Be the first to ask a question'} countFormat={{ count: topicPreview?.commentCount, singular: 'response', plural: 'responses' }} title={topicPreview?.name ?? text} instanceKey={key} commentPreview={topicPreview?.commentPreview} commentCount={topicPreview?.commentCount}  callToAction="Ask a question"/>
                :
                <WideTopicTeaserWithComment header={'Ask us anything'} icon={'help'} emptyState={'Be the first to ask a question'} countFormat={{ count: topicPreview?.commentCount, singular: 'response', plural: 'responses' }} title={topicPreview?.name ?? text} instanceKey={key} commentPreview={topicPreview?.commentPreview} commentCount={topicPreview?.commentCount}  callToAction="Ask a question"/>
            }
        </View>
        {isAdmin && <PadBox top={20}><AdminTeaserAction /></PadBox>}
    </TeaserScreen>
}

export function AdaptiveLayoutTopicTeaserWithComment({ topic }) {
    const derivedTopics = useCollection('derived_topicPreview');
    const [narrow, setNarrow] = useState(false);
    const isAdmin = useIsAdmin();
    const { key, text } = topic;
    const topicPreview = useObject('derived_topicPreview', key);

    if (derivedTopics.length === 0) {
        return <TeaserScreen color={colorTeaserBackground}>
            <UtilityText label='No topic for this content - No derived topics' />
            {toBool(isAdmin) && <PadBox top={20}><AdminTeaserAction /></PadBox>}
        </TeaserScreen>;
    }


    function onLayout(event) {
        const { width } = event.nativeEvent.layout;
        setNarrow(width < 550);
    }

    return <TeaserScreen>
        <View onLayout={onLayout}>
            {narrow ?
                <NarrowTopicTeaserWithComment header={'Topic of the moment'} icon={'star'} emptyState={'Be the first to share your thoughts'} countFormat={{ count: topicPreview?.commentCount, singular: 'participant', plural: 'participants' }} title={topicPreview?.name ?? text} instanceKey={key} commentPreview={topicPreview?.commentPreview} commentCount={topicPreview?.commentCount} callToAction="Share your thoughts"/>
                :
                <WideTopicTeaserWithComment header={'Topic of the moment'} icon={'star'} emptyState={'Be the first to share your thoughts'} countFormat={{ count: topicPreview?.commentCount, singular: 'participant', plural: 'participants' }} title={topicPreview?.name ?? text } instanceKey={key} commentPreview={topicPreview?.commentPreview} commentCount={topicPreview?.commentCount} callToAction="Share your thoughts" />
            }
        </View>
        {isAdmin && <PadBox top={20}><AdminTeaserAction /></PadBox>}
    </TeaserScreen>
}

export function NarrowTopicTeaserWithComment({ header, icon, emptyState, title, instanceKey, commentPreview, countFormat, commentCount, callToAction }) {
    const s = NarrowTopicTeaserStyle;
    const datastore = useDatastore();
    const articleInfo = useGlobalProperty('articleInfo') ?? {};
    const articleInstanceKey = useInstanceKey();
    const globalParams = {entryUrl: keyToUrl(articleInstanceKey), entryTitle: articleInfo.title, entryImage: articleInfo.image};
    return <View style={s.outer}>
        <PadBox horiz={20} vert={20}>
            <TopicInfo header={header} icon={icon} emptyState={emptyState} title={title} structureKey={"topic"} instanceKey={instanceKey} countFormat={countFormat} commentCount={commentCount} />
        </PadBox>
        <View style={s.comment}>
            <PadBox bottom={20}><CommentPreview commentPreview={commentPreview} /></PadBox>
            <TeaserButton label={callToAction} icon={<Edit color='white' />} wide={true}
                onPress={() => datastore.gotoInstance({ structureKey: 'topic', instanceKey, globalParams })}
            />
        </View>
    </View>
}

const NarrowTopicTeaserStyle = StyleSheet.create({
    outer: {
        backgroundColor: colorTeaserBackground,
        borderRadius: 10,
    },
    comment: {
        backgroundColor: '#E8EFF9',
        padding: 20,
        flex: 1,
        justifyContent: 'center',
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
    }
})


export function WideTopicTeaserWithComment({ header, icon,emptyState, title, instanceKey, commentPreview, countFormat, commentCount, callToAction }) {
    const s = WideTopicTeaserStyle; 
    const datastore = useDatastore();
    const articleInfo = useGlobalProperty('articleInfo') ?? {};
    const articleInstanceKey = useInstanceKey();
    const globalParams = {entryUrl: keyToUrl(articleInstanceKey), entryTitle: articleInfo.title, entryImage: articleInfo.image};
    return <View style={s.outer}>
        <View style={s.left}>
        <TopicInfo header={header} icon={icon} title={title} emptyState={emptyState} structureKey={"topic"} instanceKey={instanceKey} countFormat={countFormat} commentCount={commentCount} />
            <Pad size={20} />
            <TeaserButton label={callToAction} icon={<Edit color='white' />}
                onPress={() => datastore.gotoInstance({ structureKey: 'topic', instanceKey, globalParams })}
            />
        </View>
        <View style={s.right}>
            <CommentPreview commentPreview={commentPreview} />
        </View>
    </View>
}

const WideTopicTeaserStyle = StyleSheet.create({
    outer: {
        borderRadius: 10,
        flexDirection: 'row',
    },
    left: {
        backgroundColor: colorTeaserBackground,
        padding: 24,
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        flex: 1,
    },
    right: {
        backgroundColor: '#E8EFF9',
        padding: 24,
        flex: 1,
        justifyContent: 'center',
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
    }
})

export function TopicTeaserIcon({ icon }) {
    switch (icon) {
        case 'star': return <Star />;
        case 'help': return <Help />;
        default: return <Star />;
    }
}

export function TopicInfo({ header, icon,emptyState, title, countFormat, structureKey, instanceKey, commentCount }) {
    const datastore = useDatastore();
    const articleInfo = useGlobalProperty('articleInfo') ?? {};
    const articleInstanceKey = useInstanceKey();
    const globalParams = {entryUrl: keyToUrl(articleInstanceKey), entryTitle: articleInfo.title, entryImage: articleInfo.image};
    return <View>
        <HorizBox center>
            <TopicTeaserIcon icon={icon} />
            <Pad size={8} />
            <UtilityText type='tiny' label={header} caps />
        </HorizBox>
        <Pad size={28} />
        <TextButton heading type='large' weight='medium' text={title}
            onPress={() => datastore.gotoInstance({ structureKey, instanceKey, globalParams })}
        />
        <Pad size={8} />
        {commentCount ?
            <UtilityText type='label' label='{count} {noun}' color={colorTextSecondary}
                formatParams={countFormat}
            />
            :
            <UtilityText type='label' label={emptyState} color={colorTextSecondary} />
        }
    </View>
}

export function DefaultTopicTeaser() {
    const s = MiniTopicPromoStyle;
    const isAdmin = useIsAdmin();
    const topics = useGlobalTopics();
    const datastore = useDatastore();
    const articleInfo = useGlobalProperty('articleInfo') ?? {};
    const articleInstanceKey = useInstanceKey();
    const globalParams = {entryUrl: keyToUrl(articleInstanceKey), entryTitle: articleInfo.title, entryImage: articleInfo.image};
    if (topics.length === 0) {
        return <TeaserScreen color={colorSurfaceDiscovery}>
            <UtilityText label='No topic for this content' />
            {toBool(isAdmin) && <PadBox top={20}><AdminTeaserAction /></PadBox>}
        </TeaserScreen>;
    } else {
        const { topic, key } = topics[0];
        return (<TeaserScreen color={colorSurfaceDiscovery}>
            <PadBox horiz={20} vert={20}>
                <HorizBox center>
                    <Pad size={8} />
                    <UtilityText type='tiny' label='Topic of the moment' caps />
                </HorizBox>
                <Pad size={16} />
                <View>
                    <HorizBox center>
                        <View style={{ flex: 1 }}>
                            <TextButton level={5} editorial style={s.topic} text={topic.name}
                                onPress={() => datastore.gotoInstance({ structureKey: 'topic', instanceKey: key, globalParams })}
                            />
                        </View>
                        <CTAButton label='Join' onPress={() => datastore.gotoInstance({ structureKey: 'topic', instanceKey: key, globalParams })} />
                    </HorizBox>
                </View>
                {toBool(isAdmin) && <PadBox top={20}><AdminTeaserAction /></PadBox>}
            </PadBox>
        </TeaserScreen>);
    }
}

const MiniTopicPromoStyle = StyleSheet.create({
    container: {
        backgroundColor: colorSurfaceDiscovery
    },
    topic: {
        fontFamily: 'PlayfairDisplay_400Regular',
        fontSize: getFontSize(18),
    },
})
