
export const productionFirebaseConfig = {
    apiKey: "AIzaSyDIg3OR3i51VYrUyUd_L5iIownjdSnExlc",
    authDomain: "np-psi-dev.firebaseapp.com",
    databaseURL: "https://np-psi-dev-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "np-psi-dev",
    storageBucket: "np-psi-dev.appspot.com",
    messagingSenderId: "768032889623",
    appId: "1:768032889623:web:634a1604eda03820ab7552",
};

export const stagingFirebaseConfig = {
    apiKey: "AIzaSyAG6jDk_GQRPtCby-amS6LoMWPnvKCDw8Q",
    authDomain: "np-psi-staging.firebaseapp.com",
    databaseURL: "https://np-psi-staging-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "np-psi-staging",
    storageBucket: "np-psi-staging.firebasestorage.app",
    messagingSenderId: "1040161687473",
    appId: "1:1040161687473:web:9f1b29060c33946325df90"
};

const rcDevFirebaseConfig = {
    apiKey: "AIzaSyBBmARny3o_UO4mS-fbec9ncTpOV1kTshY",
    authDomain: "apppsi-40af9.firebaseapp.com",
    databaseURL: "https://apppsi-40af9-default-rtdb.firebaseio.com",
    projectId: "apppsi-40af9",
    storageBucket: "apppsi-40af9.firebasestorage.app",
    messagingSenderId: "563226474199",
    appId: "1:563226474199:web:a8ff571ea7f016b11aa292"
};

const rcProdFirebaseConfig = {
    apiKey: "AIzaSyAupzFkfGhu4Do7t4B78emtcEIkI8gI0Cc",
    authDomain: "rc-prod-psi-product.firebaseapp.com",
    projectId: "rc-prod-psi-product",
    storageBucket: "rc-prod-psi-product.firebasestorage.app",
    messagingSenderId: "935278692468",
    appId: "1:935278692468:web:4ac6ef065ab675e2daa921"
  };

export const deploymentConfigMap = {
    local: {
        host: `http://${window.location.hostname}:5000`,
        firebaseConfig: productionFirebaseConfig,
        emulateFirebase: true,
        defaultToAdminDash: true,
        adminEmailDomain: 'admin.org',
        ssoDebug: true,
        storagePrefix: 'https://firebasestorage.googleapis.com/v0/b/new-public-demo.appspot.com/o/',
    },
    production: {
        host: 'https://psi.newpublic.org',
        firebaseConfig: productionFirebaseConfig,
        storagePrefix: 'https://firebasestorage.googleapis.com/v0/b/new-public-demo.appspot.com/o/',
    },
    staging: {
        host: 'https://np-psi-staging.firebaseapp.com',
        firebaseConfig: stagingFirebaseConfig,
        storagePrefix: 'https://firebasestorage.googleapis.com/v0/b/new-public-demo.appspot.com/o/',
    },
    rcDev: {
        host: 'https://dev-conversations.radio-canada.ca',
        firebaseConfig: rcDevFirebaseConfig,
    },
    rcProd: {
        host: 'https://rc-prod-psi-product.web.app',
        firebaseConfig: rcProdFirebaseConfig,
    }
};

export const domainToDeploymentMap = {
    'np-psi-dev.firebaseapp.com': 'dev',
    'np-psi-staging.firebaseapp.com': 'staging',
    'np-psi-staging.web.app': 'staging',
    'psi.newpublic.org': 'production',
    'conversations.radio-canada.ca': 'production',
    'dev-conversations.radio-canada.ca': 'rcDev',
    'rc-prod-psi-product.web.app': 'rcProd',
    'localhost': 'local',
    'test': 'production',
};
