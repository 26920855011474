import { HoverView, Pad, PadBox } from "component/basics";
import { colorAmbigousBlack, colorBorderPrimary, colorSurfacePrimary } from "component/color";
import { StyleSheet, Text, View } from "react-native";
import { UtilityText } from "component/text";
import { Information } from '@carbon/icons-react';
import { useState } from 'react';
import { Modal } from "component/modal";
import { useDatastore, useModulePublicData, useSiloKey } from "util/datastore";
import Svg, { Defs, LinearGradient, Path, Stop } from "react-native-svg";
import { RichText } from "./richtext";
import { getDeploymentConfig } from "util/util";


export function ClickableAITag() {
    const [showModal, setShowModal] = useState(false);
    const onPress = () => {
        setShowModal(!showModal);
    };

    return <View>
        <AITag infoIcon onPress={onPress} />
        {showModal && <AITagModal onClose={() => setShowModal(false)} />}
    </View>
}

export function AITagModal({onClose}) {
    const siloKey = useSiloKey();
    const deploymentConfig = getDeploymentConfig();
    const defaultCommunityGuidelinesLink = deploymentConfig.host + '/pages/' + siloKey + '/community-guidelines.html';
    const communityGuidelinesLink = useModulePublicData('admin', ['links', 'communityGuidelines']) ?? defaultCommunityGuidelinesLink;
    const datastore = useDatastore();

    function onPress() {
        datastore.openUrl(communityGuidelinesLink);
    }

    return <Modal onClose={onClose}>
        <PadBox horiz={20} vert={20}>
            <Pad size={20} />
            <AITag onPress={onPress}/>
            <Pad size={12} />
            <Text style={{flexWrap: 'wrap'}}>
                <RichText 
                    label="To maintain a welcoming and respectful environment, this space uses AI to help moderate comments. AI may flag your post for your additional review if it does not follow the [Community Guidelines]($onPress)" 
                    suffixText='.'
                    callbacks={{onPress}}
                />
            </Text>
        </PadBox>  
    </Modal>
}

export function AILogo() {
    return <Svg width="16" height="16" viewBox="0 0 16 16" fill="none">
        <Path
            d="M5.80478 14.96C5.70245 14.9168 5.61742 14.8408 5.5631 14.7439C5.50878 14.647 5.48826 14.5348 5.50478 14.425L6.41478 8.5H3.99978C3.92321 8.50206 3.8472 8.4865 3.7776 8.45453C3.70801 8.42255 3.64668 8.37502 3.59837 8.31559C3.55005 8.25616 3.51603 8.18643 3.49894 8.11177C3.48185 8.03711 3.48213 7.95953 3.49978 7.885L4.99978 1.385C5.02617 1.27313 5.0903 1.17377 5.18137 1.10365C5.27243 1.03353 5.38488 0.996924 5.49978 0.999996H10.4998C10.5745 0.999742 10.6483 1.01623 10.7158 1.04824C10.7833 1.08026 10.8427 1.12699 10.8898 1.185C10.9375 1.24366 10.9713 1.31239 10.9886 1.386C11.0059 1.45962 11.0063 1.5362 10.9898 1.61L10.1248 5.5H12.4998C12.5935 5.49981 12.6854 5.52596 12.7649 5.57547C12.8445 5.62497 12.9085 5.69584 12.9498 5.78C12.9856 5.86076 12.9994 5.94959 12.9897 6.03742C12.9801 6.12525 12.9473 6.20895 12.8948 6.28L6.39478 14.78C6.35085 14.8451 6.29219 14.899 6.22356 14.9372C6.15494 14.9754 6.07827 14.9969 5.99978 15C5.93288 14.9986 5.8668 14.9851 5.80478 14.96Z"
            fill="url(#paint0_linear_11932_16600)"
        />
        <Defs>
            <LinearGradient id="paint0_linear_11932_16600" x1="8.23953" y1="0.999817" x2="8.23953" y2="15" gradientUnits="userSpaceOnUse">
                <Stop stopColor="#F07D7D" />
                <Stop offset="0.5" stopColor="#BE73CD" />
                <Stop offset="0.75" stopColor="#7D7DFF" />
                <Stop offset="1" stopColor="#666666" />
            </LinearGradient>
        </Defs>
    </Svg>
}

export function AITag({label='AI-moderated', testID='ai-moderated-tag', infoIcon=false , onPress}) {
    const s = AITagStyle;
    return <View style={s.container}>
        <AILogo />
        <Pad size={6}/>
        <UtilityText label={label} weight='medium' type='tiny' />
        <Pad size={6}/>
        {infoIcon && <View style={s.iconWrapper}>
            <HoverView ariaLabel={label} onPress={onPress} testID={testID} style={s.iconTapTarget}>
                <Information />
            </HoverView>
        </View>}
    </View>
}

const AITagStyle = StyleSheet.create({
    container: {
        height: 28,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 32,
        borderWidth: 1,
        backgroundColor: colorSurfacePrimary,
        borderColor: colorBorderPrimary,
        paddingHorizontal: 6,
        alignSelf: 'flex-start',
    },
    iconTapTarget: {
        width: 44, // expands tap target beyond the iconWrapper
        height: 44,
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconWrapper: {
        position: 'relative',
        width: 16,
        height: 16,
        justifyContent: 'center',
        alignItems: 'center',
    },
    infoBoxWrapper: {
        position: 'absolute',
        top: 28,
        right: 0,
    },
    infoBox: {
        backgroundColor: colorAmbigousBlack,
        borderRadius: 8,
        width: 300,
    },
});
