import { View } from "react-native-web";
import { DemoSection, SpacedArray } from "../../system/demo";
import { Save, TrashCan } from "@carbon/icons-react";
import { colorBorderSelected, colorTextWarning, colorWhite } from "../../component/color";
import { CTAButtonWithLoadingSpinner } from "../../component/zdf/button";

export function CTAButtonWithLoadingSpinnerDemo() {
    function onPress() {
        console.log("press");
    }

    return (
        <View>
            <DemoSection text="No loading spinner">
                <SpacedArray horiz>
                    <CTAButtonWithLoadingSpinner text="Primary" type="primary" onPress={onPress} />
                    <CTAButtonWithLoadingSpinner text="Secondary" type="secondary" onPress={onPress} />
                    <CTAButtonWithLoadingSpinner text="Accent" type="accent" onPress={onPress} />
                    <CTAButtonWithLoadingSpinner icon={<TrashCan style={{ fill: colorTextWarning }} />} text="Delete" type="delete" onPress={onPress} />
                    <CTAButtonWithLoadingSpinner icon={<TrashCan style={{ fill: colorTextWarning }} />} testID="delete" type="delete" onPress={onPress} />
                    <CTAButtonWithLoadingSpinner text="✨ Emoji" type="accent" onPress={onPress} />
                    <CTAButtonWithLoadingSpinner text="Disabled" type="primary" disabled />
                </SpacedArray>
                <CTAButtonWithLoadingSpinner wide text="Wide" onPress={onPress} />
                <CTAButtonWithLoadingSpinner size="compact" text="Compact" onPress={onPress} />
            </DemoSection>

            <DemoSection text="Text and loading spinner">
                <SpacedArray horiz>
                    <CTAButtonWithLoadingSpinner text="Processing Primary" type="primary" processing onPress={onPress} />
                    <CTAButtonWithLoadingSpinner text="Processing Secondary" type="secondary" processing onPress={onPress} />
                    <CTAButtonWithLoadingSpinner text="Processing Accent" type="accent" processing onPress={onPress} />
                    <CTAButtonWithLoadingSpinner text="Processing Delete" type="delete" processing onPress={onPress} /> 
                </SpacedArray>
            </DemoSection>

            <DemoSection text="Text, icon, and loading spinner">
                <SpacedArray horiz>
                    <CTAButtonWithLoadingSpinner icon={<Save style={{ fill: colorWhite }} />} text="Processing Primary" type="primary" processing onPress={onPress} />
                    <CTAButtonWithLoadingSpinner icon={<Save style={{ fill: colorBorderSelected }} />} text="Processing Secondary" type="secondary" processing onPress={onPress} />
                    <CTAButtonWithLoadingSpinner icon={<Save style={{ fill: colorWhite }} />} text="Processing Accent" type="accent" processing onPress={onPress} />
                    <CTAButtonWithLoadingSpinner icon={<TrashCan style={{ fill: colorTextWarning }} />} text="Processing Delete" type="delete" processing onPress={onPress} />
                </SpacedArray>
            </DemoSection>

            <DemoSection text="No text, only icon and loading spinner">
                <SpacedArray horiz>
                    <CTAButtonWithLoadingSpinner icon={<Save style={{ fill: colorWhite }} />} type="primary" processing onPress={onPress} />
                    <CTAButtonWithLoadingSpinner icon={<Save style={{ fill: colorBorderSelected }} />} type="secondary" processing onPress={onPress} />
                    <CTAButtonWithLoadingSpinner icon={<Save style={{ fill: colorWhite }} />} type="accent" processing onPress={onPress} />
                    <CTAButtonWithLoadingSpinner icon={<TrashCan style={{ fill: colorTextWarning }} />} type="delete" processing onPress={onPress} />
                </SpacedArray>
            </DemoSection>
        </View>
    );
}                    