import { Narrow } from "component/basics"
import { CLICK, DemoSection } from "system/demo"
import { VideoVotingQuestionDialog } from "../../../contrib/zdf/structure/videovoting";
import { Datastore } from "util/datastore"
import { videoVotingDemoTemplates, videoVotingDemoVotes } from "./videovoting.data";
import { View } from "react-native-web";

const questionIndividual = videoVotingDemoTemplates.templateIndividual.questions[0];
const otherVotesIndividual = [
    {
        from: "b",
        questionKey: questionIndividual.key,
        optionKey: questionIndividual.options[0].key,
    },
    {
        from: "c",
        questionKey: questionIndividual.key,
        optionKey: questionIndividual.options[0].key,
    },
    {
        from: "d",
        questionKey: questionIndividual.key,
        optionKey: questionIndividual.options[0].key,
        anonymous: true,
    },
];

const myVotesIndividual = [
    {
        from: "a",
        questionKey: questionIndividual.key,
        optionKey: questionIndividual.options[1].key,
    },
];

export function VideoVotingQuestionDialogDemo() {
    return (
        <Narrow>
            <DemoSection text="Question Dialog">
                <Datastore 
                    testState={{
                        collections: {videoVote: [...otherVotesIndividual]},
                        sessionData: {
                            'videoVotingSessionState': {
                                anonymous: false
                            }
                        }
                    }}
                    >
                    <VideoVotingQuestionDialog
                        question={questionIndividual}
                        onFinishQuestionVoting={null}
                    />
                </Datastore>
            </DemoSection>
            <DemoSection text="Question Dialog (with secondary header)">
                <Datastore
                    testState={{
                        collections: {videoVote: [...otherVotesIndividual]},
                        sessionData: {
                            'videoVotingSessionState': {
                                anonymous: false
                            }
                        }                        
                    }}>
                    <VideoVotingQuestionDialog
                        question={questionIndividual}
                        onFinishQuestionVoting={null}
                        headerText={"What do you think about it now?"}
                    />
                </Datastore>
            </DemoSection>
            <DemoSection text="Question Dialog (already answered and with secondary header)">
                <Datastore
                    testState={{
                        collections: {videoVote: [...otherVotesIndividual, ...myVotesIndividual]},
                        sessionData: {
                            videoVotingSessionState: {
                                anonymous: false
                            }
                        }
                    }}>
                    <VideoVotingQuestionDialog
                        question={questionIndividual}
                        onFinishQuestionVoting={null}
                        headerText={"What do you think about it now?"}
                    />
                </Datastore>
            </DemoSection>
            <DemoSection text="Question Dialog with slider">
                <Datastore
                    testState={{
                        collections: {votes: videoVotingDemoVotes.votesSlider},
                        sessionData: {
                            videoVotingSessionState: {
                                anonymous: false
                            }
                        }
                    }}>
                    <VideoVotingQuestionDialog
                        question={videoVotingDemoTemplates.templateSlider.questions[6]}
                        onFinishQuestionVoting={null}
                    />
                </Datastore>
            </DemoSection>
            <DemoSection text="Question Dialog with slider (already answered)">
                <Datastore
                    testState={{
                        collections: {votes: videoVotingDemoVotes.votesSlider},
                        sessionData: {
                            videoVotingSessionState: {
                                anonymous: false
                            }
                        }
                    }}>
                    <VideoVotingQuestionDialog
                        question={videoVotingDemoTemplates.templateSlider.questions[0]}
                        onFinishQuestionVoting={null}
                    />
                </Datastore>
            </DemoSection>
        </Narrow>
    );
}

export function videovotingQuestionDialogStorySets() {
    return [
        {
            label: "Voting Actions",
            collections: { videoVote: [...otherVotesIndividual, ...videoVotingDemoVotes.votesSlider] },
            sessionData: {
                videoVotingSessionState: {
                    anonymous: false,
                },
            },
            content: (
                <View>
                    <DemoSection>
                        <VideoVotingQuestionDialog
                            question={questionIndividual}
                            onFinishQuestionVoting={null}
                            sessionData={{
                                videoVotingSessionState: {
                                    anonymous: false,
                                },
                            }}
                        />
                    </DemoSection>
                    <DemoSection>
                        <VideoVotingQuestionDialog
                            question={videoVotingDemoTemplates.templateSlider.questions[0]}
                            onFinishQuestionVoting={null}
                            sessionData={{
                                videoVotingSessionState: {
                                    anonymous: false,
                                },
                            }}
                        />
                    </DemoSection>
                </View>
            ),
            stories: [
                { label: "Vote for option", actions: [CLICK(questionIndividual.options[1].text)] },
                {
                    label: "Vote for option and change your mind",
                    actions: [CLICK(questionIndividual.options[1].text), CLICK(questionIndividual.options[0].text)],
                },
                {
                    label: "Vote for option and remove your vote",
                    actions: [CLICK(questionIndividual.options[1].text), CLICK(questionIndividual.options[1].text)],
                },
                {
                    // You can only demo setting the value to 0 but you cannot slide the handle
                    label: "Vote for slider value", 
                    actions: [CLICK("slider-bar")]
                }
            ],
        },
    ];
}