import { HorizBox, Pad } from "component/basics"
import { colorTagVerified } from "component/color"
import { UtilityText } from "component/text"
import { usePersonaKey } from "util/datastore"
import { StyleSheet, Text } from "react-native";
import { View } from "react-native-web";
import { ViewOff } from "@carbon/icons-react";
import { logEventAsync } from "util/eventlog"

/*
Figma: https://www.figma.com/design/VDCebWSu7lU0GynG1qmtF5/Conversation-Helper---Testing?node-id=136-4610&t=VlCGmpdSrRKAKHst-0

This feature works in conjunction with the Conversation Helper feature.

Every time a new comment is posted or an existing comment is updated, we check if this comment aligns with the current thought-provoking prompt provided by the Conversation Helper. If we find that the comment does what the prompt suggests, then the user will receive positive feedback. Currently, feedback is limited to a private thank-you message that appears as a widget under the user's comment.

In the future, we may make feedback independent from the Conversation Helper, and/or include more nuanced feedback.
*/

export const PrivateCommentFeedbackFeature = {
    name: "Private Comment Feedback",
    key: "privatecommentfeedback",
    parentFeature: "conversationhelper",
    dev:true,
    config: {
        commentMiddleWidgets: [PrivateThankYouMessage],
        commentPostCheckers: [checkPostAsync],
    },
};

//TODO: Consider putting this into the translation files maybe?
const thankYouMessagesDE = [
    "Hier, nimm diesen Keks als Dankeschön: 🍪",
    "Danke für deinen Kommentar! Nimm diesen Keks: 🍪",
    "Gute Antwort! Hier ist ein Keks nur für dich: 🍪",
    "Für diese Antwort hast du dir einen Keks verdient! 🍪",
    "Bitte sehr, du hast dir diesen Keks verdient: 🍪",
    "Danke fürs Teilen! 🍪",
];

const thankYouMessagesFR = [ "Tenez, prenez ce biscuit en guise de remerciement : 🍪",
    "Merci d'avoir commenté ! Prenez ce biscuit : 🍪",
    "Bonne réponse ! Voici un cookie rien que pour vous : 🍪",
    "Tu mérites un cookie pour cette réponse ! 🍪",
    "Voilà, tu mérites ce cookie : 🍪",
    "Merci d'avoir partagé ! 🍪"
];

const thankYouMessagesEN = [
    "Here, have this cookie as a thank-you: 🍪",
    "Thanks for commenting! Have this cookie: 🍪",
    "Good answer! Here is a cookie just for you: 🍪",
    "You deserve a cookie for that answer! 🍪",
    "Here you go, you deserve this cookie: 🍪",
    "Thanks for sharing! 🍪",
];

async function checkPostAsync({ datastore, comment }) {

    const feedbackResult = await checkIfCommentDeservesPositiveFeedbackAsync({ datastore, comment });

    let thankYouText;
    if (feedbackResult.deservesPositiveFeedback === true) {
        thankYouText = selectRandomLocalizedThankYouMessage(datastore, comment.text);
    }

    logEventAsync(datastore, "conversationhelper-comment-feedback", {
        deservesPositiveFeedback: feedbackResult.deservesPositiveFeedback.toString(),
        commentText: comment.text,
        feedbackText: feedbackResult.deservesPositiveFeedback ? thankYouText : "",
        explanation: feedbackResult.explanation,
    });

    return {
        allow: true,
        commentProps: {
            deservesPositiveFeedback: feedbackResult.deservesPositiveFeedback,
            thankYouText: feedbackResult.deservesPositiveFeedback ? thankYouText : null,
        },
    };
}

export function PrivateThankYouMessage({ comment, showAuthor=false }) {
    const s = ThankYouStyle;
    const personaKey = usePersonaKey();

    if (comment.deservesPositiveFeedback && comment.from === personaKey) {
        return (
            <View style={s.textbox}>
                <HorizBox center>
                    <ViewOff />
                    <Pad size={8} />
                    <UtilityText caps type="tiny" label="Only visible to you" />
                </HorizBox>
                <Pad size={3} />
                <HorizBox>
                    {showAuthor && (
                        <View>
                            {/* TODO: Use conversationHelperAppearance.name from Conversation Helper config instead of a hard-coded name. This has low priority because we're not testing this feature right now. */}
                            <Text style={s.robotText}>{"Auto-Moderator"}: </Text>
                            <Pad size={5} />
                        </View>
                    )}
                    <Text>
                        {comment.thankYouText ? comment.thankYouText : "You deserve a cookie for that answer! 🍪"}
                    </Text>
                </HorizBox>
            </View>
        );
    }
}

const ThankYouStyle = StyleSheet.create({
    textbox: {
        backgroundColor: colorTagVerified,
        paddingVertical: "5px",
        paddingHorizontal: "10px",
        borderRadius: "8px",
        width: "fit-content",
        marginTop: "15px",
    },
    robotText: {
        fontStyle: "italic",
    },
});

export function selectRandomLocalizedThankYouMessage(datastore, commentText) {
    const lang = datastore.getLanguage();
    if (lang === "english" || lang === null)
        return selectRandomThankYouMessage(thankYouMessagesEN, commentText);
    else if(lang === "german")
        return selectRandomThankYouMessage(thankYouMessagesDE, commentText);
    else if (lang === "french")
        return selectRandomThankYouMessage(thankYouMessagesFR, commentText);
}

export function selectRandomThankYouMessage(messages, text="") {

    let hash = 0;
    for (let i = 0; i < text.length; i++) {
        hash = (hash << 5) - hash + text.charCodeAt(i);
        hash |= 0; // Convert to 32bit integer
    }
    const index = Math.abs(hash) % messages.length;
    return messages[index];
}

async function checkIfCommentDeservesPositiveFeedbackAsync({ datastore, comment }) {
    const topic = datastore.getGlobalProperty("name");

    const thoughtProvokingPrompts = datastore.getCollection("thought_provoking_prompt", { sortBy: 'time', reverse: true });
    const thoughtProvokingPromptExists = (thoughtProvokingPrompts && thoughtProvokingPrompts.length > 0);

    // Check if this comment aligns with the current thought-provoking prompt
    const commentAlignsWithPromptResult = await datastore.callServerAsync(
        "conversationhelper", "checkIfCommentAlignsWithThoughtProvokingPrompt", {
            text: comment.text,
            question: thoughtProvokingPromptExists ? thoughtProvokingPrompts[0].question : "No sub-question provided.",
            reminder: thoughtProvokingPromptExists ? thoughtProvokingPrompts[0].reminder : "No reminder provided.",
            topic,
        }
    );

    // console.log("commentAlignsWithPromptResult:", commentAlignsWithPromptResult);

    // If this comment aligns with the thought-provoking prompt, it deserves positive feedback
    return commentAlignsWithPromptResult;
}
