import { colorAmbigousBlack, colorAmbiguousWhite, colorHydrangea3, colorTealBackground } from "component/color"

export const BaseTopicConfigFeature = {
    key: 'basetopicconfig',
    name: 'Base Topic',
    defaultConfig: {
        topicTopWidgets: [],
        topicBottomWidgets: [],
        headerBackgroundColor: colorTealBackground,
        headerFontColor: colorAmbiguousWhite,
    }
}

// Parent feature to group all the features that are related to the Topic of the Moment.
export const TopicOfTheMomentConfigFeature = {
    key: 'topicofthemomentconfig',
    name: 'Topic of the moment',
    defaultConfig: {
        topicTopWidgets: [],
        topicBottomWidgets: [],
        headerBackgroundColor: colorHydrangea3,
        headerFontColor: colorAmbigousBlack,
    }
}

// Parent feature to group all the features that are related to the Ask Me Anything.
export const AskMeAnythingConfigFeature = {
    key: 'askmeanything',
    name: 'Ask us anything',
    defaultConfig: {
        topicTopWidgets: [],
        topicBottomWidgets: [],
        headerBackgroundColor: colorHydrangea3,
        headerFontColor: colorAmbigousBlack,
    }
}