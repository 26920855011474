import { AdminScreen, TopicListScreen } from "feature/cbcrc/admin/TopicListFeature"
import { CLICK, INPUT } from "system/demo";

export const topicListFeatureDemo = [
    { label: 'Topic List', key: 'topic-list', storySets: topicListStorySets },
]

function topicListStorySets() {
    return [
        {
            label: 'Topic List',
            content: <TopicListScreen />,
            modulePublic: {
                topic: {
                    a: { name: 'What is the capital of France?', key: 'a' },
                    b: { name: 'What is the capital of Germany?', key: 'b' },
                    c: { name: 'What is the capital of Italy?', key: 'c' },
                }
            },
            stories: [
                {
                    label: 'Search for a topic', actions: [
                        INPUT('search-topic', 'Germany'),
                    ]
                },
                {
                    label: 'Select a topic', actions: [
                        CLICK('What is the capital of Germany?')
                    ]
                },
                {
                    label: 'Open the topic creation form', actions: [
                        CLICK('Create a topic')
                    ]
                }
            ],
        },
        {
            label: 'Topic Creation',
            content: <AdminScreen />,
            serverCall: {
                topics: {
                    add: () => null,
                }
            },
            stories: [
                {
                    label: 'Fill out the form', actions: [
                        INPUT('topic-name', 'What is the capital of Spain?'),
                        INPUT('photo-url', 'https://placehold.co/600x400/EEE/31343C'),
                        INPUT('starts-at', '2025-01-01'),
                        INPUT('ends-at', '2025-01-02'),
                        INPUT('conversation-starter', 'Tell us about your last trip to Spain.'),
                        CLICK('Active'),
                        CLICK('Topic poll'),
                        INPUT('poll-question-Topic poll', 'What is your favorite city in Spain?'),
                        INPUT('poll-option-0-Topic poll', 'Madrid'),
                        CLICK('Add topic')
                    ]
                },
                {
                    label: 'Add poll options', actions: [
                        CLICK('Topic poll'),
                        CLICK('Add option'),
                        CLICK('Add option'),
                        CLICK('Add option'),
                        CLICK('Add option'),
                        CLICK('Remove option'),
                        CLICK('Remove option'),
                        CLICK('Topic poll'),
                        CLICK('Upcoming topic poll'),
                        CLICK('Add option'),
                        CLICK('Add option'),
                        CLICK('Add option'),
                        CLICK('Add option'),
                        CLICK('Remove option'),
                        CLICK('Remove option'),
                    ],
                }
            ],
        }
    ];
}