import { useState } from "react";
import { RecirculationSlider } from "component/cbcrc/recirculationCards";
import { Card, ConversationScreen, HorizBox, Pad, PadBox, Separator } from "component/basics"
import { Heading, TextField } from "component/text"
import { spacings } from "component/constants";
import { useCollection, useDatastore, useObject } from "util/datastore";
import { useIsAdmin } from "component/admin"
import { CTAButton, DropDownSelector } from "component/button";
import { Catcher } from "system/catcher";
import { FormField, Toggle } from "component/form";


export const TopicRelatedArticlesCarouselFeature = {
    key: 'topicrelatedarticlescarousel',
    name: 'Related Articles Carousel',
    subscreens: {
        relatedArticlesAdmin: () => <RelatedArticlesAdmin />,
    },
    config: {
        topicBottomWidgets: [RelatedArticlesCarousel]
    }
}


function RelatedArticlesCarousel() {
    const isAdmin = useIsAdmin();
    const datastore = useDatastore();
    const relatedArticles = useCollection('relatedArticles', { filter: { published: true } });
    return <PadBox vert={spacings.xl2} horiz={spacings.lg}>
        {isAdmin && (
            <>
                <CTAButton type='primary' label='Manage related articles' onPress={datastore.needsLogin(() => datastore.pushSubscreen('relatedArticlesAdmin', {}))} />
                <Pad size={spacings.xl} />
            </>
        )}
        <Heading type="large" weight="medium" label='Need more information to make up your mind?' />
        <Pad size={spacings.xl} />
        <RecirculationSlider cardsData={relatedArticles} cardType='content' controls />
    </PadBox>
}

function Article({ article }) {
    const datastore = useDatastore();
    const selectedArticle = useObject('relatedArticles', article.key);
    const isChosen = selectedArticle && selectedArticle.published;

    async function onChange(selected) {
        await datastore.callServerAsync('topics', selected ? 'publishArticleMetaData' : 'unpublishArticleMetaData', { article: {
            id: article.id,
            key: article.key,
            text: article.text,
            picture: article.picture,
            type: article.type,
            url: article.url,
            deleted: article.deleted,
        } });
    }

    async function onDelete() {
        await datastore.callServerAsync('topics', 'deleteArticleMetaData', { articleKey: article.key });
    }

    return <PadBox bottom={20} horiz={20}>
        <Card>
            <HorizBox spread>
                {/* <UtilityText text={article.text} />
                <Pad /> */}
                <Toggle text={article.text}value={isChosen} onChange={onChange} />
                <Pad />
                <CTAButton label='Delete' onPress={onDelete} />
            </HorizBox>
        </Card>
    </PadBox>
}

export function RelatedArticlesAdmin() {
    const datastore = useDatastore();
    const relatedArticles = useCollection('relatedArticles', { deleted: false});
    const [articleUrl, setArticleUrl] = useState('');
    const [type, setType] = useState('text');
    const [isLoading, setIsLoading] = useState(false);

    async function onAdd() {
        setIsLoading(true);
        await datastore.callServerAsync('topics', 'addArticleMetaData', {url: articleUrl, type });
        setArticleUrl('');
        setIsLoading(false);
    }

    return (
        <ConversationScreen>
            <Pad />
            <PadBox horiz={20}><Heading label='Manage related articles' />
            <Pad />
            <FormField label='Add article'>
                <TextField testID="article-url-field" autoFocus value={articleUrl} onChange={setArticleUrl}
                    placeholder='Paste url here' />
            </FormField>
            <Pad />
            <DropDownSelector label='Article type'
                value={type} onChange={(value) => setType(value)}
                options={[
                    {key: 'text', label: 'Text'},
                    {key: 'media', label: 'Media'},
                    {key: 'audio', label: 'Audio'}
            ]} />
            <Pad />
            <CTAButton label='Add article' onPress={onAdd} disabled={isLoading} />
            </PadBox>
            <Pad />
            <Separator />
            <Pad />
            {relatedArticles?.map(article =>
                <Catcher key={article.key}>
                    <Article article={article} />
                </Catcher>
            )}
        </ConversationScreen>
    )
}