import { replaceAtPositon } from "../replaceatposition";

export function generateAlternativeSpellings({ term, characterReplacements }) {
    if (!term || !characterReplacements) {
        return [];
    }

    const spellingVariants = [term];
    const replaceableCharacters = Object.values(characterReplacements);
    const possibleReplacements = term
        .split("")
        .filter((char) => replaceableCharacters.includes(char))
        .slice(0, 4);
    const replacementCounter = {};
    possibleReplacements.forEach((v) => (replacementCounter[v] = 0));
    for (const replacement of possibleReplacements) {
        const valueToUse = Object.keys(characterReplacements).find((key) => characterReplacements[key] === replacement);
        spellingVariants.push(
            replaceAtPositon({
                text: term,
                replacement: valueToUse,
                position: replacementCounter[replacement],
                charToReplace: replacement,
            })
        );
        replacementCounter[replacement] += 1;
    }
    return spellingVariants;
}
