import { ConversationScreen, HorizBox, Pad } from "component/basics"
import { CTAButton, TextButton } from "component/button"
import { Heading } from "component/text"
import { useCollection, useDatastore, useInstanceKey, useStructureKey } from "util/datastore"
import { gotoInstanceScreen, makeUrl } from "util/navigate"
import { Toggle } from "../../../component/form"
import { View } from "react-native-web"
import { useAllVideoVotings } from "../../../component/zdf/videovoting/videovoting.util"
import { VideoVoting } from "../../../contrib/zdf/structure/videovoting"
import { VideoPlayerStateContextProvider } from "../../../contrib/zdf/videoPlayerStateContext"

// TODO: Make this a generic function for everyone
// There was no possibility to change the url of the teaser Iframe
export function gotoUrlTeaser({ structureKey, instanceKey, screenKey, targetTeaser = null }) {
    const url = makeUrl([structureKey, instanceKey, screenKey]);
    window.parent.postMessage({ type: 'psi-teaser-gotoUrl', url, targetTeaser }, '*');
}

export const VideoVotingFeature = {
    name: 'Video Voting',
    key: 'videovotingArticle',
    subscreens: {
        manageVideoVoting: VideoVotingAssignmentScreen,
    },
    config: {
        additionalAdminTeaserWidgets: [ManageVideoVotingButton],
        overlayScreen: VideoVotingOverlay,
    },
    defaultConfig: {
        showVotingWithinTextTeaser: false,

    }
}

function ManageVideoVotingButton() {
    const instanceKey = useInstanceKey();
    const structureKey = useStructureKey();
    const datastore = useDatastore();

    return <CTAButton type="secondary" label={"Manage Voting (Admin)"}
        onPress={() => datastore.gotoInstanceScreen({ structureKey, instanceKey, screenKey: 'manageVideoVoting', params: {} })}
    />
}

function VideoVotingAssignmentScreen() {
    const votings = useAllVideoVotings();
    return <ConversationScreen pad>
        <Heading label={"Select a voting for this article"} type="large" />
        <Pad />
        <CTAButton label={"Create a new voting"} onPress={() => { gotoInstanceScreen({ structureKey: "admin", instanceKey: "one", screenKey: "createVoting" }) }} />
        <Pad />
        {Object.values(votings ?? {}).map((voting) => {
            return <VideoVotingItem key={voting.key} voting={voting} />
        })}
    </ConversationScreen>
}

function VideoVotingItem({ voting }) {
    const datastore = useDatastore();
    const assignedVotings = useCollection("videovoting");
    const moduleVoting = useAllVideoVotings()
    const assigned = assignedVotings.filter(v => !v.deleted).map(v => v.key).includes(voting.key);
    const assignmentEnabled = assignedVotings.filter(v => !v.deleted).length <= 0 || assigned

    const toggle = async () => {
        if (assigned) {
            const result = await datastore.callServerAsync('videovoting', 'unassign', { key: voting.key });
        } else {
            const result = await datastore.callServerAsync('videovoting', 'assign', { key: voting.key });
        }
    }

    return <HorizBox spread center>
        <TextButton key={voting.key} text={moduleVoting?.[voting.key]?.template?.name} onPress={() => { gotoInstanceScreen({ structureKey: "videovoting", instanceKey: voting.key, screenKey: "edit", params: { key: voting.key } }) }} />
        <View style={{ visibility: assignmentEnabled ? "visible" : "hidden" }}><Toggle value={assigned} spread onChange={toggle} /></View>
    </HorizBox>
}

function VideoVotingOverlay() {

    const assignedVideotVotings = useCollection("videovoting")
    const assignedVoting = assignedVideotVotings.find(v => !v.deleted)

    if(!assignedVoting) return null

    return <View>
        <VideoPlayerStateContextProvider useOriginalVideo={true}>
            <VideoVoting repositionIframe={true} votingKey={assignedVoting.key}></VideoVoting>
        </VideoPlayerStateContextProvider>
    </View>
}


// Determines if the video voting should be shown on top of the video or below it in the teaser Iframe
export const VideoVotingVideoOverlayFeature = {
    name: 'Show voting UI below video',
    key: 'videovotingArticleTextIntegration',
    parentFeature: 'videovotingArticle',
    config: {},
    defaultConfig: {
        showVotingWithinTextTeaser: true
    }
}