import { Narrow } from "component/basics"
import { DemoHeader, DemoSection } from "system/demo"
import { useState } from "react";
import { Heading, UtilityText } from "component/text"
import { useTypewriter } from "../../component/zdf/typewriter";
import { TextButton } from "component/button"

export function TypewriterDemo() {
    const demoText = "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.";
    const demoText2 =" At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."

    const [text, setText] = useState(demoText)


    const typeWriter2 = useTypewriter({text: text, timePerCharacterInMs: 5, callback:()=>{}, play:false})
    const typeWriter1 = useTypewriter({text: text, timePerCharacterInMs: 5, callback:()=>{}, nextTypewriter: typeWriter2})

    return (
        <Narrow>
            <DemoHeader text={"Chained Typewriter"}/>
            <DemoSection>
                <TextButton text={"Change Text"} onPress={()=>{setText(demoText2)}}></TextButton>
                <Heading text={typeWriter1.displayText} />
                <UtilityText text={typeWriter2.displayText}/>
            </DemoSection>
        </Narrow>
    );
}