
export const MostRecentFeature = {
    name: 'Newest',
    key: 'most-recent',
    dev: true,
    config: {
        commentRankers: [{label: 'Newest', ranker: mostRecentRanker}]
    }
}

export function mostRecentRanker({datastore, comments}) {
    return comments.sort((a, b) => b.time - a.time);
}


export const OldestFeature = {
    name: 'Oldest',
    key: 'oldest',
    dev: true,
    config: {
        commentRankers: [{label: 'Oldest', ranker: oldestRanker}]
    }
}

function oldestRanker({datastore, comments}) {
    return comments.sort((a, b) => a.time - b.time);
}


export const MostUpvotedFeature = {
    name: 'Most Upvoted',
    key: 'most-upvoted',
    dev: true,
    config: {
        commentRankers: [{label: 'Most Upvoted', ranker: mostUpvotedRanker}]
    }
}   

function mapByKey(collection, func) {
    var map = {};
    collection.forEach(item => {
        map[item.key] = func(item);
    });
    return map;
}

function mostUpvotedRanker({datastore, comments}) {
    const upvotes = datastore.getCollection('upvote');
    const upvoteCounts = mapByKey(comments, comment => upvotes.filter(upvote => upvote.comment === comment.key).length);
    return comments.sort((a, b) => upvoteCounts[b.key] - upvoteCounts[a.key]);
}

export const MostReactedFeature = {
    name: 'Most Reactions',
    key: 'most-reactions',
    dev: true,
    config: {
        commentRankers: [{label: 'Most Reactions', ranker: mostReactionsRanker}]
    }
}   

function mostReactionsRanker({datastore, comments}) {
    const reactions = datastore.getCollection('reaction');
    const reactionCounts = mapByKey(comments, comment => reactions.filter(reaction => reaction.comment === comment.key).length);
    return comments.sort((a, b) => reactionCounts[b.key] - reactionCounts[a.key]);
}

