import { View } from "react-native-web";
import { ModDashboardUserProfileInformation, ModDashboardUserStatistics } from "../../../component/zdf/moddashboard/moddashboarduserprofile";
import { CLICK, DemoSection, POPUP } from "../../../system/demo";
import { Datastore } from "../../../util/datastore";
import { modDashboardDemoData } from "./moddashboard.data";

const userProfileDataNoEntries={
    commentCount: 0,
    rejectedCommentCount: 0,
    flagsGivenCount: 0, 
    flagsReceivedCount: 0, 
    suspension:{warnings: {
    }}  
}

const userProfileDataOneEntry={
    commentCount: 1,
    rejectedCommentCount: 1,
    flagsGivenCount: 1, 
    flagsReceivedCount: 1, 
    suspension:{warnings: {
        w : {},
    }}  
}

const userProfileDataMultipleEntries={
    commentCount: 2,
    rejectedCommentCount: 2,
    flagsGivenCount: 2, 
    flagsReceivedCount: 2, 
    suspension:{warnings: {
        w1 : {},
        w2 : {}
    }}  
}


export function ModDashboardUserProfileDemo() {
    return <View>
        <DemoSection text={"User profile information"}>
            <Datastore testState={{
                serverCall: modDashboardDemoData.serverCalls
            }}>
                <ModDashboardUserProfileInformation userId={modDashboardDemoData.demoAuthors.a.key} />
            </Datastore>
        </DemoSection>
        <DemoSection text={"User statistics with various counts"}>
            <ModDashboardUserStatistics userProfileData={userProfileDataNoEntries} />
            <ModDashboardUserStatistics userProfileData={userProfileDataOneEntry} />
            <ModDashboardUserStatistics userProfileData={userProfileDataMultipleEntries} />
        </DemoSection>
    </View>
}



export function ModDashboardUserProfileStorySets () {
    return [
        {
            label: "Blockable user",
            serverCall: modDashboardDemoData.serverCalls,
            modulePublic: {
                moderation: {
                    queue: modDashboardDemoData.modTaskQueues.thread1
                }
            },
            content: <ModDashboardUserProfileInformation userId={modDashboardDemoData.demoAuthors.c.key}/>,
            stories: [
                {
                    label: "Block",
                    actions: [
                        CLICK("Block user"),
                        POPUP(CLICK("Block user"))
                    ],
                }
            ],
        },
        {
            label: "Blocked user",
            serverCall: modDashboardDemoData.serverCalls,
            modulePublic: {
                moderation: {
                    queue: modDashboardDemoData.modTaskQueues.thread1
                }
            },
            content: <ModDashboardUserProfileInformation userId={modDashboardDemoData.demoAuthors.d.key}/>,
            stories: [
                {
                    label: "Unblock",
                    actions: [
                        CLICK("Unblock user"),
                        POPUP(CLICK("Unblock user"))
                    ],
                }
            ],
        }
    ]
}