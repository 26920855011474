import { View } from "react-native-web";
import { Narrow, Pad, ShadowBox } from "../../component/basics";
import { Tag } from "../../component/button";
import { DocumentLevelTooltip, ToolTip } from "../../component/zdf/tooltip";
import { DemoHeader, DemoSection } from "../../system/demo";
import { UtilityText } from "../../component/text";
import { Tools } from "@carbon/icons-react";
import { StyleSheet } from "react-native";
import { colorAmbiguousWhite } from "../../component/color";

export function TooltipDemo() {
    return (
        <Narrow>
            <DemoHeader text="Regular Tooltip" />
            <Pad />

            <DemoSection text={"Default tooltip"}>
                <ToolTip label={"Tooltip"}>
                    <Tag text={"Hover over me"} />
                </ToolTip>
            </DemoSection>
            <DemoSection text={"Custom offset value"}>
                <ToolTip label={"Tooltip"} offset={50}>
                    <Tag text={"Hover over me"} />
                </ToolTip>
            </DemoSection>
            <DemoSection text={"Tooltip with long text"}>
                <ToolTip label={"Tooltip with a long text across multiple lines"} width={175}>
                    <Tag text={"Hover over me"} />
                </ToolTip>
            </DemoSection>
            <DemoSection text={"Tooltip with custom tooltip widget"}>
                <ToolTip toolTipWidget={<CustomTooltipWidget/>}>
                    <Tag text={"Hover over me"} />
                </ToolTip>
            </DemoSection>

            <DemoHeader text="Document Level Tooltip" />
            <Pad />

            <DemoSection text={"Default tooltip"}>
                <DocumentLevelTooltip label={"Tooltip"}>
                    <Tag text={"Hover over me"} />
                </DocumentLevelTooltip>
            </DemoSection>
            <DemoSection text={"Custom offset value"}>
                <DocumentLevelTooltip label={"Tooltip"} offset={50}>
                    <Tag text={"Hover over me"} />
                </DocumentLevelTooltip>
            </DemoSection>
            <DemoSection text={"Custom width"}>
                <DocumentLevelTooltip label={"Tooltip with a long text across multiple lines"} width={175}>
                    <Tag text={"Hover over me"} />
                </DocumentLevelTooltip>
            </DemoSection>
            <DemoSection text={"Tooltip with custom tooltip widget"}>
                <DocumentLevelTooltip tooltipWidget={<CustomDocumentLevelTooltipWidget/>}>
                    <Tag text={"Hover over me"} />
                </DocumentLevelTooltip>
            </DemoSection>
        </Narrow>
    );
}

const CustomTooltipWidgetStyle = StyleSheet.create({
    container: {
        width: 220,
        gap: 8,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    textContent: {
        display: "flex",
        flexDirection: "column",
    },
});

function CustomTooltipWidget() {
    const s = CustomTooltipWidgetStyle;

    return (
        <View style={s.container}>
            <Tools />
            <View style={s.textContent}>
                <UtilityText weight="strong" text="I'm a custom tooltip widget!" />
                <UtilityText text="Fill me with custom content." />
            </View>
        </View>
    );
}

const CustomDocumentLevelTooltipWidgetStyle = StyleSheet.create({
    container: {
        width: 230,
        gap: 8,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: 12,
        backgroundColor: colorAmbiguousWhite,
        borderRadius: 8,
    },
    textContent: {
        display: "flex",
        flexDirection: "column",
    },
});

function CustomDocumentLevelTooltipWidget() {
    const s = CustomDocumentLevelTooltipWidgetStyle;

    return (
        <ShadowBox>
            <View style={s.container}>
                <Tools />
                <View style={s.textContent}>
                    <UtilityText weight="strong" text="I'm a custom tooltip widget!" />
                    <UtilityText text="Fill me with custom content." />
                </View>
            </View>
        </ShadowBox>
    );
}