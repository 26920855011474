import { ui_machine_translations_french_canadian } from './machinetranslations/ui_machine_french_canadian';
import { ui_machine_translations_french_belgian } from './machinetranslations/ui_machine_french_belgian';
import { ui_machine_translations_german } from './machinetranslations/ui_machine_german';
import { ui_machine_translations_french } from './machinetranslations/ui_machine_french';
import { ui_human_translations_french_canadian } from './humantranslations/ui_human_french_canadian';
import { ui_human_translations_french_belgian } from './humantranslations/ui_human_french_belgian';
import { ui_human_translations_german } from './humantranslations/ui_human_german';
import { ui_human_translations_french } from './humantranslations/ui_human_french';
import { ui_deviant_translations_german } from './devianttranslations/ui_deviant_german';

export var allTranslations = {
    french_canadian: {
        ...ui_machine_translations_french_canadian,
        ...ui_human_translations_french_canadian,
    },
    french_belgian: {
        ...ui_machine_translations_french_belgian,
        ...ui_human_translations_french_belgian,
    },
    german: {
        ...ui_machine_translations_german,
        ...ui_human_translations_german,
        ...ui_deviant_translations_german
    },
    french: {
        ...ui_machine_translations_french,
        ...ui_human_translations_french,
    }
}

const deviantTranslations = {
    german: {
        ...ui_deviant_translations_german,
    }
}

export function addTestTranslations(translations) {
    Object.keys(translations).forEach(language => {
        allTranslations[language] = {...allTranslations[language], ...translations[language]};
    });
}

export function checkForDeviantTranslations(language) {
    if (deviantTranslations) {
        var hasDeviantTranslations = false;
        Object.keys(deviantTranslations).forEach(deviantLanguage => {
            if (deviantLanguage === language) {
                console.error('Deviant translations found in', deviantLanguage, deviantTranslations[deviantLanguage]);
                console.error('Deviant translations lead to product analysis confusion and should be fixed as soon as is practical.')
            }
            hasDeviantTranslations = true;            
        });
        if (hasDeviantTranslations) {
            console.log('Found deviant translations', deviantTranslations);
        }
    }
}
