import { HorizBox, Pad, PadBox } from "component/basics"
import { colorSurfaceSecondary, colorTextSecondary } from "component/color"
import { LinkText, UtilityText } from "component/text"
import { Image, StyleSheet, View } from "react-native";

export function VideoLink({ videoTitle, videoUrl, thumbnailUrl }) {
    const s = VideoLinkStyle;
    return <View style={s.linkContainer}>
        <PadBox horiz={10} vert={10}>
            <HorizBox center>
                <Image style={s.previewImage} source={{ uri: thumbnailUrl }} />
                <Pad size={10}/>
                <UtilityText text={videoTitle} color={colorTextSecondary} />
                <Pad size={10}/>
                <LinkText label="Go to video" url={videoUrl} />
            </HorizBox>
        </PadBox>
    </View>
}

const VideoLinkStyle = StyleSheet.create({
    linkContainer: {
        backgroundColor: colorSurfaceSecondary,
        borderRadius: "8px"
    },
    previewImage: {
        width: "50px",
        height: "50px",
        borderRadius: "8px"
    }
});