import { CLICK } from "system/demo"
import { DemoVideoVotingContainer } from "./videovotingdemo.util";
import { videoVotingDemoTemplates } from "./videovoting.data";

const modulePublicIndividual = {
    videovoting: { all: { i: { template: videoVotingDemoTemplates.templateIndividual } } }
}

const modulePublicConnected = {
    videovoting: { all: { c: { template: videoVotingDemoTemplates.templateConnected } } }
}

const videoStateNotLoaded = {
    isPlaying: false,
    currentTime: 0,
    duration: 900,
    ended:false,
    ready: false
}

const videoStateLoaded = {
    ...videoStateNotLoaded,
    ready: true
}

export function VideoVotingIntroScreenStorySets() {return [
    {
        label: 'Start video voting session: First question shows up immediately',
        instanceKey: 'i', personaKey: 'a',
        modulePublic: modulePublicIndividual,
        content: <DemoVideoVotingContainer videoState={videoStateLoaded} showStats />,
        serverCall: {eventlog: {
            logEvent: () => {}
        }},
        stories: [
            {label: 'Start the video', actions: [
                CLICK('Start Video')
            ]},
            {label: 'Start anonymously', actions: [
                CLICK('Vote anonymously'), CLICK('Start Video'),              
            ]},
            {label: 'Disable video voting and start', actions: [
                CLICK('Enable voting'), CLICK('Start Video'),              
            ]},
            {label: 'Open settings', actions: [
                CLICK('videoVotingSettings')
            ]}
        ]
    },
    {
        label: 'Start video voting session: No initial question',
        instanceKey: 'c', personaKey: 'a',
        modulePublic: modulePublicConnected,
        content: <DemoVideoVotingContainer videoState={videoStateLoaded} showStats />,
        serverCall: {eventlog: {
            logEvent: () => {}
        }},
        stories: [
            {label: 'Start the video', actions: [
                CLICK('Start Video')
            ]}
        ]
    },
    {
        label: 'User is not logged in',
        instanceKey: 'c',
        modulePublic: modulePublicConnected,
        personaKey: null,
        firebaseUser: null,
        content: <DemoVideoVotingContainer videoState={videoStateLoaded} showStats />,
        serverCall: {eventlog: {
            logEvent: () => {}
        }},
        stories: [
            {label: 'Start the video without logging in', actions: [
                CLICK('Start Video')
            ]},
            {label: 'Click on Login', actions: [
                CLICK('videoVotingLogin')
            ]}
        ]
    },
    {
        label: 'Load video to initialize video voting session',
        instanceKey: 'i', personaKey: 'a',
        modulePublic: modulePublicIndividual,
        content: <DemoVideoVotingContainer videoState={videoStateNotLoaded} showStats />,
        serverCall: {eventlog: {
            logEvent: () => {}
        }},
        // stories: [
        //     {label: 'Load the video', actions: [
        //         CLICK('Load video element')
        //     ]}
        // ]
    }
]}