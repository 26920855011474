import { useModulePublicData } from "util/datastore";

// REQUEST: Merge this into component/date.js
export function formatToDateRangeString({ start = new Date(Date.UTC(2024, 5, 4, 4, 0, 0)), end = new Date(Date.UTC(2024, 5, 20, 4, 0, 0)), locale = 'fr-CA' }) {
    const options = {
    month: 'long',
    };
    const startDay = start.getDate();
    const endDay = end.getDate();
    const endYear = end.getFullYear();
    const isSameMonth = start.getMonth() === end.getMonth();

    switch (locale) {
        case 'de-DE':
            // Format to either '10. - 15. Mai 2024' if the range is in the same month, or '10. Mai - 15. Juni 2024' if the range is over multiple months
            return `${_formatDay({day: startDay, locale})}.${!isSameMonth ? ` ${start.toLocaleDateString(locale, options)}`: ''} - ${_formatDay({day: endDay, locale})}. ${end.toLocaleDateString(locale, options)} ${endYear}`;
        case 'fr-CA':
            // Format to either '10 - 15 mai' if the range is in the same month, or '10 mai - 15 juin' if the range is over multiple months
            return `${_formatDay({day: startDay, locale})}${!isSameMonth ? ` ${start.toLocaleDateString(locale, options)}`: ''} - ${_formatDay({day: endDay, locale})} ${end.toLocaleDateString(locale, options)} ${endYear}`;
        default:
            // Format to either 'May 10 - 15' if the range is in the same month, or 'May 10 - June 15' if the range is over multiple months
            return `${start.toLocaleDateString(locale, options)} ${_formatDay({day: startDay, locale})} - ${!isSameMonth ? `${end.toLocaleDateString(locale, options)} `: ''}${_formatDay({day: endDay, locale})}, ${endYear}`;
    }
}

function _formatDay({day, locale}) {
    if (locale === 'fr-CA' && day === 1) {
        return `${day}er`
    }
    return day
}

export function useClientLocale() {
    const siloLanguage = useModulePublicData("language");
    const language = siloLanguage || "english";
    let clientLocale = "";
    switch (language) {
        case "english":
            clientLocale = "en-US";
            break;
        case "german":
            clientLocale = "de-DE";
            break;
        case "french":
            clientLocale = "fr-CA";
            break;
        default:
            clientLocale = "en-US";
            break;
    }
    return clientLocale;
}