import { ConversationScreen, FlowBox, Pad } from "component/basics"
import { CTAButton } from "component/button"
import { FormField } from "component/form"
import { Heading, TextField } from "component/text"
import { useDatastore, useSiloKey } from "util/datastore"
import { useState } from "react";

export const PuppetSupportFeature = {
    name: 'Puppet Test Support',
    key: 'puppettest',
    subscreens: {
        puppetTools: PuppetToolsScreen,
    }
}

export function PuppetToolsScreen() {
    const [puppetSecret, setPuppetSecret] = useState('');
    const [badSecret, setBadSecret] = useState(false);
    const siloKey = useSiloKey();
    const datastore = useDatastore();

    if (siloKey !== 'puppet-test') {
        return null;
    }

    async function onLogin(testUserKey) {
        const {loginToken} = await datastore.callServerAsync('puppet', 'login', {
            puppetSecret, testUserKey
        });
        if (!loginToken) {
            setBadSecret(true);
            return;
        }
        setBadSecret(false);
        await datastore.signInWithTokenAsync(loginToken);
    }

    async function onReset() {
        await datastore.callServerAsync('puppet', 'reset', {puppetKey: puppetSecret});
    }

    return <ConversationScreen pad>
        <Pad />
        <Heading type='large' weight='strong' text='Puppet Test Tools' />
        <Pad />
        {/* {badSecret && <BannerMessage error text='Bad secret' />} */}
        <FormField text='Puppet Secret' errorText={badSecret ? 'Bad secret' : null}>
            <TextField error={badSecret} testID='puppet-secret' placeholder='!Enter puppet secret' value={puppetSecret} onChange={setPuppetSecret} />
        </FormField>
        <Pad />
        <FlowBox rowGap={10} columnGap={10}>
            <CTAButton text='Login as User A' onPress={() => onLogin('a')} />
            <CTAButton text='Login as User B' onPress={() => onLogin('b')} />
            <CTAButton text='Login as User C' onPress={() => onLogin('c')} />
        </FlowBox>

        <Pad />
        <CTAButton text='Reset test silo' onPress={() => onReset()} />
    </ConversationScreen>
}
