import { RelatedArticlesAdmin } from "feature/cbcrc/topic/RelatedArticlesCarouselFeature";
import { RelatedConversationsAdmin } from "feature/cbcrc/topic/RelatedConversationsFeature"
import { CLICK, INPUT } from "system/demo";

export const relatedConversationsDemo = [
    { label: 'Related Conversations', key: 'related-conversations', storySets: relatedConversationsStorySets },
    { label: 'Related Articles', key: 'related-articles', storySets: relatedArticlesStorySets },
]

const relatedConversations_derived = [
    { key: 'le-temps-dcran' },
    { key: 'le-vieillissement-de-la-population' },
    { key: 'le-vieillissement-de-la-population-2' },
]

const relatedConversations = [
    {
        deleted: false,
        from: "a",
        id: "a",
        key: "le-temps-dcran",
        text: "Le temps d'écran",
        time: 1733775285001
    },
    {
        deleted: false,
        from: "b",
        id: "b",
        key: "le-vieillissement-de-la-population",
        text: "Le vieillissement de la population",
        time: 1733775279409
    },
    {
        deleted: false,
        from: "b",
        id: "c",
        key: "le-vieillissement-de-la-population-2",
        text: "Le vieillissement de la population, suivi",
        time: 1733775279409
    }
]

const oldTime = new Date('2024-1-1').valueOf();
const recentTime = new Date("2026-1-1").valueOf();

function relatedConversationsStorySets() {
    return [
        {
            label: 'Related conversations admin screen',
            collections: {
                relatedConversations_derived,
                relatedConversations
            },
            modulePublic: {
                question: {
                    a: { question: 'What is the capital of France?', key: 'a', time: recentTime },
                    b: { question: 'What is the capital of Germany?', key: 'b', time: recentTime },
                    c: { question: 'What is the capital of Italy?', key: 'c', time: recentTime },
                    d: { question: 'This is an old question you cannot see?', key: 'd', time: oldTime },
                }
            },
            serverCall: {
                topics: {
                    selectRelatedQuestion: () => null,
                    unselectRelatedQuestion: () => null,
                }
            },
            stories: [
                {
                    label: 'Select a related conversation',
                    actions: [
                        CLICK('What is the capital of France?')
                    ]
                },
            ],
            content: <RelatedConversationsAdmin />,
        }
    ]
}


const relatedArticles = [
    {
        deleted: false,
        id: "httpswwwrtbfbe",
        key: "a",
        picture: "https://ds.static.rtbf.be/image/dossier/16x9/1920x1080/d/e/f/default-rtbf_0.jpg",
        published: false,
        text: "La référence de l'actualité belge et internationale. Ce titre est trop long.",
        type: "text",
        url: "https://www.rtbf.be/",
    },
    {
        deleted: false,
        id: "httpsiciradio-canadaca",
        key: "b",
        picture: "https://images.radio-canada.ca/v1/unit/perso/partage-radio-canadaca.jpg",
        published: true,
        text: "Information, radio, télé, sports, arts",
        type: "text",
        url: "https://ici.radio-canada.ca",

    },
    {
        deleted: false,
        id: "httpswwwzdfde",
        key: "c",
        picture: "https://www.zdf.de/assets/mediathek-devices-100~1280x720?cb=1510668819336",
        published: true,
        text: "ZDFmediathek",
        type: "text",
        url: "https://www.zdf.de/",
    }
]

function relatedArticlesStorySets() {
    return [
        {
            label: 'Related articles admin screen',
            collections: { relatedArticles },
            stories: [
                {
                    label: 'Add a text related article',
                    actions: [
                        INPUT('article-url-field', 'https://newpublic.org'),
                        CLICK('Add article')
                    ]
                },
                {
                    label: 'Select a related article',
                    actions: [
                        CLICK('La référence de l\'actualité belge et internationale. Ce titre est trop long.'),

                    ]
                },
                {
                    label: 'Unselect a related article',
                    actions: [
                        CLICK('Information, radio, télé, sports, arts')
                    ]
                }
            ],
            serverCall: {
                topics: {
                    addArticleMetaData: ({ datastore, url, type }) => null,
                    publishArticleMetaData: ({ datastore, article }) => null,
                    unpublishArticleMetaData: ({ datastore, article }) => null,
                    deleteArticleMetaData: ({ datastore, articleKey }) => null,
                }
            },
            content: <RelatedArticlesAdmin />,
        }
    ];
}