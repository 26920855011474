import { makeAssetUrl } from 'util/util'

export const imagesZdf = {
    conversationhelperProfileCallcenter: makeAssetUrl('zdf/conversationhelper_profile.svg'),
    conversationhelperProfileFacilitator: makeAssetUrl('zdf/conversationhelper_profile_facilitator.png'),
    conversationhelperProfileBulb: makeAssetUrl('zdf/conversationhelper_profile_bulb.png'),
    conversationHelperRobo: makeAssetUrl('faces/robo.jpeg'),
    moddashboardZDFHeute: makeAssetUrl("zdf/zdf_heute.png")
}

