import { useCollection, useDatastore, useInstanceKey, useObject, usePersonaKey } from "../../../../util/datastore";
import { keyToUrl } from "../../../../util/util";
import { useIsAdmin } from "../../../../component/admin";
import { TeaserScreen } from "../../../../system/teaser";
import { Pad, PadBox } from "../../../../component/basics";
import { CommentPreview, getTeaserPalette, NarrowQuestionTeaserWithComment, QuestionInfo, WideQuestionTeaserStyle } from "../../../../feature/teaser/EditorialQuestionFeature";
import { AdminTeaserAction } from "../../../../feature/teaser/TopicPhotoTeaser";
import { useConfig } from "../../../../util/features";
import { Pressable, Text, TouchableOpacity, View } from "react-native";
import { Heading } from "../../../../component/text";
import { useState } from "react";

export const teaserPalette = getTeaserPalette();

export function DialogueAdaptiveLayoutArticleQuestionTeaserWithComment({question}) {
    const instanceKey = useInstanceKey();
    const entryUrl = keyToUrl(instanceKey);

    const questionText = question.text;
    const questionKey = question.key;
    const questionPreview = useObject('derived_questionPreview', questionKey);
    const commentPreview = questionPreview?.commentPreview;
    const commentCount = questionPreview?.commentCount;
    const isAdmin = useIsAdmin();

    return <TeaserScreen>
        <DialogueAdaptiveLayoutQuestionTeaser questionText={questionText} questionKey={questionKey} entryUrl={entryUrl} commentPreview={commentPreview} commentCount={commentCount} />
        {isAdmin && <PadBox top={20}><AdminTeaserAction /></PadBox>}
    </TeaserScreen>
}

export const DialogueGreenQuestionTeaserFeature = {
    name: 'Question Dialogue (Green)',
    key: 'dialoguegreenquestionteaser',
    config: {
        teaserSingleQuestion: DialogueAdaptiveLayoutArticleQuestionTeaserWithComment,
        teaserColorPalette: teaserPalette.green
    },
};

export const DialogueTanQuestionTeaserFeature = {
    name: 'Question Dialogue (Tan)',
    key: 'dialoguetanquestionteaser',
    config: {
        teaserSingleQuestion: DialogueAdaptiveLayoutArticleQuestionTeaserWithComment,
        teaserColorPalette: teaserPalette.tan
    },
};

export const DialogueEmbeddedQuestionTeaserFeature = {
    name: 'Dialogue Embedded Teaser',
    key: 'dialogueembeddedquestionteaser',
    dev: true,
    config: {
        teaserSingleQuestion: DialogueAdaptiveLayoutQuestionTeaser,
        teaserColorPalette: teaserPalette.green
    },
};


export function DialogueQuestionInfo({questionText, commentCount, narrow=false, questionLabel="Let's talk about..."}) {
    const {teaserColorPalette: { questionTextColor: textColor }} = useConfig();
    return <View>
        <Pad size={narrow ? 20 :24}/>
        <Heading text={questionText} type="large" weight="medium" color={textColor}></Heading>
        <Pad size={8}/>
    </View>
}

export function DialogueWideQuestionTeaserWithComment({ questionText, commentPreview, commentCount }) {
    const personaKey = usePersonaKey();
    const myVote = useCollection('vote', { filter: { from: personaKey } })?.[0];
    const datastore = useDatastore();

    const selectedOption = myVote?.value?.label;
    function setSelectedOption(option) {
        const existing = datastore.getCollection('vote', { filter: { from: personaKey } });
        existing.forEach(r => datastore.deleteObject('vote', r.key));
        datastore.addObject('vote', { from: personaKey, value: { label: option } });
    }

    const s = WideQuestionTeaserStyle
    const {
        teaserColorPalette: {
            questionBackgroundColor,
            commentBackgroundColor,
            wideTeaserElements: { buttonPalette },
        },
    } = useConfig()

    return (
        <View style={s.outer}>
            <View style={[{ backgroundColor: questionBackgroundColor }, s.left]}>
                <QuestionInfo questionText={questionText} commentCount={5} />
                <Pad size={20} />
                <View style={{ gap: 8 }}>
                    <TouchableOpacity
                        onPress={datastore.needsLogin(() => setSelectedOption("Yes"))}
                        style={{
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                            paddingHorizontal: 16,
                            paddingVertical: 12,
                            backgroundColor: "white",
                            borderRadius: 50,
                            borderWidth: 1,
                            borderColor: "#e5e7eb",
                            shadowColor: "#000",
                            shadowOffset: { width: 0, height: 1 },
                            shadowOpacity: 0.05,
                            shadowRadius: 1,
                            elevation: 1,
                        }}
                    >
                        <View style={{ flexDirection: "row", alignItems: "center", gap: 12 }}>
                            <View style={{ width: 16, height: 16, borderRadius: 8, backgroundColor: "#14b8a6" }} />
                            <Text style={{ color: "#374151" }}>Yes</Text>
                        </View>
                        <View
                            style={{
                                width: 24,
                                height: 24,
                                borderRadius: 12,
                                borderWidth: 1,
                                backgroundColor: selectedOption === "Yes" ? "#14b8a6" : "white",
                                borderColor: selectedOption === "Yes" ? "#14b8a6" : "#d1d5db",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {selectedOption === "Yes" && (
                                <View style={{ width: 8, height: 8, backgroundColor: "white", borderRadius: 4 }} />
                            )}
                        </View>
                    </TouchableOpacity>

                    <TouchableOpacity
                        onPress={datastore.needsLogin(() => setSelectedOption("No"))}
                        style={{
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                            paddingHorizontal: 16,
                            paddingVertical: 12,
                            backgroundColor: "white",
                            borderRadius: 50,
                            borderWidth: 1,
                            borderColor: "#e5e7eb",
                            shadowColor: "#000",
                            shadowOffset: { width: 0, height: 1 },
                            shadowOpacity: 0.05,
                            shadowRadius: 1,
                            elevation: 1,
                        }}
                    >
                        <View style={{ flexDirection: "row", alignItems: "center", gap: 12 }}>
                            <View style={{ width: 16, height: 16, borderRadius: 8, backgroundColor: "#ef4444" }} />
                            <Text style={{ color: "#374151" }}>No</Text>
                        </View>
                        <View
                            style={{
                                width: 24,
                                height: 24,
                                borderRadius: 12,
                                borderWidth: 1,
                                backgroundColor: selectedOption === "No" ? "#ef4444" : "white",
                                borderColor: selectedOption === "No" ? "#ef4444" : "#d1d5db",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {selectedOption === "No" && (
                                <View style={{ width: 8, height: 8, backgroundColor: "white", borderRadius: 4 }} />
                            )}
                        </View>
                    </TouchableOpacity>
                </View>
            </View>
            <View style={[{ backgroundColor: commentBackgroundColor }, s.right]}>
                <CommentPreview commentPreview={commentPreview} />
            </View>
        </View>
    )
}

export function DialogueAdaptiveLayoutQuestionTeaser({questionText, questionKey, entryUrl,commentPreview, commentCount}) {
    const [narrow, setNarrow] = useState(false);
    const datastore = useDatastore();
    function onLayout(event) {
        const {width} = event.nativeEvent.layout;
        setNarrow(width < 540);
    }

    function onPress() {
        const globalParams = {entryUrl};
        datastore.gotoInstance({structureKey: 'question', instanceKey: questionKey, globalParams});
    }

    return <Pressable testID='question-teaser' onLayout={onLayout} onPress={onPress}>
        {narrow ?
            <NarrowQuestionTeaserWithComment questionText={questionText} commentPreview={commentPreview} commentCount={commentCount} />
            :
            <DialogueWideQuestionTeaserWithComment questionText={questionText} commentPreview={commentPreview} commentCount={commentCount} />
        }
    </Pressable>
}