import { useEffect } from "react";
import { useVideo } from "./videoPlayerStateContext";


/** This keeps the video paused, in case it somehow gets started again
TODO: Find a more elegant solution to prevent autoplays */
export function ForceVideoPauseComponent() {
    const { paused, currentTime, pause } = useVideo();
    useEffect(()=> {
        pause && pause();
    },[paused, currentTime])
}