export function replaceAtPositon({ text, charToReplace, position, replacement }) {
    const internalCounter = {}
    const textSplit = text.split("")
    textSplit.forEach(element => {
        internalCounter[element] = 0
    });
    const updatedText = textSplit.map((char) => {
        if (char === charToReplace && internalCounter[char] === position) {
            internalCounter[char] += 1
            return replacement
        } else {
            internalCounter[char] += 1
            return char
        }
    }).join("")
    return updatedText
}