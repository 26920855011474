import { useHasCapability } from "component/admin"
import { Pad, PadBox } from "component/basics";
import { CTAButton } from "component/button";
import { Modal } from "component/modal";
import { TextField, UtilityText } from "component/text";
import { useState } from "react";
import { useCollection, useDatastore, useSiloKey } from "util/datastore";

export const ImportCommentsFeature = {
    name: 'Import Comments',
    key: 'import-comments',
    dev: true,
    config: {
        pageTopWidgets: [ImportCommentsButton]
    }
}

export const ExportCommentsFeature = {
    name: 'Export Comments',
    key: 'export-comments',
    dev: true,
    config: {
        pageTopWidgets: [ExportCommentsButton]
    }
}

function ImportCommentsButton() {
    const isDev = useHasCapability('developer/add-comments');
    const [showModal, setShowModal] = useState(false);
    const siloKey = useSiloKey();

    if (!['puppet-test', 'test', 'demo', 'demo-de', 'demo-fr'].includes(siloKey)) {
        return <UtilityText label="!Import Comments is only available in the test silo" />
    }

    if (!isDev) {
        return null;
    }
    return <PadBox horiz={20} vert={20}>
        <CTAButton label="!Import Comments" onPress={() => setShowModal(true)} />
        {showModal && <ImportCommentsModal onClose={() => setShowModal(false)} />}
    </PadBox>
}

function unescapeCommentText(text) {
    return text.replace(/\\n/g, '\n').replace(/\\"/g, '\"').replace(/,$/, '');
}

function ImportCommentsModal({onClose}) {
    const [comments, setComments] = useState('');
    const datastore = useDatastore();

    async function onImport() {
        const commentTexts = comments.split('\n');
        for (const commentText of commentTexts) {
            const comment = {
                text: unescapeCommentText(commentText),
            }
            await datastore.addObject('comment', comment);
        }
        onClose();
    }

    return <Modal onClose={onClose}>
        <PadBox horiz={20} vert={20}>
            <TextField placeholder='!Enter comments pasted from a spreadsheet' value={comments} onChangeText={setComments} />
            <Pad size={10} />
            <CTAButton label="!Import Comments" onPress={onImport} />
        </PadBox>
    </Modal>
}


function ExportCommentsButton() {
    const isDev = useHasCapability('developer/add-comments');
    const [showModal, setShowModal] = useState(false);

    if (!isDev) {
        return null;
    }
    return <PadBox horiz={20} vert={20}>
        <CTAButton label="!Export Comments" onPress={() => setShowModal(true)} />
        {showModal && <ExportCommentsModal onClose={() => setShowModal(false)} />}
    </PadBox>
}

function ExportCommentsModal({onClose}) {
    const comments = useCollection('comment');
    const topLevelComments = comments.filter(comment => !comment.replyTo && comment.text);
    const commentText = topLevelComments.map(comment => comment.text.replace(/\n/g, '\\n')).join('\n');

    return <Modal onClose={onClose}>
        <PadBox horiz={20} vert={20}>
            <TextField value={commentText} />
        </PadBox>
    </Modal>
}
