import { logEventAsync } from "util/eventlog";
import { SubtleButton } from "../../component/button";
import { colorAmbigousBlack, colorTextSecondary } from "../../component/color";
import { IconUpvote, IconUpvoted } from "../../component/icon";
import { useCollection, useDatastore, useObject, usePersonaKey } from "../../util/datastore";
import { useIsReadOnly } from "../../util/features";

export const UpvoteFeature = {
    name: 'Upvote Comments',
    key: 'upvote',
    dev: true,
    config: {
        commentActions: [ActionUpvote]
    }
}


export function ActionUpvote({commentKey}) {
    const personaKey = usePersonaKey();
    const comment = useObject('comment', commentKey);
    const upvotes = useCollection('upvote', {filter: {comment: commentKey}});
    const datastore = useDatastore();
    const readOnly = useIsReadOnly();
    const count = upvotes.length;
    const upvoted = upvotes.some(upvote => upvote.from == personaKey);

    async function onUpvote() {
        if (upvoted) {
            logEventAsync(datastore, 'upvote-undo', {commentKey})
            const myUpvote = upvotes.find(upvote => upvote.from == personaKey);
            await datastore.deleteObject('upvote', myUpvote.key);
        } else {
            await datastore.addObject('upvote', {comment: commentKey, from: personaKey});
            logEventAsync(datastore, 'upvote', {commentKey})
        }
    }
    
    const disabled = comment?.from == personaKey || readOnly;

    return <SubtleButton icon={upvoted ? IconUpvoted : IconUpvote} bold={upvoted}
        ariaLabel='Upvote' testID='upvote'
        color={upvoted ? colorAmbigousBlack : colorTextSecondary}
        disabled={disabled}
        text={count.toString()}
        padRight
        onPress={datastore.needsLogin(onUpvote, 'upvote')} />
}


