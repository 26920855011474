import { useEffect } from 'react';
import { closeSidebar, getScreenStackForUrl, gotoInstance, useLiveUrl } from 'util/navigate';
import { getStructureForKey, ScreenStack, useStandardFonts } from 'util/instance';
import { addDefaultFeatures, addFeatures } from 'feature/index';
import { newDefaultFeatures, newFeatures, newRoles } from './feature';
import { setFirebaseConfig } from 'util/firebase';
import { extendRoles } from 'component/admin';
import { Text } from 'react-native';
import { registerLoginProviders } from 'structure/login';
import { cbcIntLogin, cbcLogin, rcIntLogin, rcLogin, rtbfLogin, zdfIntLogin, zdfProdLogin } from 'util/loginproviders';
import useSSOLoginFromParent from './util/useSSOLoginFromParent';
import { FocusLoopView } from 'system/focustrap';
import { KeyboardShortcutHandler } from 'platform-specific/keyboardShortcutHandler';
import { useSetDocumentLang } from 'platform-specific/useSetDocumentLang';
import { getDeploymentConfig } from 'util/util';
import Constants from 'expo-constants';

const deploymentConfig = getDeploymentConfig();
console.log('Using server host', deploymentConfig.host);

console.log('App Version', Constants.expoConfig?.version);


setFirebaseConfig(deploymentConfig);
addFeatures(newFeatures);
addDefaultFeatures(newDefaultFeatures);
addDefaultFeatures(deploymentConfig?.defaultFeatures ?? {});
extendRoles(newRoles);
registerLoginProviders([rcIntLogin, rcLogin, cbcLogin, cbcIntLogin, zdfIntLogin, zdfProdLogin, rtbfLogin]);

export default function App() {
    useStandardFonts();
    const url = useLiveUrl();
    const {siloKey, structureKey, instanceKey, screenStack} = getScreenStackForUrl(url);
    const structure = getStructureForKey(structureKey);

    console.log('App', { url, siloKey, structureKey, instanceKey, screenStack, structure });
    useSetDocumentLang(siloKey);
    useSSOLoginFromParent(siloKey);
    useEffect(() => {
        if (deploymentConfig.defaultToAdminDash && !structureKey) {
            gotoInstance({structureKey: 'admin', instanceKey: 'one'});
        }
    }, [url]);

    if (!structureKey) {
        return <Error message='You need a valid space URL to see a space' />
    } else if (!structure) {
        return <Error message={'Unknown structure: ' + structureKey} />
    } else if (!instanceKey) {
        return <Error message='Missing instance key' />
    } else {
        if (structure.hasFocusTrap) {
            return <FocusLoopView onCloseLoop={closeSidebar} index={0}>
                <ScreenStack url={url} screenStack={screenStack} siloKey={siloKey} structureKey={structureKey} instanceKey={instanceKey} />
                <KeyboardShortcutHandler />
            </FocusLoopView>;
        }
        else return <ScreenStack url={url} screenStack={screenStack} siloKey={siloKey} structureKey={structureKey} instanceKey={instanceKey} />;

    }
}

function Error({message}) {
    return <Text>{message}</Text>
}
