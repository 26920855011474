import { View } from "react-native"
import { VideoLink } from "../../../contrib/zdf/link"
import { Center, HorizBox, Pad, PadBox, Separator } from "component/basics"
import { DataVizText, Heading, Paragraph, UtilityText } from "component/text"
import { useCollection, useDatastore, useModulePublicData, usePersonaKey } from "util/datastore"
import React from "react"
import { getPrettyTimestamp } from "../../../system/videotime"
import { colorAmbigousBlack, colorAmbiguousAccent, colorGrey5, colorTextSecondary } from "component/color"
import { ProfilePhoto } from "component/people"
import { ColorLegend, CountScale } from "../../../contrib/zdf/videovoting/videovotingBaseElements"
import { PercentageBarContainer, PercentageSegment } from "../../../contrib/zdf/videovoting/videovotingPercentageBars"
import { QuestionResultIndividual } from "../../../contrib/zdf/videovoting/VideoVotingOverviews"
import { REPLACE_ZDF_AccordionField } from "../../../component/zdf/form"
import { useAllVideoVotings } from "../../../component/zdf/videovoting/videovoting.util"
import { keyToUrl } from "../../../util/util"

/*
Figma: https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=20-5&t=5DeU2AZDvMXzjf7G-0
*/

export const VideoVotingOverviewFeature = {
    name: 'Related Polls',
    key: 'votingoverview',
    dev: true,
    config: {
        tabs: [{
            key: "tab-voting",
            label: "Votings",
            component: VideoVotingOverview
        }]
    }
}

export function VideoVotingOverview() {

    const datastore = useDatastore();
    const videoVotings = useAllVideoVotings()
    const articlesWithVotings = useModulePublicData('videovoting', ['instanceToVoting', 'article'])
    const backlink_articles = useCollection("backlink_article");

    const filteredArticles = backlink_articles.filter(article=>(articlesWithVotings?.[article.key]!=null && videoVotings[articlesWithVotings?.[article.key]] != null));

    if (videoVotings == null || filteredArticles.length < 1) {
        return <View>
            <UtilityText label="No voting data available." />
        </View>
    }
    else {
        return <View>
            {filteredArticles.map((article, idx) =><VotingResults articleKey={article.key} key={idx} votingDataEntry={videoVotings[articlesWithVotings?.[article.key]]?.template} votingInstanceKey={article.key} />)}
        </View>
    }
}

export function VotingResults({ votingDataEntry, votingInstanceKey, articleKey }) {
    const article = useModulePublicData('article', ['articleInfo', articleKey])
    
    const chronologicalResultItems = getChaptersAndChapterlessQuestions();

    /** @returns questions assigned to the specified chapter in chronological order */
    function getQuestionsInChapter({ chapter }) {
        const questionsInChapter = votingDataEntry.questions?.filter((question) => question.chapterKey === chapter.key);

        questionsInChapter.sort((questionA, questionB) => {
            return questionA.timestamp - questionB.timestamp;
        });

        return questionsInChapter;
    }

    function getEarliestTimestampInChapter({ chapter }) {
        const questionsInChapter = getQuestionsInChapter({ chapter });
        const timestampsInChapter = questionsInChapter.map((question) => {
            return question.timestamp;
        });
        const earliestTimestamp = Math.min(...timestampsInChapter);

        return earliestTimestamp;
    }

    /** @returns array of chapters and questions without chapters in chronological order; each element has an additional property `itemType` with the value `"chapter"` or `"question"` */
    function getChaptersAndChapterlessQuestions() {
        // Filter out chapters without questions. We don't want to show empty chapters.
        // Then add the timestamp of the earliest question as a new property to each chapter.
        const chaptersWithTimestamps = votingDataEntry.chapters
            ? votingDataEntry.chapters
                  .filter((chapter) => getQuestionsInChapter({ chapter }).length > 0)
                  .map((chapter) => {
                      return {
                          ...chapter,
                          timestamp: getEarliestTimestampInChapter({ chapter }),
                          itemType: "chapter",
                      };
                  })
            : null;

        // Get questions that are not assigned to a chapter
        const chapterlessQuestions = votingDataEntry.questions
            .filter((question) => !question.chapterKey)
            .map((question) => {
                return { ...question, itemType: "question" };
            });

        // Combine chapters and questions without chapters in one array
        const chronologicalResultItems = chaptersWithTimestamps
            ? [...chaptersWithTimestamps, ...chapterlessQuestions]
            : [...chapterlessQuestions];

        // Sort the chapters and chapterless questions chronologically. For chapters, we use the earliest question timestamp.
        chronologicalResultItems.sort((resultA, resultB) => {
            return resultA.timestamp - resultB.timestamp;
        });

        return chronologicalResultItems;
    }

    return (
        <PadBox horiz={20}>
            <Pad size={5} />
            <VideoLink
                videoTitle={article?.title}
                videoUrl={keyToUrl(articleKey)}
                thumbnailUrl={article?.image}
            />
            <Pad size={10} />
            <View>
                {/* Layout with chapters, chapters and chapterless questions are mixed */}
                {votingDataEntry.chapters &&
                    chronologicalResultItems.map((resultItem) => (
                        <View key={"result-" + resultItem.key}>
                            {resultItem.itemType === "chapter" && (
                                <VotingResultChapterWithQuestions
                                    chapter={resultItem}
                                    questionsInChapter={getQuestionsInChapter({ chapter: resultItem })}
                                    votingInstanceKey={votingInstanceKey}
                                />
                            )}

                            {resultItem.itemType === "question" && (
                                <VotingResultChapterlessQuestion
                                    question={resultItem}
                                    votingInstanceKey={votingInstanceKey}
                                />
                            )}
                        </View>
                    ))}

                {/* Layout without chapters */}
                {!votingDataEntry.chapters &&
                    votingDataEntry.questions.map((question) => (
                        <VotingResultSimpleQuestion
                            key={question.key}
                            question={question}
                            votingInstanceKey={votingInstanceKey}
                        />
                    ))}
            </View>
            <Pad size={35} />
        </PadBox>
    );
}

/** Collapsible chapter with questions inside. Questions are always expanded. */
export function VotingResultChapterWithQuestions({ chapter, questionsInChapter, votingInstanceKey }) {
    return (
        <REPLACE_ZDF_AccordionField
            key={chapter.key}
            nameInLog={chapter.name}
            testId={chapter.name}
            titleContent={
                <PadBox horiz={16}>
                    <UtilityText key={chapter.key} text={chapter.name} />
                </PadBox>
            }
            borderBelowTitle={true}
        >
            {questionsInChapter.map((question, index) => (
                <View key={question.key}>
                    {/* Questions inside of a collapsible chapter are not collapsible */}
                    <PadBox vert={16} left={16} right={16 * 3}>
                        <QuestionResults question={question} votingInstanceKey={votingInstanceKey} showQuestionText />

                        {index < questionsInChapter.length - 1 && (
                            <>
                                <Pad size={10} />
                                <Separator />
                            </>
                        )}
                    </PadBox>
                </View>
            ))}
        </REPLACE_ZDF_AccordionField>
    );
}

/** Collapsible question that does not belong to a chapter. Use if the voting results contain chapters. */
export function VotingResultChapterlessQuestion({ question, votingInstanceKey }) {
    return (
        <REPLACE_ZDF_AccordionField
            nameInLog={question.text}
            titleContent={
                <PadBox horiz={16}>
                    <UtilityText weight="regular" text={question.text} />
                </PadBox>
            }
            testId={question.text}
            borderBelowTitle={true}
        >
            <PadBox vert={16} left={16} right={16 * 3}>
                <QuestionResults question={question} votingInstanceKey={votingInstanceKey} />
            </PadBox>
        </REPLACE_ZDF_AccordionField>
    );
}

/** Collapsible question that does not belong to a chapter. Use if the voting results don't contain any chapters. */
export function VotingResultSimpleQuestion({ question, votingInstanceKey }) {
    return (
        <View>
            <REPLACE_ZDF_AccordionField
                nameInLog={question.text}
                titleContent={
                    <PadBox horiz={16}>
                        <UtilityText weight="medium" text={question.text} />
                    </PadBox>
                }
                testId={question.text}
            >
                <PadBox vert={16} left={16} right={16 * 3}>
                    <QuestionResults question={question} votingInstanceKey={votingInstanceKey} />
                </PadBox>
            </REPLACE_ZDF_AccordionField>
            <Pad size={10} />
        </View>
    );
}

export function QuestionResults({ question, votingInstanceKey, showQuestionText = false }) {

    let publicVotes = useCollection("derived_videoVote");
    // Filter out revoked votes
    const publicVotesFiltered = publicVotes.filter(
        (vote) => vote.questionKey === question.key && vote.instanceKey === votingInstanceKey
    );

    function renderQuestionResultVariant({ type }) {
        switch (type) {
            case "connected":
                return (
                    <QuestionResultConnected question={question} publicVotes={publicVotes} showTotalVotes noHeadline />
                );
            // TODO: Merge with slider branch and add correct component here
            case "slider":
                return <QuestionResultSlider question={question} allVotes={publicVotes}/>
            default:
                return <QuestionResultIndividual allVotes={publicVotesFiltered} question={question} />;
        }
    }

    return (
        <>
            {showQuestionText && (
                <>
                    <UtilityText weight="medium" text={question.text} />
                    <Pad size={16 * 2} />
                </>
            )}
            {renderQuestionResultVariant({type: question.type})}
        </>
    );
}

export function filterVotingDataForInstance(allVotings, votingInstanceKey) {
    return allVotings.filter(vote => (vote.instanceKey === votingInstanceKey && vote.optionKey));
}

export function filterVotingDataForPersona(allVotings, personaKey) {
    return allVotings.filter(vote => (vote.from === personaKey));
}

export function filterVotingDataForQuestion(allVotings, questionKey) {
    return allVotings.filter(vote => (vote.questionKey === questionKey));
}

export function filterVotingDataForOption(allVotings, optionKey) {
    return allVotings.filter(vote => (vote.optionKey === optionKey));
}

export function getConnectedQuestionStats(allVotes, question, personaKey) {
    const subQuestionData = {}
    let totalVoteCount = 0;
    let questionWithMostVotes = {count: 0}

    question.subQuestions.forEach((subQuestion) => {
        const subQuestionVotesFiltered = filterVotingDataForQuestion(allVotes, subQuestion.key);
        const subQuestionVotesPersonal = filterVotingDataForPersona(subQuestionVotesFiltered, personaKey);
        totalVoteCount += subQuestionVotesFiltered.length

        subQuestionData[subQuestion.key] = {
            key: subQuestion.key,
            votingsAll: subQuestionVotesFiltered,
            personalVote: subQuestionVotesPersonal?.[0],
            count: subQuestionVotesFiltered.length
        }

        if(questionWithMostVotes?.count < subQuestionData[subQuestion.key].count){
            questionWithMostVotes = subQuestionData[subQuestion.key]
        }
    })

    subQuestionData.totalAmount = totalVoteCount

    return {
        subQuestionData,
        totalVoteCount,
        questionWithMostVotes
    }
}

export function QuestionResultConnected({
    question, 
    noHeadline = false,
    absolute = false,
    showScale = false,
    showTotalVotes = false,
    showIndividualVotes = false,
    publicVotes
}) {

    const personaKey = usePersonaKey();

    const personalVotes = publicVotes.filter((vote) => (vote.from === personaKey));

    // Filter out revoked votes
    publicVotes = publicVotes.filter(vote => (vote.optionKey));

    const connectedQuestionData = getConnectedQuestionStats(publicVotes, question, personaKey)

    return <View style={{ gap: 24 }}>

        {noHeadline ?
            <></>
            : <View style={{ gap: 16 }}>
                <Heading label="Voting Results"></Heading>
                <Paragraph text={question.text}></Paragraph>
            </View>
        }
        
        {showScale && <HorizBox>
            <View style={{flex: 1}}>
                <CountScale maxValue={connectedQuestionData.questionWithMostVotes.count}></CountScale>
            </View>
           {showTotalVotes && <View style={{width: 56}}></View>} 
        </HorizBox>}
        <View style={{gap: 32}}>
            <View style={{gap: 16}}>
                {question.subQuestions.map((subQuestion) =>(
                    <React.Fragment key={subQuestion.key}>
                        <QuestionResultsConnectedSubQuestionPercentageBar
                            key={subQuestion.key}
                            question={question} 
                            subQuestion={subQuestion} 
                            allVotes={publicVotes}
                            personalVotes={personalVotes}
                            maxCount={connectedQuestionData.questionWithMostVotes.count}
                            absolute={absolute}
                            showIndividualVotes ={showIndividualVotes}
                            showTotalVotes={showTotalVotes}
                            >
                        </QuestionResultsConnectedSubQuestionPercentageBar>
                    </React.Fragment> 
                ))}
            </View>
            <ColorLegend labels={question.options.map(o=>o.text)} colors={connectedQuestionColorPalette}></ColorLegend>
        </View>
        
    </View>
    
    
}

const connectedQuestionColorPalette = ["#A1A5FF", "#716FE8", "#5A1EF5"]
export function QuestionResultsConnectedSubQuestionPercentageBar({question, subQuestion, allVotes, personalVotes, maxCount, absolute=false, showIndividualVotes=true, showTotalVotes}) {

    let allVotesFiltered = allVotes.filter(vote=>(vote.questionKey === subQuestion.key && vote.optionkey!== null));
    let personalVote = personalVotes.find(vote=>((vote.questionKey === subQuestion.key) && vote.optionKey))
    let allVotesCount = allVotesFiltered.length;
    const emptyVote = allVotesFiltered.length === 0
    

    let barPercentage = absolute ? allVotesCount*100/(maxCount!==0 ? maxCount : 1) : 100;

    let optionCountArray = []
    question.options.forEach(option => {
        const optionInfo = {}
        optionInfo.count = (allVotesFiltered.filter(vote=>(vote.optionKey === option.key)).length)
        optionInfo.percentage = optionInfo.count*100/allVotesCount
        optionCountArray.push(optionInfo)
    })

    return <View>
    <HorizBox spread center>
        <View style={{width: 64, overflow:"hidden"}}>
            <QuestionTimeStampLabel question={subQuestion} color={colorTextSecondary}> 
               <DataVizText type='heading2' />
            </QuestionTimeStampLabel>
        </View> 
        <View style={{flex: 1}}>
            <PercentageBarContainer style={{height: 24, overflow:"hidden",
                width: maxCount ? barPercentage+"%" : "100%"
            }}>
            {   !emptyVote &&
                question.options.map((q, i) => {
                    return <PercentageSegment key={i} percentage={optionCountArray[i].percentage} color={connectedQuestionColorPalette[i]}>
                        {personalVote?.optionKey === q.key ? <ProfilePhoto type="tiny" userId={personalVote.from}/> : <></>}
                    </PercentageSegment>
                })
            }
            </PercentageBarContainer>  
        </View>

        {showTotalVotes && <View style={{width: 34}}>
            <Center>
                <UtilityText text={allVotesCount.toString()}></UtilityText>  
            </Center>  
        </View>}
              
    </HorizBox>
    {
        showIndividualVotes &&
        <HorizBox>
            <View style={{width: 56}}/>
            <View style={{flex: 1}}>
                <PercentageBarContainer style={{width: maxCount ? barPercentage+"%" : "100%"}}>
                    {!emptyVote &&
                        question.options.map((option, i)=>{
                            const renderEmptyVote = emptyVote && i == 0;
                            const percentage = !renderEmptyVote ? optionCountArray[i].percentage : 100
                            // console.log(optionCountArray[i].count)
                            return <PercentageSegment key={i} percentage={percentage}>
                                <UtilityText text={optionCountArray[i].count.toString()} color={colorTextSecondary}></UtilityText>  
                            </PercentageSegment>
                        })
                    }  
                </PercentageBarContainer>
            </View>
                      
        </HorizBox>
    }
  </View>  
}

export function QuestionResultSlider({ question, allVotes }) {

    const personaKey = usePersonaKey();
    const personalVote = allVotes.find((vote) => vote.from === personaKey && vote.questionKey === question.key);
    const communityVotes = allVotes.filter((vote) => vote.questionKey === question.key);

    let communityAverageValue = 0;
    if (communityVotes && communityVotes.length > 0) {
        let communityTotalValue = 0;

        communityVotes.forEach((communityVote) => {
            communityTotalValue = communityTotalValue + communityVote.value;
        });

        communityAverageValue = Math.round(communityTotalValue / communityVotes.length);
    }

    const predeterminedAnswerValues = question.predeterminedAnswers?.map((predeterminedAnswer) => { return predeterminedAnswer.value });

    let maxValue = 0;
    if (predeterminedAnswerValues && personalVote) {
        maxValue = Math.max(...predeterminedAnswerValues, personalVote.value, communityAverageValue);
    }
    else if (predeterminedAnswerValues && !personalVote) {
        maxValue = Math.max(...predeterminedAnswerValues, communityAverageValue);
    }
    else if (!predeterminedAnswerValues && personalVote) {
        maxValue = Math.max(personalVote.value, communityAverageValue);
    }
    else {
        maxValue = communityAverageValue;
    }

    return (
        <View>
            <HorizBox>
                <UtilityText label="Time:" color={colorTextSecondary} />
                <Pad size={5} />
                <UtilityText text={getPrettyTimestamp(question.timestamp)} color={colorTextSecondary} />
            </HorizBox>
            <Pad size={16}/>
            {personalVote && (
                <PadBox bottom={16}>
                    <SliderResultBar
                        label="You"
                        value={personalVote.value}
                        maxValue={maxValue}
                        unit={question.unit}
                        isUnitBeforeValue={question.isUnitBeforeValue}
                        color={colorAmbiguousAccent}
                    />
                </PadBox>
            )}
            <SliderResultBar
                label="Community average"
                value={communityAverageValue}
                maxValue={maxValue}
                unit={question.unit}
                isUnitBeforeValue={question.isUnitBeforeValue}
                color={colorGrey5}
            />
            {question.predeterminedAnswers &&
                question.predeterminedAnswers.map((predeterminedAnswer, index) => (
                    <PadBox top={16} key={"predeterminedAnswer-" + question.key + "-" + index}>
                        <SliderResultBar
                            text={predeterminedAnswer.text}
                            value={predeterminedAnswer.value}
                            maxValue={maxValue}
                            unit={question.unit}
                            isUnitBeforeValue={question.isUnitBeforeValue}
                            color={colorGrey5}
                        />
                    </PadBox>
                ))}
        </View>
    );
}

function SliderResultBar({ label, text, value, maxValue, unit = "", isUnitBeforeValue = false, color = colorAmbigousBlack }) {
    // If no one has voted yet or if the number is tiny, just show a dot (i.e. flex: 0.01)
    const flexSize = value && maxValue > 0 ? Math.max(value / maxValue, 0.01) : 0.01;

    return (
        <View style={{ alignContent: "center" }}>
            <UtilityText label={label} text={text} />
            <Pad size={8} />
            <HorizBox center>
                <View
                    style={{
                        height: 7,
                        backgroundColor: color,
                        flex: flexSize,
                        borderRadius: 3.5,
                    }}
                />
                <Pad size={8} />
                <UtilityText text={isUnitBeforeValue ? unit + value.toString() : value.toString() + unit} />
            </HorizBox>
        </View>
    );
}

export function QuestionTimeStampLabel({children, question, color}) {

    let text = getPrettyTimestamp(question.timestamp)
    let colorOut = color
    if(question.initial) text = "START"
    if(question.final) text = "END"
    if(question.final || question.initial) colorOut=colorAmbigousBlack

    return <View style={{flex: 1}}>
        {React.cloneElement(children, {text, strong: (question.final || question.initial) ? true : false, color: colorOut})}
    </View>
}