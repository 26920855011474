import { getStructureForKey, StackedScreen } from './instance';
import { Datastore } from './datastore';
import { assembleConfig, assembleScreenSet, noDefaultFeatures } from './features';
import { act, fireEvent, screen, within } from '@testing-library/react';
import { global_textinput_test_handlers, UtilityText } from '../component/text';
import { personaA } from './testpersonas';
import { useEffect, useState } from 'react';
import { View } from 'react-native';
import { undefA, undefR } from './stdtypes';

export function WithFeatures({dataRef=undefA,
        siloKey='test', structureKey='componentdemo', instanceKey='test', 
        features={}, language='english', personaPreview=personaA,
        testState=undefR, children}) {

    const activeFeatures = {...noDefaultFeatures(structureKey), ...features};

    const structure = getStructureForKey(structureKey);
    const config = assembleConfig({structure, activeFeatures});
    return <Datastore 
            ref={dataRef}
            siloKey={siloKey}
            structureKey={structureKey}
            instanceKey={instanceKey} 
            testState={testState}
            isLive={false}
            language={language}
            personaPreview={personaPreview}
            config={config}>
        {children}
    </Datastore>
}

export function TestInstance({dataRef, structureKey, siloKey='test', instanceKey='test', screenKey=null, 
        params={}, features={}, testState, personaPreview=personaA}) {
    const structure = getStructureForKey(structureKey);
    const activeFeatures = {...noDefaultFeatures(structureKey), ...features};
    const screenSet = assembleScreenSet({structure, activeFeatures});
    return <WithEnv dataRef={dataRef} siloKey={siloKey} structureKey={structureKey} instanceKey={instanceKey}
            features={activeFeatures} testState={testState} personaPreview={personaPreview} >
        <StackedScreen screenSet={screenSet} screenInstance={{structureKey, instanceKey, screenKey, params}} features={activeFeatures} />
    </WithEnv>
}

export const WithEnv = WithFeatures;

export function TestDatastore({dataRef=undefA, testState=undefR, siloKey='test', structureKey='test', instanceKey='test', 
        features={}, language='english', personaPreview=personaA, config=undefR, children}) {
    return <Datastore dataRef={dataRef} testState={testState} siloKey={siloKey} structureKey={structureKey} instanceKey={instanceKey}
            features={features} language={language} personaPreview={personaPreview} config={config} isLive={false} >
        {children}
    </Datastore>
}

export function getMatchingObject(dataRef, type, data) {
    const objects = dataRef.current.getData()[type];
    if (!objects) {
        console.error('No objects of type', type);
        return false;
    }
    for (let key in objects) {
        if (objectIsSubset(data, objects[key])) return key;
    }
    throw new Error(type + ' not found: ' + JSON.stringify(data));
}

function objectIsSubset(subset, superset) {
    for (let key in subset) {
        if (subset[key] !== superset[key]) return false;
    }
    return true;
}


async function testStoryActionAsync(parent, action) {
    if (action.action === 'click') {
        await act(async () => {
            await fireEvent.click(await parent.findByTestId(action.matcher))
        });
    } else if (action.action === 'input') {
        const onChangeText = global_textinput_test_handlers[action.matcher];
        await onChangeText(action.text);
    } else if (action.action === 'within') {
        const withinParent = await parent.findByTestId(action.matcher);
        await testStoryActionAsync(within(withinParent), action.innerAction);
    } else if (action.action === 'popup') {
        const popupContent = await parent.findByTestId('popup-content');
        await testStoryActionAsync(within(popupContent), action.popupAction);
    } else if (action.action === 'popup-close') {
        const popupContent = await parent.findByTestId('Close Popup');
        await fireEvent.click(popupContent);
    } else if (action.action === 'check_text') {
        const textFound = parent.getByText(action.expectedText);
        if (!textFound) {
            throw new Error(`Expected text "${action.expectedText}" not found.`);
        }        
    } else if (action.action === 'key_press') {
        await fireEvent.keyDown(window, {charCode: action.key.keyCode, ctrlKey: action.key.ctrlKey, shiftKey: action.key.shiftKey, altKey: action.key.altKey});
    } else {
        throw new Error('Unsupported action: ' + action.action);
    }
}

export async function testStoryActionListAsync(actions) {
    for (let action of actions) {
        await act(async () => {
            await testStoryActionAsync(screen, action);
        });
    }
}

let global_testEventReceiver = {};

export function TestEventReceiver({sinkKey='test-event-receiver'}) {
    const [events, setEvents] = useState([]);

    function addEvent(event) {
        setEvents(oldEvents => [...oldEvents, event]);
    }

    useEffect(() => {
        global_testEventReceiver[sinkKey] = addEvent;
        return () => {
            global_testEventReceiver[sinkKey] = null;
        }
    }, []);

    return <View>
        {events.map((event, index) => <UtilityText key={index} text={event} />)}
    </View>
}

export function sendTestEvent(sinkKey, event) {
    if (global_testEventReceiver[sinkKey]) {
        global_testEventReceiver[sinkKey](event);
    }
}