import React from "react"
import { View } from "react-native"

export function PercentageBarContainer({children, vertical, style={}, backgroundColor, thickness}) {
    let barStyle = {
        flexDirection: vertical? "column-reverse" : "row",
        backgroundColor: backgroundColor,
        flexGrow: !thickness ? 1 : 0,
        width: vertical && thickness ? thickness : null,
        height: !vertical && thickness ? thickness : null,
        borderRadius: 4,
    }

    barStyle = {
        ...barStyle,
        ...style
    }
    return <View style={barStyle}>
        {children}
    </View>
}


export function PercentageSegment({children, percentage, color}) {
    let elementStyle = {
        backgroundColor: color,
        flex: percentage,
        overflow: "hidden"
    }

    elementStyle = {
        ...elementStyle
    }

    return  <View style={elementStyle}>
        {children}
    </View>
}

export function PercentageBar({ children, percentages, colors, totalValue=100  }) {

    const sum = percentages.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
    }, 0);

    const childrenArray = React.Children.toArray(children);

    return <>
        {percentages.map((p,i)=>(
            <PercentageSegment key={i} color={colors[i]} percentage={p}>
                {childrenArray[i]}
            </PercentageSegment>
        ))}
        <PercentageSegment percentage={totalValue - sum}></PercentageSegment>
    </>
}
