import { ConversationScreen, Pad, PadBox } from "component/basics";
import { Heading, WebLinkTextButton } from "component/text";
import { useLanguage } from "component/translation";
import { makeApiUrl } from "system/servercall";
import { useSiloKey } from "util/datastore";

export const EmailPreviewFeature = {
    name: 'Email Preview',
    key: 'emailpreview',
    subscreens: {
        emailPreview: EmailPreviewScreen,
    },
    config: {
        quickLinks: [
            {label: '!Email Preview', screenKey: 'emailPreview'},
        ]
    },   
}

const previewEmails = [
    {
        name: 'Reply Notif',
        templateId: 'reply-notif',
        data: {
            replyAuthorName: 'John Doe',
            conversationName: 'Test Conversation',
            replyText: 'This is a test reply',
            notifUrl: 'https://www.google.com',
        }
    },
    {
        name: 'Mod Approve',
        templateId: 'mod-approve',
        data: {
            moderatorName: 'John Doe',
            conversationName: 'Test Conversation',
            commentText: 'This is a test comment',
        }
    },
    {
        name: 'Mod Reject',
        templateId: 'mod-reject',
        data: {
            moderatorName: 'John Doe',
            conversationName: 'Test Conversation',
            reasoningTextLines: ['This would be okay if you removed the profanity', 'And if you avoided calling for the death of your opponents.'],
            commentText: 'This is a test comment',
        }
    },
]


export function EmailPreviewScreen() {
    const siloKey = useSiloKey();
    const language = useLanguage();

    function makeEmailPreviewUrl(preview) {
        const url = new URL(makeApiUrl('debug', 'renderEmailTemplate'));
        url.searchParams.set('templateId', preview.templateId);
        url.searchParams.set('data', JSON.stringify(preview.data));
        url.searchParams.set('siloKey', siloKey);
        url.searchParams.set('language', language || 'english');
        return url.toString();
    }
    
    return <ConversationScreen>
        <PadBox horiz={20} bottom={20}>
            <Pad />
            <Heading type="large" weight="medium" label='!Email Preview' />
            <Pad />
            {previewEmails.map(preview => (
                <WebLinkTextButton key={preview.templateId} text={preview.name} url={makeEmailPreviewUrl(preview)} />
            ))}
        </PadBox>
    </ConversationScreen>
}