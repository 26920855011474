import { ChevronLeft, FaceDissatisfied, FaceSatisfied, Information, Locked, User, ViewFilled } from "@carbon/icons-react";
import React, { useEffect, useState } from "react";
import { StyleSheet } from "react-native";
import { ScrollView, View } from "react-native-web";
import { GUIDELINES } from "../../../feature/question/CommunityGuidelinesFeature";
import { DocumentLevelTooltip, ToolTip } from "../tooltip";
import { useParentDatastore } from "../parentdatastore";
import { useDatastore, usePersonaKey, usePersonaObject, useServerCallResult } from "../../../util/datastore";
import { colorAmbigousBlack, colorAmbiguousHighlight, colorAmbiguousWhite, colorBlack, colorBlackHover, colorTextDisabled, colorTextSecondary, colorTextSuccess, colorTextWarning, colorWhite } from "../../color";
import { CTAButton, SubtleButton, TextButton } from "../../button";
import { HorizBox, Pad, PadBox, Separator } from "../../basics";
import { Heading, UtilityText } from "../../text";
import { Checkbox, Toggle } from "../../form";
import { Banner } from "../../banner";
import { Modal } from "../../modal";
import { FaceImage, LetterFace } from "../../people";
import { useInstanceParams } from "../../../util/params";
import { setToastMessage } from "../toast";
import { translateLabel } from "../../translation";
import { logEventAsync } from "util/eventlog";
import { CTAButtonWithLoadingSpinner } from "../button";

const JudgementCardStyle = StyleSheet.create({
    elevatedCard: {
        backgroundColor: colorAmbiguousWhite,
        width: "fit-content",
        height: "fit-content",
        display: "flex",
        flexDirection: "column",
        borderRadius: 8,
        padding: 16,
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
        borderColor: colorAmbiguousWhite,
        borderWidth: 1,
        gap: 12
    },
    guidelineColumn: {
        margin: 4,
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        justifyContent: "space-evenly"
    }
});


async function setJudgementAsync({ judgement, datastore, modTask, reasoning }) {
    logEventAsync(datastore, 'moderation-human-judgement', { judgement, commentKey: modTask.key });
    await datastore.callServerAsync('moderation', 'setJudgement', {
        key: modTask.key, judgement, reasoning: reasoning || modTask.reasoning
    });
}

const JudgementFlowState = {
    JUDGE: "JUDGE",
    REJECTREASON: "REJECTREASON",
}

export function JudgementCard({ modTask, modAuthor, taskWatchState, isSelected, isWarnDisabled = false, onCollapse }) {
    const datastore = useDatastore();
    const parentDatastore = useParentDatastore()
    const personaKey = usePersonaKey();
    const amIViewing = taskWatchState?.amIViewing
    const [pageIndex, setPageIndex] = useState(JudgementFlowState.JUDGE);
    const [processing, setProcessing] = useState(false);

    const [isReduced, setIsReduced] = useState(false);
        
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= window.screen.availWidth / 1.3) {
                setIsReduced(true);
            } else {
                setIsReduced(false);
            }
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    async function onApprove() {
        try {
            setProcessing(true);
            // Reset warning for this comment if there is one to avoid giving multiple warnings per comment
            if (modTask.receivedWarning) {
                const userId = modAuthor.authorKey;

                await datastore.callServerAsync('moderationZdf', 'warnUser', {
                    key: userId, commentKey: modTask.key, receivedWarning: false
                });
            }
            await setJudgementAsync({ judgement: "approve", datastore, modTask, reasoning: "" });
            setToastMessage({datastore: parentDatastore, label: "The comment has been successfully approved", visible: true })
            onCollapse && onCollapse();
        } catch (e) {
            setToastMessage({datastore: parentDatastore, label: "Operation could not be executed, please try again", visible: true, type:"error" })
        } finally {
            setProcessing(false);
        }
    }   

    // Judgement card is visible if the user has selected it or someone else is working on it
    return (taskWatchState || isSelected) && <ToolTip
        label={modTask.deleted === true ? "You cannot work on a deleted comment." : "Only one moderator at a time can work on a comment."}
        disabled={(!taskWatchState || amIViewing || !isSelected) && !modTask.deleted}
    >
        <View style={[JudgementCardStyle.elevatedCard,
        {
            borderColor: isSelected ? colorAmbigousBlack : colorAmbiguousWhite,
            backgroundColor: !taskWatchState || colorAmbiguousWhite,
        }]}
        testID={"judgementCard-" + modTask.key}
        >
            {pageIndex === JudgementFlowState.JUDGE && (
                <>
                    {taskWatchState && <HorizBox center gap={8}>

                    <View>
                        {taskWatchState?.photoUrl ? <FaceImage type={"tiny"} photoUrl={taskWatchState?.photoUrl} /> :
                        <LetterFace type={"tiny"} name={taskWatchState?.name} hue={taskWatchState?.hue}/>}
                    </View>
                        <UtilityText
                            color={colorAmbiguousHighlight}
                            label={amIViewing ? "You are reviewing" : "{viewerName} is reviewing"}
                            formatParams={{
                                viewerName: !isReduced ? (taskWatchState?.name ?? "") : getShortenedName(taskWatchState?.name ?? "")
                            }}
                            type="tiny"
                            weight="medium"
                        />
                        {!isReduced && (amIViewing ? (<ViewFilled color={colorAmbiguousHighlight} />) : (<Locked color={colorAmbiguousHighlight} />))}
                    </HorizBox>}
                    {!modTask.deleted && isSelected && (
                        <>
                            {(modTask.judgement === "reject" || !modTask.judgement) && (
                                <CTAButtonWithLoadingSpinner
                                    icon={<FaceSatisfied style={{ fill: colorTextSuccess }} />}
                                    label="Approve"
                                    type="secondary"
                                    onPress={() => onApprove()}
                                    disabled={(taskWatchState && !amIViewing) || modTask.deleted || processing.approval}
                                    wide
                                    processing={processing}
                                />
                            )}
                            {(modTask.judgement === "approve" || !modTask.judgement) && (
                                <CTAButton
                                    icon={<FaceDissatisfied style={{ fill: colorTextWarning }} />}
                                    label="Reject"
                                    type="secondary"
                                    onPress={() => setPageIndex(JudgementFlowState.REJECTREASON)}
                                    disabled={(taskWatchState && !amIViewing) || modTask.deleted || processing.rejection}
                                    wide
                                />
                            )}
                        </>
                    )}
                </>
            )}
            {pageIndex === JudgementFlowState.REJECTREASON && (
                <RejectionPanel
                    onBack={() => setPageIndex(JudgementFlowState.JUDGE)}
                    onNext={() => setPageIndex(JudgementFlowState.JUDGE)}
                    modTask={modTask}
                    modAuthor={modAuthor}
                    disabled={isWarnDisabled}
                    onCollapse={onCollapse}
                />
            )}
        </View>
    </ToolTip>
}

function RejectionPanel({ onBack, onNext, modTask, modAuthor, onCollapse }) {
    const datastore = useDatastore();
    const parentDatastore = useParentDatastore();
    const guidelineNames = [...Object.values(GUIDELINES), 'Other'];
    const userId = modAuthor.authorKey;
    const params = useInstanceParams();

    const [isWarned, setIsWarned] = useState(false);
    const [isBlocked, setIsBlocked] = useState(false);

    const [selectedGuidelines, setSelectedGuidelines] = useState(()=>initalGuidelineSelection());

    function initalGuidelineSelection(){
        const initialSelection = {}
        if (modTask.violations) {
            guidelineNames.forEach(guidelineName => {
                initialSelection[guidelineName] = modTask.violations.includes(guidelineName);
            });
        }
        return initialSelection;
    }
    const maybeSortedGuidelines = guidelineNames.sort((a,b)=>selectedGuidelines[a]==true ? -1 : 1)

    useEffect(() => {
        async function fetchBlockStatus() {
            const blocked = await datastore.callServerAsync("moderationZdf", "getIsUserBlocked", { key: userId });
            setIsBlocked(blocked);
        }
        fetchBlockStatus();
    }, [userId]);

    let warningCount = useServerCallResult("moderationZdf", "getUserWarningCount", { key: userId }) || 0;

    // Warning users is not implemented yet
    function onWarn() {
        //setIsWarned(true);
    }
    
    // Warning users is not implemented yet
    function onUnwarn() {
        //setIsWarned(false);
    }

    function onProfile() {
        if (!params) return
        params.userId = userId;
        datastore.gotoInstance({structureKey: "moddashboard", instanceKey: parentDatastore.props.instanceKey, params: params})
    }
    const [processing, setProcessing] = useState(false)

    async function onReject() {
        // Check if user is already blocked. If they are, skip the warn & block dialog and finish rejecting the comment immediately.
        // If not, set judgement and reasoning but don't update the queue yet. Otherwise, the comment will disappear from the current filter category before the warn/block dialog can be shown.
        setProcessing(true)
        const reasoningsUntranslated = Object.entries(selectedGuidelines).filter(value => value[1] === true).map(value => value[0])
        const reasoningTranslated = reasoningsUntranslated.map((reasoning)=>translateLabel({label: reasoning,language:datastore.getLanguage()}))
        const preliminaryJudgement = {
            judgement: "reject",
            datastore,
            modTask,
            reasoning: JSON.stringify(reasoningTranslated),
        };

        try {
            if (isWarned) {
                await datastore.callServerAsync("moderationZdf", "warnUser", {
                    key: userId,
                    commentKey: modTask.key,
                    receivedWarning: true,
                });
                setToastMessage({
                    datastore: parentDatastore,
                    label: "The comment has been successfully rejected and \n{user} was successfully warned",
                    visible: true,
                    formatParams: { user: modAuthor.authorName },
                });
                onCollapse && onCollapse();
            }
    
            await setJudgementAsync(preliminaryJudgement);
            onNext();
            if (!isWarned) {
                setToastMessage({
                    datastore: parentDatastore,
                    label: "The comment has been successfully rejected",
                    visible: true,
                });
                onCollapse && onCollapse();
            }
        } catch (error) {
            setToastMessage({
                datastore: parentDatastore,
                label: "Operation could not be executed, please try again",
                visible: true,
                type: "error",
            });
        } finally {
            setProcessing(false);
        }
    }

    return (
        <View style={JudgementCardStyle.guidelineColumn}>
            <SubtleButton icon={ChevronLeft} label={"Back to selection"} onPress={onBack} />
            <Pad size={12} />
            <UtilityText label={"Which Community Guideline does this comment violate?"} weight='medium' />
            <Pad size={12} />
            <ScrollView style={{height: 250}}>
                {maybeSortedGuidelines.map((guidelineName) => (
                    <PadBox horiz={8} key={"padbox-"+guidelineName}>
                        <Checkbox
                        label={guidelineName}
                        key={guidelineName}
                        value={selectedGuidelines[guidelineName]}
                        onChange={(isSelected) => {
                            setSelectedGuidelines(selection => ({...selection, [guidelineName]: isSelected}));
                        }}
                    />
                    </PadBox> 
                ))}
            </ScrollView>

            <Pad size={12} />
            <Separator />
            <Pad size={12} />
            <ToolTip label={"This feature is not implemented yet."}>
                <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <UtilityText label={"Warn user"} />
                        <Pad size={8} />
                        <InfoTag label="The user receives an automatic message by e-mail after the warning. It is possible to cancel the warning." />
                    </View>
                    <Toggle value={isWarned} onChange={isWarned ? onUnwarn : onWarn} testID={"warn-toggle"}/>
                </View>
            </ToolTip>

            {warningCount >= 1 && (
                <>
                    <Pad size={12} />
                    <Banner>
                        <UtilityText
                            label='User has already been warned {count} {noun}.' 
                            formatParams={{count: warningCount, singular: 'time', plural: 'times'}}
                            color={colorBlackHover}
                        />
                        <Pad size={16} />
                        <Separator />
                        <Pad size={16} />
                        <HorizBox>
                            <User />
                            <Pad size={4} />
                            <TextButton
                                type="small"
                                color={colorBlackHover}
                                strong
                                label="Show user profile"
                                onPress={onProfile}
                            />
                        </HorizBox>
                    </Banner>
                </>
            )}

            <Pad size={12} />
            {(warningCount >= 2 || isBlocked) && (
                <JudgementCardBlockWidget modAuthor={modAuthor} modTask={modTask} />
            )}

            <CTAButtonWithLoadingSpinner
                wide
                label={"Reject"}
                disabled={Object.values(selectedGuidelines).filter(s => s === true).length <= 0}
                onPress={onReject}
                processing={processing}
            />
        </View>
    );
}

export function InfoTag({ label }) {
    return (
        <View>
            <DocumentLevelTooltip label={label} width={215} color={colorBlack} textColor={colorWhite}>
                <Information size={14} color={colorTextDisabled} />
            </DocumentLevelTooltip>
        </View>
    );
}

function JudgementCardBlockWidget({ modAuthor}) {
    const datastore = useDatastore();
    const parentDatastore = useParentDatastore();
    const userId = modAuthor.authorKey;

    const [showBlockConfirmationModal, setShowBlockConfirmationModal] = useState(false);
    const [showUnblockConfirmationModal, setShowUnblockConfirmationModal] = useState(false);

    const [isBlocked, setIsBlocked] = useState(false);
    const currentModeratorKey = usePersonaKey();
    const currentModeratorPersona = usePersonaObject(currentModeratorKey);

    async function onBlock() {
        try {
            await datastore.callServerAsync("moderationZdf", "setIsUserBlocked", {
                key: userId,
                isBlocked: true,
                blockedByKey: currentModeratorKey,
                blockedByName: currentModeratorPersona.name,
            });
            setToastMessage({datastore: parentDatastore, label: '{user} was successfully blocked', visible: true, formatParams: {user: modAuthor.authorName}});
            setIsBlocked(true);
        } catch (e) {
            setToastMessage({datastore: parentDatastore, label: "Operation could not be executed, please try again", visible: true, type:"error" })
        }
        setShowBlockConfirmationModal(false);
    }

    async function onUnblock() {
        try {
            await datastore.callServerAsync("moderationZdf", "setIsUserBlocked", {
                key: userId,
                isBlocked: false,
            });
            setToastMessage({datastore: parentDatastore, label: '{user} was successfully unblocked', visible: true, formatParams: {user: modAuthor.authorName}});
            setIsBlocked(false);
        } catch (e) {
            setToastMessage({datastore: parentDatastore, label: "Operation could not be executed, please try again", visible: true, type:"error" })
        }
        setShowUnblockConfirmationModal(false);
    }

    useEffect(() => {
        async function fetchBlockStatus() {
            const blocked = await datastore.callServerAsync("moderationZdf", "getIsUserBlocked", { key: userId });
            setIsBlocked(blocked);
        }
        fetchBlockStatus();
    }, [userId]);

    return <>
        <HorizBox spread center>
            <HorizBox center>
                <UtilityText label={"Block user"} />
                <Pad size={8} />
                <InfoTag label="Blocking prevents the user from posting further content. This action can be undone at any time if needed." />
            </HorizBox>
            <Toggle
                value={isBlocked}
                onChange={() => {
                    if (!isBlocked) {
                        setShowBlockConfirmationModal(true);
                    } else {
                        setShowUnblockConfirmationModal(true);
                    }
                }}
                testID={"block-toggle"}
            />
        </HorizBox>

        {showBlockConfirmationModal && (
            <BlockConfirmationModal
                onBlock={() => {
                    onBlock();
                    setShowBlockConfirmationModal(false);
                }}
                onClose={() => setShowBlockConfirmationModal(false)}
            />
        )}

        {showUnblockConfirmationModal && (
            <UnblockConfirmationModal
                onUnblock={() => {
                    onUnblock();
                    setShowUnblockConfirmationModal(false);
                }}
                onClose={() => setShowUnblockConfirmationModal(false)}
            />
        )}

        <Pad size={12} />
    </>
}

export function BlockConfirmationModal({ onBlock, onClose }) {
    return (
        <Modal onClose={onClose}>
            <PadBox vert={40} horiz={20}>
                <Heading label="Block this user?" level={1} />
                <Pad size={8} />
                <UtilityText
                    label="Do you really want to block this user? You can unblock the user via the profile page."
                    color={colorTextSecondary}
                />
                <Pad size={32} />
                <CTAButton
                    wide
                    type="delete"
                    label={"Block user"}
                    onPress={async () => {
                        onBlock();
                    }}
                />
            </PadBox>
        </Modal>
    );
}

export function UnblockConfirmationModal({ onUnblock, onClose }) {
    return (
        <Modal onClose={onClose}>
            <PadBox vert={40} horiz={20}>
                <Heading label="Unblock this user?" level={1} />
                <Pad size={8} />
                <UtilityText label="Do you really want to unblock this user?" color={colorTextSecondary} />
                <Pad size={32} />
                <CTAButton
                    wide
                    type="delete"
                    label={"Unblock user"}
                    onPress={async () => {
                        onUnblock();
                    }}
                />
            </PadBox>
        </Modal>
    );
}

function getShortenedName(name) {
    if (!name) return ""; 
    const parts = name.split(" ");
    if (parts.length === 1) {
        return parts[0];
    }

    const firstName = parts[0];
    const lastName = parts[parts.length - 1];

    const lastNameParts = lastName.split("-");
    const abbreviatedLastName = lastNameParts
        .map(part => `${part[0]}.`)
        .join("-");

    return `${firstName} ${abbreviatedLastName}`.trim();
} 