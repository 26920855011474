import { useGlobalTopics } from "../cbcrc/admin/TopicListFeature";
import { TeaserScreen } from "../../system/teaser";
import { useIsAdmin } from "component/admin"
import { BetaTag, CTAButton } from "component/button"
import { useCollection, useDatastore, useGlobalParams, useGlobalProperty, useInstanceKey, useModulePublicData, useObject, useStructureKey } from "util/datastore"
import { Image, Pressable, StyleSheet, useWindowDimensions, View } from "react-native";
import { useState } from "react";
import { ConversationScreen, FlowBox, HorizBox, Pad, PadBox } from "component/basics"
import { Heading, UtilityText } from "component/text"
import { Toggle } from "component/form"
import { Catcher } from "system/catcher"
import { colorAmbiguousWhite, colorTealBackground } from "component/color"
import { AddComment, Edit } from "@carbon/icons-react";
import { keyToUrl } from "../../util/util";


export const TopicPhotoTeaserFeature = {
    name: 'Topic Photo Teaser',
    key: 'topicphototeaser',
    config: {
        teaser: TopicPhotoTeaserForArticle,
        adminScreen: TopicChooserAdminScreen
    }
}

export const TopicPhotoTeaserForTopicFeature = {
    name: 'Topic Photo Teaser (direct)',
    key: 'topicphototeaser_topic',
    dev: true,
    config: {
        teaser: TopicPhotoTeaserForTopic
    }
}

export function TopicPhotoTeaserForArticle() {
    const instanceKey = useInstanceKey();
    const articleInfo = useGlobalProperty('articleInfo') ?? {};
    const topics = useCollection('topic').filter(q => !q.deleted);
    const topic = topics[0];

    return <TopicPhotoTeaser topicKey={topic?.key}
            topicPhoto={topic?.photoUrl} topicName={topic?.text}
            entryUrl={keyToUrl(instanceKey)} 
            entryTitle={articleInfo.title} entryImage={articleInfo.image} />
}

export function TopicPhotoTeaserForTopic() {
    const {entryUrl, entryTitle, entryImage} = useGlobalParams();
    const name = useGlobalProperty('name');
    const photoUrl = useGlobalProperty('photoUrl');
    const topicKey = useInstanceKey();

    return <TopicPhotoTeaser topicKey={topicKey} topicPhoto={photoUrl} topicName={name}
            entryUrl={entryUrl} entryTitle={entryTitle} entryImage={entryImage} />
}

export function TopicPhotoTeaser({topicKey, topicPhoto, topicName, entryUrl, entryTitle, entryImage}) {
    const s = TopicPhotoTeaserStyle;
    const datastore = useDatastore();
    const isAdmin = useIsAdmin();

    const globalParams = {entryUrl, entryTitle, entryImage};

    return <TeaserScreen>
        <Pressable testID='photo-teaser' onPress={() => datastore.gotoInstance({ structureKey: 'topic', instanceKey: topicKey, globalParams})}>
            <PhotoTeaser photoUrl={topicPhoto}>
                <View style={s.inner}>
                    <TagLine />
                    <View>
                        <Heading type="large" weight="medium" color={colorAmbiguousWhite} text={topicName} />
                        <Pad size={20}/>
                        <FakeResponseCount/>
                        <Pad size={20}/>
                        <View style={s.fakeButton}>
                            <UtilityText type='large' label='Share your thoughts' />
                            <Pad size={12} />
                            <Edit />
                        </View>
                    </View>
                </View>
            </PhotoTeaser>
        </Pressable>
        {isAdmin && <AdminTeaserAction />}
    </TeaserScreen>
}

const TopicPhotoTeaserStyle = StyleSheet.create({
    inner: {
        padding: 16,
        justifyContent: 'space-between',
        height: '100%'
    },
    fakeButton: {
        flexDirection: 'row',
        backgroundColor: colorAmbiguousWhite,
        paddingHorizontal: 16,
        paddingVertical: 12,
        borderRadius: 32,
        alignSelf: 'flex-start',
        height: 44, 
        alignItems: 'center',
    }
});

function TagLine() {
    const showBetaTag = useModulePublicData('isBeta')
    return <HorizBox center>
        <AddComment color={colorAmbiguousWhite} />
        <Pad size={4} />
        <UtilityText color={colorAmbiguousWhite} type='tiny' weight='medium' caps label="Let's talk about..." />
        {showBetaTag && <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Pad size={8} />
            <BetaTag />
        </View>}        
    </HorizBox>
}

function FakeResponseCount() {
    const count = 5;
    return <UtilityText label='{count} {noun}' color={colorAmbiguousWhite} 
        formatParams={{count, plural: 'responses', singular: 'response'}} />
}

function PhotoTeaser({photoUrl, children}) {
    const s = PhotoTeaserStyle;

    const {width} = useWindowDimensions();
    const height = 200;
    const small = width < 360;

    return <HorizBox spread>
        {!small && 
            <View style={s.left} >
                <Image source={{uri: photoUrl}} style={[s.image, {height, width:'100%'}]} />
            </View>
        }   
        <View style={[small ? s.full : s.right, {height}]}>
            {children}
        </View>
    </HorizBox>
}

const PhotoTeaserStyle = StyleSheet.create({
    left: {
        borderTopLeftRadius: 12,
        borderBottomLeftRadius: 12,
        // height: 280,
        // width: 280,
        flex: 1
    },
    full: {
        borderRadius: 12,
        backgroundColor: colorTealBackground,      
        width: '100%'
    },
    right: {
        borderTopRightRadius: 12,
        borderBottomRightRadius: 12,
        backgroundColor: colorTealBackground,
        flex: 2
    },
    image: {
        height: '100%',
        width: '100%',
        borderTopLeftRadius: 12,
        borderBottomLeftRadius: 12,
    },
    container: {
        borderRadius: 12,
    }
});

export function AdminTeaserAction() {
    const instanceKey = useInstanceKey();
    const structureKey = useStructureKey();
    const datastore = useDatastore();
    return <PadBox top={10}>
        <FlowBox rowGap={10} columnGap={10}>
            <CTAButton type='secondary' label='Create a topic'
                onPress={() => datastore.gotoInstanceScreen({ structureKey: 'admin', instanceKey: 'one', screenKey: 'createTopic', params: {} })} />
            <CTAButton type='secondary' label='Select topics for article (admin)'
                onPress={() => datastore.gotoInstanceScreen({ structureKey, instanceKey, screenKey: 'admin', params: {} })} />
            <CTAButton type='secondary' label='Configure Teaser Features (admin)' 
                onPress={() => datastore.gotoInstanceScreen({structureKey, instanceKey, screenKey: 'features', params: {}})} />
        </FlowBox>
    </PadBox>
}


export function TopicChooserAdminScreen() {
    const topics = useGlobalTopics();
    const [search, setSearch] = useState('');
    const localSelectedTopics = useCollection('topic').filter(q => !q.deleted);

    const filteredTopics = topics?.filter(topic => !search || topic.name?.toLowerCase().includes(search.toLowerCase()));
    const selectedTopics = filteredTopics.filter(topic => localSelectedTopics.find(t => t.key == topic.key));
    const otherTopics = filteredTopics.filter(topic => !selectedTopics.find(t => t.key == topic.key));

    return <ConversationScreen>
        <Pad />
        <PadBox horiz={20}><Heading label='Choose Topics for this Article' /></PadBox>
        <Pad />
        {selectedTopics?.map(topic =>
            <Catcher key={topic.key}><Topic topic={topic} /></Catcher>
        )}
        {otherTopics?.map(topic =>
            <Catcher key={topic.key}><Topic topic={topic} /></Catcher>
        )}
    </ConversationScreen>
}

function Topic({ topic }) {
    const datastore = useDatastore();
    const selectedQuestion = useObject('topic', topic.key);
    const isChosen = selectedQuestion && !selectedQuestion.deleted;

    async function onChange(selected) {
        await datastore.callServerAsync('topics', selected ? 'selectTopic' : 'unselectTopic', { topicKey: topic.key })
    }

    return <PadBox horiz={20}>
        <Toggle spread text={topic.name} value={isChosen} onChange={onChange} />
    </PadBox>
}