import { setSSOParentLoginToken } from 'platform-specific/ssoinjection';
import { getIsInPsiNativeAppWebview } from 'platform-specific/url';
import { useEffect } from 'react';
import { callServerApiAsync } from "system/servercall"
import { signInWithTokenAsync } from 'util/firebase'
import { getIsInAndroid, getIsInIFrame, getIsInIOS } from 'util/util'


const useSSOLoginFromParent = (siloKey) => {
    useEffect(() => {
        const iframeMessageHandler = async (event) => {
            if (event?.data?.type === 'psi-sso-parent-login') {
                const { ssoToken, provider } = event?.data ?? {};
                await hydrateSessionTokenFn(ssoToken, provider);
            }
        }

        const hydrateSessionTokenFn = async (ssoToken, provider) => {
            const result = await callServerApiAsync({
                component: 'auth', funcname: 'convertToken', params: {
                    ssoToken, provider, siloKey
                }
            });
            if (result?.loginToken) {
                if (result?.personaPreview?.name) {
                    await signInWithTokenAsync(result.loginToken);
                } else {
                    setSSOParentLoginToken(result.loginToken);
                }
            }
        };

        const hydrateSessionListener = async (event) => {
            const { ssoToken, provider } = event?.detail ?? {};
            await hydrateSessionTokenFn(ssoToken, provider);
        }

        document.addEventListener("nativeMessageEvent", hydrateSessionListener);
        window.addEventListener("message", iframeMessageHandler)

        if (getIsInPsiNativeAppWebview()) {
            if (getIsInAndroid(window)) {
                window.Android?.appReady();
            } else if (getIsInIOS(window)) {
                window.webkit?.messageHandlers?.appReady?.postMessage('');
            }
        } else if (getIsInIFrame(window)) {
            window.parent.postMessage('psi-app-ready', '*');
        }

        return () => {
            document.removeEventListener('nativeMessageEvent', hydrateSessionListener);
            window.removeEventListener('message', iframeMessageHandler);
        };
    }, []);
};

export default useSSOLoginFromParent;