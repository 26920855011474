import { ProfileTitledWriterSetting } from "feature/cbcrc/profile/ProfileTitledWriterFeature";
import { CLICK, INPUT } from "system/demo";

export const profileTitleWriterFeatureDemo = [
    { label: 'Title Writer Settings', key: 'profile-title-writer', storySets: profileTitleWriterSettingsStorySets },
];

function profileTitleWriterSettingsStorySets() {
    return [
        {
            label: 'Set a title',
            instanceKey: 'a',
            personaKey: 'a',
            roles: ['Owner'],
            serverCall: {
                profile: {
                    update: ({ datastore, updates, preview }) => {
                        datastore.updateGlobalProperty('fields', updates);
                        datastore.updateObject('persona', 'a', preview);
                    },
                },
            },
            content: <ProfileTitledWriterSetting />,
            stories: [
                {
                    label: 'Input a title', actions: [
                        INPUT('profile-title', 'Journalist'),
                        CLICK('Is titled writer'),
                        CLICK('Update')
                    ]
                },
            ]
        },
        {
            label: 'Non-admin user',
            content: <ProfileTitledWriterSetting />,
        }
    ]
}