import { HorizBox, Narrow } from "component/basics"
import { Heading, UtilityText } from "component/text"
import { View } from "react-native"
import { useEffect, useState } from "react"
import { DemoHeader } from "system/demo"
import { CircularBar, useCountdown, VideoVotingCountdownElement } from "../../contrib/zdf/countdown"


export function CountdownDemo() {
    const timer = 5;
    const countdown = useCountdown(timer, 1000)
    const countdown2 = useCountdown(timer, 100)

    const [cbText, setCbText] = useState("Countdown running")
    const callback = () => {
        setCbText("Countdown finished!")
    }

    useEffect(() => {

    }, [countdown])

    return (
        <Narrow>
            <View style={{ gap: 12 }}>
                <DemoHeader strong text="Countdown" />
                <UtilityText text="Usage of the useCoundown hook"></UtilityText>
                <Heading text={"Countdown every 1000 ms: " + countdown.count} />
                <Heading text={"Countdown every 100 ms: " + countdown2.count} />
                <HorizBox center>
                    <Heading text={"Countdown with progress bar (interval 1000ms)"} />
                    <CircularBar percentage={(countdown.count * 100) / timer}>
                        <UtilityText text={countdown.count.toString()}></UtilityText>
                    </CircularBar>
                </HorizBox>
                <HorizBox center>
                    <Heading text={"Countdown with progress bar (interval 100ms)"} />
                    <CircularBar percentage={(countdown2.count * 100) / timer}>
                        <UtilityText text={Math.ceil(countdown2.count).toString()}></UtilityText>
                    </CircularBar>
                </HorizBox>
                <HorizBox center>
                    <Heading text={"Video voting countdown element with callback"} />
                    <VideoVotingCountdownElement timer={timer} callback={callback}></VideoVotingCountdownElement>
                    <Heading text={cbText} />
                </HorizBox>
            </View>
        </Narrow>
    );
}