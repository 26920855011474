import { TabBar, TabbedView } from "component/tabs";
import { UtilityText } from "component/text";
import { useState } from "react";

const tabs = [
    {
        label: 'Top',
        key: 'top',
        component: () => <UtilityText label="Top" />
    },
    {
        label: 'All',
        key: 'all',
        component: () => <UtilityText label="All" />
    }
]

function TabBarDemo() {
    const [selectedTabKey, setSelectedTabKey] = useState('top');
    return <TabBar tabs={tabs} selectedTabKey={selectedTabKey} onSelectTab={setSelectedTabKey} />
}

function TabbedViewDemo() {
    const [selectedTabKey, setSelectedTabKey] = useState('top');
    return <TabbedView tabs={tabs} selectedTabKey={selectedTabKey} onSelectTab={setSelectedTabKey} />
}

export function tabStorySets() {return [
    {
        label: 'Tab Bar',
        content: <TabBarDemo />
    },
    {
        label: 'Tabbed View',
        content: <TabbedViewDemo />
    }
]}
