import { Pad } from "component/basics"
import { spacings } from "component/constants"
import { EditorialHeading, TextField } from "component/text"
import { useDatastore, useGlobalProperty } from "util/datastore"
import { useState } from "react";
import { View } from "react-native";
import { CTAButton } from "component/button"
import { Toggle } from "component/form"
import { useIsAdmin } from "component/admin"
import { REPLACE_CBC_Tag } from "component/cbcrc/tag";
import { colorAmbigousBlack, colorSurfaceWarning } from "component/color";

export const ProfileTitledWriterFeature = {
    name: 'Profile titled writer setting',
    key: 'profiletitledwritersetting',
    config: {
        profileWidgets: [ProfileTitledWriterSetting]
    }
}

export function ProfileTitledWriterSetting() {
    const isAdmin = useIsAdmin();
    if (!isAdmin) return null;
    return <View>
        <ProfileTitleEditor />
    </View>
}

export function ProfileTitleEditor() {
    const isAdmin = useIsAdmin();
    const datastore = useDatastore();
    const fields = useGlobalProperty('fields');
    const [title, setTitle] = useState(fields?.title);
    const [isAffiliatedToPSM, setIsAffiliatedToPSM] = useState(fields?.isAffiliatedToPSM);
    const onSave = async () => {
        const updates = { title, isAffiliatedToPSM };
        if (isAdmin) {
            await datastore.callServerAsync('profile', 'update', {
                updates, preview: updates,
                structureKey: 'profile',
                instanceKey: datastore.getPersonaKey(),
            });
        }
    }
    return <View>
        <Pad size={spacings.md} />
        <EditorialHeading level={5} strong text='Title' />
        <Pad size={spacings.md} />
        {fields?.title && <><REPLACE_CBC_Tag color={colorSurfaceWarning} textColor={colorAmbigousBlack} type="tiny" text={fields.title} /> <Pad size={spacings.md} /></>}
        <TextField testID='profile-title' value={title} onChange={(value) => setTitle(value)} placeholder='Title... (ex: journalist)' />
        <Pad size={spacings.md} />
        <Toggle value={isAffiliatedToPSM} onChange={(value) => setIsAffiliatedToPSM(value)} label='Is titled writer' />
        <Pad size={spacings.md} />
        <CTAButton label='Update' onPress={onSave} />
    </View>
}



